import * as React from "react";

export const Svg_003 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <path fill="url(#a)" d="M24.387 0h10.226v5.774H24.387V0Z" />
    <path fill="url(#b)" d="M23.751 17.3H35.25v3.458H23.75V17.3Z" />
    <path
      fill="url(#c)"
      d="M38.071 0v5.774h8.704V53.25h-34.55V5.774h8.704V0H6.462v59h46.076V0H38.07Z"
    />
    <path
      fill="url(#d)"
      d="M15.683 49.792h27.634V9.232H15.683v40.56Zm4.61-35.95h18.413v10.374H20.293V13.842Zm0 20.747h18.413v3.458H20.293V34.59Zm0 6.916h18.413v3.458H20.293v-3.458Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={26.752}
        x2={28.659}
        y1={0.866}
        y2={6.334}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={26.41}
        x2={27.07}
        y1={17.819}
        y2={21.369}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={17.117}
        x2={47.613}
        y1={8.85}
        y2={47.394}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={22.073}
        x2={43.514}
        y1={15.316}
        y2={38.956}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)
