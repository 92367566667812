export const Svg_007 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path fill="url(#b)" d="M0 53.813h59V59H0v-5.186Z" />
      <path fill="url(#c)" d="M43.904 24.459h10.371v25.897h-10.37V24.46Z" />
      <path
        fill="url(#d)"
        d="M27.771 9.133h3.457v3.227H59L29.5.002 0 12.359h27.771V9.134Z"
      />
      <path fill="url(#e)" d="M4.725 24.459h10.37v25.897H4.726V24.46Z" />
      <path fill="url(#f)" d="M24.314 24.459h10.371v25.897h-10.37V24.46Z" />
      <path fill="url(#g)" d="M0 15.816h59v5.186H0v-5.186Z" />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={13.644}
        x2={13.942}
        y1={54.591}
        y2={60.083}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={46.303}
        x2={58.858}
        y1={28.343}
        y2={36.481}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={13.644}
        x2={15.315}
        y1={1.855}
        y2={14.766}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={7.123}
        x2={19.678}
        y1={28.343}
        y2={36.481}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={26.713}
        x2={39.268}
        y1={28.343}
        y2={36.481}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={13.644}
        x2={13.942}
        y1={16.594}
        y2={22.086}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h59v59H0z" />
      </clipPath>
    </defs>
  </svg>
)