import styled, { css } from 'styled-components';
import InputBase from '@mui/material/InputBase';
import { alpha, styled as MuiStyled } from '@mui/material/styles';

interface IContainerProps {
  subLabel?: string;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  .title_wrapper_textfield {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      p {
        margin-right: .5rem;
      }

      span {
        margin-left: -.5rem;
        
      }
      }


    ${({ subLabel }) => subLabel && css`
      label {
        color: #004A80;
        font-size: 18px;
        font-weight: 700;
        line-height: 110%;
        font-style: normal;
        font-family: Roboto;
      }
    `}
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }

  textarea {

    border-radius: 4px;
    background-color: #FFF;
    border: 1px solid;
    border-color: #DEE2E6;
    font-size: 16px;
    width: 100%;
    padding: 16px;

    &::placeholder {
      color: var(--Gray-500, #ADB5BD);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;