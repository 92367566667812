import { persist } from 'zustand/middleware';
import { UseBoundStore, StoreApi, create } from 'zustand';

interface IProps {
  tipo: string;
  planId: string;
  setTipo: (e: string) => void;
  setPlanId: (e: string) => void;
}

// contexto zustand responsável pelo tema dark e light da aplicação
export const useTipeOfFlow: UseBoundStore<StoreApi<IProps>> = create(
  persist(
    (set, get) => ({
      tipo: "",
      planId: "",
      setTipo: (e) => set(() => ({ tipo: e })),
      setPlanId: (e) => set(() => ({ planId: e }))
    }),
    {
      name: 'tipo_fluxo',
    },
  ),
);

