import React from "react";
import _ from 'lodash';

interface IHierarchyPreparationOfPlans {

}

export const HierarchyPreparationOfPlansContext = React.createContext({} as IHierarchyPreparationOfPlans);

interface IHierarchyPreparationOfPlansProps {
  children: React.ReactNode;
}

export const HierarchyPreparationOfPlansProvider: React.FC<IHierarchyPreparationOfPlansProps> = ({ children }) => {
  const traverseTree = (node: any, callback: any) => {
    if (!node || typeof node !== 'object') return;
  
    callback(node);
  
    for (let key in node) {
      if (Array.isArray(node[key])) {
        node[key].map((item: any) => traverseTree(item, callback));
      } else if (typeof node[key] === 'object') {
        traverseTree(node[key], callback);
      }
    }
  }

  const insertItemById = (root: any, targetId: any, newItem: any, layerName: any) => {
    if (!root || typeof root !== 'object' || !targetId || !newItem || !layerName) {
      return;
    }
  
    let idFound = false;
    
    traverseTree(root, (node: any) => {
      if (node.id === targetId && node.relacionamento && node.relacionamento[layerName]) {
        idFound = true;
        node.relacionamento[layerName].items.push(newItem);
      }
    });
  
    if (!idFound) {
      console.log(`ID ${targetId} não encontrado na camada ${layerName}. Verificando a necessidade de criar a estrutura...`);
      let currentNode = root;
      const keys = layerName.split('.');
  
      for (let key of keys) {
        if (!currentNode[key]) {
          currentNode[key] = {};
        }
  
        currentNode = currentNode[key];
      }
  
      if (!currentNode.items) {
        console.log(`Camada ${layerName} está vazia. Criando a estrutura necessária...`);
        currentNode.items = [newItem];
      }
    }
  }

  const deleteItemById = (root: any, targetId: any, layerName: any) => {
    let idDeleted = false;
    traverseTree(root, (node: any) => {
      if (node.relacionamento && node.relacionamento[layerName]) {
        const index = node.relacionamento[layerName].items.findIndex((item: any) => item.id === targetId);
        if (index !== -1) {
          node.relacionamento[layerName].items.splice(index, 1);
          idDeleted = true;
          return true; // Retorna true para interromper a iteração
        }
      }
    });
  
    if (!idDeleted) {
      console.log(`ID ${targetId} não encontrado na camada ${layerName} para deleção.`);
    }
  }
  
  const printJson = (data: any) => {
    console.log(JSON.stringify(data, null, 2));
  }
  
  const data = {
    "eixo_tematico": {
      "items": [
        {
          "id": 121,
          "relacionamento": {
            "estrategia": {
              "items": [
                {
                  "id": 140,
                  "relacionamento": {
                    "objetivos": {
                      "items": [
                        {
                          "id": 180,
                          "relacionamento": {
                            "metas": {
                              "items": []
                            }
                          }
                        }
                      ]
                    }
                  }
                }
              ]
            }
          }
        },
        {
          "id": 120,
          "relacionamento": {
            "aspiracao": {
              "items": [
                {
                  "id": 110,
                  "relacionamento": {
                    "objetivos": {
                      "items": [
                        {
                          "id": 130,
                          "relacionamento": {
                            "metas": {
                              "items": []
                            }
                          }
                        }
                      ]
                    }
                  }
                }
              ]
            }
          }
        }
      ]
    }
  };

  React.useEffect(() => {
    const newItem = {
      "id": 200,
      "relacionamento": {
        "macroacoes": {
          "items": []
        }
      }
    };
    
    insertItemById(data, 180, newItem, "metas");
    // console.log("Após inserção:");
    // printJson(data);
    
    // Testando deleção de um item
    deleteItemById(data, 200, "metas");
    // console.log("Após deleção:");
    // printJson(data);
    
  }, []);

  const value = {
  } as IHierarchyPreparationOfPlans;

  return <HierarchyPreparationOfPlansContext.Provider value={value}>{children}</HierarchyPreparationOfPlansContext.Provider>;
};