import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFormReset,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";

interface IFormThematicAxis {
  isOptionalThematicAxis: string;
  removeThematicAxis: UseFieldArrayRemove;
  fieldsThematicAxis: Record<"id", string>[];
  errosThematicAxis: FieldErrors<InputsThematicAxis>;
  resetThematicAxis: UseFormReset<InputsThematicAxis>;
  watchThematicAxis: UseFormWatch<InputsThematicAxis>;
  controlThematicAxis: Control<InputsThematicAxis, any>;
  setValueThematicAxis: UseFormSetValue<InputsThematicAxis>;
  registerThematicAxis: UseFormRegister<InputsThematicAxis>;
  appendThematicAxis: UseFieldArrayAppend<InputsThematicAxis, "eixo_tematico">;
  handleSubmitThematicAxis: UseFormHandleSubmit<InputsThematicAxis, undefined>;
}

export const ThematicAxisContext = React.createContext({} as IFormThematicAxis);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsThematicAxis = {
  eixo_tematico: any;
};

const defaultValue = {
  id: '',
  texto: '',
}

const SchemaThematicAxis = Yup.object().shape({
  eixo_tematico: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
    }))
}).required('O eixo temático é obrigatória');

export const ThematicAxisContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetThematicAxis,
    watch: watchThematicAxis,
    control: controlThematicAxis,
    register: registerThematicAxis,
    setValue: setValueThematicAxis,
    handleSubmit: handleSubmitThematicAxis,
    formState: { errors: errosThematicAxis } } = useForm<InputsThematicAxis>({
      resolver: yupResolver(SchemaThematicAxis as any) as any,
      defaultValues: { eixo_tematico: [defaultValue] }
    });

  const { fields: fieldsThematicAxis, append: appendThematicAxis, remove: removeThematicAxis } = useFieldArray({
    control: controlThematicAxis,
    name: "eixo_tematico"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans();
  const { GetThematicAxisElaboration } = new ElaborationService();

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))
  //     GetThematicAxisElaboration(router.query.id).then((res: any) => {
  //       if (res.data.length > 0) {
  //         setValueThematicAxis('eixo_tematico', res.data)
  //       } else {
  //         setValueThematicAxis("eixo_tematico", [defaultValue])
  //       }
  //     })
  // }, [router.query, router.pathname, completCode]);

  const value = {
    errosThematicAxis,
    resetThematicAxis,
    watchThematicAxis,
    removeThematicAxis,
    appendThematicAxis,
    fieldsThematicAxis,
    controlThematicAxis,
    setValueThematicAxis,
    registerThematicAxis,
    handleSubmitThematicAxis,
  } as IFormThematicAxis;

  return <ThematicAxisContext.Provider value={value}>{children}</ThematicAxisContext.Provider>;
};