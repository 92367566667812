import React from "react"
import dayjs from "dayjs";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { Grid, Pagination } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA } from "../utils";

type InputsProjects = {
  projetos: any;
  is_optional?: boolean;

}

const SchemaProjects = Yup.object().shape({
  projetos: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      valor: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
    })
  ).required(''),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  texto: '',
  valor: '',
  prazo: null,
  territorio: '',
  description: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformProjectsData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.ptojetosTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.ptojetosTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      valor: item?.valor,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      description: item?.description,
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item?.data ? dayjs(item?.data) : null,
    };
  });
};

const transformProjectsDataReuse = (item: any) => {
  const tor = item?.ptojetosTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.ptojetosTerritorio)

  return {
    tor,
    territorio,
    valor: item?.valor,
    texto: item?.texto || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    description: item?.description,
    publicoAlvo: item?.publicoAlvo || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
  };
};

export const ProjectsForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchProjects,
    control: controlProjects,
    register: registerProjects,
    setValue: setValueProjects,
    handleSubmit: handleSubmitProjects,
    formState: { errors: errosProjects } } = useForm<InputsProjects>({
      resolver: yupResolver(SchemaProjects),
      defaultValues: { projetos: [defaultValue] }
    });

  const { append: appendProjects, update: updateProjects, remove: removeProjects } = useFieldArray({
    control: controlProjects,
    name: "projetos"
  });
  const fields = watchProjects('projetos');
  const isOptional = watchProjects("is_optional") as unknown as string;

  const {
    PostProgressCode,
    ProjectsElaboration,
    GetProjectsElaborations,
    UpdateProjectsElaborations,
    DeleteProjectsElaborations,
    GetThematicAxisElaboration,
    GetProgramsEixoElaborations,
    GetInitiativesEixoElaborations,
    GetMacroactionsEixoElaborations,
  } = new ElaborationService();

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [disable, setDisable] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [projetos, setProjetos] = React.useState<any[]>([]);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [deleteProjectId, setDeleteProjectId] = React.useState(0);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteProjectIndex, setDeleteProjectIndex] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable, completCode } = usePreparationOfPlans();

  const total = projetos.length;


  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitProjects(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  const handleChange = async (event: any, value: any) => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitProjects(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const handleLeftClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  };

  const handleRightClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  };

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.projetos?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  };

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  }

  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  React.useEffect(() => {
    if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);
    if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
      const programas = completCode.find(item => item.code == 'programas' && item.skipped === false);
      const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped === false);
      const iniciativas = completCode.find(item => item.code == 'iniciativas' && item.skipped === false);


      if (zustandSelectedCardItem.title == 'Plano Orçamentário') {
        GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setProjetos(res.data);
          setLabel('Programas');
        }).finally(() => setLoading(false));
      } else {
        if (programas !== undefined && macroacoes != undefined && iniciativas != undefined) {
          GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Programas');
          }).finally(() => setLoading(false));
          return;
        }

        if (programas !== undefined && macroacoes != undefined && iniciativas == undefined) {
          GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Programas');
          }).finally(() => setLoading(false));
          return;
        }


        if (programas !== undefined && macroacoes == undefined && iniciativas != undefined) {
          GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Programas');
          }).finally(() => setLoading(false));
          return;
        }


        if (programas == undefined && macroacoes != undefined && iniciativas == undefined) {
          GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Macroação');
          }).finally(() => setLoading(false));
          return;
        }

        if (programas == undefined && macroacoes == undefined && iniciativas != undefined) {
          GetInitiativesEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Iniciativas');
          }).finally(() => setLoading(false))
        }

        if (programas == undefined && macroacoes != undefined && iniciativas != undefined) {
          GetInitiativesEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
            setProjetos(res.data);
            setLabel('Iniciativas');
          }).finally(() => setLoading(false))
        }
      }

    }
  }, [completCode, router.query, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      const data = projetos[page - 1];
      clearErrors()
      if (data?.projetos?.length > 0) {
        setValueProjects('projetos', transformProjectsData(data.projetos));
      } else {
        setValueProjects('projetos', [defaultValue])
      }
    }
  }, [router.query.id, page, projetos, router.pathname, completCode])

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetProjectsElaborations(planId).then(res => {
        setData(res.data);
      });
    }
  }, [planId, tipoFluxo, router.pathname]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'projetos');

    if (code?.code?.length > 0) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'projetos' && item.skipped === false);

    if (code != undefined) {
      setValueProjects('is_optional', true)
    }
  }, [completCode])

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      });
    };
  }, [router.pathname]);

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router])

  React.useEffect(() => {
    if (zustandSelectedCardItem.title == 'Plano de Gestão' || zustandSelectedCardItem.title == 'Plano Orçamentário') {
      setValueProjects('is_optional', true)
    }
  }, [zustandSelectedCardItem])

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { projetos: projetosData } = data;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let eixo = '';
    if (label === 'Macroação') {
      eixo = 'macroacaoId';
    }

    if (label === 'Programas') {
      eixo = 'programaId';
    }

    if (label === 'Iniciativas') {
      eixo = 'iniciativaId';
    }

    try {
      for (const [index, itemPrograms] of projetosData.entries()) {
        const { tor, id, ...rest } = itemPrograms;

        const macroacao = {
          ...rest,
          [eixo]: projetos[page - 1]?.id,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          data: dayjs(itemPrograms.prazo).format('YYYY-MM-DD') + 'T00:00:00',
          territorio: encontrarCorrespondentes(itemPrograms.tor, filterData.territorio),
        };

        if (id) {
          await UpdateProjectsElaborations({ ...macroacao, prazo: macroacao.data }, id);
        } else {
          await ProjectsElaboration(macroacao, router.query.id);
        }

        await delay(500);
      }

      toggleSuccessSnackbar()

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true)

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false)
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id)
        handleFetchProgressCodePlanId(router.query.id)
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000)
    } catch (error) {
      toggleErrorSnackbar()
    }
    setDisableSubmit(false);

  };

  const Fields = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined) return null;
    if (Boolean(isOptional) == false || isOptional == undefined) return null;
    if (projetos.length == 0) return null;

    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          <form onSubmit={() => { }}>
            {fields.map((field: any, goalIndex: any) => {
              const randomId = Math.random();
              const tor = watchProjects(`projetos.${goalIndex}.tor`);
              const orgao = watchProjects(`projetos.${goalIndex}.orgao`);
              const texto = watchProjects(`projetos.${goalIndex}.texto`);
              const setor = watchProjects(`projetos.${goalIndex}.setor`);
              const valor = watchProjects(`projetos.${goalIndex}.valor`);
              const data = watchProjects(`projetos.${goalIndex}.prazo`);
              const territorio = watchProjects(`projetos.${goalIndex}.territorio`);
              const publicoAlvo = watchProjects(`projetos.${goalIndex}.publicoAlvo`);
              const responsavel = watchProjects(`projetos.${goalIndex}.responsavel`);
              const description = watchProjects(`projetos.${goalIndex}.description`);

              return (
                <>
                  <Grid key={randomId} container spacing={2} sx={{ background: '#DFDFDF', borderRadius: '5px', mt: 2, padding: '0 1rem  0 0' }}>
                    <Grid item xs={12}>
                      <input
                        type="hidden"
                        defaultValue={field?.id}
                        {...registerProjects(`projetos.${goalIndex}.id`, { value: field?.id })}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextFieldElaboration
                        required={true}
                        defaultValue={texto}
                        errors={errosProjects}
                        label="Título do projeto"
                        register={registerProjects}
                        commentTittle={texto}
                        name={`projetos.${goalIndex}.texto`}
                        placeholder="Digite o título do projeto"
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'texto')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <DatePickerElaboration
                        width="90%"
                        label="Prazo"
                        required={true}
                        defaultValue={data}
                        commentTittle={texto}
                        errors={errosProjects}
                        control={controlProjects}
                        name={`projetos.${goalIndex}.prazo`}
                      />
                      <div className="error_wrapper" style={{ marginTop: '1.5rem' }}>
                        <span>{getMessageErrorByNameRef(errosProjects, 'prazo')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldElaboration
                        rows={3}
                        required={true}
                        multiline={true}
                        commentTittle={texto}
                        errors={errosProjects}
                        defaultValue={description}
                        register={registerProjects}
                        label="Descrição do projeto"
                        placeholder="Digite a descrição"
                        name={`projetos.${goalIndex}.description`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'description')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectFieldElaboration
                        label="Órgão"
                        required={true}
                        defaultValue={orgao}
                        commentTittle={texto}
                        errors={errosProjects}
                        placeholder="Selecione"
                        register={registerProjects}
                        name={`projetos.${goalIndex}.orgao`}
                        setValue={(value) => setValueProjects(`projetos.${goalIndex}.orgao`, value)}
                        data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'orgao')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectFieldElaboration
                        label="Setor"
                        required={true}
                        defaultValue={setor}
                        commentTittle={texto}
                        errors={errosProjects}
                        placeholder="Selecione"
                        register={registerProjects}
                        name={`projetos.${goalIndex}.setor`}
                        data={filterSectors(filterData, orgao)}
                        setValue={(value) => setValueProjects(`projetos.${goalIndex}.setor`, value)}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'setor')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        required={true}
                        label="Responsável"
                        errors={errosProjects}
                        commentTittle={texto}
                        defaultValue={responsavel}
                        register={registerProjects}
                        placeholder="Nome do responsável"
                        name={`projetos.${goalIndex}.responsavel`}
                      />
                      <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                        <span>{getMessageErrorByNameRef(errosProjects, 'responsavel')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4} sx={{ mb: 1 }}>
                      <SelectFieldElaboration
                        required={true}
                        label="Território"
                        commentTittle={texto}
                        errors={errosProjects}
                        defaultValue={territorio}
                        register={registerProjects}
                        placeholder="Selecione o território"
                        name={`projetos.${goalIndex}.territorio`}
                        data={[
                          { value: 0, name: '-' },
                          { value: 1, name: 'AP' },
                          { value: 2, name: 'RP' },
                          { value: 3, name: 'RA' },
                          { value: 4, name: 'Bairros' }
                        ]}
                        onClick={() => updateProjects(goalIndex, { ...field, tor: [] })}
                        setValue={(value) => setValueProjects(`projetos.${goalIndex}.territorio`, value)}
                      />
                      <div className="error_wrapper" style={{ marginTop: '.2rem' }}>
                        <span>{getMessageErrorByNameRef(errosProjects, 'territorio')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        required={true}
                        label="Público-alvo"
                        errors={errosProjects}
                        defaultValue={publicoAlvo}
                        register={registerProjects}
                        commentTittle={texto}
                        placeholder="Nome do público-alvo"
                        name={`projetos.${goalIndex}.publicoAlvo`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'publicoAlvo')}</span>
                      </div>
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldElaboration
                        type='number'
                        label="Valor"
                        required={true}
                        defaultValue={valor}
                        errors={errosProjects}
                        register={registerProjects}
                        commentTittle={texto}
                        placeholder="Digite o valor R$"
                        name={`projetos.${goalIndex}.valor`}
                      />
                      <div className="error_wrapper">
                        <span>{getMessageErrorByNameRef(errosProjects, 'valor')}</span>
                      </div>
                    </Grid>
                    {territorio != 0 && (
                      <Grid item xs={12} sx={{ mb: 2 }}>
                        <MultipleSelect
                          fullWidth={true}
                          value={field.tor}
                          setValue={(e) => {
                            const items = removeDuplicatesFromA(tor, e)
                            updateProjects(goalIndex, { ...field, tor: items })
                          }}
                          {...registerProjects(`projetos.${goalIndex}.tor`, { required: false })}
                          data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                        />
                      </Grid>
                    )}
                    {goalIndex != 0 && !preview && (
                      <Grid item xs={12} display='flex' sx={{ mb: 2, justifyContent: 'end', alignItems: 'center' }}>
                        <TrashIcon onClick={() => {
                          setDeleteProjectIndex(goalIndex)
                          setDeleteProjectId(field.id)
                          setOpenDeleteModal(true);
                        }} />
                      </Grid>
                    )}
                    <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={() => {
                      removeProjects(deleteProjectIndex);
                      if (deleteProjectId) {
                        DeleteProjectsElaborations(deleteProjectId);
                        setDeleteProjectId(0)
                      }
                      setOpenDeleteModal(false);
                    }} />
                  </Grid>
                </>
              );
            })}
          </form>
        </Grid>
        <Grid item xs={12}>
          <div className="add_line_wrapper">
            <p onClick={() => appendProjects(defaultValue)}>
              {`+ Adicionar novo projeto`}
            </p>
          </div>
        </Grid>

      </>
    );
  },
    [completCode, isOptional, fields, appendProjects, removeProjects, page, total, filterData, disable, projetos, openDeleteModal, errosProjects])

  const ThematicAxisBanner = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined) return null;
    if (Boolean(isOptional) == false || isOptional == undefined) return null;

    return (
      <>
        <Grid item xs={12} className="title_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className="pagination_wrapper">
            <Pagination
              count={total}
              page={page}
              onChange={handleChange}
              hidePrevButton hideNextButton
              variant="outlined" shape="rounded"
            />
            <p>Página {page} de {total}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          {projetos.length == 0 ? null : (
            <div className="strategy_wrapper">
              <p>
                <b>{label}:</b>&nbsp;
                {projetos[page - 1]?.texto}
              </p>
            </div>
          )}
        </Grid>
      </>
    );
  }, [projetos, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick]);

  const ReplaceButton = React.useCallback(() => {
    if (isOptional == 'false' || isOptional == undefined && (zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orçamentário')) return null;
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, projetos, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, openDisclaimer, reutilizar]);

  const SubmitButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'false' || isOptional == undefined;
      } else {
        return Boolean(isOptional) == false || isOptional == undefined;
      }
    }


    return (
      <Grid item xs={12}>
        <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional={opitional()} isEmpty={projetos.length == 0} onClick={() => {
          if (optionalV2()) {
            setNavigable(true)
            PostProgressCode({ code: 'projetos', skipped: true }, router.query.id)
            router.push('/dashboard/elaboracao_planos/' + router.query.id);

            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            if (plan && plan[selectedItemsMenu?.layer as string]) {
              const planId = plan[selectedItemsMenu?.layer as string]
                .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
              setDefaultItemMenuId(planId);
            }
          }
        }} />
      </Grid>
    );
  }, [isOptional, projetos, disabeSubmit]);

  const RadioButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof isOptional == 'string') {
        return isOptional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(isOptional) == true ? 'true' : 'false'
      }
    }
    return (
      <RadioCheckedElaboration
        required={false}
        name="is_optional"
        disabled={disable}
        errors={errosProjects}
        control={controlProjects}
        defaultValue={opitional()}
        data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
        label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
      />
    )
  }, [isOptional, disable])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitProjects(onSubmit)}>
      <Container>
        <Grid container spacing={1}>
          {zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orçamentário' && (
            <Grid item xs={12}>
              {RadioButton()}
            </Grid>
          )}
          {ReplaceButton()}
          {ThematicAxisBanner()}
          {Fields()}
          <Grid item xs={12} sx={{ mt: 4 }}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {SubmitButton()}
        </Grid>
        <ReutilizarElaborationDialog
          title="Programa"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValueProjects(`projetos.${index}`, transformProjectsDataReuse(item))
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
}
