import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";

interface IFormMacroactions {
  page: number;
  metas: any[];
  label: string;
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removeMacroactions: UseFieldArrayRemove;
  fieldsMacroactions: Record<"id", string>[];
  errosMacroactions: FieldErrors<InputsMacroactions>;
  resetMacroactions: UseFormReset<InputsMacroactions>;
  watchMacroactions: UseFormWatch<InputsMacroactions>;
  transformMacroactionsDataReuse: (data: any[]) => any;
  controlMacroactions: Control<InputsMacroactions, any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setValueMacroactions: UseFormSetValue<InputsMacroactions>;
  registerMacroactions: UseFormRegister<InputsMacroactions>;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  updateMacroactions: UseFieldArrayUpdate<InputsMacroactions, "macroacao">;
  appendMacroactions: UseFieldArrayAppend<InputsMacroactions, "macroacao">;
  handleSubmitMacroactions: UseFormHandleSubmit<InputsMacroactions, undefined>;
}

export const MacroactionsContext = React.createContext({} as IFormMacroactions);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsMacroactions = {
  macroacao: any;
  is_optional?: boolean;
}

const SchemaMacroactions = Yup.object().shape({
  macroacao: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string(),
      responsavel: Yup.string(),
      publicoAlvo: Yup.string()
    })
  ).required(''),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  prazo: null,
  texto: '',
  territorio: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformMacroactionsData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.macroacaoTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);
    
    const territorio = analisarTerritorioArray(item.macroacaoTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

const transformMacroactionsDataReuse = (item: any) => {
  const tor = item?.macroacaoTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.macroacaoTerritorio)

  return {
    tor,
    territorio,
    texto: item.texto || '',
    prazo: item.data ? dayjs(item.data) : null,
    orgao: item.orgao?.id || '',
    setor: item.setor?.id || '',
    publicoAlvo: item.publicoAlvo || '',
    responsavel: item.responsavel || '',
  };
};

export const MacroactionsContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetMacroactions,
    watch: watchMacroactions,
    control: controlMacroactions,
    register: registerMacroactions,
    clearErrors,
    setValue: setValueMacroactions,
    handleSubmit: handleSubmitMacroactions,
    formState: { errors: errosMacroactions } } = useForm<InputsMacroactions>({
      resolver: yupResolver(SchemaMacroactions),
      defaultValues: { macroacao: [defaultValue] }
    });

  const { fields: fieldsMacroactions, append: appendMacroactions, update: updateMacroactions, remove: removeMacroactions } = useFieldArray({
    control: controlMacroactions,
    name: "macroacao"
  });

  const router = useRouter();
  const routerId = router.query.id;
  const { completCode } = usePreparationOfPlans();
  const { GetThematicAxisElaboration, GetGoalsEixoElaborations: GetGoalsElaborations } = new ElaborationService();

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [metas, setMetas] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
  //     GetThematicAxisElaboration(routerId).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos') && eixos_tematicos.length > 0) {
  //    if(eixos_tematicos[currentIndexEixo]){
  //      GetGoalsElaborations(routerId, eixos_tematicos[currentIndexEixo].id).then((res: any) => {
  //        setLabel('Meta')
  //        setMetas(res.data);
  //      });
  //    }
  //   }
  // }, [currentIndexEixo, router.query.id, eixos_tematicos, router.pathname, completCode]);

  // React.useEffect(() => {
  //   const meta = metas[page - 1];
  //   clearErrors()
  //   if (meta?.macroacoesMetas?.length > 0) {
  //     setValueMacroactions('macroacao', transformMacroactionsData(meta.macroacoesMetas))
  //   } else {
  //     setValueMacroactions('macroacao', [defaultValue])
  //   }
  // }, [metas, page, completCode]);

  const value = {
    page,
    metas,
    label,
    setPage,
    eixos_tematicos,
    currentIndexEixo,
    errosMacroactions,
    watchMacroactions,
    resetMacroactions,
    fieldsMacroactions,
    updateMacroactions,
    removeMacroactions,
    appendMacroactions,
    controlMacroactions,
    setCurrentIndexEixo,
    setValueMacroactions,
    registerMacroactions,
    handleSubmitMacroactions,
    transformMacroactionsDataReuse,
  } as IFormMacroactions;

  return <MacroactionsContext.Provider value={value}>{children}</MacroactionsContext.Provider>;
};