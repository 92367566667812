import { ModalContent } from "@/components/aprovacao_planos/partials/TypePlans/styles";
import { Dialog, Switch } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SendPlanosWrapper } from "./styles";
import { PlansService } from "@/services/endpoints/plans";
import { useSnackbar } from "@/hooks/useSnackbar";

export const InsertPlan = () => {
  const router = useRouter();

  const { handleOpenSnackbar } = useSnackbar();
  const { PostEplEnviar } = new PlansService()
  const [epl, setEpl] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [vigente, setVigente] = useState<boolean>(false);
  const [blocked, setblocked] = useState<boolean>(false);
  const [visivel, setVisivel] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false)
  }

  const handleEnviar = () => {

    const params = {
      isRefused: false,
      isVigente: vigente,
      isVisivel: visivel,
      isBlocked: blocked
    }

    PostEplEnviar(params, router.query.id)
      .then(() => {
        handleOpenSnackbar(
          "Plano inserido com sucesso!",
          ""
        )
        handleClose()
        window.location.href ='/dashboard/aprovacao_planos'
      }).catch((err:any) => {
        console.error(err)
      })
  }

  useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl'));
  }, [router])

  if (!epl) return

  return (
    <SendPlanosWrapper>
      <button data-action='insert_plan' onClick={() => setOpen(true)} >
        <p>Inserir plano no sistema</p>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <ModalContent>
          <h3>
            Inserir Plano no sistema
          </h3>
          <div>
            <p>
              Escolha como o plano será configurado em Gestão de Planos. Atente-se que após clicar em "Enviar Plano", essa ação não pode ser desfeita.
            </p>
          </div>
          <div>
            <h4>Vigência do plano:</h4>
            <div>
              <Switch
                checked={vigente}
                onChange={(event) => setVigente(event.target.checked)}
              />
              {vigente ? 'Vigente' : 'Não vigente'}
            </div>
          </div>

          <div>
            <h4>Visualização do plano:</h4>
            <Switch
              checked={visivel}
              onChange={(event) => setVisivel(event.target.checked)}
            />
            {visivel ? 'Visível' : 'Invisível'}
            <span></span>
          </div>

          <div>
            <h4>Monitoramento dos planos:</h4>
            <Switch
              checked={blocked}
              onChange={(event) => setblocked(event.target.checked)}
            />
            {blocked ? 'Trancado para monitoramento' : 'Aberto para monitoramento'}
          </div>
          <div className='button_wrapper'>
            <button
              data-not-shadow
              onClick={() => handleClose()}
              className='cancel'
            >
              Cancelar
            </button>
            <button
              data-not-shadow
              autoFocus
              onClick={() => handleEnviar()}
            >
              Enviar plano
            </button>
          </div>
        </ModalContent>
      </Dialog>
    </SendPlanosWrapper>
  )
}