import React from 'react';
import { Button, Container, GradientContainer, StatusWrapper, Title, WrapperResults } from './card';
import { processString } from '@/utils/processString';
import { PlanoCicloviario } from '@/utils/SvgDictionaries/Icons/plano_cicloviario'

interface IProps {
  item: any;
}

export const CardConsultElaboration: React.FC<IProps> = ({ item }) => {
  const desc = `
  Plano de longo prazo, instituído por lei ou decreto, com período de vigência de 10 anos ou mais, com caráter permanente e sistêmico. Objetiva ordenar o desenvolvimento sustentável e integrado das políticas públicas em atendimento às legislações específicas ou Acordos Nacionais e Internacionais. Sua elaboração envolve o desenvolvimento de estudos técnicos, cenários e tendências que devem nortear a construção das políticas de estado.
  `;


  return (
    <GradientContainer>
    <Container>
      <StatusWrapper status={item?.status}>
        <div className="status_item">
          <p>{item?.status === 1 ? 'Vigente' : 'Não vigente'}</p>
        </div>
      </StatusWrapper>
      <Title len={item?.titulo?.length}>
        <p className="resizeable-accessibility">{item?.titulo}</p>
      </Title>
      <div className='svg_container'>
        {item?.svg}
        <PlanoCicloviario />
      </div>
      <div className="wrapper_content">
        <p className="resizeable-accessibility content">
          {processString(item?.descricao ?? desc, 180)}
        </p>
      </div>
      {item?.hits?.length > 0 && (
        <WrapperResults>
          <p>Busca: <b>{item?.hits?.length} resultados</b></p>
        </WrapperResults>
      )}
      <Button
        className="resizeable-accessibility"
      >
        <h1>Saiba mais</h1>
      </Button>
    </Container>
  </GradientContainer>
  );
};