function ToSort(data: any) {
  return data?.sort(
    (a: any, b: any) =>
      a?.descricao?.localeCompare(b?.descricao) ??
      a?.nome?.localeCompare(b?.nome)
  );
}

function descriptionOrder(array: any[]) {
  return ToSort(array);
}

export const loopArraySort = (obj: any) => {
  const items = obj;

  for (const propriedade in items) {
    if (items?.hasOwnProperty(propriedade)) {
      items[propriedade] = descriptionOrder(items[propriedade]);
    }
  }

  return items;

};
