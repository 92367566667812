import styled from 'styled-components';


export const Container = styled.header`
  width:100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 9999999;

  
`;

export const ContainerLeft = styled.div`
display: flex;
gap: 2rem;
align-items: center;

`
export const ContainerBlue = styled.div`
  width:100%;
  height:100px;
  padding: 24px 44px 24px 44px;
  background: #004A80;
  display: flex;
  justify-content: center;
  @media (max-width: 820px) {
    padding: 24px
  }
`;

export const MainContainer = styled.div`
  width:100%;
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h1`
  
  font-size: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFFFFF;

  @media (max-width: 820px) {
    display: none;
  }
`

export const SubNav = styled.nav`
  width:100%;
  height:91px;
  padding: 24px 44px 24px 44px;
  background: ${props => props.theme.colors.sub_nav};
  display: flex;
  justify-content: center;
  box-shadow: 7px 8px 14px 0px #0000000D;
  @media (max-width: 820px) {
    padding: 24px
  }
`

export const TitleNav = styled.h1`
  
  font-size: 26px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => props.theme.colors.secundary};
  
  @media (max-width: 820px) {
    
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 180px;
  }

`

export const ContainerAcessibilidadeWrraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .box{
    width: 43px;
    height: 43px;
    border-radius: 4px;
    background: #004A80;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    svg path{
        fill: white;
    } 
  }
  .box-clear{
    width: 43px;
    height: 43px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    justify-content: center;
    
  }
`