import lodash from "lodash";
import { removeArticles } from "../removeArticles";

interface IProps {
  newArray: any[];
  isFinalOrder: boolean;
  isAlphaOrder: boolean;
  isInitialOrder: boolean;
}

export const OrderArrayByClassification = ({
  isAlphaOrder,
  isFinalOrder,
  isInitialOrder,
  newArray,
}: IProps) => {

  if (isAlphaOrder && isFinalOrder) {
    const it = newArray?.map((item) => ({
      ...item,
      orderAlpha: removeArticles(item?.titulo),
    }));
    const arr = lodash.orderBy(
      it,
      ["anoInicialVigencia", "orderAlpha"],
      ["asc", "asc"]
    );
    return arr;
  }

  if (!isAlphaOrder && isFinalOrder) {
    const arr = lodash.orderBy(newArray, ["anoInicialVigencia"], ["asc"]);
    return arr;
  }

  if (isAlphaOrder && isInitialOrder) {

    const it = newArray?.map((item) => ({
      ...item,
      orderAlpha: removeArticles(item?.titulo),
    }));
    const arr = lodash.orderBy(
      it,
      ["anoInicialVigencia", "orderAlpha"],
      ["desc", "asc"]
    );
    return arr;
  }

  if (!isAlphaOrder && isInitialOrder) {
    const arr = lodash.orderBy(newArray, ["anoInicialVigencia"], ["desc"]);
    return arr;
  }

  if (!isFinalOrder && !isInitialOrder && isAlphaOrder) {

    const it = newArray?.map((item) => ({
      ...item,
      orderAlpha: removeArticles(item?.titulo),
    }));
    const arr = lodash.orderBy(it, ["orderAlpha"], ["asc"]);
    return arr;
  }

  if ((!isAlphaOrder && !isInitialOrder) || (!isAlphaOrder && !isFinalOrder)) {
    const arr = lodash.orderBy(newArray, ["id"], ["asc"]);
    return arr;
  }

  return newArray;
};
