import _ from 'lodash';
import React from "react";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { SnackbarInterplan } from "@/components/global/Errors/Snackbar";

interface ISnackbarElaboration {
  ErrorSubmitSnackbar: any;
  SuccessSubmitSnackbar: any;
  toggleErrorSnackbar: (text?: string, message?: string) => void;
  toggleSuccessSnackbar: (text?: string, message?: string) => void;
}

export const SnackbarElaboration = React.createContext({} as ISnackbarElaboration);

interface ISnackbarElaborationProps {
  children: React.ReactNode;
}

export const SnackbarElaborationContextProvider: React.FC<ISnackbarElaborationProps> = ({ children }) => {
  const [title, setTitle] = React.useState('');
  const [message, setMessage] = React.useState("");
  const { selectedItemsMenu } = usePreparationOfPlans();
  const [submitError, setSubmitError] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);

  React.useEffect(() => {
    if (selectedItemsMenu) {
      setTitle(selectedItemsMenu.title);
    };
  }, [selectedItemsMenu])

  const toggleSuccessSnackbar = (text?: string, message?: string) => {
    if (text) {
      setTitle(text)
    }

    if (message) {
      setMessage(message)
    }

    setSubmitSuccess(prev => !prev);
  }

  const toggleErrorSnackbar = (text?: string, message?: string) => {
    if (text) {
      setTitle(text)
    }
    
    if (message) {
      setMessage(message)
    }

    setSubmitError(prev => !prev);
  }

  const ErrorSubmitSnackbar = React.useMemo(() => {
    if (!submitError) return null;

    return (
      <SnackbarInterplan
        title={title}
        severity="error"
        open={submitError}
        margin="5rem 0 0 0"
        onClose={() => setSubmitError(false)}
        message={message}
      />
    );
  }, [submitError]);

  const SuccessSubmitSnackbar = React.useMemo(() => {
    if (!submitSuccess) return null;

    return (
      <SnackbarInterplan
        title={title}
        severity="success"
        margin="5rem 0 0 0"
        open={submitSuccess}
        message={message}
        onClose={() => setSubmitSuccess(false)}
      />
    );
  }, [submitSuccess]);

  const value = {
    ErrorSubmitSnackbar,
    toggleErrorSnackbar,
    SuccessSubmitSnackbar,
    toggleSuccessSnackbar,
  } as ISnackbarElaboration;

  return <SnackbarElaboration.Provider value={value}>{children}</SnackbarElaboration.Provider>;
};