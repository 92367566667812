import React from "react";
import { DescriptionContainer } from './styles';

export const DescriptionMissionPurpose = () => {
  return (
    <DescriptionContainer>
      <p>
        <b>Missão:</b> Representa a razão de ser de determinado governo, secretaria de governo, organização, empresa ou órgão público. Descreve a identidade da organização, seu foco de atuação e, se mais detalhada, pode conter o público-alvo, as linhas de produtos/serviços e algumas condições de desempenho. Deve ser sintética, de fácil compreensão e transmitir credibilidade. 
      </p>
      <p><b>Propósito:</b> Explicita o porquê de se fazer algo, acrescentando um aspecto moral que mobiliza os atores envolvidos na geração de benefícios para o público-alvo ou na solução de problemas específicos. Uma boa definição de propósito dá um motivo tangível para que decisões sejam tomadas em prol de um impacto a ser gerado.</p>
    </DescriptionContainer>
  )
}