import { useTheme } from "@/services/zustand/theme";

export const PlanoMetroviario = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 105V100L37.5 95C32.5833 95 28.4375 93.3125 25.0625 89.9375C21.6875 86.5625 20 82.4167 20 77.5V30C20 23.0833 23.2083 18.0208 29.625 14.8125C36.0417 11.6042 46.1667 10 60 10C74.3333 10 84.5833 11.5417 90.75 14.625C96.9167 17.7083 100 22.8333 100 30V77.5C100 82.4167 98.3125 86.5625 94.9375 89.9375C91.5625 93.3125 87.4167 95 82.5 95L90 100V105H30ZM27.5 51.875H92.5V32.5H27.5V51.875ZM82.5 59.375H27.5H92.5H82.5ZM60.0074 80.625C61.9191 80.625 63.5417 79.9559 64.875 78.6176C66.2083 77.2794 66.875 75.6544 66.875 73.7426C66.875 71.8309 66.2059 70.2083 64.8676 68.875C63.5294 67.5417 61.9044 66.875 59.9926 66.875C58.0809 66.875 56.4583 67.5441 55.125 68.8824C53.7917 70.2206 53.125 71.8456 53.125 73.7574C53.125 75.6691 53.7941 77.2917 55.1324 78.625C56.4706 79.9583 58.0956 80.625 60.0074 80.625ZM37.5 88.125H82.5C85.3333 88.125 87.7083 87.0833 89.625 85C91.5417 82.9167 92.5 80.4167 92.5 77.5V59.375H27.5V77.5C27.5 80.4167 28.4583 82.9167 30.375 85C32.2917 87.0833 34.6667 88.125 37.5 88.125ZM60 17.5C50 17.5 42.7917 18.1458 38.375 19.4375C33.9583 20.7292 30.7917 22.5833 28.875 25H91.5C90 22.75 86.8125 20.9375 81.9375 19.5625C77.0625 18.1875 69.75 17.5 60 17.5ZM60 25H91.5H28.875H60Z"
        fill="url(#paint0_linear_1941_17662)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_17662"
          x1="38.5"
          y1="24.25"
          x2="86.6314"
          y2="89.8474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
