import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { ACCESS } from "@/utils/enviroments";
import { DashboardService } from "@/services/endpoints/login";

export interface IAuthContextProps {
  name: string;
  email: string;
  password: string;
  loginError: boolean;
  emailError: boolean;
  isDashboard: boolean;
  userIsAdmin: boolean;
  passwordError: boolean;
  authenticated: boolean;
  dashboardAuth: boolean;
  handleLogin: () => void;
  handleLogout: () => void;
  emailErrorMessage: string;
  setEmail: (e: string) => void;
  setPassword: (e: string) => void;
  setEmailError: (e: boolean) => void;
  handleLoginDashboard: (e: any) => void;
  setLoginError: React.Dispatch<React.SetStateAction<boolean>>;
  setPasswordError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AuthContext = React.createContext({} as IAuthContextProps);

interface IProps {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<IProps> = ({ children }) => {
  const router = useRouter();
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [isDashboard, setIsDashboard] = React.useState(false);
  const [userIsAdmin, setUserIsAdmin] = React.useState(false);
  const [dashboardAuth, setDashboardAuth] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState("");
  const [authenticated, setAuthenticated] = React.useState<boolean>(false);
  const { LoginDashboard, LoginCerberusDashboard } = new DashboardService();

  const handleLogin = () => {
    const API = ACCESS.API_URL
    axios
      // MUDA SUA ENV , NÃO ALTERA AQUI
      // ALTERACAO PARA PEGAR TOKEN E NAO PRECISAR FAZER LOGIN
     // WARNINGGGGGGGGGGGGGGGGGGGGGG
      .post(API+"user/token", {})
      .then(({ data }: any) => {
        
        if (data?.token) {
          Cookies.set("TOKEN_JWT", data?.token);
          Cookies.set("LOGGED_USER", JSON.stringify(data?.info?.usuario));
          setAuthenticated(true);
          // handleETLLogin()
        }
      })
      .catch((err:any) => {
        console.error(err)
      })
  };

  
 

  const handleLoginDashboard = (e: any) => {
    e.preventDefault();
    setEmailError(false);
    setDashboardAuth(false);
    localStorage.removeItem("DASHBOARD_JWT");
    if (password.length === 0) {
      setPasswordError(true);
      return;
    }

    if (email.length === 0) {
      setEmailError(true);
      return;
    }

    const isCerberus = ACCESS.CERBERUS
    console.log(isCerberus, "isCerberus");
    
    if(isCerberus == 'false' || isCerberus == null || isCerberus == undefined) {
    LoginDashboard({ email, password })
      .then((res) => {
        const { token, info } = res.data as any;
        
        localStorage.setItem("DASHBOARD_JWT", JSON.stringify(token));

        setDashboardAuth(true);
        
        const name = info.usuario.nome.split(" ")[0] as string;
        const parseName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

        localStorage.setItem("DASHBOARD_NAME", JSON.stringify(parseName));

        if ([...info?.perfis].includes("ADMINISTRADOR") || [...info?.perfis].includes("ADMINISTRADORES")) {
          localStorage.setItem("DASHBOARD_ADMIN", JSON.stringify(true));
          setUserIsAdmin(true);
        }

        setName(parseName);
        
        router.push("/dashboard");
      })
      .catch((e) => {
        setEmailErrorMessage(e.response.data.message);
        setEmailError(true);
      });
    }

    if(isCerberus == 'true') {
      LoginCerberusDashboard({ email, password })
      .then((res) => {
        const { token, info } = res.data as any;
        
        localStorage.setItem("DASHBOARD_JWT", JSON.stringify(token));

        setDashboardAuth(true);
        
        const name = info.usuario.nome.split(" ")[0] as string;
        const parseName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

        localStorage.setItem("DASHBOARD_NAME", JSON.stringify(parseName));

        if ([...info?.perfis].includes("ADMINISTRADOR") || [...info?.perfis].includes("ADMINISTRADORES")) {
          localStorage.setItem("DASHBOARD_ADMIN", JSON.stringify(true));
          setUserIsAdmin(true);
        }

        setName(parseName);
        
        router.push("/dashboard");
      })
      .catch((e) => {
        setEmailErrorMessage(e.response.data.message);
        setEmailError(true);
      });
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("DASHBOARD_JWT");
    localStorage.removeItem("DASHBOARD_NAME");
    localStorage.removeItem("DASHBOARD_ADMIN");
    router.push("/");
    setDashboardAuth(false);
    setUserIsAdmin(false);
  };

  React.useEffect(() => {
    handleLogin();
  }, []);


  React.useEffect(() => {
    if ( router.pathname.split("/").includes('dashboard')) {
      setIsDashboard(true);
    } else {
      setIsDashboard(false);
    }
  }, [router]);

  React.useEffect(() => {
    const token = localStorage.getItem("DASHBOARD_JWT") as string;
    const jwt = JSON.parse(token);

    const userIsAdmin = JSON.parse(localStorage.getItem("DASHBOARD_ADMIN") as any)
    setUserIsAdmin(userIsAdmin);


    if (jwt) {
      setDashboardAuth(true);
      setName(JSON.parse(localStorage.getItem("DASHBOARD_NAME") as string));

      router.pathname.split("/").find((item) => item !== "dashboard") &&
        router.push("/dashboard");
    }

    if (!jwt) {
      setDashboardAuth(false);
      setUserIsAdmin(JSON.parse(localStorage.getItem("DASHBOARD_ADMIN") as any));
      router.pathname.split("/").find((item) => item === "dashboard") &&
        router.push("/");
    }
  }, []);

  const value = {
    name,
    email,
    setEmail,
    password,
    loginError,
    emailError,
    setPassword,
    userIsAdmin,
    handleLogin,
    isDashboard,
    handleLogout,
    passwordError,
    dashboardAuth,
    setEmailError,
    authenticated,
    setLoginError,
    setPasswordError,
    emailErrorMessage,
    handleLoginDashboard,
  } as IAuthContextProps;

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
