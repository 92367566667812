import { useTheme } from "@/services/zustand/theme";

export const RecursosHidricosEstadual = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M55.8334 41.6667V23.3333H35.8334V15H84.1666V23.3333H64.1666V41.6667H55.8334ZM20 105V65.8334H28.3333V70.8334H45.8334V54.1666H40.8334V45.8334H79.1666V54.1666H74.1666V70.8334H91.6667V65.8334H100V105H91.6667V100H28.3333V105H20ZM28.3333 91.6667H91.6667V79.1666H65.8334V54.1666H54.1666V79.1666H28.3333V91.6667Z"
        fill="url(#paint0_linear_1941_43838)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43838"
          x1="38.5"
          y1="28.5"
          x2="83.3054"
          y2="92.9569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
