import { dictSvgs } from "./svg";
import { parseToSnakeCase } from "../parseToSnakCase";

export function AddSvgToData<T extends { titulo: string }>(data: T[]) {
  return data.map((item) => {
    const titulo = parseToSnakeCase(item?.titulo);
    const svg = dictSvgs[titulo];

    if (svg) {
      return {
        ...item,
        svg,
      };
    }

    return {
      ...item,
    };
  });
}
