import {
  Body,
  BoxLink,
  HomeIcon,
  BoxClicks,
  Container,
  PlanoIcon,
  ContainerMenu,
  CircleFluid,
  BoxClicksV2,
} from "./styles";
import { useMenu } from "@/hooks/useMenu";
import PersonIcon from "../Header/ButtonEntrar/PersonIcon";
import { useAuth } from "@/hooks/useAuth";

export function SideBar() {
  const { open, setOpen } = useMenu();
  const { dashboardAuth } = useAuth();

  const url = dashboardAuth ? "/dashboard" : "/login";

  const handleChangePage = (url_ : any = null) => {
    if(url_){
      window.location.href  = url_
      return
    } 
    window.location.href = url
  }

  return (
    <Container open={open} onClick={() => setOpen(!open)}>
      <ContainerMenu open={open}>
        <Body>
          <a>
            <BoxClicks onClick={() => handleChangePage()} style={{ alignItems: "center", margin: "0 3rem 0 0" }}>
              <PersonIcon />
              <p>Entrar</p>
            </BoxClicks>
          </a>
          <CircleFluid>
            <p>{"<"}</p>
          </CircleFluid>
          <BoxLink>
            <a >
              <BoxClicks onClick={() => handleChangePage('/')}>
                <HomeIcon />
                <p>Home</p>
              </BoxClicks>
            </a>
            <a >
              <BoxClicks onClick={() => handleChangePage('/planos')}>
                <PlanoIcon />
                <p>Planos</p>
              </BoxClicks>
            </a>
            <a>
              <BoxClicksV2 onClick={() => handleChangePage('/mapa_interativo')}>
                <PlanoIcon />
                <p>Mapa interativo</p>
              </BoxClicksV2>
            </a>
          </BoxLink>
        </Body>
      </ContainerMenu>
    </Container>
  );
}
