import { AxiosPromise } from "axios";
import { Api } from "@/services/configs/axios";

interface IPaginationProps {
  page: string;
  sort: string;
}

interface IResearchProps {
  page: string;
  sort: string;
  palavra: string;
}

interface IUpdatePlansProps {
  visivel?: 0 | 1;
  status?: 0 | 1 | 2;
}

interface IDesactiveProps {
  ids: Array<number>;
}

export class DashboardManagementPlansService {
  desactivePlans = (data: IDesactiveProps): AxiosPromise<any> =>
    Api.post("plano/desactive", data);
  paginationPlans = (data: IPaginationProps): AxiosPromise<any[]> =>
    Api.get(`plano/gestao-planos`, {
      params: data,
    });
  searchPlans = (data: IResearchProps): AxiosPromise<any> =>
    Api.get("plano/search", { params: data });
  updatePlans = (data: any, id: number): AxiosPromise<any> =>
    Api.post(`plano/update/${id}`, data);

    getDinamicTable = (data: any, ): AxiosPromise<any> =>
    Api.post(`dashboard/json`, data);

    getMapDelivery = (data: any): AxiosPromise<any> =>
    Api.post(`entregas`, data);
    getMapDeliveryParams = (params: number): AxiosPromise<any> => 
    Api.get('entregas/' + params);
    getAllOrgans = (): AxiosPromise<any> => Api.get('orgao-responsavel');
    getAllThemes = (): AxiosPromise<any> => Api.get('tema');
    createNewThemes = (data: any): AxiosPromise<any> => Api.post('tema', data)
    getAllFilters = (): AxiosPromise<any> => Api.get('dashboard/filtros')
    getAllFiltersParams = (id: any): AxiosPromise<any> => Api.get('dashboard/filtros?orgao=' + id)
}
