import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";

interface IFormScenario {
  removeScenario: UseFieldArrayRemove;
  fieldsScenario: Record<"id", string>[];
  errosScenario: FieldErrors<InputsScenario>;
  resetScenario: UseFormReset<InputsScenario>;
  watchScenario: UseFormWatch<InputsScenario>;
  controlScenario: Control<InputsScenario, any>;
  setValueScenario: UseFormSetValue<InputsScenario>;
  registerScenario: UseFormRegister<InputsScenario>;
  appendScenario: UseFieldArrayAppend<InputsScenario, "texto">;
  handleSubmitScenario: UseFormHandleSubmit<InputsScenario, undefined>;
}

export const ScenarioContext = React.createContext({} as IFormScenario);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsScenario = {
  texto: any;
  is_optional?: boolean;
  is_complete_pdf?: boolean;
};

const defaultValue = {
  id: '',
  texto: ''
}

const SchemaScenario = Yup.object().shape({
  is_optional: Yup.boolean(),
  is_complete_pdf: Yup.boolean(),
  texto: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
    })
  )
}).required('A síntese é obrigatória');

export const ScenarioContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetScenario,
    watch: watchScenario,
    control: controlScenario,
    register: registerScenario,
    setValue: setValueScenario,
    handleSubmit: handleSubmitScenario,
    formState: { errors: errosScenario } } = useForm<InputsScenario>({
      resolver: yupResolver(SchemaScenario as any) as any,
      defaultValues: { texto: [defaultValue] }
    });

  const { fields: fieldsScenario, append: appendScenario, remove: removeScenario } = useFieldArray({
    control: controlScenario,
    name: "texto"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans()
  const { GetSummaryScenarioElaboration } = new ElaborationService();


  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
  //     GetSummaryScenarioElaboration(Number(router.query.id)).then((res: any) => {

  //       const result = res.data.map((item: any) => {
  //         return {
  //           ...item,
  //           texto: item.texto.replace("descricao: ", "")
  //         };
  //       });

  //       if (result.length > 0) {
  //         setValueScenario('texto', result);
  //       } else {
  //         setValueScenario('texto', [defaultValue]);
  //       }
  //     })
  //   }

  // }, [router.query, router.pathname, completCode])

  const value = {
    errosScenario,
    resetScenario,
    removeScenario,
    fieldsScenario,
    appendScenario,
    watchScenario,
    controlScenario,
    setValueScenario,
    registerScenario,
    handleSubmitScenario,
  } as IFormScenario;

  return <ScenarioContext.Provider value={value}>{children}</ScenarioContext.Provider>;
};