import { useTheme } from "@/services/zustand/theme";

export const EstrategicoPublicaSaude = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M53.75 83.75H66.25V71.25H78.75V58.75H66.25V46.25H53.75V58.75H41.25V71.25H53.75V83.75ZM20 105V45L60 15L100 45V105H20ZM27.5 97.5H92.5V48.25L60 24.625L27.5 48.25V97.5Z"
        fill="url(#paint0_linear_2012_91962)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91962"
          x1="38.5"
          y1="28.5"
          x2="83.3054"
          y2="92.9569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
