import { useTheme } from "@/services/zustand/theme";

export const DiretorTransportePDTU = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2012_91978)">
        <path
          d="M56.25 18.75V0H63.75V18.75H56.25ZM32.875 25.375L19 11.375L24.25 6L38.25 20L32.875 25.375ZM18.75 115C17.6875 115 16.7969 114.641 16.0781 113.922C15.3594 113.203 15 112.312 15 111.25V70.75L25.5 40.1686C26 38.6395 26.8958 37.3958 28.1875 36.4375C29.4792 35.4792 30.9583 35 32.625 35H46.875V25.625H66C64.0833 28.0417 62.5208 30.6667 61.3125 33.5C60.1042 36.3333 59.3333 39.3333 59 42.5H32.25L24.75 64.5H65.125C66.2083 65.9167 67.4167 67.2708 68.75 68.5625C70.0833 69.8542 71.5 71 73 72H22.5V97H97.5V76.625C98.8333 76.2917 100.121 75.9107 101.364 75.4821C102.607 75.0535 103.819 74.5178 105 73.875V111.25C105 112.312 104.641 113.203 103.922 113.922C103.203 114.641 102.312 115 101.25 115H98.625C97.5625 115 96.6719 114.641 95.9531 113.922C95.2344 113.203 94.875 112.312 94.875 111.25V104.5H25V111.25C25 112.312 24.6406 113.203 23.9219 113.922C23.2031 114.641 22.3125 115 21.25 115H18.75ZM31.25 88.25H46.25C47.3333 88.25 48.2292 87.8885 48.9375 87.1656C49.6458 86.4426 50 85.5468 50 84.4781C50 83.4094 49.6406 82.5208 48.9219 81.8125C48.2031 81.1042 47.3125 80.75 46.25 80.75H31.25V88.25ZM88.75 88.25V80.75H73.75C72.6667 80.75 71.7708 81.1115 71.0625 81.8344C70.3542 82.5574 70 83.4532 70 84.5219C70 85.5906 70.3594 86.4792 71.0781 87.1875C71.7969 87.8958 72.6875 88.25 73.75 88.25H88.75ZM87.25 56.25L104.875 38.5L101.375 35L87.25 49.25L79.875 41.75L76.375 45.375L87.25 56.25ZM90.625 21.375C97.3342 21.375 103.053 23.7394 107.782 28.4681C112.511 33.1969 114.875 38.9158 114.875 45.625C114.875 52.3342 112.511 58.0531 107.782 62.7819C103.053 67.5106 97.3342 69.875 90.625 69.875C83.9158 69.875 78.1969 67.5106 73.4681 62.7819C68.7394 58.0531 66.375 52.3342 66.375 45.625C66.375 38.9158 68.7394 33.1969 73.4681 28.4681C78.1969 23.7394 83.9158 21.375 90.625 21.375Z"
          fill="url(#paint0_linear_2012_91978)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2012_91978"
          x1="38.0961"
          y1="17.25"
          x2="95.799"
          y2="98.3551"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
        <clipPath id="clip0_2012_91978">
          <rect width="120" height="120" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
