import * as React from 'react';
import { topFilms } from './constant';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { AnySoaRecord } from 'dns';

interface Film {
  title: string;
  year: number;
}

function sleep(duration: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

interface IProps {
  label: string;
  items?: any[];
  setItem?: any;
  handleSearch?: any;
  searchLoading?: any;
  name?: string;
  defaultValue?: any;
  onChangeValue?: any;
  disabled?: any,
}

export function Asynchronous({ label = 'Escolha um plano', disabled = false, items = [], setItem, handleSearch, onChangeValue, searchLoading = false, defaultValue, name, ...props }: IProps) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly Film[]>([]);
  const loading = open && options && options.length === 0 && !searchLoading;

  React.useEffect(() => {
    if (searchLoading) {
      setOptions([]);
    }
  }, [searchLoading])

  React.useEffect(() => {
    let active = true;

    handleLoading()

    return () => {
      active = false;
    };
  }, [loading, items]);

  const handleLoading = () => {

    if (!loading) {
      return undefined;
    }
    setOptions([...items]);
  }

  const searchFunc = (value: any) => {
    if (handleSearch) {
      handleSearch(value)
    }
  }

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: '100%' }}
      open={open}
      onInputChange={(event, newInputValue) => {
        setItem && setItem(newInputValue);
      }}
      onChange={(event: any, newValue: string | null) => {
        onChangeValue && onChangeValue(newValue);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.title === value.title}
      getOptionLabel={(option) => option.title}
      options={options}
      loading={loading}
      defaultValue={defaultValue}
      readOnly={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          value={true}
          disabled={disabled}
          onChange={(event) => searchFunc(event.target.value)}
          placeholder={label}
          {...props}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}