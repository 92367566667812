import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { getTransformToDiagnosisLayer, transformDiagnosisLayer } from "@/utils/elaborationParseData";

interface IFormDiagnosis {
  SchemaDiagnosis: any;
  removeDiagnosis: UseFieldArrayRemove;
  errosDiagnosis: FieldErrors<InputsDiagnosis>;
  watchDiagnosis: UseFormWatch<InputsDiagnosis>;
  controlDiagnosis: Control<InputsDiagnosis, any>;
  registerDiagnosis: UseFormRegister<InputsDiagnosis>;
  setValueDiagnosis: UseFormSetValue<InputsDiagnosis>;
  updateDiagnosis: UseFieldArrayUpdate<InputsDiagnosis, "sintese">;
  handleSubmitDiagnosis: UseFormHandleSubmit<InputsDiagnosis, undefined>;
}

export const DiagnosisContext = React.createContext({} as IFormDiagnosis);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsDiagnosis = {
  sintese: any
  is_optional?: boolean;
  is_complete_pdf?: boolean;
};

const defaultValue = {
  diagnostico: '',
  forcas: [{ id: '', texto: '' }],
  ameacas: [{ id: '', texto: '' }],
  fraquezas: [{ id: '', texto: '' }],
  oportunidades: [{ id: '', texto: '' }],
}

const SchemaDiagnosis = Yup.object().shape({
  is_complete_pdf: Yup.boolean(),
  sintese: Yup.array().of(
    Yup.object().shape({
      diagnostico: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string().required('Campo obrigatório'),
        })
      ),
      forcas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string(),
        })
      ),
      ameacas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string().required('Campo obrigatório'),
        })
      ),
      fraquezas: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string().required('Campo obrigatório'),
        })
      ),
      oportunidades: Yup.array().of(
        Yup.object().shape({
          texto: Yup.string().required('Campo obrigatório'),
        })
      ),
    })
  ).required('A síntese é obrigatória')
}).required('O objeto de diagnóstico é obrigatório');

  export const DiagnosisProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
    const {
      watch: watchDiagnosis,
      control: controlDiagnosis,
      register: registerDiagnosis,
      setValue: setValueDiagnosis,
      handleSubmit: handleSubmitDiagnosis,
      formState: { errors: errosDiagnosis } } = useForm<InputsDiagnosis>({
        resolver: yupResolver(SchemaDiagnosis) as any,
        defaultValues: { sintese: [defaultValue] }
      });

    const { update: updateDiagnosis, remove: removeDiagnosis } = useFieldArray({
      control: controlDiagnosis,
      name: "sintese"
    });

    const router = useRouter();
    const { completCode } = usePreparationOfPlans();
    const { GetSummaryDiagnosisElaboration } = new ElaborationService();

    // React.useEffect(() => {
    //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
    //     GetSummaryDiagnosisElaboration(Number(router.query.id)).then((res: any) => {
    //       const result = transformDiagnosisLayer(res?.data);
    //       if (Object.keys(result).length > 0) {
    //         setValueDiagnosis("sintese", [result])
    //       } else {
    //         setValueDiagnosis('sintese', [defaultValue])
    //       }
    //     })
    //   }
    // }, [router.query.id, router.pathname, completCode])

    const value = {
      errosDiagnosis,
      watchDiagnosis,
      updateDiagnosis,
      controlDiagnosis,
      SchemaDiagnosis,
      registerDiagnosis,
      setValueDiagnosis,
      removeDiagnosis,
      handleSubmitDiagnosis,
    } as IFormDiagnosis;

    return <DiagnosisContext.Provider value={value}>{children}</DiagnosisContext.Provider>;
  };