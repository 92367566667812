import styled from "styled-components";
import HomeI from "../../../../public/header/home.svg";
import PlanoI from "../../../../public/header/planos.svg";
interface IpropsContainer {
  open?: boolean;
}

export const HomeIcon = styled(HomeI)``;
export const PlanoIcon = styled(PlanoI)``;

export const CircleFluid = styled.div`
  position: absolute;
  width: 25px;
  height: 24px;
  right: -15px;
  padding: 8px;
  background-color: white;
  top: 95px;
  border-radius: 18.26px;
  gap: 7.61px;
  box-shadow: 0px 4px 4px 0px #00000040;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  p {
    font-size: 19px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: #004a80;
  }
`;
export const Container = styled.div<IpropsContainer>`
  width: ${(props) => (props.open ? "100%" : "0")};
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => (props.open ? "0.5" : "0")});
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  transition: background-color 0.3s;
`;

export const ContainerMenu = styled.div<IpropsContainer>`
  margin-top: 100px;
  box-shadow: 8px 7px 16px 0px #00000029;
  width: 250px;
  height: 95%;
  position: fixed;
  background: #fefefe;
  display: ${(props) => (props.open ? "flex" : "none")};
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 15%;

  a {
    text-decoration: none;
  }
`;

export const BoxClicks = styled.div`
  width: 158.29px;
  height: 48.35px;
  top: 88.3515625px;
  left: 20px;
  padding: 12.175999641418457px 15.219999313354492px 12.175999641418457px
    15.219999313354492px;
  border-radius: 12px;
  display: flex;
  &:hover {
    background: #d2effa;
  }
  p {
    margin-left: 19px;
    color: #004a80;
  }
`;

export const BoxLink = styled.div`
  border-top: 1px solid #d2effa;
  padding: 15% 0;
  margin-top: 10%;
  width: 80%;
`;


export const BoxClicksV2 = styled.div`
  width: auto;
  height: 48.35px;
  top: 88.3515625px;
  left: 20px;
  padding: 12.175999641418457px 15.219999313354492px 12.175999641418457px
    15.219999313354492px;
  border-radius: 12px;
  display: flex;
  justify-content: start;
  align-items: center;
  &:hover {
    background: #d2effa;
  }
  p {
    margin-left: 19px;
    color: #004a80;
  }
`;
