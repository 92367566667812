import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    background: #fff; 
    height: 40px;
  }

  .title_wrapper_datepicker {
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    label {
      p {
        margin-right: .5rem;
      }

      span {
        margin-left: -.5rem;
      }
    }
  }

  .error_wrapper {
    width: 100%;
    margin-top: 1.5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }
`;