import styled from "styled-components";

export const PaginationWrapper = styled.div`
  
  width: 100%;
  display:grid;
  place-items:center;
  margin-block: 40px 20px;

  & button{
    font-size: 20px;
  }
`;


export const PaginationBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  p{
    font-size: 14px ;

    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }

  h2{
    font-size: 16px;
  }
`;
