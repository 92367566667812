import { useTheme } from "@/services/zustand/theme";

export const EstrategicoPlanetario = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.0332 110C53.1387 110 46.6596 108.688 40.5957 106.062C34.5319 103.438 29.2292 99.8542 24.6875 95.3125C20.1458 90.7708 16.5625 85.465 13.9375 79.3949C11.3125 73.3249 10 66.8391 10 59.9375C10 53.0359 11.3125 46.5501 13.9375 40.4801C16.5625 34.41 20.1458 29.125 24.6875 24.625C29.2292 20.125 34.535 16.5625 40.6051 13.9375C46.6751 11.3125 53.1609 10 60.0625 10C66.9641 10 73.4499 11.3125 79.5199 13.9375C85.59 16.5625 90.875 20.125 95.375 24.625C99.875 29.125 103.438 34.4167 106.062 40.5C108.688 46.5833 110 53.0723 110 59.9668C110 66.8613 108.688 73.3404 106.062 79.4043C103.438 85.4681 99.875 90.7632 95.375 95.2895C90.875 99.8158 85.5833 103.399 79.5 106.039C73.4167 108.68 66.9277 110 60.0332 110ZM60 102.5C71.8646 102.5 81.9141 98.3542 90.1485 90.0625C98.3828 81.7708 102.5 71.75 102.5 60V58.375C102 60.5417 100.858 62.3542 99.0744 63.8125C97.2905 65.2708 95.1824 66 92.75 66H82.75C80 66 77.6458 65.0208 75.6875 63.0625C73.7292 61.1042 72.75 58.75 72.75 56V51H52.75V41C52.75 38.25 53.7292 35.8958 55.6875 33.9375C57.6458 31.9792 60 31 62.75 31H67.75V28.25C67.75 26.9167 68.3125 25.25 69.4375 23.25C70.5625 21.25 71.8333 20.0417 73.25 19.625C71.1667 18.9583 69.0268 18.4375 66.8304 18.0625C64.634 17.6875 62.3572 17.5 60 17.5C48.25 17.5 38.2292 21.6172 29.9375 29.8515C21.6458 38.0859 17.5 48.1354 17.5 60H36.25C41.75 60 46.4583 61.9583 50.375 65.875C54.2917 69.7917 56.25 74.5 56.25 80V85H41.25V98.125C44.0833 99.5417 47.0708 100.625 50.2125 101.375C53.3542 102.125 56.6167 102.5 60 102.5Z"
        fill="url(#paint0_linear_1941_43865)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43865"
          x1="33.125"
          y1="25"
          x2="80.625"
          y2="101.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
