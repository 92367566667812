export const ConsideracaoIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_6_40854)">
        <path d="M0.125007 8C0.125007 3.65075 3.65076 0.125 8.00001 0.125C12.3493 0.125 15.875 3.65075 15.875 8C15.875 12.3492 12.3493 15.875 8.00001 15.875C6.66801 15.875 5.41138 15.5437 4.31001 14.9587L1.20051 15.8429C1.05599 15.8839 0.903156 15.8855 0.757792 15.8477C0.612427 15.8098 0.479803 15.7339 0.373616 15.6276C0.267429 15.5214 0.191531 15.3887 0.153759 15.2433C0.115988 15.098 0.117713 14.9451 0.158757 14.8006L1.04301 11.6917C0.438622 10.5551 0.123344 9.28726 0.125007 8ZM4.62501 6.3125C4.62501 6.46168 4.68427 6.60476 4.78976 6.71025C4.89525 6.81574 5.03832 6.875 5.18751 6.875H10.8125C10.9617 6.875 11.1048 6.81574 11.2103 6.71025C11.3157 6.60476 11.375 6.46168 11.375 6.3125C11.375 6.16332 11.3157 6.02024 11.2103 5.91475C11.1048 5.80926 10.9617 5.75 10.8125 5.75H5.18751C5.03832 5.75 4.89525 5.80926 4.78976 5.91475C4.68427 6.02024 4.62501 6.16332 4.62501 6.3125ZM5.18751 9.125C5.03832 9.125 4.89525 9.18426 4.78976 9.28975C4.68427 9.39524 4.62501 9.53832 4.62501 9.6875C4.62501 9.83668 4.68427 9.97976 4.78976 10.0852C4.89525 10.1907 5.03832 10.25 5.18751 10.25H8.56251C8.71169 10.25 8.85476 10.1907 8.96025 10.0852C9.06574 9.97976 9.12501 9.83668 9.12501 9.6875C9.12501 9.53832 9.06574 9.39524 8.96025 9.28975C8.85476 9.18426 8.71169 9.125 8.56251 9.125H5.18751Z" fill="#004A80" />
      </g>
      <defs>
        <clipPath id="clip0_6_40854">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}