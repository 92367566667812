import {
  Dialog,
  Checkbox,
  TextField,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CancelButton, ContinueButton, Container, ContentConsultElaborationWrapper, ContentConsultItemWrapper } from './styles.modal';

interface IProps {
  title: string;
  open: boolean;
  reuseData?: any[];
  setValue?: (e: any) => void;
  setOpen: (value: boolean) => void;
}

export const ReutilizarElaborationDialog: React.FC<IProps> = ({
  open,
  title,
  setOpen,
  setValue,
  reuseData,
}) => {
  const [data, setData] = React.useState<any[]>([]);

  const handleClose = () => {
    setOpen(false);
    setData(prev => prev.map(item => ({ ...item, checked: false })))
  };

  React.useEffect(() => {
    if (reuseData) {
      setData(reuseData);
    }
  }, [reuseData]);

  const Fields = React.useCallback(() => {
    if (data.length == 0) return null;

    return (
      <>
        {data?.map((item, index: any) => {
          if (title == 'Metas') {
            return (
              <>
                {item.metas.map((subItem: any) => (
                  <div className='content'>
                    <div className='checkbox_wrapper'>
                      <Checkbox color='info' onChange={(e) => {
                        setData(prev => {
                          const arr = [...prev];
                          arr[index]['checked'] = e.target.checked
                          return arr;
                        })
                      }} />
                    </div>
                    <span>
                      {subItem.texto}
                    </span>
                  </div>
                ))}
              </>
            );
          }

          return (
            <div className='content'>
              <div className='checkbox_wrapper'>
                <Checkbox color='info' onChange={(e) => {
                  setData(prev => {
                    const arr = [...prev];
                    arr[index]['checked'] = e.target.checked
                    return arr;
                  })
                }} />
              </div>
              <span>
                {title == 'Visão' && item.texto}
                {title == 'Valores' && item.texto}
                {title == 'Produtos' && item.texto}
                {title == 'Programa' && item.texto}
                {title == 'Objetivos' && item.texto}
                {title == 'Macroações' && item.texto}
                {title == 'Pré cadastro' && item.titulo}
                {title == 'Eixo Temático' && item.texto}
                {title == 'Síntese do cenário' && item.texto}
                {title == 'Síntese do diagnóstico' && item['diagnostico'][0]?.texto}
              </span>
            </div>
          );
        })}
      </>
    );
  }, [data, title])

  return (
    <>
      <Container>
        <Dialog
          open={open}
          sx={{ mt: 10 }}
          maxWidth='md'
          onClose={handleClose}
        >
          <div style={{ padding: '0 27px', width: '875px' }}>
            <DialogTitle>
              Reutilizar conteúdo
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#004A80'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ mt: -4 }}>
              <p className='sub_title'>Escolha o conteúdo que deseja reutilizar e edite-o na página de preenchimento.</p>
              <ContentConsultElaborationWrapper>
                <p style={{ padding: '.5rem 0' }}>
                  {title}
                </p>
                <ContentConsultItemWrapper>
                  <div className='input_wrapper'>
                    <span style={{ marginBottom: '1rem' }}>Pesquisar</span>
                    <div className='input_component'>
                      <TextField sx={{ width: '100%' }} label="Digite algo para pesquisar" variant="outlined" />
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.6245 27.7083L23.1083 20.1921C24.4978 18.0803 25.0544 15.528 24.6702 13.0294C24.286 10.5308 22.9883 8.26365 21.0285 6.66686C19.0687 5.07007 16.5862 4.25724 14.0615 4.38575C11.5368 4.51426 9.14957 5.57497 7.36203 7.3625C5.57449 9.15004 4.51379 11.5372 4.38528 14.0619C4.25677 16.5866 5.06959 19.0692 6.66639 21.029C8.26318 22.9888 10.5303 24.2864 13.0289 24.6706C15.5275 25.0548 18.0798 24.4983 20.1916 23.1088L27.7079 30.625L30.6245 27.7083ZM7.2912 14.5833C7.2912 10.5627 10.5622 7.29167 14.5829 7.29167C18.6035 7.29167 21.8745 10.5627 21.8745 14.5833C21.8745 18.604 18.6035 21.875 14.5829 21.875C10.5622 21.875 7.2912 18.604 7.2912 14.5833Z" fill="#004A80" />
                      </svg>
                    </div>
                  </div>
                  <div className='content_wrapper'>
                    {Fields()}
                  </div>
                  <div className='disclaimer_wrapper'>
                    <span>{data.filter(item => item.checked == true).length} itens selecionados</span>
                    {/* <span>Exibindo {data.length} de {data.length} elementos</span> */}
                  </div>
                  {/* <div className='pagination_wrapper'>
                    <Pagination count={data.length} variant="outlined" shape="rounded" hidePrevButton hideNextButton />
                  </div> */}
                </ContentConsultItemWrapper>
              </ContentConsultElaborationWrapper>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
              <CancelButton onClick={handleClose}>
                <p>Cancelar</p>
              </CancelButton>
              <ContinueButton type='button' onClick={() => {
                const filterData = data.filter(item => item.checked == true);
                if (setValue) {
                  setValue(filterData);
                  handleClose();
                };
              }}>
                <p>Salvar</p>
              </ContinueButton>
            </DialogActions>
          </div>
        </Dialog>
      </Container>
    </>
  );
};

export const ReutilizarElaborationDialogV2 = React.forwardRef(({ open, setOpen, title, reuseData, setValue }: IProps, ref) => {
  const [data, setData] = React.useState<any[]>([]);

  const handleClose = () => {
    setOpen(false);
    setData(prev => prev.map(item => ({ ...item, checked: false })))
  };

  React.useEffect(() => {
    if (reuseData) {
      setData(reuseData);
    }
  }, [reuseData]);

  React.useImperativeHandle(ref, () => {
    return {
      reuseLen: data.length,
    };
  }, [data, reuseData]);

  const Fields = React.useCallback(() => {
    if (data.length == 0) return null;

    return (
      <>
        {data?.map((item, index: any) => {
          if (title == 'Metas') {
            return (
              <>
                {item.metas.map((subItem: any) => (
                  <div className='content'>
                    <div className='checkbox_wrapper'>
                      <Checkbox color='info' onChange={(e) => {
                        setData(prev => {
                          const arr = [...prev];
                          arr[index]['checked'] = e.target.checked
                          return arr;
                        })
                      }} />
                    </div>
                    <span>
                      {subItem.texto}
                    </span>
                  </div>
                ))}
              </>
            );
          }

          return (
            <div className='content'>
              <div className='checkbox_wrapper'>
                <Checkbox color='info' onChange={(e) => {
                  setData(prev => {
                    const arr = [...prev];
                    arr[index]['checked'] = e.target.checked
                    return arr;
                  })
                }} />
              </div>
              <span>
                {title == 'Visão' && item.texto}
                {title == 'Valores' && item.texto}
                {title == 'Produtos' && item.texto}
                {title == 'Programa' && item.texto}
                {title == 'Diretriz' && item.texto}
                {title == 'Objetivos' && item.texto}
                {title == 'Aspiração' && item.texto}
                {title == 'Estrategia' && item.texto}
                {title == 'Macroações' && item.texto}
                {title == 'Pré cadastro' && item.titulo}
                {title == 'Eixo Temático' && item.texto}
                {title == 'Síntese do cenário' && item.texto}
                {title == 'Síntese do diagnóstico' && item['diagnostico'][0]?.texto}
              </span>
            </div>
          );
        })}
      </>
    );
  }, [data, title])

  return (
    <>
      <Container>
        <Dialog
          open={open}
          sx={{ mt: 10 }}
          maxWidth='md'
          onClose={handleClose}
        >
          <div style={{ padding: '0 27px', width: '875px' }}>
            <DialogTitle>
              Reutilizar conteúdo
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#004A80'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ mt: -4 }}>
              <p className='sub_title'>Escolha o conteúdo que deseja reutilizar e edite-o na página de preenchimento.</p>
              <ContentConsultElaborationWrapper>
                <p style={{ padding: '.5rem 0' }}>
                  {title}
                </p>
                <ContentConsultItemWrapper>
                  <div className='input_wrapper'>
                    <span style={{ marginBottom: '1rem' }}>Pesquisar</span>
                    <div className='input_component'>
                      <TextField sx={{ width: '100%' }} label="Digite algo para pesquisar" variant="outlined" />
                      <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M30.6245 27.7083L23.1083 20.1921C24.4978 18.0803 25.0544 15.528 24.6702 13.0294C24.286 10.5308 22.9883 8.26365 21.0285 6.66686C19.0687 5.07007 16.5862 4.25724 14.0615 4.38575C11.5368 4.51426 9.14957 5.57497 7.36203 7.3625C5.57449 9.15004 4.51379 11.5372 4.38528 14.0619C4.25677 16.5866 5.06959 19.0692 6.66639 21.029C8.26318 22.9888 10.5303 24.2864 13.0289 24.6706C15.5275 25.0548 18.0798 24.4983 20.1916 23.1088L27.7079 30.625L30.6245 27.7083ZM7.2912 14.5833C7.2912 10.5627 10.5622 7.29167 14.5829 7.29167C18.6035 7.29167 21.8745 10.5627 21.8745 14.5833C21.8745 18.604 18.6035 21.875 14.5829 21.875C10.5622 21.875 7.2912 18.604 7.2912 14.5833Z" fill="#004A80" />
                      </svg>
                    </div>
                  </div>
                  <div className='content_wrapper'>
                    {Fields()}
                  </div>
                  <div className='disclaimer_wrapper'>
                    <span>{data.filter(item => item.checked == true).length} itens selecionados</span>
                    {/* <span>Exibindo {data.length} de {data.length} elementos</span> */}
                  </div>
                  {/* <div className='pagination_wrapper'>
                    <Pagination count={data.length} variant="outlined" shape="rounded" hidePrevButton hideNextButton />
                  </div> */}
                </ContentConsultItemWrapper>
              </ContentConsultElaborationWrapper>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
              <CancelButton onClick={handleClose}>
                <p>Cancelar</p>
              </CancelButton>
              <ContinueButton type='button' onClick={() => {
                const filterData = data.filter(item => item.checked == true);
                const parsedFilterData = filterData.map((item: any) => {
                  const { checked } = item
                })
                if (setValue) {
                  setValue(filterData);
                  handleClose();
                };
              }}>
                <p>Salvar</p>
              </ContinueButton>
            </DialogActions>
          </div>
        </Dialog>
      </Container>
    </>
  );
})
