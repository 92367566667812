import React from "react";
import { Container } from './styles';
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { Grid } from "@mui/material";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";

export const StrategicLayerForm = () => {
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <p>
          Na Camada Estratégica, você preencherá os elementos essenciais que definem o rumo do Plano. O exercício de planejamento deve incluir reuniões de alinhamento entre as equipes e discussões de temas fundamentais, com base nos resultados da Camada Diagnóstica.
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
          É importante ressaltar que a partir dos Eixos Temáticos, seu Plano será estruturado hierarquicamente. Siga a paginação para garantir que cada elemento gere pelo menos um elemento subsequente. Por exemplo, cada Eixo Temático deve gerar pelo menos uma meta, e cada meta deve gerar ao menos uma macroação, e assim por diante.
          </p>
        </Grid>
        <Grid item xs={12}>
          <div className="gif_wrapper">
          <img src="/planos/gifs/tatica-estrategica.gif" alt="" />
          </div>
        </Grid>
        <Grid item xs={12}>
          <p>
            A depender do tipo de plano, um elemento pode ser complementar ou obrigatório para preenchimento. Dentro de um elemento, campos marcados com * são obrigatórios; os demais são complementares.
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
            Você só conseguirá avançar no botão "Próxima etapa" se todos os campos obrigatórios forem preenchidos e se todos os elementos anteriores derivarem em algum elemento posterior.
          </p>
        </Grid>
        <Grid item xs={12}>
          <p>
            Escreva de forma clara e objetiva, seguindo o conceito de cada elemento.
          </p>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
      </Grid>
      <ActionButtonElaborations onClick={()=> {
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const id = plan[selectedItemsMenu?.layer as string]
        ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
      }} layer_indicator={selectedItemsMenu?.layer_indicator} isOptional={'false'} />
    </Container>
  )
}