import { useTheme } from "@/services/zustand/theme";

export const DesenvolvimentoEconomico = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.5 85.75H48V80.125H54C55.3333 80.125 56.3958 79.625 57.1875 78.625C57.9792 77.625 58.375 76.5 58.375 75.25V60.9069C58.375 59.5523 57.9792 58.3958 57.1875 57.4375C56.3958 56.4792 55.3333 56 54 56H37.125V47.375H58.375V39.875H48V34.25H40.5V39.875H34.5C33.1667 39.875 32.0208 40.375 31.0625 41.375C30.1042 42.375 29.625 43.5465 29.625 44.8896V59.1104C29.625 60.4535 30.1042 61.5208 31.0625 62.3125C32.0208 63.1042 33.1667 63.5 34.5 63.5H50.875V72.625H29.625V80.125H40.5V85.75ZM81.25 81.375L88.75 73.875H73.75L81.25 81.375ZM73.75 47.375H88.75L81.25 39.875L73.75 47.375ZM17.5 100C15.5 100 13.75 99.25 12.25 97.75C10.75 96.25 10 94.5 10 92.5V27.5C10 25.5 10.75 23.75 12.25 22.25C13.75 20.75 15.5 20 17.5 20H102.5C104.5 20 106.25 20.75 107.75 22.25C109.25 23.75 110 25.5 110 27.5V92.5C110 94.5 109.25 96.25 107.75 97.75C106.25 99.25 104.5 100 102.5 100H17.5ZM17.5 92.5H102.5V27.5H17.5V92.5Z"
        fill="url(#paint0_linear_1941_43875)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43875"
          x1="33.125"
          y1="32"
          x2="66.8828"
          y2="100.293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
