import React from "react";

export function ParqueEstadualChacrinha() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Camada 1"
    viewBox="0 0 60 60"
  >
    <defs>
      <linearGradient
        id="a"
        x1={26.21}
        x2={6.86}
        y1={43.41}
        y2={15.77}
        data-name="Gradiente sem nome 7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#23bcef" />
        <stop offset={1} stopColor="#004b80" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={50.5}
        x2={34.48}
        y1={51.61}
        y2={28.73}
        data-name="Gradiente sem nome 7"
      />
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={47.69}
        x2={39.54}
        y1={23.56}
        y2={11.92}
        data-name="Gradiente sem nome 7"
      />
    </defs>
    <path
      d="M28.89 30.48c-.39-.66-.88-1.26-1.46-1.77.95-1.16 1.48-2.6 1.48-4.11a6.53 6.53 0 0 0-4.37-6.17v-.38c0-4.21-3.43-7.64-7.64-7.64s-7.64 3.43-7.64 7.64v.38c-2.59.9-4.37 3.35-4.37 6.17 0 1.51.53 2.95 1.48 4.11a7.573 7.573 0 0 0-2.57 5.71c0 4.21 3.43 7.64 7.64 7.64h4.36v6.55c0 .6.49 1.09 1.09 1.09s1.09-.49 1.09-1.09v-6.55h4.36c1.39 0 2.75-.38 3.93-1.1.51-.31.68-.99.36-1.5a1.09 1.09 0 0 0-1.5-.36c-.83.51-1.8.78-2.79.78h-4.36v-6.09l2.95-2.95c.43-.43.43-1.12 0-1.54s-1.12-.43-1.54 0l-1.41 1.41v-2.82c0-.6-.49-1.09-1.09-1.09s-1.09.49-1.09 1.09v5.45l-1.31-1.75c-.36-.48-1.04-.58-1.53-.22-.48.36-.58 1.04-.22 1.53l3.05 4.07v2.91h-4.36c-3.01 0-5.45-2.45-5.45-5.45 0-1.91.98-3.65 2.62-4.65.3-.19.5-.51.52-.86.02-.35-.13-.7-.4-.92a4.353 4.353 0 0 1-1.64-3.38c0-2.11 1.5-3.91 3.58-4.28.59-.11.98-.67.88-1.26a5.456 5.456 0 0 1 5.36-6.45c3 0 5.45 2.45 5.45 5.45 0 .31-.03.64-.09 1-.1.59.29 1.15.88 1.26 2.07.37 3.58 2.17 3.58 4.28 0 1.31-.6 2.54-1.64 3.38-.28.22-.43.57-.4.92.02.35.22.67.52.86a5.37 5.37 0 0 1 1.81 1.83c.31.52.97.69 1.49.38.52-.31.69-.98.38-1.49Z"
      style={{
        fill: "url(#a)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M54 38.78v-6.55c0-1.2-.98-2.18-2.18-2.18H33.28c-1.2 0-2.18.98-2.18 2.18v6.55c-1.2 0-2.18.98-2.18 2.18v2.18c0 1.2.98 2.18 2.18 2.18v3.27c0 .6.49 1.09 1.09 1.09s1.09-.49 1.09-1.09v-3.27h18.54v3.27c0 .6.49 1.09 1.09 1.09S54 49.19 54 48.59v-3.27c1.2 0 2.18-.98 2.18-2.18v-2.18c0-1.2-.98-2.18-2.18-2.18Zm-20.73 0V36.6h18.54v2.18H33.27Zm18.55-6.55v2.18H33.27v-2.18h18.55ZM54 43.14H31.09v-2.18H54v2.18Z"
      style={{
        fill: "url(#b)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M35.65 20.23h16.1c.66 0 1.28-.29 1.7-.8.41-.5.58-1.15.45-1.79-.19-.95-.65-1.82-1.34-2.5-.9-.92-2.12-1.43-3.39-1.44-.86-1.97-2.79-3.28-4.99-3.28s-4.17 1.34-5.01 3.31c-1.59-.13-3.17.43-4.3 1.56-.59.59-1.02 1.28-1.29 2.07-.23.66-.12 1.39.29 1.96.41.58 1.08.92 1.79.92Zm.76-3.4c.61-.61 1.44-.94 2.3-.94.3 0 .61.04.91.13.29.08.6.04.86-.12s.44-.41.5-.71c.32-1.5 1.66-2.59 3.2-2.59s2.81 1.03 3.17 2.51c.14.56.69.92 1.26.81.87-.16 1.77.12 2.4.76.38.38.64.87.75 1.37H35.64c.16-.46.42-.87.77-1.23Z"
      style={{
        strokeWidth: 0,
        fill: "url(#c)",
      }}
    />
  </svg>
  );
}