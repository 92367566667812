import { useTheme } from "@/services/zustand/theme";

export const DoisIrmaos = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 90L35 50L59.375 82.5H100L70 42.625L54.375 63.375L49.625 57.125L70 30L115 90H5ZM20 82.5H50L35 62.5L20 82.5Z"
        fill="url(#paint0_linear_2012_91964)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91964"
          x1="30.4375"
          y1="39"
          x2="49.7269"
          y2="96.2337"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
