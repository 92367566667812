import React from "react";
import lodash from "lodash";
import { useAuth } from "@/hooks/useAuth";
import { removeArticles } from "@/utils/removeArticles";
import { ISearchJsonProps } from "@/types/endpoints/plans";
import { AddSvgToData } from "@/utils/SvgDictionaries/addSvgToData";
import { OrderArrayByClassification } from "@/utils/orderArrayReseach";
import {
  PlansService,
  IResponsePlans,
  IClassificationRequest,
} from "@/services/endpoints/plans";
import { shuffleArray } from "@/utils/shuffleArray";
import { useRouter } from "next/router";

interface ISearchTerms {
  data: any, isInitialOrder: boolean, isFinalOrder: boolean, isAlphaOrder: boolean
}

export interface IPlansContextProps {
  pagePlans: number;
  totalPlans: number;
  isLoading: boolean;
  isResearch: boolean;
  getAllError: boolean;
  plan: IResponsePlans;
  isFinalOrder: boolean;
  typeOfPlans: string;
  setTypeOfPlans: React.Dispatch<React.SetStateAction<string>>;
  isAlphaOrder: boolean;
  getAllerror404: boolean;
  isInitialOrder: boolean;
  plans: IResponsePlans[];
  errorGetOnePlan: boolean;
  inputResearchText: string;
  SearchPlansError: boolean;
  orderPlansByAlpha: () => void;
  orderPlansByFinalYear: () => void;
  handleGetOnePlan: (id: any) => void;
  orderPlansByInitialYear: () => void;
  handleSearchPlans: (data: ISearchTerms) => void;
  handleClearAllSearchInputs: () => void;
  searchJsonTerms: Record<string, unknown>;
  handleLoadMore: (pageNumber: number) => void;
  setSearchJsonTerms: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >;
  handleGetAllPlans: (pageNumber: number) => void;
  handleSearchTerm: (data: ISearchJsonProps) => void;
  setPagePlans: React.Dispatch<React.SetStateAction<number>>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsResearch: React.Dispatch<React.SetStateAction<boolean>>;
  setGetAllError: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFinalOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAlphaOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setIsInitialOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setPlans: React.Dispatch<React.SetStateAction<IResponsePlans[]>>;
  setInputResearchText: React.Dispatch<React.SetStateAction<string>>;
  SETNTPLANS: React.Dispatch<any>
  NTPLANS: any
}

export const PlansContext = React.createContext({} as IPlansContextProps);

interface IProps {
  children: React.ReactNode;
}

export const PlansContextProvider: React.FC<IProps> = ({ children }) => {
  const { authenticated } = useAuth();
  const {
    getAllPlans,
    searchPlans,
    getOnePlan,
    getClassificationPlans,
    searchQueryPlans,
  } = new PlansService();

  //armazena um plano
  const [plan, setPlan] = React.useState<IResponsePlans>();
  const [typeOfPlans, setTypeOfPlans] = React.useState("");

  // Array de planos
  const [plans, setPlans] = React.useState<IResponsePlans[]>([]);
  // estado de paginação dos planos - botão carregar mais
  const [pagePlans, setPagePlans] = React.useState(1);
  // total de planos vindo da api
  const [totalPlans, setTotalPlans] = React.useState(0);
  // Objeto que será enviado para o endpoint api/search
  const [searchJsonTerms, setSearchJsonTerms] = React.useState(
    {} as Record<string, unknown>
  );
  const [inputResearchText, setInputResearchText] = React.useState("");

  // estado que indica se foi realizado um filtrp
  const [isResearch, setIsResearch] = React.useState(false);
  // os três estados a baixo fazem referencia ao filtro de classificação na busca dos planos
  const [isFinalOrder, setIsFinalOrder] = React.useState(false);
  const [isAlphaOrder, setIsAlphaOrder] = React.useState(false);
  const [isInitialOrder, setIsInitialOrder] = React.useState(false);

  const [NTPLANS, SETNTPLANS] = React.useState<any>()

  const handleAddSvgToData = (data: any) => {
    return AddSvgToData(data) as any;
  };

  const handleSearchQueryPlans = (data: any, record: any) => {
    searchQueryPlans({ data, record }).then((res) => {
      const { data } = res.data as any;
      const newArray = handleAddSvgToData(data);
      const orderArray = OrderArrayByClassification({
        isAlphaOrder,
        isFinalOrder,
        isInitialOrder,
        newArray,
      });
      setPlans(orderArray);
    });
  };

  const handleAlphaAndIninitalOrder = (record: any) => {
    getClassificationPlans(record).then((res) => {
      const { data } = res.data as any;
      const dataSvg = handleAddSvgToData(data);
      const it = dataSvg?.map((item: any) => ({
        ...item,
      }));
      const newArray = lodash.orderBy(
        it,
        ["anoInicialVigencia", "titulo"],
        ["desc", "asc"]
      );
      setPlans(newArray);
    });
  };

  const handleAlphaAndFinalOrder = (record: any) => {
    getClassificationPlans(record).then((res) => {
      const { data } = res.data as any;
      const dataSvg = handleAddSvgToData(data);
      const it = dataSvg?.map((item: any) => ({
        ...item,
      }));
      const newArray = lodash.orderBy(
        it,
        ["anoInicialVigencia", "titulo"],
        ["asc", "asc"]
      );
      setPlans(newArray);
    });
  };

  const handleInitialOrder = (record: any) => {
    getClassificationPlans(record).then((res) => {
      const { data } = res.data as any;
      const dataSvg = handleAddSvgToData(data);
      const newArray = lodash.orderBy(dataSvg, ["anoInicialVigencia"], ["asc"]);
      setPlans(newArray);
    });
  };

  const handleFinalOrder = (record: any) => {
    getClassificationPlans(record).then((res) => {
      const { data } = res.data as any;
      const dataSvg = handleAddSvgToData(data);
      const newArray = lodash.orderBy(dataSvg, ["anoInicialVigencia"], ["desc"]);
      setPlans(newArray);
    });
  };

  const handleAlphaOrder = (record: any) => {
    getClassificationPlans(record).then((res) => {
      const { data } = res.data as any;
      const dataSvg = handleAddSvgToData(data);
      const it = dataSvg?.map((item: any) => ({
        ...item,
      }));
      // const newArray = lodash.orderBy(it, ["titulo"], ["asc"]);
      // Use a função de comparação personalizada para ordenar as palavras
      const palavrasOrdenadas = it.sort((a: any, b: any) => {
        return a.titulo.localeCompare(b.titulo, undefined, {
          sensitivity: 'base',
          ignorePunctuation: true,
        });
      });
      setPlans(palavrasOrdenadas);

    });
  };
  // função que ordena os planos a partir do mais recenter
  const orderPlansByInitialYear = () => {
    
    if (isInitialOrder && isAlphaOrder) {
      const data = {
        date: "DESC",
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "DESC", sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaAndIninitalOrder(data);
      return;
    }

    if (!isInitialOrder && isAlphaOrder) {
      const data = {
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaOrder(data);
      return;
    }

    if (isInitialOrder && !isAlphaOrder) {
      const data = {
        date: "DESC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "DESC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleInitialOrder(data);
    }


  };

  // função que ordena os planos a partir dos mais antigos
  const orderPlansByFinalYear = () => {
    if (isFinalOrder && isAlphaOrder) {
      const data = {
        date: "ASC",
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "ASC", sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaAndFinalOrder(data);
      return;
    }

    if (isFinalOrder && !isAlphaOrder) {
      const data = {
        date: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleFinalOrder(data);
      return;
    }

    if (!isFinalOrder && isAlphaOrder) {
      const data = {
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaOrder(data);
    }
  };

  // função que ordena os planos por ordem alfabética
  const orderPlansByAlpha = () => {
    if (isAlphaOrder && isFinalOrder) {
      const data = {
        date: "ASC",
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { sort: "ASC", date: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaAndFinalOrder(data);
      return;
    }

    if (!isAlphaOrder && isFinalOrder) {
      const data = {
        date: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleFinalOrder(data);
      return;
    }

    if (isAlphaOrder && isInitialOrder) {
      const data = {
        date: "ASC",
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "ASC", sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaAndIninitalOrder(data);
      return;
    }

    if (!isAlphaOrder && isInitialOrder) {
      const data = {
        date: "DESC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { date: "DESC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleInitialOrder(data);
      return;
    }

    if (!isFinalOrder && !isInitialOrder && isAlphaOrder) {
      const data = {
        sort: "ASC",
        page: "1",
        size: String(plans.length),
      } as IClassificationRequest;

      if (Object.keys(searchJsonTerms).length > 0) {
        const record = { sort: "ASC" };
        handleSearchQueryPlans(searchJsonTerms, record);
        return;
      }

      handleAlphaOrder(data);
      return;
    }


    if (!isInitialOrder && !isFinalOrder && !isAlphaOrder) {
      const newArray = lodash.orderBy(plans, ["id"], ["desc"]);
      setPlans(newArray);
      return
    }

    if ((!isInitialOrder && !isAlphaOrder) && (Object.keys(searchJsonTerms).length > 0)) {
      const orderArray = lodash.orderBy(plans as any, [i => i?.hits?.length], ['desc']);
      setPlans(orderArray);
      return;
    }

  };

  // estado que controla o efeito skeleton dos planos em relação as requisições
  const [isLoading, setIsLoading] = React.useState(false);
  // estado que controla se houve um erro na requisição getAllPlans
  const [getAllError, setGetAllError] = React.useState(false);
  // estado que controla um erro 404 na requisição getAllPlans
  const [getAllerror404, setGetAllError404] = React.useState(false);
  const [errorGetOnePlan, setErrorGetOnePlan] = React.useState(false);
  // Função que busca todos os planos, é usada na primeira renderização

  const handleGetAllPlans = React.useCallback(
    (pageNumber: number) => {
      setIsLoading(true);
      setIsResearch(false);
      getAllPlans(pageNumber)
        .then((res) => {
          const { data, total } = res.data as any;
          setTotalPlans(total);
          const newArray = handleAddSvgToData(data) as IResponsePlans[];
          const orderArray = OrderArrayByClassification({
            isAlphaOrder,
            isFinalOrder,
            isInitialOrder,
            newArray,
          });
          setPlans(pageNumber === 1 ? shuffleArray(orderArray) : orderArray);
        })
        .catch((e) => {
          setGetAllError(true);
          if (e?.response?.status === 404) {
            setGetAllError404(true);
          }
        })
        .finally(() => {
          setIsLoading(false);
          setGetAllError(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAlphaOrder, isFinalOrder, isInitialOrder]
  );

  // UseEffect da primeira renderização
  const router = useRouter();
  React.useEffect(() => {
    if (authenticated && (router.pathname.split("/").includes("/dashboard") || 
    router.pathname.split("/").includes("dashboard") || 
    router.pathname.split("/").includes("planos") || 
    router.pathname.split("/").includes("mapa_interativo"))) {
      handleGetAllPlans(pagePlans);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, router.pathname]);

  // função que busca apenas um plano, utilizada na página "plano/id"
  const handleGetOnePlan = (id: string) => {
    if (id !== undefined) {
      setIsLoading(true);
      getOnePlan(id)
        .then((res) => {
          const { data } = res.data as any;
          setPlan(data);
        })
        .catch((err) => {
          setErrorGetOnePlan(true);
        })
        .finally(() => setIsLoading(false));
    }
  };

  // estado que controla se ouve erro na requisição searchPlans
  const [SearchPlansError, setSearchPlansError] = React.useState(false);

  // Função que busca os novos planos a partir do filtro lateral e barra de pesquisa
  const handleSearchPlans = React.useCallback(({ data, isAlphaOrder, isFinalOrder, isInitialOrder }: ISearchTerms) => {
    setIsLoading(true);
    setIsResearch(false);

    searchPlans(data)
      .then((res) => {
        const { data } = res.data as any;
        const newArray = handleAddSvgToData(data);
        let orderArray: any = [];
        if (isAlphaOrder || isFinalOrder || isInitialOrder) {
          orderArray = OrderArrayByClassification({
            isAlphaOrder,
            isFinalOrder,
            isInitialOrder,
            newArray,
          });
        } else {
          orderArray = lodash.orderBy(newArray, [i => i?.hits?.length], ['desc']);
        }
        setPlans(orderArray);

      })
      .catch((e) => setSearchPlansError(true))
      .finally(() => {
        const initalPageState = 1;
        setIsLoading(false);
        setIsResearch(true);
        setPagePlans(initalPageState);
      });
  }, [isAlphaOrder, isFinalOrder, isInitialOrder]);

  // função que controla a busca no campo de pesquisa
  const handleSearchTerm = React.useCallback(
    (data: ISearchJsonProps) => {
      const { query } = data;
      const item = query as string;
      searchJsonTerms["query"] = item;

      if (item?.length > 0) {
        handleSearchPlans({
          data: searchJsonTerms,
          isAlphaOrder,
          isFinalOrder,
          isInitialOrder,
         });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isInitialOrder, isFinalOrder, isInitialOrder]
  );

  // função responsável por carregar mais itens analisando os planos pré existentes - botão carregar mais
  const handleLoadMore = (pageNumber: number) => {
    setIsLoading(true);
    setIsResearch(false);
    getAllPlans(pageNumber === 1 ? pageNumber + 1 : pageNumber)
      .then((res) => {
        const { data } = res.data as any;
        const it = data;

        if (it?.length === 0) {
          setIsLoading(false);
          return;
        }

        const newArray = handleAddSvgToData(it) as IResponsePlans[];

        setPlans((prev) => {
          const prevArray = [...prev];
          const prevIds = new Set(prevArray.map((obj) => obj.id));
          const uniqueNewArray = newArray.filter((obj) => !prevIds.has(obj.id));
          const mergedArray = [...prevArray, ...uniqueNewArray];
          return mergedArray;
        });
      })
      .finally(() => setIsLoading(false));
  };

  const value = {
    plan,
    NTPLANS,
    SETNTPLANS,
    typeOfPlans,
    setTypeOfPlans,
    plans,
    setPlans,
    isLoading,
    pagePlans,
    isResearch,
    totalPlans,
    getAllError,
    isFinalOrder,
    setPagePlans,
    setIsLoading,
    isAlphaOrder,
    setIsResearch,
    getAllerror404,
    isInitialOrder,
    handleLoadMore,
    setGetAllError,
    setIsFinalOrder,
    setIsAlphaOrder,
    searchJsonTerms,
    errorGetOnePlan,
    SearchPlansError,
    handleGetOnePlan,
    handleSearchTerm,
    inputResearchText,
    orderPlansByAlpha,
    setIsInitialOrder,
    handleSearchPlans,
    handleGetAllPlans,
    setSearchJsonTerms,
    setInputResearchText,
    orderPlansByFinalYear,
    orderPlansByInitialYear,
  } as IPlansContextProps;

  return (
    <PlansContext.Provider value={value}>{children}</PlansContext.Provider>
  );
};
