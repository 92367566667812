import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title_wrapper_datepicker {
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    label {
      color: var(--Text-Primary, #272930);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px;
    }
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
      font-family: Inter !important; 
    }
  }

  select {
    &::placeholder {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;