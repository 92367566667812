import { useTheme } from "@/services/zustand/theme";

export const DiretorDecenal2021 = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 100V78.375H31.625V100H10ZM39.125 100V78.375H110V100H39.125ZM10 70.875V49.125H31.625V70.875H10ZM39.125 70.875V49.125H110V70.875H39.125ZM10 41.625V20H31.625V41.625H10ZM39.125 41.625V20H110V41.625H39.125Z"
        fill="url(#paint0_linear_1941_17654)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_17654"
          x1="33.125"
          y1="32"
          x2="66.8828"
          y2="100.293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
