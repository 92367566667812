import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";

interface IFormPrograms {
  page: number;
  label: string;
  programas: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removePrograms: UseFieldArrayRemove;
  fieldsPrograms: Record<"id", string>[];
  errosPrograms: FieldErrors<InputsPrograms>;
  resetPrograms: UseFormReset<InputsPrograms>;
  watchPrograms: UseFormWatch<InputsPrograms>;
  controlPrograms: Control<InputsPrograms, any>;
  transformProgramsDataReuse: (item: any) => any;
  setValuePrograms: UseFormSetValue<InputsPrograms>;
  registerPrograms: UseFormRegister<InputsPrograms>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  updatePrograms: UseFieldArrayUpdate<InputsPrograms, "programa">;
  appendPrograms: UseFieldArrayAppend<InputsPrograms, "programa">;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  handleSubmitPrograms: UseFormHandleSubmit<InputsPrograms, undefined>;
}

export const ProgramsContext = React.createContext({} as IFormPrograms);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsPrograms = {
  programa: any;
  is_optional?: boolean;

}

const SchemaPrograms = Yup.object().shape({
  programa: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      valor: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
    })
  ),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  texto: '',
  valor: '',
  prazo: null,
  territorio: '',
  description: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformProgramsData = (data: any[]) => {
  return data.map((item: any) => {
    const tor = item?.programasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.programasTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      valor: item?.valor,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      description: item?.description,
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item?.data ? dayjs(item?.data) : null,
    };
  });
};

const transformProgramsDataReuse = (item: any) => {
  const tor = item?.programasTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.programasTerritorio)

  return {
    tor,
    territorio,
    valor: item?.valor,
    texto: item?.texto || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    description: item?.description,
    publicoAlvo: item?.publicoAlvo || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
  };
};

export const ProgramsContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetPrograms,
    watch: watchPrograms,
    control: controlPrograms,
    register: registerPrograms,
    clearErrors,
    setValue: setValuePrograms,
    handleSubmit: handleSubmitPrograms,
    formState: { errors: errosPrograms } } = useForm<InputsPrograms>({
      resolver: yupResolver(SchemaPrograms) as any,
      defaultValues: { programa: [defaultValue] }
    });

  const { fields: fieldsPrograms, append: appendPrograms, update: updatePrograms, remove: removePrograms } = useFieldArray({
    control: controlPrograms,
    name: "programa"
  });


  const router = useRouter();
  const routerId = router.query.id;
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);

  const { completCode } = usePreparationOfPlans();
  const { GetThematicAxisElaboration, GetGoalsEixoElaborations: GetGoalsElaborations, GetMacroactionsEixoElaborations: GetMacroactionsElaborations, GetProgramsEixoElaborations: GetProgramsElaborations } = new ElaborationService();

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [programas, setProgramas] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
  //     const metas = completCode.find(item => item.code == 'metas' && item.skipped == false);
  //     const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped == false);

  //     if (zustandSelectedCardItem.title == 'Plano Setorial' || zustandSelectedCardItem.title == 'Plano Institucional') {
  //       GetMacroactionsElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setProgramas(res.data);
  //         setLabel('Macroação')
  //       });
  //     } else {

  //       if (metas != undefined && macroacoes != undefined) {
  //         GetMacroactionsElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProgramas(res.data);
  //           setLabel('Macroação')
  //         });

  //         return;
  //       }

  //       if (metas != undefined && macroacoes == undefined) {
  //         GetGoalsElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProgramas(res.data);
  //           setLabel('Meta')
  //         });

  //         return;
  //       }

  //       if (metas == undefined && macroacoes != undefined) {
  //         GetMacroactionsElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProgramas(res.data);
  //           setLabel('Macroação')
  //         });
  //       }
  //     }
  //   }
  // }, [completCode, routerId, router.pathname, eixos_tematicos, currentIndexEixo, zustandSelectedCardItem]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
  //     const data = programas[page - 1];
  //     clearErrors()
  //     if (data?.programas?.length > 0) {
  //       setValuePrograms('programa', transformProgramsData(data.programas));
  //     } else {
  //       setValuePrograms('programa', [defaultValue])
  //     }
  //   }
  // }, [router.query.id, page, programas, router.pathname, completCode]);

  const value = {
    page,
    label,
    setPage,
    programas,
    errosPrograms,
    watchPrograms,
    resetPrograms,
    fieldsPrograms,
    updatePrograms,
    removePrograms,
    appendPrograms,
    controlPrograms,
    eixos_tematicos,
    currentIndexEixo,
    setValuePrograms,
    registerPrograms,
    setCurrentIndexEixo,
    handleSubmitPrograms,
    transformProgramsDataReuse,
  } as IFormPrograms;

  return <ProgramsContext.Provider value={value}>{children}</ProgramsContext.Provider>;
};