import { useTheme } from "@/services/zustand/theme";

export const EstrategicoMultirio_2024 = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 90C51.9167 90 45 87.25 39.25 81.75C33.5 76.25 30.4167 69.4583 30 61.375L37.875 63.875C38.7917 69.2083 41.2917 73.6458 45.375 77.1875C49.4583 80.7292 54.3333 82.5 60 82.5C66.25 82.5 71.5625 80.3125 75.9375 75.9375C80.3125 71.5625 82.5 66.25 82.5 60C82.5 54.4167 80.7292 49.5625 77.1875 45.4375C73.6458 41.3125 69.2083 38.7917 63.875 37.875L61.5 30C69.5 30.4167 76.25 33.5 81.75 39.25C87.25 45 90 51.9167 90 60C90 68.3333 87.0833 75.4167 81.25 81.25C75.4167 87.0833 68.3333 90 60 90ZM60 110C53.0833 110 46.5833 108.688 40.5 106.062C34.4167 103.438 29.125 99.875 24.625 95.375C20.125 90.875 16.5625 85.5833 13.9375 79.5C11.3125 73.4167 10 66.9167 10 60C10 59.25 10.0208 58.5 10.0625 57.75C10.1042 57 10.1667 56.25 10.25 55.5L17.5 57.75V60C17.5 71.8333 21.625 81.875 29.875 90.125C38.125 98.375 48.1667 102.5 60 102.5C71.8333 102.5 81.875 98.375 90.125 90.125C98.375 81.875 102.5 71.8333 102.5 60C102.5 48.1667 98.375 38.125 90.125 29.875C81.875 21.625 71.8333 17.5 60 17.5H57.75L55.5 10.25C56.25 10.1667 57 10.1042 57.75 10.0625C58.5 10.0208 59.25 10 60 10C66.9167 10 73.4167 11.3125 79.5 13.9375C85.5833 16.5625 90.875 20.125 95.375 24.625C99.875 29.125 103.438 34.4167 106.062 40.5C108.688 46.5833 110 53.0833 110 60C110 66.9167 108.688 73.4167 106.062 79.5C103.438 85.5833 99.875 90.875 95.375 95.375C90.875 99.875 85.5833 103.438 79.5 106.062C73.4167 108.688 66.9167 110 60 110ZM52.625 62.5L31.25 41.125L25 60L10 10L60 25L41.125 31.25L62.5 52.625L52.625 62.5Z"
        fill="url(#paint0_linear_2012_91956)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91956"
          x1="33.125"
          y1="25"
          x2="80.625"
          y2="101.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
