import _ from "lodash";
import React from "react";
import { useAuth } from "@/hooks/useAuth";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import dayjs from "dayjs";
import { PlansService } from "@/services/endpoints/plans";
import { customOrderPlanManagement } from "@/utils/order_array_gestao_planos";
import { useRouter } from "next/router";

export interface Data {
  updatedAt: any;
  id: number;
  orgaos: any[];
  pontoFocal: string;
  titulo: string;
  tipoPlano: { nome: string };
  alerta: number;
  status: number;
  visivel: number;
  atoNormativoUrl: string;
  tema: { id: string }
  nomeEditor: number;
  isLocked: boolean;
  atoNormativo: string;
  dataAlteracao: number;
  anoFinalVigencia: string;
  anoInicialVigencia: string;
  enteResponsavel?: { descricao: string };
}

type GraphData = {
  label: string;
  value: number;
};


type GraphContent = {
  macroGraph: GraphData[];
  metaGraph: GraphData[];
  entregaGraph: GraphData[];
  metaPizzaData: GraphData[];
  entregasPizzaData: GraphData[];
  macroacoesPizzaData: GraphData[];
  totalMacroacoes: number;
  totalMacroacoesAlcancadasPercent: any;
  totalMetas: number;
  totalMetasAlcancadasPercent: any;
  totalEntregas: number;
  totalEntregasAlcancadasPercent: any;
};

type Plano = {
  id: any;
  titulo: any;
  isLocked: boolean;
  eixosTematicos: any[];
  entregas: any[];
  objetivos: any[];
  programas: any[];
  projetos: any[];
  sinteseDiagnostico: any[];
  metas: any[];
  macroacoes: any[];
  status: number;
  historico: any[];
  orgaos: any[];
};


type PageContent = {
  plano: Plano;
  graphContent: GraphContent;
};


type Order = "asc" | "desc";

export interface IDashboardManagementPlansContextProps {
  page: number;
  order: Order;
  rowsApi: Data[];
  planName: string;
  visibleRows: Data[];
  pagePagination: number;
  handleChangePaginationMonitoring: (event: any, value: any) => void;
  orderBy: keyof Data;
  statusModal: boolean;
  textResearch: string;
  visibleModal: boolean;
  labelPagination: string;
  statusSelectdId: number;
  visibleSelectdId: number;
  editSelectdId: number;
  loadingIndicatorTable: boolean;
  totalPlans: number;
  openDeleteModal: boolean;
  nameArray: (string | null)[];
  handleChangePagination: (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => void;
  handleRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  isSelected: (id: number) => boolean;
  selectedCheckboxId: readonly number[];
  handleChangeStatusPlan: (rowId: number) => void;
  handleChangeVisiblePlan: (rowId: number) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setRowsApi: React.Dispatch<React.SetStateAction<Data[]>>;
  setNameArray: React.Dispatch<React.SetStateAction<string[]>>;
  handleTextResearch: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setStatusModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTextResearch: React.Dispatch<React.SetStateAction<string>>;
  setvisibleModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusSelectedId: React.Dispatch<React.SetStateAction<number>>;
  setVisibleSelectedId: React.Dispatch<React.SetStateAction<number>>;
  handleSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClickOneItem: (event: React.MouseEvent<unknown>, id: number) => void;
  setSelectedCheckboxId: React.Dispatch<
    React.SetStateAction<readonly number[]>
  >;
  setEditSelectedId: React.Dispatch<React.SetStateAction<number>>;
  setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  editModal: boolean;
  handleChangeEditPlan: (rowId: number) => void;
  setPageContent: React.Dispatch<React.SetStateAction<PageContent | undefined>>;
  pageContent: PageContent | undefined;
  handleGetPlanDashboardInfoId: (planId: number) => void;
  setTotalPlans: React.Dispatch<React.SetStateAction<number>>;
  insertAlertProps: (array: any[]) => any[],
  filterData: {
    orgaoResponsavel: any[];
    setor: any[];
    territorio: Record<string, any>;
  }
  totalPagination: number;
  handleGetMapDeliveryParams: (queryId: number) => void;
  tooltipContent: any[];
  arrCod: any[];
  setArrCod: React.Dispatch<React.SetStateAction<any[]>>
  setArrayAnoMetas: React.Dispatch<React.SetStateAction<any[]>>;
  arrayAnoMetas: Array<any>;
  set_comprimento_planos: React.Dispatch<React.SetStateAction<number>>
  comprimento_planos: number;
  setArrayAnoProdutos: React.Dispatch<React.SetStateAction<any[]>>
  arrayAnoProdutos: Array<any>;
  setArrayAnoMacroacoes: React.Dispatch<React.SetStateAction<any[]>>;
  arrayAnoMacroacoes: Array<any>;
  setLabelPagination: React.Dispatch<React.SetStateAction<string>>
}

export const DashboardManagementPlansContext = React.createContext(
  {} as IDashboardManagementPlansContextProps
);

interface IProps {
  children: React.ReactNode;
}

export const DashboardManagementPlansContextProvider: React.FC<IProps> = ({
  children,
}) => {
  const router = useRouter();
  const planId = router.query.id;
  const [pagePagination, setPagePagination] = React.useState(1);
  const [totalPagination, setTotalPagination] = React.useState(0);
  const [pageContent, setPageContent] = React.useState<PageContent>();

  const [labelPagination, setLabelPagination] = React.useState('metas');
  const { getMeusPlanosInfoId, GetMacroactions, GetDeliveries, GetGoals } = new PlansService();

  const handleChangePaginationMonitoring = (event: any, value: any) => {
    setPagePagination(value);
  };

  const [loadingIndicatorTable, setLoadingIndicatorTable] = React.useState(false);

  const handleGetPlanDashboardInfoId = (planId: number, it?: boolean) => {
    if (planId == undefined) {
      return;
    }
    setLoadingIndicatorTable(true);

    const macroacoes: any[] = [];
    const metas: any[] = [];
    const entregas: any[] = [];

    if (labelPagination == 'metas') {
      GetGoals(planId, pagePagination, 10).then(res => {
        setTotalPagination(res.data.totalPages)
        metas.push(res.data.data)

        getMeusPlanosInfoId(planId).then(({ data }: any) => {
          const planos = data.plano;
          const conteudo_plano = {
            id: planos.id,
            isLocked: planos.isLocked,
            titulo: planos.titulo,
            eixosTematicos: planos.eixosTematicos,
            entregas: [],
            objetivos: planos.objetivos,
            programas: planos.programas,
            projetos: planos.projetos,
            sinteseDiagnostico: planos.sinteseDiagnostico,
            metas: res.data.data,
            macroacoes: [],
            status: planos.status,
            historico: planos.historico,
            orgaos: planos.orgaos,
          };
    
          const macroGraphData = data.macroacoesData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaGraphData = data.metaData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const entregaGraphData = data.entregasData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaPizzaData = Object.entries(data.metaPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const entregasPizzaData = Object.entries(data.entregasPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const macroacoesPizzaData = Object.entries(data.macroacoesPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          function calcularPorcentagemConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
              const total = dados.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Concluído").value;
              const porcentagemConcluido = (concluido / total) * 100;
    
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
            return 0
          }
    
          function calcularPorcentagemMetaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
    
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Alcançada").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          function calcularPorcentagemEntregaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Entregue").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          const graphContent = {
            macroGraph: macroGraphData,
            metaGraph: metaGraphData,
            entregaGraph: entregaGraphData,
            totalMacroacoes: data.totalMacroacoes,
            totalMacroacoesAlcancadasPercent:
              calcularPorcentagemConcluido(macroacoesPizzaData),
            totalMetas: data.totalMetas,
            totalMetasAlcancadasPercent:
              calcularPorcentagemMetaConcluido(metaPizzaData),
            totalEntregas: data.totalEntregas,
            totalEntregasAlcancadasPercent: calcularPorcentagemEntregaConcluido(entregasPizzaData),
            metaPizzaData: metaPizzaData,
            entregasPizzaData: entregasPizzaData,
            macroacoesPizzaData: macroacoesPizzaData,
          };
    
          setPageContent({
            plano: conteudo_plano,
            graphContent: graphContent,
          });
        }).finally(() => setLoadingIndicatorTable(false));
      })
    }

    if (labelPagination ==  'macroacoes' && router.query.id) {

      GetMacroactions(planId, pagePagination, 10).then(res => {
        macroacoes.push(res.data.data);
        setTotalPagination(res.data.totalPages);

        getMeusPlanosInfoId(planId).then(({ data }: any) => {
          const planos = data.plano;
          const conteudo_plano = {
            id: planos.id,
            isLocked: planos.isLocked,
            titulo: planos.titulo,
            eixosTematicos: planos.eixosTematicos,
            entregas: [],
            objetivos: planos.objetivos,
            programas: planos.programas,
            projetos: planos.projetos,
            sinteseDiagnostico: planos.sinteseDiagnostico,
            metas: [],
            macroacoes: res.data.data,
            status: planos.status,
            historico: planos.historico,
            orgaos: planos.orgaos,
          };
    
          const macroGraphData = data.macroacoesData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaGraphData = data.metaData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const entregaGraphData = data.entregasData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaPizzaData = Object.entries(data.metaPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const entregasPizzaData = Object.entries(data.entregasPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const macroacoesPizzaData = Object.entries(data.macroacoesPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          function calcularPorcentagemConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
              const total = dados.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Concluído").value;
              const porcentagemConcluido = (concluido / total) * 100;
    
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
            return 0
          }
    
          function calcularPorcentagemMetaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
    
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Alcançada").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          function calcularPorcentagemEntregaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Entregue").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          const graphContent = {
            macroGraph: macroGraphData,
            metaGraph: metaGraphData,
            entregaGraph: entregaGraphData,
            totalMacroacoes: data.totalMacroacoes,
            totalMacroacoesAlcancadasPercent:
              calcularPorcentagemConcluido(macroacoesPizzaData),
            totalMetas: data.totalMetas,
            totalMetasAlcancadasPercent:
              calcularPorcentagemMetaConcluido(metaPizzaData),
            totalEntregas: data.totalEntregas,
            totalEntregasAlcancadasPercent: calcularPorcentagemEntregaConcluido(entregasPizzaData),
            metaPizzaData: metaPizzaData,
            entregasPizzaData: entregasPizzaData,
            macroacoesPizzaData: macroacoesPizzaData,
          };
    
          setPageContent({
            plano: conteudo_plano,
            graphContent: graphContent,
          });
        }).finally(() => {
          setLoadingIndicatorTable(false);
        });
      })
    }

    if (labelPagination == 'entregas') {

      GetDeliveries(planId, pagePagination).then(res => {
        setTotalPagination(res.data.totalPages)
        entregas.push(res.data.data)

        getMeusPlanosInfoId(planId).then(({ data }: any) => {
          const planos = data.plano;
          const conteudo_plano = {
            id: planos.id,
            isLocked: planos.isLocked,
            titulo: planos.titulo,
            eixosTematicos: planos.eixosTematicos,
            entregas: res.data.data,
            objetivos: planos.objetivos,
            programas: planos.programas,
            projetos: planos.projetos,
            sinteseDiagnostico: planos.sinteseDiagnostico,
            metas: [],
            macroacoes: [],
            status: planos.status,
            historico: planos.historico,
            orgaos: planos.orgaos,
          };
    
          const macroGraphData = data.macroacoesData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaGraphData = data.metaData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const entregaGraphData = data.entregasData.map((item: any) => {
            return {
              label: `${item.year}`,
              value: item.quantidade,
            };
          });
    
          const metaPizzaData = Object.entries(data.metaPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const entregasPizzaData = Object.entries(data.entregasPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          const macroacoesPizzaData = Object.entries(data.macroacoesPizzaData).map(
            ([key, value]) => {
              return {
                label: key,
                value: Number(value),
              };
            }
          );
    
          function calcularPorcentagemConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
              const total = dados.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Concluído").value;
              const porcentagemConcluido = (concluido / total) * 100;
    
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
            return 0
          }
    
          function calcularPorcentagemMetaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
    
    
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Alcançada").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          function calcularPorcentagemEntregaConcluido(dados: any) {
            if (!dados) return
    
            if (dados.length > 0) {
              const total = dados?.reduce((acc: any, item: any) => acc + item.value, 0);
              const concluido = dados.find((item: any) => item.label === "Entregue").value;
              const porcentagemConcluido = (concluido / total) * 100;
              return isNaN(porcentagemConcluido) ? 0 : porcentagemConcluido.toFixed(2) ?? 0;
            }
          }
    
          const graphContent = {
            macroGraph: macroGraphData,
            metaGraph: metaGraphData,
            entregaGraph: entregaGraphData,
            totalMacroacoes: data.totalMacroacoes,
            totalMacroacoesAlcancadasPercent:
              calcularPorcentagemConcluido(macroacoesPizzaData),
            totalMetas: data.totalMetas,
            totalMetasAlcancadasPercent:
              calcularPorcentagemMetaConcluido(metaPizzaData),
            totalEntregas: data.totalEntregas,
            totalEntregasAlcancadasPercent: calcularPorcentagemEntregaConcluido(entregasPizzaData),
            metaPizzaData: metaPizzaData,
            entregasPizzaData: entregasPizzaData,
            macroacoesPizzaData: macroacoesPizzaData,
          };
    
          setPageContent({
            plano: conteudo_plano,
            graphContent: graphContent,
          });
        }).finally(() => {
          setLoadingIndicatorTable(false)
        });
      })
    }
  };

  React.useEffect(() => {
    if (planId && router.pathname.split('/').includes('dashboard')) {
      handleGetPlanDashboardInfoId(planId as unknown as number);
    }
  }, [planId, router.pathname, pagePagination, labelPagination]);

  const { getMapDeliveryParams } = new DashboardManagementPlansService();
  const [tooltipContent, setTooltipContent] = React.useState<any[]>([]);
  const [arrCod, setArrCod] = React.useState<any[]>([]);
  const [arrayAnoMetas, setArrayAnoMetas] = React.useState<any[]>([]);
  const [arrayAnoProdutos, setArrayAnoProdutos] = React.useState<any[]>([]);
  const [arrayAnoMacroacoes, setArrayAnoMacroacoes] = React.useState<any[]>([]);
  const [comprimento_planos, set_comprimento_planos] = React.useState(7)

  const handleGetMapDeliveryParams = (queryId: number) => {
    getMapDeliveryParams(queryId).then((res) => {
      const { data } = res.data as any;
      const arrCod = [] as any[];

      Object.entries(data).map(([_, value]: [key: any, value: any]) => {
        const entregasTerritorio = value?.entregasTerritorio as any[];
        setTooltipContent(entregasTerritorio);
        entregasTerritorio.forEach((i) => {
          arrCod.push(i?.areaPlanejamento?.cod);
        });
      });

      setArrCod(arrCod);
    });
  };

  const { authenticated } = useAuth();
  const [rowsApi, setRowsApi] = React.useState<Data[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);

  // Função, estados e chamadas de api referêntes a busca de texto
  const [textResearch, setTextResearch] = React.useState("");
  const { searchPlans } = new DashboardManagementPlansService();

  const [totalPlans, setTotalPlans] = React.useState(0);

  const insertAlertProps = (array: any[]) => {
    return array.map((item: any) => ({
      ...item,
      alerta:
        dayjs(item.updatedAt).isValid() &&
        dayjs(item.updatedAt).diff(dayjs(), "month") * -1 > 3,
    }))
  }

  const { getAllFilters } = new DashboardManagementPlansService();

  const [filterData, setFilterData] = React.useState({
    orgaoResponsavel: [] as any[],
    setor: [] as any[],
    territorio: {} as Record<string, any>
  });

  React.useEffect(() => {
    if (router.pathname.split('/').includes('dashboard'))

      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      })

  }, [router.pathname]);

  // Busca de planos por texto
  const handleTextResearch = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const textValue = e.target.value;
    setTextResearch(textValue);
    searchPlans({ page: String(page), sort: titleOrder, palavra: textValue }).then(
      (res) => {
        const { data: dataSearch, total } = res.data as any;
        setTotalPlans(total);
        if ([...dataSearch]?.length === 0) {
          paginationPlans({ page: String(page), sort: titleOrder }).then((res) => {
            const { data, total } = res.data as any;
            setTotalPlans(total);
            setRowsApi(insertAlertProps(data));
          });
        } else {
          setRowsApi(insertAlertProps(dataSearch));
        }
      }
    );
  };
  // -------------------------------------------------------------------------------

  // Função, estados e chamadas de api referêntes ao checkbox
  const [planName, setPlanName] = React.useState("");
  const [selectedCheckboxId, setSelectedCheckboxId] = React.useState<
    readonly number[]
  >([]);

  // Selecção de todos os items da tabela de planos
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rowsApi?.map((n) => n.id);
      setSelectedCheckboxId(newSelected);
      return;
    }
    setSelectedCheckboxId([]);
  };

  // Seleção de apenas um item da tabela de planos
  const handleClickOneItem = (_: React.MouseEvent<unknown>, id: number) => {
    const selectedCheckboxIdIndex = selectedCheckboxId.indexOf(id);
    let newselectedCheckboxId: readonly number[] = [];

    if (selectedCheckboxIdIndex === -1) {
      newselectedCheckboxId = newselectedCheckboxId.concat(
        selectedCheckboxId,
        id
      );
    } else if (selectedCheckboxIdIndex === 0) {
      newselectedCheckboxId = newselectedCheckboxId.concat(
        selectedCheckboxId.slice(1)
      );
    } else if (selectedCheckboxIdIndex === selectedCheckboxId.length - 1) {
      newselectedCheckboxId = newselectedCheckboxId.concat(
        selectedCheckboxId.slice(0, -1)
      );
    } else if (selectedCheckboxIdIndex > 0) {
      newselectedCheckboxId = newselectedCheckboxId.concat(
        selectedCheckboxId.slice(0, selectedCheckboxIdIndex),
        selectedCheckboxId.slice(selectedCheckboxIdIndex + 1)
      );
    }

    setSelectedCheckboxId(newselectedCheckboxId);
    setPlanName(rowsApi[selectedCheckboxIdIndex]?.titulo);
  };

  // Referência de qual item foi selecionado
  const isSelected = (id: number) => selectedCheckboxId.indexOf(id) !== -1;
  // ------------------------------------------------------------------------------

  // Função, estados e chamadas de api referêntes a ordenação
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("" as any);
  const [titleOrder, setTitleOrder] = React.useState("ASC");
  const [blockRequest, setBlockRequest] = React.useState(false);

  // Função que monta a referência da ordenação da tabela de planos
  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    if (blockRequest) return;

    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    if (property === 'titulo') {
      setTitleOrder(isAsc ? 'DESC' : "ASC");
    }
    setOrderBy(property);
  };

  // Segundo a alteração de handleRequestSort o useEffect é disparado
  React.useEffect(() => {
    const isValidPathName = router.pathname.split("/").includes('dashboard')
    if (isValidPathName) {
      if (orderBy === 'titulo') {
        setBlockRequest(true);
        paginationPlans({ page: String(page), sort: titleOrder }).then((res) => {
          const { data } = res.data as any;
          setRowsApi(insertAlertProps(data));
        }).then(() => setBlockRequest(false))
      }

      if (orderBy == 'updatedAt' as any) {
        setRowsApi(insertAlertProps(_.orderBy(rowsApi, [customOrderPlanManagement, orderBy], [order])));
      } else {
        setRowsApi(insertAlertProps(_.orderBy(rowsApi, [orderBy], [order])));
      }

    }
  }, [order, orderBy, router.pathname]);
  //----------------------------------------------------------------------

  // Função, estados e chamadas de api referêntes a paginação
  const { paginationPlans } = new DashboardManagementPlansService();
  const [page, setPage] = React.useState(1);

  const handleChangePagination = (
    _: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    paginationPlans({ page: String(value), sort: titleOrder }).then((res) => {
      const { data, total } = res.data as any;
      setTotalPlans(total)
      setRowsApi(insertAlertProps(data));
    });
  };

  React.useEffect(() => {
    const isValidPathName = router.pathname.split("/").includes('dashboard')
    if (authenticated && isValidPathName) {
      paginationPlans({ page: "1", sort: titleOrder }).then((res) => {
        const { data, total } = res.data as any;
        setTotalPlans(total);
        setRowsApi(insertAlertProps((data)));
      });
    }
  }, [authenticated, router.pathname]);

  //----------------------------------------------------------------

  // Função, estados e chamadas de api referêntes a mudança de status
  const [statusModal, setStatusModal] = React.useState(false);
  const [statusSelectdId, setStatusSelectedId] = React.useState(0);

  const handleChangeStatusPlan = (rowId: number) => {
    setStatusModal(!statusModal);
    setStatusSelectedId(rowId);

    const indice = rowsApi?.findIndex((item) => item.id === rowId);

    if (indice !== -1) {
      setPlanName(rowsApi[indice]?.titulo);
    }
  };
  // ------------------------------------------------------------------------

  // Função, estados e chamadas de api referêntes a mudança de visualização
  const [visibleModal, setvisibleModal] = React.useState(false);
  const [visibleSelectdId, setVisibleSelectedId] = React.useState(0);

  const handleChangeVisiblePlan = (rowId: number) => {
    setvisibleModal(!visibleModal);
    setVisibleSelectedId(rowId);

    const indice = rowsApi?.findIndex((item) => item.id === rowId);

    if (indice !== -1) {
      setPlanName(rowsApi[indice]?.titulo);
    }
  };

  const [editModal, setEditModal] = React.useState(false);
  const [editSelectdId, setEditSelectedId] = React.useState(0);

  const handleChangeEditPlan = (rowId: number) => {
    setEditModal(!editModal);
    setEditSelectedId(rowId);

    const indice = rowsApi?.findIndex((item) => item.id === rowId);

    if (indice !== -1) {
      setPlanName(rowsApi[indice]?.titulo);
    }
  };


  // ------------------------------------------------------------------------

  // Função, estados e chamadas de api referêntes a mudança de nome dos modais
  const [nameArray, setNameArray] = React.useState<string[]>([]);

  React.useEffect(() => {
    let names = selectedCheckboxId
      .map((id) => {
        // Encontrar o objeto com base no ID
        const findObject = rowsApi?.find((objeto) => objeto.id === id);

        // Verificar se o objeto foi encontrado
        if (findObject) {
          // Extrair a propriedade 'name'
          return findObject?.titulo;
        }

        return null;
      })
      .filter((item) => item !== null);

    setNameArray(names as string[]);
  }, [selectedCheckboxId]);
  // -----------------------------------------------------------------------------
  const value = {
    page,
    order,
    rowsApi,
    setPage,
    handleChangePaginationMonitoring,
    pagePagination,
    pageContent,
    setEditSelectedId,
    setPageContent,
    handleGetPlanDashboardInfoId,
    orderBy,
    planName,
    nameArray,
    isSelected,
    totalPlans,
    editSelectdId,
    setRowsApi,
    filterData,
    statusModal,
    handleChangeEditPlan,
    visibleModal,
    setNameArray,
    textResearch,
    setStatusModal,
    setvisibleModal,
    statusSelectdId,
    openDeleteModal,
    setTextResearch,
    editModal,
    setTotalPlans,
    insertAlertProps,
    setEditModal,
    visibleSelectdId,
    handleRequestSort,
    selectedCheckboxId,
    setOpenDeleteModal,
    handleClickOneItem,
    handleTextResearch,
    setStatusSelectedId,
    setVisibleSelectedId,
    handleSelectAllClick,
    setSelectedCheckboxId,
    handleChangeStatusPlan,
    labelPagination,
    handleChangePagination,
    handleChangeVisiblePlan,
    handleGetMapDeliveryParams,
    arrCod,
    loadingIndicatorTable,
    tooltipContent,
    setArrayAnoMetas,
    arrayAnoMetas,
    setLabelPagination,
    comprimento_planos,
    set_comprimento_planos,
    totalPagination,
    setArrCod,
    arrayAnoProdutos,
    setArrayAnoProdutos,
    arrayAnoMacroacoes,
    setArrayAnoMacroacoes
  } as IDashboardManagementPlansContextProps;

  return (
    <DashboardManagementPlansContext.Provider value={value}>
      {children}
    </DashboardManagementPlansContext.Provider>
  );
};
