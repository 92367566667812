import React from "react";
import { Container } from './styles';
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

export const DiagnosisLayerForm = () => {
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <p>
          O exercício de planejamento é uma etapa crucial para o sucesso de qualquer organização.
          Ele envolve a realização de reuniões e a discussão de temas importantes que orientarão a
          elaboração do plano. Na Camada Diagnóstica, poderão ser preenchidos os elementos Síntese
          do Diagnóstico e Síntese do Cenário, a depender do tipo de plano selecionado.
        </p>
        <p>
          Um passo a passo que pode ajudar no exercício de planejamento desta camada é:
        </p>
        <p>
          Análise de Fragilidades: Liste as fraquezas e os passivos estruturais que podem impactar negativamente.
        </p>
        <p>          Análise de Potencialidades: Identifique as forças e ativos que podem ser alavancados para melhorar os resultados.</p>
        <p>          Resumo dos Problemas: Destaque os problemas mais críticos que precisam ser enfrentados.</p>
        <p>          Resumo das Oportunidades: Enumere as principais oportunidades que podem ser exploradas.</p>
        <p>Preencha cada campo com informações claras e objetivas, garantindo que todos os pontos importantes sejam cobertos. Isso ajudará na criação de um Plano bem estruturado e direcionado para a melhoria contínua. O exercício de planejamento é uma etapa crucial para o sucesso de qualquer organização. Ele envolve a realização de reuniões e a discussão de temas importantes que orientarão a elaboração do plano. Na Camada Diagnóstica, poderão ser preenchidos os elementos Síntese do Diagnóstico e Síntese do Cenário, a depender do tipo de plano selecionado.</p>
      </div>
      <ActionButtonElaborations
        layer_indicator={true}
        isOptional="false"
        onClick={() => {
          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const id = plan[selectedItemsMenu?.layer as string]
            ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
          handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
        }}
      />
    </Container>
  )
}