import React from "react";
import Link from "next/link";
import {
  Container,
  ContainerBlue,
  MainContainer,
  ContainerLeft,
  ContainerAcessibilidadeWrraper,
} from "./styles";
import { useRouter } from "next/router";
import { ButtonPlus } from "./ButtonPlus";
import { ButtonMenu } from "./ButtonMenu";
import { ButtonSair } from "./ButtonSair";
import { useAuth } from "@/hooks/useAuth";
import { LogoPrefeitura } from "../Footer/styles";
import ButtonTheme from "./ButtonTheme/ButtonTheme";
import { useAccessibility } from "@/hooks/useAccessibility";
import { useMediaQuery, query } from "@/hooks/useMediaQuery";
import { LogoIcon } from "./logo";

export function Header() {
  const { decreaseFontSize, increaseFontSize, fontSizeIncreased } =
    useAccessibility();
  const router = useRouter();
  const { dashboardAuth } = useAuth();

  const { isMobile } = useMediaQuery(query);

  const handleChangePage = () => {
    window.location.href = '/'
  }

  return (
    <Container>
      <ContainerBlue>
        <MainContainer>
          <ContainerLeft>
            <ButtonMenu />
            <a>
              <LogoIcon onClick={handleChangePage} />
            </a>
          </ContainerLeft>
          {!isMobile && (
            <ContainerAcessibilidadeWrraper>
              <div className={fontSizeIncreased ? "box" : "box-clear"}>
                <ButtonPlus
                  onClick={() =>
                    !fontSizeIncreased ? increaseFontSize() : decreaseFontSize()
                  }
                />
              </div>
              <ButtonTheme />
              {dashboardAuth &&
              router.pathname.split("/").includes("dashboard") && (
                <ButtonSair />
              )}
            </ContainerAcessibilidadeWrraper>
          )}
          {isMobile && (
            <>
              {dashboardAuth &&
              router.pathname.split("/").includes("dashboard") && (
                <ButtonSair />
              )}
            </>
          )}
        </MainContainer>
      </ContainerBlue>
    </Container>
  );
}
