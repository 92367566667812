import styled from "styled-components";

export const Circle = styled.div`
  width: var(--width, 50px);
  aspect-ratio: 1;

  background: linear-gradient(white,white) padding-box, conic-gradient(white, gray 50%) border-box;
  border-radius: 50em;
  border: 5px solid transparent;
  animation: rotation 2000ms infinite linear;

  @keyframes rotation {
    from {
        transform: rotate(0deg);
        }
    to {
        transform: rotate(359deg);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: grid;
  place-items:center;

  margin-block: 25px;

`;

