import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFormSetError,
} from "react-hook-form";
import _ from 'lodash';
import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";

interface IFormStrategy {
  isOptionalStrategy: string;
  fieldsStrategy: Record<"id", string>[];
  errosStrategy: FieldErrors<InputsStrategy>;
  resetStrategy: UseFormReset<InputsStrategy>;
  watchStrategy: UseFormWatch<InputsStrategy>;
  setErrorStrategy: UseFormSetError<InputsStrategy>;
  controlStrategy: Control<InputsStrategy, any>;
  setValueStrategy: UseFormSetValue<InputsStrategy>;
  registerStrategy: UseFormRegister<InputsStrategy>;
  updateStrategy: UseFieldArrayUpdate<InputsStrategy, "estrategia">;
  appendStrategy: UseFieldArrayAppend<InputsStrategy, "estrategia">;
  handleSubmitStrategy: UseFormHandleSubmit<InputsStrategy, undefined>;
}

export const StrategyContext = React.createContext({} as IFormStrategy);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsStrategy = {
  estrategia: any
  is_optional?: boolean;
};

const defaultValue = {
  id: '',
  texto: '',
  data: null
}

const SchemaStrategy = Yup.object().shape({
  estrategia: Yup.array().required('Campo obrigatório')
});

export const StrategyContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetStrategy,
    watch: watchStrategy,
    setError: setErrorStrategy,
    control: controlStrategy,
    register: registerStrategy,
    setValue: setValueStrategy,
    handleSubmit: handleSubmitStrategy,
    formState: { errors: errosStrategy } } = useForm<InputsStrategy>({
      resolver: yupResolver(SchemaStrategy),
      defaultValues: { estrategia: [[defaultValue]] }
    });

  const { fields: fieldsStrategy, append: appendStrategy, update: updateStrategy } = useFieldArray({
    control: controlStrategy,
    name: "estrategia"
  });

  const value = {
    errosStrategy,
    resetStrategy,
    watchStrategy,
    fieldsStrategy,
    appendStrategy,
    updateStrategy,
    controlStrategy,
    setValueStrategy,
    registerStrategy,
    setErrorStrategy,
    handleSubmitStrategy,
  } as IFormStrategy;

  return <StrategyContext.Provider value={value}>{children}</StrategyContext.Provider>;
};