import { useTheme } from "@/services/zustand/theme";

export const MunicipalEducacao = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.875 105L23.625 85.125V55.125L5 45L59.875 15L115 45V84.625H107.5V49.375L96.125 55.125V85.125L59.875 105ZM59.875 66.5L99.25 45L59.875 23.875L20.75 45L59.875 66.5ZM59.875 96.5L88.625 80.625V59.625L59.875 75L31.125 59.375V80.625L59.875 96.5Z"
        fill="url(#paint0_linear_1941_43851)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43851"
          x1="30.4375"
          y1="28.5"
          x2="68.9307"
          y2="104.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
