import React from "react"
import { Grid } from '@mui/material'
import { Container } from './styles';
import { ActionButtonElaborations } from "../../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";

const howWorks = [
  {
    title: `Processamento de Linguagem Natural (PLN):`,
    subItems: [
      {
        description: 'Entende e interpreta a linguagem humana.'
      },
      {
        description: 'Foca na interação entre computadores e linguagem natural, compreendendo e gerando texto.'
      }
    ]
  },
  {
    title: 'Inteligência Artificial (IA):',
    subItems: [
      {
        description: 'Realiza tarefas que normalmente exigiriam inteligência humana, como aprendizado, raciocínio, resolução de problemas e reconhecimento de padrões.'
      }
    ]
  }
]

const tecnologies = [
  {
    title: 'Transformação de Textos:',
    subItems: [
      {
        description: 'Converte os textos dos objetivos em representações numéricas.',
      },
      {
        description: 'Utiliza uma técnica chamada word embedding, que gera vetores numéricos densos, representando o contexto e significado dos textos.'
      }
    ]
  },
  {
    title: 'Modelo BERT:',
    subItems: [
      {
        description: 'Usa o BERT (Bidirectional Encoder Representations from Transformers), um modelo de Deep Learning desenvolvido pelo Google AI Language.'
      },
      {
        description: 'Treinado especificamente com textos jurídicos para melhor compreensão do contexto dos planos, usando a versão stjiris/bert-large-portuguese-cased-legal-tsdae-gpl-nli-sts-v1.'
      }
    ]
  }
]

export const SimilarityMacroactionDemonstrationForm: React.FC = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();
  return (
    <Container>
      <Grid container spacing={2}>
        <div className="first_row">
          <p>A Ferramenta de Similaridade ajuda você a comparar suas metas com as metas de todos os planos vigentes na plataforma Interplan.Rio, usando tecnologias avançadas de Processamento de Linguagem Natural (PLN) e Inteligência Artificial (IA). </p>
          <span>Como funciona?</span>
          {howWorks.map((item, index) => {
            return (
              <div>
                <b>
                  <p>{`${index + 1} ${item.title}`}</p>
                </b>
                <ul>
                  {item.subItems.map((subitem, subIndex) => {
                    return (
                      <li>
                        {subitem.description}
                      </li>
                    )
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="second_row">
          <span>Tecnologia utilizada</span>
          <p>
            A ferramenta usa uma API que analisa e compara os elementos preenchidos com os existentes, identificando metas semelhantes. O processo inclui:
          </p>
          <ul>
            {tecnologies.map((item, index) => {
              return (
                <div>
                  <li className="first_li">
                    {item.title}
                  </li>
                  {item.subItems.map((subItem, subIndex) => {
                    return (
                      <li className="second_li">{subItem.description}</li>
                    );
                  })}
                </div>
              )
            })}
          </ul>
        </div>
        <div className="third_row">
          <span>Calculando a Similaridade</span>
          <p>
            A ferramenta calcula a similaridade entre os vetores numéricos das metas dos planos. Essa similaridade é determinada pela proximidade dos vetores no espaço multidimensional, medida pelo cosseno do ângulo entre eles. O resultado varia de -1 a 1, sendo que valores mais próximos de 1 indicam maior similaridade.
          </p>
        </div>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12}>
          <ActionButtonElaborations sendingText="Avançar" onClick={() => {
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            const id = plan[selectedItemsMenu?.layer as string]
              ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
          }} isOptional="false" />
        </Grid>
      </Grid>
    </Container>
  )
}
