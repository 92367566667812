import { useTheme } from "@/services/zustand/theme";

export const MunicipalMataAtlantica = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 55C20.3333 55 18.5417 54.2917 17.125 52.875C15.7083 51.4583 15 49.6667 15 47.5C15 45.3333 15.7083 43.5417 17.125 42.125C18.5417 40.7083 20.3333 40 22.5 40C24.6667 40 26.4583 40.7083 27.875 42.125C29.2917 43.5417 30 45.3333 30 47.5C30 49.6667 29.2917 51.4583 27.875 52.875C26.4583 54.2917 24.6667 55 22.5 55ZM15 110V85H10V65C10 63.5833 10.4792 62.3958 11.4375 61.4375C12.3958 60.4792 13.5833 60 15 60H30C31.4167 60 32.6042 60.4792 33.5625 61.4375C34.5208 62.3958 35 63.5833 35 65V85H30V100H70V75H61.25C55.3333 75 50.3125 72.9375 46.1875 68.8125C42.0625 64.6875 40 59.6667 40 53.75C40 49.3333 41.1875 45.3958 43.5625 41.9375C45.9375 38.4792 49 35.9167 52.75 34.25C53.6667 28.8333 56.1875 24.2708 60.3125 20.5625C64.4375 16.8542 69.3333 15 75 15C80.6667 15 85.5625 16.8542 89.6875 20.5625C93.8125 24.2708 96.3333 28.8333 97.25 34.25C101 35.9167 104.062 38.4792 106.438 41.9375C108.812 45.3958 110 49.3333 110 53.75C110 59.6667 107.938 64.6875 103.812 68.8125C99.6875 72.9375 94.6667 75 88.75 75H80V100H105V110H15ZM61.25 65H88.75C91.9167 65 94.5833 63.9167 96.75 61.75C98.9167 59.5833 100 56.9167 100 53.75C100 51.5 99.3958 49.4583 98.1875 47.625C96.9792 45.7917 95.3333 44.4167 93.25 43.5L88 41.25L87.25 35.75C86.75 32.6667 85.3542 30.1042 83.0625 28.0625C80.7708 26.0208 78.0833 25 75 25C71.9167 25 69.2292 26.0208 66.9375 28.0625C64.6458 30.1042 63.25 32.6667 62.75 35.75L62 41.25L56.75 43.5C54.6667 44.4167 53.0208 45.7917 51.8125 47.625C50.6042 49.4583 50 51.5 50 53.75C50 56.9167 51.0833 59.5833 53.25 61.75C55.4167 63.9167 58.0833 65 61.25 65Z"
        fill="url(#paint0_linear_2105_38540)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2105_38540"
          x1="33.125"
          y1="29.25"
          x2="77.1806"
          y2="104.303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
