import { useTheme } from "@/services/zustand/theme";

export const PortoMaravilha = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 94.875V87.375C12.9167 87.0417 15.2708 86.1458 17.0625 84.6875C18.8542 83.2292 21.75 82.5 25.75 82.5C30 82.5 33.1667 83.3125 35.25 84.9375C37.3333 86.5625 39.875 87.375 42.875 87.375C45.9583 87.375 48.5833 86.5625 50.75 84.9375C52.9167 83.3125 56 82.5 60 82.5C64.25 82.5 67.3958 83.3125 69.4375 84.9375C71.4792 86.5625 74.0833 87.375 77.25 87.375C80.1667 87.375 82.6667 86.5625 84.75 84.9375C86.8333 83.3125 90 82.5 94.25 82.5C98.25 82.5 101.146 83.2292 102.938 84.6875C104.729 86.1458 107.083 87.0417 110 87.375V94.875C106.75 94.875 103.958 94.0625 101.625 92.4375C99.2917 90.8125 96.7917 90 94.125 90C91.0417 90 88.4583 90.8125 86.375 92.4375C84.2917 94.0625 81.2083 94.875 77.125 94.875C72.9583 94.875 69.8542 94.0625 67.8125 92.4375C65.7708 90.8125 63.1667 90 60 90C56.9167 90 54.3542 90.8125 52.3125 92.4375C50.2708 94.0625 47.1667 94.875 43 94.875C38.8333 94.875 35.7292 94.0625 33.6875 92.4375C31.6458 90.8125 29.0417 90 25.875 90C23.2083 90 20.75 90.8125 18.5 92.4375C16.25 94.0625 13.4167 94.875 10 94.875ZM28.75 75C26.9167 75 25.1667 74.6667 23.5 74C21.8333 73.3333 20.3333 72.3333 19 71L13 65L18.25 59.75L24.25 65.625C24.9167 66.2917 25.625 66.7708 26.375 67.0625C27.125 67.3542 27.9167 67.5 28.75 67.5H36.25V44.25L28.625 49.75L24.25 43.75L60 17.5L95.75 43.75L91.375 49.875L83.75 44.25V67.5H91.25C92.1667 67.5 92.9792 67.3542 93.6875 67.0625C94.3958 66.7708 95.0833 66.2917 95.75 65.625L101.75 59.75L107 65L101 71C99.6667 72.3333 98.1667 73.3333 96.5 74C94.8333 74.6667 93.0833 75 91.25 75H28.75ZM43.75 67.5H56.25V57.5H63.75V67.5H76.25V38.75L60 26.875L43.75 38.75V67.5Z"
        fill="url(#paint0_linear_1941_17658)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_17658"
          x1="33.125"
          y1="29.1063"
          x2="65.1092"
          y2="96.0062"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
