import React from "react";
import { Container } from './styles';
import { useRouter } from "next/router";

interface IProps {
  preview?: boolean;
  isEmpty?: boolean;
  disable?: boolean;
  isOptional?: string;
  onClick?: () => void;
  sendingText?: string;
  layer_indicator?: boolean;
}

export const ActionButtonElaborations: React.FC<IProps> = ({ 
  disable, 
  onClick, 
  preview, 
  isOptional,
  sendingText, 
  isEmpty=false, 
  layer_indicator, 
}) => {
  const router = useRouter();
  if(router.asPath.includes('preview')){
    return
  }

  return (
    <Container disable={disable}>
      {(!layer_indicator && isOptional == 'true') && (!isEmpty) && (
        <button type='submit' className="save_button_elaborarion">
          <p>Salvar</p>
        </button>
      )}
      {preview && (
        <button type='button' className="next_step_elaboration" onClick={() => {
          router.push("pre_visualizacao")
        }}>
          <p>Pré-visualizar plano</p>

        </button>
      )}
      <button
        type="submit"
        name="next_step"
        disabled={disable}
        className="next_step_elaboration"
        onClick={() => onClick && onClick()}
      >
        {sendingText && ( <p>{sendingText}</p> )}
        {!sendingText && ( <p>{'Próxima etapa'}</p> )}
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 4.5L10.59 5.91L16.17 11.5H4V13.5H16.17L10.59 19.09L12 20.5L20 12.5L12 4.5Z" fill="white" />
        </svg>
      </button>
    </Container>
  );
}