import styled, { css } from 'styled-components';

interface IContainerProps {
  disable?: boolean;
}

export const Container = styled.div<IContainerProps>`
  gap: 17px;
  width: 100%;
  display: flex;
  margin-top: 30px;
  margin-bottom: 87px;
  justify-content: end;


  ${({ disable }) => disable && css`
    button {
      background: rgb(180, 180, 180) !important;
    }
  
  `}

  .save_button_elaborarion {
    border: none;
    outline: none;
    display: flex;
    padding: 8px 22px;
    background: #00C0F3;
    border-radius: 20px;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 1px 5px 0px 
    rgba(0, 0, 0, 0.12), 0px 2px 2px 0px 
    rgba(0, 0, 0, 0.14), 0px 3px 1px -2px 
    rgba(0, 0, 0, 0.20);
  
    p {
      color: #FFF;
      font-size: 19px;
      font-weight: 700;
      line-height: 130%;
      font-style: normal;
      font-family: "Open Sans";
    }
  }

  .next_step_elaboration {
    border: none;
    display: flex;
    align-items: center;
    background: #004A80;
    border-radius: 20px;
    justify-content: center;
    padding: 8px 18px 8px 22px;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);

    p {
      color: #FFF;
      font-size: 19px;
      font-weight: 700;
      line-height: 130%;
      font-style: normal;
      font-family: "Open Sans";
    }
}
`