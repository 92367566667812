import React from "react"
import { Grid } from '@mui/material'
import { MainContainer } from './styled';
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

export const MacroactionDemonstrationForm: React.FC = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();

  return (
    <MainContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="first_row">
            <span>Como montar uma Macroação?</span>
            <p>
              Para criar uma macroação eficaz, descreva claramente a ação estruturante que precisa ser realizada. A macroação deve indicar de maneira específica o que deve ser feito para produzir os resultados desejados e alcançar os objetivos e metas estabelecidas. Assegure-se de que cada macroação esteja conectada a pelo menos uma meta da camada estratégica do plano.
            </p>
          </div>
          <div className="second_row">
            <span>Exemplos</span>
            <p>
              “Criar campanhas e conteúdos infanto-juvenis em planejamento sustentável com periodicidade para alimentar a plataforma Participa.rio.” (Fonte: Plano de Desenvolvimento Sustentável e Ação Climática)
            </p>
            <p>
              Implementar um programa de formação continuada para professores visando a integração de metodologias bilíngues em todas as escolas municipais.
            </p>
            <p>
              Construir e equipar cinco novos centros de atendimento especializado em doenças crônicas.
            </p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12}>
          <ActionButtonElaborations onClick={() => {
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            const id = plan[selectedItemsMenu?.layer as string]
              ?.find((item: any) => item?.id == selectedItemsMenu?.id)?.id + 1;

            handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
          }} isOptional="false" />
        </Grid>
      </Grid>
    </MainContainer>
  )
}
