import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { InfoIncon } from '../../../Inputs/info';
import { ConsiderationModal } from '../../ConsiderationModal';
import { ButtonWrapper } from '../../ConsiderationModal/styles';
import { ConsideracaoIcon } from '../../../Inputs/consideracao';
import { Container, ListIconWrapper, SvgContainer } from './styled';
import { Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';

import { Svg_007 } from './svgs/004-bank';
import { Svg_002 } from './svgs/001-email';
import { Svg_006 } from './svgs/003-coins';
import { Svg_004 } from './svgs/002-wallet';
import { Svg_005 } from './svgs/003-address';
import { Svg_003 } from './svgs/002-clipboard';
import { Svg_001 } from './svgs/001-100 dollar bill';
import { PlansService } from '@/services/endpoints/plans';

const svg_dict_icon = [
  {
    id: 1,
    clicked: false,
    icon: (<Svg_001 />),
    file: '/files_icon_download/001_dollar.svg'
  },
  {
    id: 2,
    clicked: false,
    icon: (<Svg_002 />),
    file: '/files_icon_download/002_email.svg'
  },
  {
    id: 3,
    clicked: false,
    icon: (<Svg_003 />),
    file: '/files_icon_download/003_clipboard.svg'
  },
  {
    id: 4,
    clicked: false,
    icon: (<Svg_004 />),
    file: '/files_icon_download/004_wallet.svg'
  },
  {
    id: 5,
    clicked: false,
    icon: (<Svg_005 />),
    file: '/files_icon_download/005_address.svg'
  },
  {
    id: 6,
    clicked: false,
    icon: (<Svg_006 />),
    file: '/files_icon_download/006_coins.svg'
  },
  {
    id: 7,
    clicked: false,
    icon: (<Svg_007 />),
    file: '/files_icon_download/007_bank.svg'
  }
]

interface IProps {
  errors: any;
  name: string;
  label: string;
  register: any;
  getDados?: any;
  tooltip?: string;
  required: boolean;
  defaultValue?: string;
  downloadName?: string;
  setValue: (e: any) => void;
  accept: "image/*" | ".pdf" | ".svg" | ".docx";
}

export const IconesModal: React.FC<IProps> = ({
  name,
  label,
  tooltip,
  required,
  register,
  setValue,
  defaultValue,
  downloadName,
}) => {
  return (
    <Container>
      <div className="title_wrapper_textfield">
        <ToolTipWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          register={register}
          value={defaultValue}
        />
      </div>
      <div className="file-input">
        <IconesWrapper setValue={(e) => setValue(e)} downloadName={downloadName} />
      </div>
    </Container>
  )
}

type TooltipWrapperProps = {
  name?: string,
  register?: any,
  value?: string,
  local?: string,
  label?: string,
  tooltip?: string,
  required?: boolean,
  hiddeInput?: boolean,
}

const ToolTipWrapper = ({
  name,
  label,
  value,
  tooltip,
  required,
  register,
}: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = useState<boolean>(false);

  useEffect(() => {
    if (!router.isReady) return
    if (!router.query.id) return
    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl && <>
        {tooltip && tooltip?.length > 0 && (
          <Tooltip arrow title={tooltip}>
            <div>
              <InfoIncon />
            </div>
          </Tooltip>
        )}
      </>
      }
      {epl && (
        <CondiserationWrapper
          name={name}
          label={label}
          value={value}
          register={register}
        />
      )}
    </div>
  )

}

const IconesWrapper = ({
  name,
  setValue,
  downloadName,
}: {
  name?: string,
  register?: any,
  label?: string,
  value?: string,
  downloadName?: string;
  setValue: (e: any) => void;
}) => {

  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [preview, setPreview] = useState<boolean>(false);
  const { DeleteFileFinal } = new PlansService()

  const handleClose = () => {
    setOpen(false);
  };

  const [blob, setBlob] = useState<File>()
  const salvar = () => {
    setValue(blob);
    handleClose();
  }

  useEffect(() => {
    if (!router.isReady) return
    setPreview(router.asPath.includes('preview'));
  }, [router]);

  const [icons, setIcons] = useState(svg_dict_icon);

  const handleIconClick = async (id: any) => {
    setIcons((prevIcons) =>
      prevIcons.map((item) =>
        item.id === id
          ? { ...item, clicked: true }
          : { ...item, clicked: false }
      )
    );

    const clickedIcon = icons.find((icon) => icon.id === id) as any;

    const response = await fetch(clickedIcon.file);
    const blob = await response.blob();

    const fileName = clickedIcon.file.split('/').pop(); // Nome do arquivo
    const file = new File([blob], fileName, { type: blob.type });

    setBlob(file)
  };

  const deleteFile = () => {
    DeleteFileFinal({
      fileType: name,
      fileName: downloadName,
      planoId: router.query.id,
    });
  }


  return (
    <>
      {!downloadName && (
        <label className={preview ? 'file-input__label_preview' : 'file-input__label'} htmlFor={"file-input" + name} onClick={() => { if (!preview) setOpen(true) }}>
          <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00610352 9.1875C0.00610352 7.56305 0.651414 6.00513 1.80007 4.85647C2.94873 3.70781 4.50665 3.0625 6.1311 3.0625H42.8811C44.5056 3.0625 46.0635 3.70781 47.2121 4.85647C48.3608 6.00513 49.0061 7.56305 49.0061 9.1875V39.8125C49.0061 41.437 48.3608 42.9949 47.2121 44.1435C46.0635 45.2922 44.5056 45.9375 42.8811 45.9375H6.1311C4.50665 45.9375 2.94873 45.2922 1.80007 44.1435C0.651414 42.9949 0.00610352 41.437 0.00610352 39.8125V9.1875ZM3.0686 36.75V39.8125C3.0686 40.6247 3.39126 41.4037 3.96559 41.978C4.53992 42.5523 5.31888 42.875 6.1311 42.875H42.8811C43.6933 42.875 44.4723 42.5523 45.0466 41.978C45.6209 41.4037 45.9436 40.6247 45.9436 39.8125V29.0938L34.3765 23.1311C34.0893 22.9872 33.7642 22.9373 33.4471 22.9884C33.1299 23.0395 32.8369 23.1891 32.6095 23.4159L21.2476 34.7778L13.1014 29.351C12.8072 29.1552 12.4544 29.0671 12.1028 29.1017C11.7511 29.1363 11.4223 29.2915 11.172 29.5409L3.0686 36.75ZM18.3811 16.8438C18.3811 15.6254 17.8971 14.457 17.0356 13.5955C16.1741 12.734 15.0057 12.25 13.7874 12.25C12.569 12.25 11.4006 12.734 10.5391 13.5955C9.67759 14.457 9.1936 15.6254 9.1936 16.8438C9.1936 18.0621 9.67759 19.2305 10.5391 20.092C11.4006 20.9535 12.569 21.4375 13.7874 21.4375C15.0057 21.4375 16.1741 20.9535 17.0356 20.092C17.8971 19.2305 18.3811 18.0621 18.3811 16.8438Z" fill="#818181" />
          </svg>
          <span>Selecionar ícone do plano</span>
        </label>
      )}
      {downloadName && (
        <div>
          <a
            href={`https://dev.interplan.macrodevplan.kinghost.net/api/final/download/${downloadName}`}
            style={{ color: '#004A80' }}
          >Baixar arquivo</a>
          {!preview && (
            <button onClick={deleteFile} style={{ background: 'transparent', border: 'none', marginLeft: 15 }}>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.755 1.12685C4.82416 0.988448 4.93048 0.872034 5.06206 0.790643C5.19364 0.709252 5.34528 0.666098 5.5 0.666016H10.5C10.6547 0.666098 10.8064 0.709252 10.9379 0.790643C11.0695 0.872034 11.1758 0.988448 11.245 1.12685L12.6817 3.99935H14.6667C14.8877 3.99935 15.0996 4.08715 15.2559 4.24343C15.4122 4.39971 15.5 4.61167 15.5 4.83268C15.5 5.0537 15.4122 5.26566 15.2559 5.42194C15.0996 5.57822 14.8877 5.66602 14.6667 5.66602H13.8333V14.8327C13.8333 15.4957 13.5699 16.1316 13.1011 16.6004C12.6323 17.0693 11.9964 17.3327 11.3333 17.3327H4.66667C4.00363 17.3327 3.36774 17.0693 2.8989 16.6004C2.43006 16.1316 2.16667 15.4957 2.16667 14.8327V5.66602H1.33333C1.11232 5.66602 0.900358 5.57822 0.744078 5.42194C0.587797 5.26566 0.5 5.0537 0.5 4.83268C0.5 4.61167 0.587797 4.39971 0.744078 4.24343C0.900358 4.08715 1.11232 3.99935 1.33333 3.99935H3.31833L4.755 1.12685ZM9.985 2.33268L10.8183 3.99935H5.18167L6.015 2.33268H9.985ZM7.16667 8.16602C7.16667 7.945 7.07887 7.73304 6.92259 7.57676C6.76631 7.42048 6.55435 7.33268 6.33333 7.33268C6.11232 7.33268 5.90036 7.42048 5.74408 7.57676C5.5878 7.73304 5.5 7.945 5.5 8.16602V13.166C5.5 13.387 5.5878 13.599 5.74408 13.7553C5.90036 13.9116 6.11232 13.9993 6.33333 13.9993C6.55435 13.9993 6.76631 13.9116 6.92259 13.7553C7.07887 13.599 7.16667 13.387 7.16667 13.166V8.16602ZM10.5 8.16602C10.5 7.945 10.4122 7.73304 10.2559 7.57676C10.0996 7.42048 9.88768 7.33268 9.66667 7.33268C9.44565 7.33268 9.23369 7.42048 9.07741 7.57676C8.92113 7.73304 8.83333 7.945 8.83333 8.16602V13.166C8.83333 13.387 8.92113 13.599 9.07741 13.7553C9.23369 13.9116 9.44565 13.9993 9.66667 13.9993C9.88768 13.9993 10.0996 13.9116 10.2559 13.7553C10.4122 13.599 10.5 13.387 10.5 13.166V8.16602Z" fill="#EC1F1F" />
              </svg>
            </button>
          )}
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">
          Ícones
        </DialogTitle>
        <DialogContent>
          <p style={{ marginBottom: '1rem' }}>
            Selecione um ícone
          </p>
          <ListIconWrapper>
            {icons.map(item => (
              <SvgContainer
                key={item.id}
                clicked={item.clicked}
                onClick={() => handleIconClick(item.id)}
              >
                {item.icon}
              </SvgContainer>
            ))}
          </ListIconWrapper>
          <ButtonWrapper>
            <button className="cancel" onClick={() => handleClose()}>
              <p>Cancelar</p>
            </button>
            <button onClick={() => salvar()}>
              <p>Salvar</p>
            </button>
          </ButtonWrapper>
        </DialogContent>
      </Dialog>
    </>
  )
}

const CondiserationWrapper = ({
  name,
  value,
  label,
  register,
}: {
  name?: string,
  register?: any,
  label?: string,
  value?: string,
}) => {

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        name={name}
        label={label}
        value={value}
        register={register}
        handleClose={handleClose}
      />
    </>
  )
}