import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import dayjs from "dayjs";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";
import React, { useState, useEffect, createContext, Dispatch, SetStateAction, ReactNode } from "react";

interface IFormProductsDeliveries {
  page: number;
  label: string;
  objetivos: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  setPage: Dispatch<SetStateAction<number>>;
  removeProductsDeliveries: UseFieldArrayRemove;
  fieldsProductsDeliveries: Record<"id", string>[];
  setCurrentIndexEixo: Dispatch<SetStateAction<number>>;
  transformProductsDeliveriesDataReuse: (item: any, label?: any) => any;
  controlProductsDeliveries: Control<InputsProductsDeliveries>;
  errosProductsDeliveries: FieldErrors<InputsProductsDeliveries>;
  resetProductsDeliveries: UseFormReset<InputsProductsDeliveries>;
  watchProductsDeliveries: UseFormWatch<InputsProductsDeliveries>;
  setValueProductsDeliveries: UseFormSetValue<InputsProductsDeliveries>;
  registerProductsDeliveries: UseFormRegister<InputsProductsDeliveries>;
  handleSubmitProductsDeliveries: UseFormHandleSubmit<InputsProductsDeliveries>;
  updateProductsDeliveries: UseFieldArrayUpdate<InputsProductsDeliveries, "produtos_entregas">;
  appendProductsDeliveries: UseFieldArrayAppend<InputsProductsDeliveries, "produtos_entregas">;
}

export const ProductsDeliveriesContext = createContext({} as IFormProductsDeliveries);

interface IFormsPreparationOfPlansProps {
  children: ReactNode;
}

type InputsProductsDeliveries = {
  produtos_entregas: any;
  is_optional?: boolean;
}

const SchemaProductsDeliveries = Yup.object().shape({
  produtos_entregas: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      setor: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      valor: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      indicadores: Yup.array().of(
        Yup.object().shape({
          fonte: Yup.string().required("Campo obrigatório"),
          indicador: Yup.string().required('Campo obrigatório'),
          polaridade: Yup.string().required('Campo obrigatório'),
          unidadeMedida: Yup.string().required('Campo obrigatório'),
          formulaCalculo: Yup.string().required('Campo obrigatório'),
          planejados: Yup.array().of(
            Yup.object().shape({
              prazo: Yup.date().nonNullable().required('Campo obrigatório'),
              planejado: Yup.string().required('Campo obrigatório')
            })
          )
        })
      )
    })
  ).required(''),
});

const defaultValue = {
  id: '',
  texto: '',
  territorio: '',
  tor: [],
  valor: '',
  orgao: '',
  setor: '',
  responsavel: '',
  indicadores: [
    {
      id: '',
      fonte: '',
      indicador: '',
      polaridade: '',
      unidadeMedida: '',
      formulaCalculo: '',
      planejados: [
        {
          id: '',
          prazo: null,
          planejado: '',
        }
      ]
    }
  ]
}

const transformProductsDeliveriesData = (data: any[], label?: string) => {
  return data.map(item => {
    let tor = [] as any[]
    tor = item?.entregasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.entregasTerritorio);

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      prazo: item?.data ? dayjs(item?.data) : null,
      valor: item?.valor || '',
      responsavel: item?.responsavel || '',
      indicadores: item?.indicadores?.map((indicador: any) => ({
        id: indicador?.id,
        fonte: indicador?.fonte || '',
        indicador: indicador?.indicador || '',
        polaridade: indicador?.polaridade || '',
        unidadeMedida: indicador?.unidadeMedida || '',
        formulaCalculo: indicador?.formulaCalculo || '',
        planejados: indicador?.values?.map((planejado: any) => ({
          id: planejado?.id,
          prazo: planejado?.data ? dayjs(planejado?.data) : null,
          planejado: planejado?.planejado || ''
        }))
      }))
    };
  });
};

const transformProductsDeliveriesDataReuse = (item: any, label: any) => {
  let tor = [] as any[]

    tor = item?.entregasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.entregasTerritorio);

  return {
    texto: item?.texto || '',
    territorio,
    tor,
    valor: item?.valor || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
    indicadores: item?.indicadores?.map((indicador: any) => ({
      id: indicador?.id,
      fonte: indicador?.fonte || '',
      indicador: indicador?.indicador || '',
      polaridade: indicador?.polaridade || '',
      unidadeMedida: indicador?.unidadeMedida || '',
      formulaCalculo: indicador?.formulaCalculo || '',
      planejados: indicador?.values?.map((planejado: any) => ({
        id: planejado?.id,
        prazo: planejado?.data ? dayjs(planejado?.data) : null,
        planejado: planejado?.planejado || ''
      }))
    }))
  };
};

export const ProductsDeliveriesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetProductsDeliveries,
    watch: watchProductsDeliveries,
    control: controlProductsDeliveries,
    register: registerProductsDeliveries,
    setValue: setValueProductsDeliveries,
    clearErrors,
    handleSubmit: handleSubmitProductsDeliveries,
    formState: { errors: errosProductsDeliveries }
  } = useForm<InputsProductsDeliveries>({
    resolver: yupResolver(SchemaProductsDeliveries),
    defaultValues: { produtos_entregas: [defaultValue] }
  });

  const { fields: fieldsProductsDeliveries, append: appendProductsDeliveries, update: updateProductsDeliveries, remove: removeProductsDeliveries } = useFieldArray({
    control: controlProductsDeliveries,
    name: "produtos_entregas"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans();
  const { GetThematicAxisElaboration, GetProjectsEixoElaborations, GetProgramsEixoElaborations, GetMacroactionsEixoElaborations } = new ElaborationService();

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [objetivos, setObjetivos] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query.id, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
  //     const projetos = completCode.find(item => item.code == 'projetos' && item.skipped === false);
  //     const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped === false);
  //     const programas = completCode.find(item => item.code == 'programas' && item.skipped === false);



  //     if (programas !== undefined && macroacoes != undefined && projetos == undefined) {

  //       GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Programas');
  //       });
  //       return;
  //     }

  //     if (projetos !== undefined && macroacoes == undefined && programas == undefined) {

  //       GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Projetos');
  //       });
  //       return;
  //     }

  //     if (projetos != undefined && macroacoes == undefined && projetos != undefined) {

  //       GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Projetos');
  //       });
  //       return;
  //     }

  //     if (programas != undefined && macroacoes != undefined && projetos != undefined) {
  //       GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Projetos');
  //       });
  //       return;
  //     }

  //     if (programas == undefined && macroacoes != undefined && projetos != undefined) {
  //       GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Projetos');
  //       });
  //       return;
  //     }

  //     if (programas != undefined && macroacoes == undefined && projetos == undefined) {

  //       GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Programas');
  //       });
  //       return;
  //     }

  //     if (macroacoes != undefined && programas == undefined && projetos == undefined) {
  //       GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setObjetivos(res.data);
  //         setLabel('Macroação');
  //       });
  //     }
  //   }
  // }, [completCode, router.query, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  // useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
  //     const data = objetivos[page - 1];
  //     clearErrors()

  //     if (label == 'Macroação') {
  //       if (data?.entregas?.length > 0) {
  //         setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregas, label));
  //       } else {
  //         setValueProductsDeliveries('produtos_entregas', [defaultValue])
  //       }
  //     }

  //     if (label == 'Projetos') {

  //       if (data?.entregasProjetos?.length > 0) {
  //         setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregasProjetos, label));
  //       } else {
  //         setValueProductsDeliveries('produtos_entregas', [defaultValue])
  //       }
  //     }
  //     if (label == 'Programas') {
  //       if (data?.entregasProjetos?.length > 0) {
  //         setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregas, label));
  //       } else {
  //         setValueProductsDeliveries('produtos_entregas', [defaultValue])
  //       }
  //     }


  //   }
  // }, [objetivos, page, router.query, router.pathname, completCode]);

  const value = {
    page,
    label,
    setPage,
    objetivos,
    eixos_tematicos,
    currentIndexEixo,
    setCurrentIndexEixo,
    watchProductsDeliveries,
    resetProductsDeliveries,
    errosProductsDeliveries,
    fieldsProductsDeliveries,
    updateProductsDeliveries,
    appendProductsDeliveries,
    removeProductsDeliveries,
    controlProductsDeliveries,
    setValueProductsDeliveries,
    registerProductsDeliveries,
    handleSubmitProductsDeliveries,
    transformProductsDeliveriesDataReuse,
  };

  return <ProductsDeliveriesContext.Provider value={value}>{children}</ProductsDeliveriesContext.Provider>;
};
