interface IProps {
  onClose: () => void;
}
export const CloseIcon = ({ onClose }: IProps) => {
  return (
    <svg
      onClick={onClose}
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
    >
      <path
        d="M34.8334 11.752L32.2484 9.16699L22.0001 19.4153L11.7517 9.16699L9.16675 11.752L19.4151 22.0003L9.16675 32.2487L11.7517 34.8337L22.0001 24.5853L32.2484 34.8337L34.8334 32.2487L24.5851 22.0003L34.8334 11.752Z"
        fill="#004A80"
      />
    </svg>
  );
};
