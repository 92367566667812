import { useTheme } from "@/services/zustand/theme";

export const EstrategicoPoliticasPromocoes = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.125 105L11 85.875L30.125 66.75L36.25 72.875L27.625 81.5H92.625L84 72.875L90.125 66.75L109.25 85.875L90.125 105L84 98.875L92.625 90.25H27.625L36.25 98.875L30.125 105ZM12.5 53.125V51.5C12.5 49.7433 12.9753 48.1329 13.926 46.6689C14.8767 45.205 16.193 44.107 17.875 43.375C19.9648 42.4982 22.115 41.8206 24.3254 41.3424C26.5358 40.8641 28.8467 40.625 31.258 40.625C33.6693 40.625 35.9784 40.8641 38.1851 41.3424C40.392 41.8206 42.5386 42.4982 44.625 43.375C46.307 44.107 47.6233 45.205 48.574 46.6689C49.5247 48.1329 50 49.7433 50 51.5V53.125H12.5ZM70.25 53.125V51.5C70.25 49.7433 70.7253 48.1329 71.676 46.6689C72.6267 45.205 73.943 44.107 75.625 43.375C77.7148 42.4982 79.865 41.8206 82.0754 41.3424C84.2858 40.8641 86.5967 40.625 89.008 40.625C91.4193 40.625 93.7284 40.8641 95.9351 41.3424C98.142 41.8206 100.289 42.4982 102.375 43.375C104.057 44.107 105.373 45.205 106.324 46.6689C107.275 48.1329 107.75 49.7433 107.75 51.5V53.125H70.25ZM31.2445 33.75C28.6648 33.75 26.4583 32.8315 24.625 30.9945C22.7917 29.1574 21.875 26.9491 21.875 24.3695C21.875 21.7898 22.7935 19.5833 24.6305 17.75C26.4676 15.9167 28.6759 15 31.2555 15C33.8352 15 36.0417 15.9185 37.875 17.7555C39.7083 19.5926 40.625 21.8009 40.625 24.3805C40.625 26.9602 39.7065 29.1667 37.8695 31C36.0324 32.8333 33.8241 33.75 31.2445 33.75ZM88.9945 33.75C86.4148 33.75 84.2083 32.8315 82.375 30.9945C80.5417 29.1574 79.625 26.9491 79.625 24.3695C79.625 21.7898 80.5435 19.5833 82.3805 17.75C84.2176 15.9167 86.4259 15 89.0055 15C91.5852 15 93.7917 15.9185 95.625 17.7555C97.4583 19.5926 98.375 21.8009 98.375 24.3805C98.375 26.9602 97.4565 29.1667 95.6195 31C93.7824 32.8333 91.5741 33.75 88.9945 33.75Z"
        fill="url(#paint0_linear_2012_91976)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91976"
          x1="33.7203"
          y1="28.5"
          x2="74.7024"
          y2="100.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
