import axios from 'axios';
import Cookies from 'js-cookie';
import { ACCESS } from '@/utils/enviroments';

// instância utilizada apenas para realizar o login e obter o token jwt
export const LoginApi = axios.create({
  baseURL: ACCESS.API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

export const Api = axios.create({
  baseURL: ACCESS.API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Interceptador de token nas requisições da instância Api
Api.interceptors.request.use(async (config: any) => {
  try {
    const token = Cookies.get("TOKEN_JWT"); // Obtém o token do cookie

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Error retrieving token:", error);
  }

  return config;
});

// Interceptador de respostas para tratar erros
Api.interceptors.response.use(
  (response) => {
    // Retorna a resposta diretamente se o status for 2xx
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      // Token inválido ou expirado, redirecionar para a tela de login
      Cookies.remove("TOKEN_JWT"); // Remove o token inválido
      window.location.href = '/login'; // Redireciona para a página de login
    }

    // Retorna a Promise rejeitada para que outros erros também possam ser capturados
    return Promise.reject(error);
  }
);
export const ApiDashboard = axios.create({
  baseURL: ACCESS.API_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

ApiDashboard.interceptors.request.use(async (config: any) => {
  try {
    const tokenString = window.localStorage.getItem("DASHBOARD_JWT");
    const token = tokenString ? JSON.parse(tokenString) : null;

    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Error parsing token:", error);
  }

  return config;
});

ApiDashboard.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error.response ? error.response.status : null;

    if (status === 401) {
      window.localStorage.removeItem("DASHBOARD_JWT");
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);