import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { yupResolver } from "@hookform/resolvers/yup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ActionButtonElaborations } from "../../ActionButton";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/material';
import { TextFieldElaboration } from "../../Inputs/TextField";
import { PlansService } from "@/services/endpoints/plans";
import { Asynchronous } from "@/components/global/AutoComplete";
import { AsynchronousSelectField } from "../../Inputs/AsynchronousSelectField";
import { useRouter } from "next/router";
import { useSnackbar } from "@/hooks/useSnackbar";
import { Loading } from "@/components/global/Loading";
import { LoadingBuffer } from "../utils/Loading";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";

type Inputs = {
  metas: any[];
  objetivos: any[];
  macroacoes: any[];
  is_optional?: string;
  justificativa?: string;
}

const Schema = Yup.object().shape({
  is_optional: Yup.string(),
  metas: Yup.array().required(''),
  objetivos: Yup.array().required(''),
  macroacoes: Yup.array().required(''),
  justificativa: Yup.string(),
});

const defaultValue = {
  metas: [{}],
  objetivos: [{}],
  macroacoes: [{}],
}

export const NextLinkingForm: React.FC = () => {
  const {
    watch,
    setValue: setValueDados,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(Schema), defaultValues: {
      metas: [...defaultValue.metas],
      macroacoes: [...defaultValue.macroacoes],
      objetivos: [...defaultValue.objetivos]
    }
  });

  const { fields: fieldsGoals, append: appendGoals, remove: removeGoal, update: updateGoal } = useFieldArray({
    control,
    name: "metas"
  });

  const { fields: fieldsMacroactions, append: appendMacroactions, remove: removeMacroactions } = useFieldArray({
    control,
    name: "macroacoes"
  });

  const { fields: fieldsObjectives, append: appendObjectives, remove: removeObjectives } = useFieldArray({
    control,
    name: "objetivos"
  });

  React.useEffect(() => console.log("fieldsMacroactions", fieldsMacroactions), [fieldsMacroactions])

  const {
    getPlanosVinculados,
    getPlanoObjetivo,
    getPlanoMetas,
    getPlanoMacroacoes,
    PostVinculoFinal,
    GetVinculoFinal,
    DeleteVinculoFinal,
  } = new PlansService()

  const router = useRouter()
  const metas = watch('metas');
  const objetivos = watch('objetivos');
  const macroacoes = watch('macroacoes');
  const [planos, setPlanos] = useState([]);
  const [preview, setPreview] = useState<boolean>(false);
  const isOptional = watch("is_optional") as unknown as string;
  const justificativa = watch("justificativa") as unknown as string;
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, setDefaultItemMenuId, setNavigable, handleFetchProgressCodePlanId } = usePreparationOfPlans();

  const [loading, setLoading] = useState(false);
  const [planosMetas, setPlanosMetas] = useState([]);
  const [planosObjetivos, setPlanosObjetivos] = useState([]);
  const [planosMacroacoes, setPlanosMacroacoes] = useState([]);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [planosSelecteds, setPlanosSelecteds] = useState<any>({});

  const onSubmit: SubmitHandler<Inputs> = (data, event: any) => {

    if (disabeSubmit) return;

    setDisableSubmit(true);

    const getValues = (id: string) => {

      const div = document.getElementById(id);
      const inputs = div?.querySelectorAll('input');
      const values: any = [];

      inputs?.forEach((input) => {
        values.push(input.value);
      });

      return values
    }

    const getIds = (values: any[], planos: any[]) => {

      const itemsIds: any[] = []

      values.forEach((el: any) => {
        const metaid = planos.find((item: any) => item.title == el) as { value: number } | undefined

        if (metaid) {
          itemsIds.push(metaid.value)
        }
      });

      return itemsIds
    }

    const metasIds = getIds(getValues('metas-selects'), planosMetas)
    const objetivosIds = getIds(getValues('objetivos-selects'), planosObjetivos)
    const macroacoesIds = getIds(getValues('macroacoes-selects'), planosMacroacoes)

    console.log("submit", { metasIds, objetivosIds, macroacoesIds })

    const params = {
      metas: metasIds,
      objetivos: objetivosIds,
      macroacaos: macroacoesIds,
      justificativa: data.justificativa,
    }

    PostVinculoFinal(router.query.id, params)
      .then(() => {
        toggleSuccessSnackbar('Vinculação', 'Salva com sucesso.')

        if (event.nativeEvent.submitter.name == 'next_step') {
          setNavigable(true);
          router.push('/dashboard/elaboracao_planos/' + router.query.id)

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;

            setDefaultItemMenuId(planId);
          }
        } else {
          setNavigable(false);
          handleFetchProgressCodePlanId(router.query.id)

          router.push('/dashboard/elaboracao_planos/' + router.query.id)

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id;

            setDefaultItemMenuId(planId);
          }
        }

        setTimeout(() => toggleSuccessSnackbar(), 3000)
        setDisableSubmit(false);
      })
      .catch((err: any) => {
        toggleErrorSnackbar()
        setDisableSubmit(false);
      })
  };

  const selectChange = async (e: any) => {
    const plan_id = e.target.value

    setLoading(true)
    removeGoal()
    removeMacroactions()
    removeObjectives()

    try {
      setPlanosObjetivos([])
      const planoObjetivoData = await getPlanoObjetivo(plan_id);
      const dataObj = planoObjetivoData.data.map((item: any) => ({
        value: item.id,
        title: item.texto
      }));
      setPlanosObjetivos(dataObj);

      planosSelecteds?.objetivos.forEach((item: any, index: number) => {
        const itemInData = dataObj.find((data: any) => data.value === item.objetivo.id);
        if (itemInData) {
          setValueDados(`objetivos.${index}.objetivos`, {
            value: item.objetivo.id,
            title: item.objetivo.texto
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    try {
      setPlanosMetas([])
      const planoMetasData = await getPlanoMetas(plan_id);
      const dataMt = planoMetasData.data.map((item: any) => ({
        value: item.id,
        title: item.texto
      }));
      setPlanosMetas(dataMt);

      planosSelecteds?.metas.forEach((item: any, index: number) => {
        const itemInData = dataMt.find((data: any) => data.value === item.meta.id);
        if (itemInData) {
          setValueDados(`metas.${index}.metas`, {
            value: item.meta.id,
            title: item.meta.texto
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    try {
      setPlanosMacroacoes([])
      const planoMacroacoesData = await getPlanoMacroacoes(plan_id);
      const dataMacr = planoMacroacoesData.data.map((item: any) => ({
        value: item.id,
        title: item.texto
      }));
      setPlanosMacroacoes(dataMacr);

      planosSelecteds?.macroacaos.forEach((item: any, index: number) => {
        const itemInData = dataMacr.find((data: any) => data.value === item.macroacaos.id);
        if (itemInData) {
          setValueDados(`macroacoes.${index}.macroacoes`, {
            value: item.macroacaos.id,
            title: item.macroacaos.texto
          });
        }
      });
    } catch (error) {
      console.error(error);
    }

    setLoading(false)
  }

  const deleteItem = (plano: string, field: any) => {
    const findPlan = planosSelecteds[`${plano}`].find((selected: any) => {
      switch (plano) {
        case 'objetivos':

          if (selected.objetivo && selected.objetivo.id == field[`${plano}`]?.value) {
            return selected
          }
          break;

        case 'metas':
          if (selected.meta && selected.meta.id == field[`${plano}`]?.value) {
            return selected
          }
          break;

        case 'macroacoes':
          if (selected.macroacaos && selected.macroacaos.id == field[`${plano}`]?.value) {
            return selected
          }
          break;

        default:
          return undefined
          break;
      }

      return undefined
    })

    if (findPlan) {

      const params: any = {
        metas: [],
        macroacaos: [],
        objetivos: [],
      }

      params[`${plano}`] = [findPlan.id]

      DeleteVinculoFinal(router.query.id, params)
        .then(() => {
          // handleOpenSnackbar(
          //   "Deletado com sucesso!",
          //   ""
          // );
        })
        .catch((err: any) => {
          console.error(err)
          // handleOpenSnackbar(
          //   "Erro ao deletar!",
          //   ""
          // );
        })
    }
  }

  const [loadingBuffer, setLoadingBuffer] = React.useState(false);

  useEffect(() => {
    setLoadingBuffer(true)
    getPlanosVinculados().then(({ data }: any) => {
      let dataPlanos = data.map((item: any) => {
        if (item.plano) {
          return {
            value: item.plano.id,
            name: item.plano.plano
          }
        }
      });

      dataPlanos = dataPlanos.filter((value: any) => value !== undefined);

      setPlanos(dataPlanos)
    }).finally(() => setLoadingBuffer(false))

  
  }, [])

  useEffect(() => {

    if (!router.isReady) return
    setPreview(router.asPath.includes('preview'));

    GetVinculoFinal(router.query.id)
      .then(({ data }: any) => {
        console.log('data', data)
        setPlanosSelecteds(data)
        setValueDados('is_optional', data?.justificativa?.length > 0 ? 'false' : 'true')
        setValueDados('justificativa', data?.justificativa)
      })
  }, [router]);

  useEffect(() => {
  }, [isOptional, justificativa])

  React.useEffect(() => console.log("planosMacroacoes", { planosObjetivos, fieldsObjectives, objetivos }),[planosObjetivos, fieldsObjectives, objetivos])

  if (loadingBuffer) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Grid container spacing={2}>

          {!preview &&
            <Grid item xs={12} mt={1}>
              <RadioCheckedElaboration
                errors={errors}
                required={false}
                control={control}
                name="is_optional"
                defaultValue={isOptional == 'true' ? 'true' : 'false'}
                data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                label="Deseja vincular algum de seus objetivos, metas ou macroações aos Planos de longo prazo da Prefeitura?"
              />
            </Grid>
          }
          <Grid item xs={12} sx={{ mt: 2, mb: 2 }}>
            <div style={{ height: '1px', border: 'dashed 1px #B4B4B4' }}></div>
          </Grid>
          {isOptional == 'true' && (
            <>
              <Grid item xs={5}>
                <SelectFieldElaboration
                  data={planos}
                  errors={errors}
                  required={false}
                  register={register}
                  dataNotDisabled={true}
                  placeholder="Selecione"
                  name={`planos_vinculados`}
                  label="Planos para vinculação"
                  onChange={(e: any) => selectChange(e)}
                />
              </Grid>
              {
                loading ?
                  <Loading loading={loading} />
                  :
                  <Grid item xs={12}>
                    <AccordionWrapper
                      errors={errors}
                      name="objetivos"
                      title="Objetivos"
                      items={objetivos}
                      preview={preview}
                      register={register}
                      deleteItem={deleteItem}
                      planosItems={planosObjetivos}
                      removeItem={removeObjectives}
                      fieldsItems={fieldsObjectives}
                      appendItems={appendObjectives}
                    />

                    <AccordionWrapper
                      name="metas"
                      title="Metas"
                      items={metas}
                      errors={errors}
                      preview={preview}
                      register={register}
                      deleteItem={deleteItem}
                      removeItem={removeGoal}
                      fieldsItems={fieldsGoals}
                      planosItems={planosMetas}
                      appendItems={appendGoals}
                    />

                    <AccordionWrapper
                      errors={errors}
                      name="macroacoes"
                      preview={preview}
                      title="Macroações"
                      items={macroacoes}
                      register={register}
                      deleteItem={deleteItem}
                      planosItems={planosMacroacoes}
                      removeItem={removeMacroactions}
                      fieldsItems={fieldsMacroactions}
                      appendItems={appendMacroactions}
                    />
                  </Grid>
              }
            </>
          )}
          {isOptional == 'false' && (
            <>
              <Grid item xs={12}>
                <TextFieldElaboration
                  rows={3}
                  errors={errors}
                  required={false}
                  multiline={true}
                  register={register}
                  name="justificativa"
                  label="Justificativa"
                  placeholder={`Deseja vincular algum de seus objetivos, metas ou macroações aos Planos de longo prazo da Prefeitura?`}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 4 }}>
                <div style={{ height: '1px', background: '#B4B4B4' }}></div>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ActionButtonElaborations isOptional={isOptional == undefined ? 'false' : 'true'}
              layer_indicator={selectedItemsMenu?.demostration_layer} />
          </Grid>
        </Grid>
      </Container>
    </form>
  )
}


const AccordionWrapper = ({
  items,
  errors,
  preview,
  register,
  name = '',
  title = '',
  deleteItem,
  removeItem,
  planosItems,
  appendItems,
  fieldsItems = [],
}: {
  items: any,
  errors: any,
  name: string,
  register: any
  title: string,
  removeItem: any,
  deleteItem: any,
  appendItems: any,
  preview: boolean
  fieldsItems: any[],
  planosItems: any[],
  setItemHandler?: any,
}) => {
  const removeItemHandler = (field: any, itemIndex: number) => {
    deleteItem(name, field)
    removeItem(itemIndex);
  }
  
  useEffect(() => {
    if (fieldsItems.length == 0) {
      appendItems([{}])
    }
  }, []);

  const handlePlaceholder = (title: any) => {
    if (title == 'Objetivos') {
      return 'Escolha um objetivo'
    }

    if (title == 'Metas') {
      return 'Escolha uma meta'
    }

    return 'Escolha uma macroação'
  };

  const AccordionCallBack = React.useCallback(() => {
    if (planosItems.length == 0) {
      return (
        <p>
          Sem {title}
        </p>
      )
    }

    return <>
      {fieldsItems.map((field: any, itemIndex) => (
        <Grid container spacing={2} key={field.id} sx={{ background: '#fff', mt: itemIndex == 0 ? 0 : 2 }}>
          <Grid item xs={12} sx={{ mb: 2, mr: 2 }}>
            <AsynchronousSelectField
              label=""
              errors={errors}
              required={false}
              disabled={preview}
              data={planosItems}
              defaultValue={field[`${name}`]}
              name={`${name}.${itemIndex}.${name}`}
              placeholder={handlePlaceholder(title)}
              {...register(`${name}.${itemIndex}.${name}`)}
            />
          </Grid>
          <div
            className="remove_line_wrapper"
          >
            {!preview && (
              <span onClick={() => removeItemHandler(field, itemIndex)}>
                <TrashIcon />
                <p>Excluir</p>
              </span>
            )}
          </div>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <div className="add_line_wrapper">
          {!preview && (
            <p onClick={() => appendItems([{}])}>
              {`+ Adicionar vinculação`}
            </p>
          )}
        </div>

      </Grid>
    </>
  }, [fieldsItems])

  return (
    <Accordion sx={{ background: '#DFDFDF', mt: 2 }} >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <p className="sub_item_topographic">
          {title}
        </p>
      </AccordionSummary>
      <AccordionDetails sx={{ ml: 2 }} id={`${name}-selects`} >
        {AccordionCallBack()}
      </AccordionDetails>
    </Accordion>
  )
}