import { PlanoVerao } from "./Icons/plano_verao";
import { PlanoCultura } from "./Icons/plano_da_cultura";
import { PlanoPlurianual } from "./Icons/plano_plurianual";
import { PlanoJuventude } from "./Icons/plano_da_juventude";
import { PlanoMetroviario } from "./Icons/plano_metroviario";
import { PlanoCicloviario } from "./Icons/plano_cicloviario";
import { MunicipalSaude } from "./Icons/plano_municipal_de_saude";
import { SegurancaViaria } from "./Icons/plano_de_seguranca_viaria";
import { DiretorDecenal2021 } from "./Icons/plano_diretor_decenal_2021";
import { MunicipalEducacao } from "./Icons/plano_municipal_de_educacao";
import { DiretorDecenal2011 } from "./Icons/plano_diretor_decenal_2011";
import { Estrategico_2021_2024 } from "./Icons/plano_estrategico_2021_2024";
import { PortoMaravilha } from "./Icons/plano_de_habitacao_do_porto_maravilha";
import { EstrategicoMultirio_2024 } from "./Icons/plano_estrategico_multirio_2024";
import { PMUSustentavel } from "./Icons/plano_de_mobilidade_urbana_sustentavel_pmus_";
import { DesenvolvimentoEconomico } from "./Icons/plano_de_desenvolvimento_economico";
import { RecursosHidricosEstadual } from "./Icons/plano_estadual_de_recursos_hidricos";
import { EstrategicoCGM_2021_2024 } from "./Icons/plano_estrategico_cgm_rio_2021_2024";
import { EstrategicoComlurb } from "./Icons/plano_estrategico_institucional_da_comlurb";
import { ArborizacaoPDAU } from "./Icons/plano_diretor_de_arborizacao_urbana_pdau_rio_";
import { SegurancaAlimentarPlansan } from "./Icons/plano_de_seguranca_alimentar_plansan_";
import { MunicipalAssistenciaSocial } from "./Icons/plano_municipal_de_assistencia_social";
import { PlanoGuanduMirim } from "./Icons/planos_das_bacias_do_guandu_guarda_e_guandu_mirim";
import { AcaoClimatica } from "./Icons/plano_de_desenvolvimento_sustentavel_e_acao_climatica";
import { DefesaCivil } from "./Icons/plano_de_contingencia_subsecretaria_de_protecao_e_defesa_civil";
import { RodrigoFreitasGestao } from "./Icons/plano_de_gestao_ambiental_da_lagoa_rodrigo_de_freitas";
import { DoisIrmaos } from "./Icons/plano_de_manejo_do_parque_natural_municipal_penhasco_dois_irmaos";
import { RodrigoFreitasContingencia } from "./Icons/plano_de_contingencia_da_lagoa_rodrigo_de_freitas";
import { EstrategicoPlanetario } from "./Icons/plano_estrategico_institucional_da_fundacao_planetario";
import { EstrategicoPublicaSaude } from "./Icons/plano_estrategico_institucional_da_empresa_publica_de_saude";
import { GestaoResiduosSolidos } from "./Icons/plano_municipal_de_gestao_integrada_de_residuos_solidos_pmgirs_";
import { ContingenciaOperacoesResiliencia } from "./Icons/plano_de_contingencia_do_centro_de_operacoes_e_resiliencia";
import { EstrategicoEspecialTurismo } from "./Icons/plano_estrategico_institucional_da_secretaria_especial_de_turismo";
import { EstrategicoProcuradoriaGeral } from "./Icons/plano_estrategico_institucional_da_procuradoria_geral_do_municipio";
import { EstrategicoOrdemPublica } from "./Icons/plano_estrategico_institucional_da_secretaria_municipal_de_ordem_publica";
import { EstrategicoOperacoesResiliencia } from "./Icons/plano_estrategico_institucional_do_centro_de_operacoes_e_resiliencia";
import { EstrategicoJuventudeCarioca } from "./Icons/plano_estrategico_institucional_da_secretaria_especial_da_juventude_carioca";
import { EstrategicoAssistenciaSocial } from "./Icons/plano_estrategico_institucional_da_secretaria_municipal_de_assistencia_social";
import { DiretorTransportePDTU } from "./Icons/plano_diretor_de_transportes_urbanos_da_regiao_metropolitana_do_rio_de_janeiro_pdtu_";
import { EstrategicoPoliticasPromocoes } from "./Icons/plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao";
import { MunicipalMataAtlantica } from "./Icons/plano_municipal_de_conservacao_e_recuperacao_da_mata_atlantica_do_rio_de_janeiro_pmma_rio_";
import { EstrategicoPromocaoMulher } from "./Icons/plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao_da_mulher";
import { EstrategicoRMRJ } from "./Icons/plano_estrategico_de_desenvolvimento_urbano_integrado_da_regiao_metropolitana_do_rio_de_janeiro_pdui_rmrj_";
import { MunicipalEsgotamentoSanitario } from "./Icons/plano_municipal_de_saneamento_basico_para_os_servicos_de_abastecimento_de_agua_e_de_esgotamento_sanitario";
import { RecursosHidricosJacarepagua } from "./Icons/plano_de_recursos_hidricos_da_regiao_hidrografica_da_baia_de_guanabara_e_dos_sistemas_lagunares_de_jacarepagua_e_marica";
import { PaoAcucarUrca } from './Icons/plano_de_manejo_do_monumento_natural_dos_morros_do_pao_de_acucar_e_da_urca';
import { ParqueEstadualChacrinha } from './Icons/plano_de_manejo_do_parque_estadual_da_chacrinha'
import { ParqueNaturalChicoMendes } from './Icons/plano_de _manejo_do_parque_natural_municipal_chico_mendes'
import { ParqueNaturalCidade } from './Icons/plano_de_manejo_do_parque_natural_municipal_da_cidade'
import { SerraMedanha } from './Icons/plano_de_manejo_do_parque_natural_municipal_da_serra_do_mendanha'
import { ParqueMunicipalMarapendi } from './Icons/plano_de_manejo_do_parque_natural_municipal_marapendi'
import { PaisagemCarioca } from "./Icons/plano_de_manejo_do_parque_natural_municipal_paisagem_carioca";
import { PrainhaGrumari } from "./Icons/plano_de_manejo_do_parque_natural_municipal_prainha_e_grumari";
import { BosqueDaBarra } from "./Icons/plano_de_manejo_do_parque_natural_municipal_bosque_da_barra";
import { TijucaNelsonMandela } from "./Icons/plano_de_manejo_do_parque_natural_municipal_da_barra_da_tijuca_nelson_mandela";
import { ParqueCatacumba } from "./Icons/plano _de_manejo_do_parque_natural_municipal_da_catacumba";
import { ParqueEstadualGrajau } from "./Icons/plano_de_manejo_do_parque_estadual_do_grajau";
import { icon } from "leaflet";

export const dictSvgs: Record<string, unknown> = {
  plano_verao: <PlanoVerao />,
  plano_metroviario: <PlanoMetroviario />,
  plano_cicloviario: <PlanoCicloviario />,
  plano_plurianual_2022_2025: <PlanoPlurianual />,
  plano_da_cultura: <PlanoCultura />,
  plano_da_juventude: <PlanoJuventude />,
  plano_municipal_de_saude: <MunicipalSaude />,
  plano_de_seguranca_viaria: <SegurancaViaria />,
  plano_diretor_decenal_2024: <DiretorDecenal2021 />,
  plano_diretor_decenal_2011: <DiretorDecenal2011 />,
  plano_municipal_de_educacao: <MunicipalEducacao />,
  plano_estrategico_2021_2024: <Estrategico_2021_2024 />,
  plano_de_habitacao_do_porto_maravilha: <PortoMaravilha />,
  plano_estrategico_multirio_2024: <EstrategicoMultirio_2024 />,
  plano_de_desenvolvimento_economico: <DesenvolvimentoEconomico />,
  plano_de_mobilidade_urbana_sustentavel_pmus_: <PMUSustentavel />,
  plano_estrategico_cgm_rio_2021_2024: <EstrategicoCGM_2021_2024 />,
  plano_estadual_de_recursos_hidricos: <RecursosHidricosEstadual />,
  plano_estrategico_institucional_da_comlurb: <EstrategicoComlurb />,
  plano_diretor_de_arborizacao_urbana_pdau_rio_: <ArborizacaoPDAU />,
  plano_de_seguranca_alimentar_plansan_: <SegurancaAlimentarPlansan />,
  plano_municipal_de_assistencia_social: <MunicipalAssistenciaSocial />,
  plano_de_contingencia_da_lagoa_rodrigo_de_freitas: (
    <RodrigoFreitasContingencia />
  ),
  planos_das_bacias_do_guandu_guarda_e_guandu_mirim: <PlanoGuanduMirim />,
  plano_de_gestao_ambiental_da_lagoa_rodrigo_de_freitas: (
    <RodrigoFreitasGestao />
  ),
  plano_de_desenvolvimento_sustentavel_e_acao_climatica_pds_: <AcaoClimatica />,
  plano_estrategico_institucional_da_fundacao_planetario: (
    <EstrategicoPlanetario />
  ),
  plano_de_contingencia_do_centro_de_operacoes_e_resiliencia: (
    <ContingenciaOperacoesResiliencia />
  ),
  plano_estrategico_institucional_da_empresa_publica_de_saude: (
    <EstrategicoPublicaSaude />
  ),
  plano_municipal_de_gestao_integrada_de_residuos_solidos_pmgirs_: (
    <GestaoResiduosSolidos />
  ),
  plano_de_contingencia_subsecretaria_de_protecao_e_defesa_civil: (
    <DefesaCivil />
  ),
  plano_de_manejo_do_parque_natural_municipal_penhasco_dois_irmaos: (
    <DoisIrmaos />
  ),
  plano_estrategico_institucional_da_secretaria_especial_de_turismo: (
    <EstrategicoEspecialTurismo />
  ),
  plano_estrategico_institucional_do_centro_de_operacoes_e_resiliencia: (
    <EstrategicoOperacoesResiliencia />
  ),
  plano_estrategico_institucional_da_procuradoria_geral_do_municipio: (
    <EstrategicoProcuradoriaGeral />
  ),
  plano_estrategico_institucional_da_secretaria_municipal_de_ordem_publica: (
    <EstrategicoOrdemPublica />
  ),
  plano_estrategico_institucional_da_secretaria_especial_da_juventude_carioca: (
    <EstrategicoJuventudeCarioca />
  ),
  plano_estrategico_institucional_da_secretaria_municipal_de_assistencia_social:
    <EstrategicoAssistenciaSocial />,
  plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao:
    <EstrategicoPoliticasPromocoes />,
  plano_diretor_de_transportes_urbanos_da_regiao_metropolitana_do_rio_de_janeiro_pdtu_:
    <DiretorTransportePDTU />,
  plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao_da_mulher:
    <EstrategicoPromocaoMulher />,
  plano_municipal_de_conservacao_e_recuperacao_da_mata_atlantica_do_rio_de_janeiro_pmma_rio_:
    <MunicipalMataAtlantica />,
  plano_municipal_de_saneamento_basico_para_os_servicos_de_abastecimento_de_agua_e_de_esgotamento_sanitario:
    <MunicipalEsgotamentoSanitario />,
  plano_estrategico_de_desenvolvimento_urbano_integrado_da_regiao_metropolitana_do_rio_de_janeiro_pdui_rmrj_:
    <EstrategicoRMRJ />,
  plano_de_recursos_hidricos_da_regiao_hidrografica_da_baia_de_guanabara_e_dos_sistemas_lagunares_de_jacarepagua_e_marica:
    <RecursosHidricosJacarepagua />,
  plano_de_manejo_do_parque_natural_municipal_marapendi: (
    <ParqueMunicipalMarapendi />
  ),
  plano_de_manejo_do_monumento_natural_dos_morros_do_pao_de_acucar_e_da_urca: (
    <PaoAcucarUrca />
  ),
  plano_de_manejo_do_parque_estadual_da_chacrinha: <ParqueEstadualChacrinha />,
  plano_de_manejo_do_parque_estadual_do_grajau: <ParqueEstadualGrajau />,
  plano_de_manejo_do_parque_natural_municipal_da_barra_da_tijuca_nelson_mandela:
    <TijucaNelsonMandela />,
  plano_de_manejo_do_parque_natural_municipal_bosque_da_barra: (
    <BosqueDaBarra />
  ),
  plano_de_manejo_do_parque_natural_municipal_chico_mendes: (
    <ParqueNaturalChicoMendes />
  ),
  plano_de_manejo_do_parque_natural_municipal_da_catacumba: (
    <ParqueCatacumba />
  ),
  plano_de_manejo_do_parque_natural_municipal_da_cidade: (
    <ParqueNaturalCidade />
  ),
  plano_de_manejo_do_parque_natural_municipal_da_serra_do_mendanha: (
    <SerraMedanha />
  ),
  plano_de_manejo_do_parque_natural_municipal_paisagem_carioca: (
    <PaisagemCarioca />
  ),
  plano_de_manejo_do_parque_natural_municipal_da_prainha_e_do_parque_natural_municipal_de_grumari: (
    <PrainhaGrumari />
  ),
};

export const dictMedias: Record<
  string,
  { icon: any; bg: string; file?: string }
> = {
  plano_verao: {
    icon: <PlanoVerao />,
    bg: "verao.png",
    file: "plano_verao.pdf",
  },
  plano_metroviario: {
    icon: <PlanoMetroviario />,
    file: "plano_metroviario.pdf",
    bg: "metroviario.png",
  },
  plano_cicloviario: {
    icon: <PlanoCicloviario />,
    file: "plano_cicloviario.pdf",
    bg: "cicloviaria.png",
  },
  plano_plurianual_2022_2025: {
    //errado
    icon: <PlanoPlurianual />,
    bg: "plano_purianual.png",
    file: "plano_plurianual.pdf",
  },
  plano_da_cultura: {
    icon: <PlanoCultura />,
    file: "plano_cultura.pdf",
    bg: "bg_cultura.png",
  },
  plano_da_juventude: {
    icon: <PlanoJuventude />,
    file: "plano_juventude.pdf",
    bg: "juventude_bg.png",
  },
  plano_municipal_de_saude: {
    icon: <MunicipalSaude />,
    file: "plano_municipal_saude.pdf",
    bg: "municipal_saude.png",
  },
  plano_de_seguranca_viaria: {
    icon: <SegurancaViaria />,
    file: "seguranca_viaria.pdf",
    bg: "plano_seguranca_viaria.png",
  },
  plano_diretor_decenal_2024: {
    icon: <DiretorDecenal2021 />,
    file: "diretor_decenal_2021.pdf",
    bg: "plano_decena.png",
  },
  plano_diretor_decenal_2011: {
    icon: <DiretorDecenal2011 />,
    file: "diretor_decenal_2011.pdf",
    bg: "PPA_Decreto.png",
  },
  plano_estrategico_2021_2024: {
    icon: <Estrategico_2021_2024 />,
    file: "estratefico_2021_2024.pdf",
    bg: "plano_estrategico.png",
  },
  plano_municipal_de_educacao: {
    icon: <MunicipalEducacao />,
    file: "plano_educacao.pdf",
    bg: "educacao.png",
  },
  plano_estrategico_multirio_2024: {
    icon: <EstrategicoMultirio_2024 />,
    bg: "multirio.png",
    file: "estrategico_multirio.pdf",
  },
  plano_de_desenvolvimento_economico: {
    icon: <DesenvolvimentoEconomico />,
    file: "desenvolvimento_economico.pdf",
    bg: "desenvolvimentoecono_plano.png",
  },
  plano_estrategico_cgm_rio_2021_2024: {
    icon: <EstrategicoCGM_2021_2024 />,
    file: "estrategico_cgm.pdf",
    bg: "cgm_estrategico.png",
  },
  plano_estadual_de_recursos_hidricos: {
    icon: <RecursosHidricosEstadual />,
    bg: "plano_hidrico.png",
    file: "estadual_recursos_hidricos.pdf",
  },
  plano_de_seguranca_alimentar_plansan_: {
    icon: <SegurancaAlimentarPlansan />,
    file: "seguranca_alimentar.pdf",
    bg: "seguranca_alimentar_bg.png",
  },
  plano_municipal_de_assistencia_social: {
    icon: <MunicipalAssistenciaSocial />,
    file: "municipal_assistencia_social.pdf",
    bg: "municipal_assistencia_social.png",
  },
  plano_de_habitacao_do_porto_maravilha: {
    icon: <PortoMaravilha />,
    file: "habitacao_porto_maravilha.pdf",
    bg: "plano_porto_maravilha.png",
  },
  plano_estrategico_institucional_da_comlurb: {
    icon: <EstrategicoComlurb />,
    file: "estrategico_comlurb.pdf",
    bg: "bg_columb.png",
  },
  plano_diretor_de_arborizacao_urbana_pdau_rio_: {
    icon: <ArborizacaoPDAU />,
    file: "arborizacao_urbana.pdf",
    bg: "arborizacao_urbana_pdau.png",
  },
  plano_de_mobilidade_urbana_sustentavel_pmus_: {
    icon: <PMUSustentavel />,
    file: "mobilidade_urbana_sustentavel.pdf",
    bg: "Mobilidade_Urbana.png",
  },
  plano_de_contingencia_da_lagoa_rodrigo_de_freitas: {
    icon: <RodrigoFreitasContingencia />,
    file: "contingencia_rodrigo_freitas.pdf",
    bg: "plano_contngencia_lagoa.png",
  },
  planos_das_bacias_do_guandu_guarda_e_guandu_mirim: {
    icon: <PlanoGuanduMirim />,
    bg: "gandu.png",
    file: "bacias_guandu.pdf",
  },
  plano_de_gestao_ambiental_da_lagoa_rodrigo_de_freitas: {
    //ate aqui
    icon: <RodrigoFreitasGestao />,
    file: "gestao_rodrigo_freitas.pdf",
    bg: "gest_freitas.png",
  },
  plano_de_desenvolvimento_sustentavel_e_acao_climatica_pds_: {
    icon: <AcaoClimatica />,
    file: "acao_climatica.pdf",
    bg: "plano_acao_climatica.png",
  },
  plano_estrategico_institucional_da_fundacao_planetario: {
    icon: <EstrategicoPlanetario />,
    file: "fundacao_planetario.pdf",
    bg: "Planetario.png",
  },
  plano_de_contingencia_do_centro_de_operacoes_e_resiliencia: {
    icon: <ContingenciaOperacoesResiliencia />,
    file: "contingencia_operacoes_resiliencia.pdf",
    bg: "Centro_Operacoes.png",
  },
  plano_estrategico_institucional_da_empresa_publica_de_saude: {
    icon: <EstrategicoPublicaSaude />,
    bg: "empresa_publica.png",
    file: "empresa_publica_saude.pdf",
  },
  plano_municipal_de_gestao_integrada_de_residuos_solidos_pmgirs_: {
    icon: <GestaoResiduosSolidos />,
    file: "gestao_residuos_solidos.pdf",
    bg: "solidos.png",
  },
  plano_de_contingencia_subsecretaria_de_protecao_e_defesa_civil: {
    icon: <DefesaCivil />,
    file: "subsecretaria_defesa_civil.pdf",
    bg: "contigencia_protecao_civil.png",
  },
  plano_de_manejo_do_parque_natural_municipal_penhasco_dois_irmaos: {
    icon: <DoisIrmaos />,
    bg: "plano_parque_penhasco.png",
    file: "parque_natural_dois_irmaos.pdf",
  },
  plano_estrategico_institucional_da_secretaria_especial_de_turismo: {
    icon: <EstrategicoEspecialTurismo />,
    file: "secretaria_turismo.pdf",
    bg: "bg_secreatreia_turismo.png",
  },
  plano_estrategico_institucional_do_centro_de_operacoes_e_resiliencia: {
    icon: <EstrategicoOperacoesResiliencia />,
    bg: "resiliencia2.png",
    file: "estrategico_operacoes_resiliencia.pdf",
  },
  plano_estrategico_institucional_da_procuradoria_geral_do_municipio: {
    icon: <EstrategicoProcuradoriaGeral />,
    file: "procuradoria_geral_municipio.pdf",
    bg: "pgm.png",
  },
  plano_estrategico_institucional_da_secretaria_municipal_de_ordem_publica: {
    icon: <EstrategicoOrdemPublica />,
    file: "secretaria_ordem_publica.pdf",
    bg: "ordem_publica.png",
  },
  plano_estrategico_institucional_da_secretaria_especial_da_juventude_carioca: {
    icon: <EstrategicoJuventudeCarioca />,
    file: "secretaria_juventude_carioca.pdf",
    bg: "estrategico_juventude.png",
  },
  plano_estrategico_institucional_da_secretaria_municipal_de_assistencia_social:
    {
      icon: <EstrategicoAssistenciaSocial />,
      file: "estrategico_assistencia_social.pdf",
      bg: "Assistencia_Social.png",
    },
  plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao:
    {
      icon: <EstrategicoPoliticasPromocoes />,
      file: "secretaria_politicas_mulher.pdf",
      bg: "politica_promo.png",
    }, // faltou
  plano_diretor_de_transportes_urbanos_da_regiao_metropolitana_do_rio_de_janeiro_pdtu_:
    {
      icon: <DiretorTransportePDTU />,
      file: "transportes_urbanos_metropolitana.pdf",
      bg: "moburbana.png",
    },
  plano_municipal_de_conservacao_e_recuperacao_da_mata_atlantica_do_rio_de_janeiro_pmma_rio_:
    {
      icon: <MunicipalMataAtlantica />,
      file: "recuperacao_mata_atlantica.pdf",
      bg: "mata_atlantica.png",
    },
  plano_estrategico_institucional_da_secretaria_especial_de_politicas_e_promocao_da_mulher:
    {
      icon: <EstrategicoPromocaoMulher />,
      file: "secretaria_politicas_mulher.pdf",
      bg: "politica_promo.png",
    },
  plano_municipal_de_saneamento_basico_para_os_servicos_de_abastecimento_de_agua_e_de_esgotamento_sanitario:
    {
      icon: <MunicipalEsgotamentoSanitario />,
      file: "plano_saneamento_basico.pdf",
      bg: "saneamento.png",
    },
  plano_estrategico_de_desenvolvimento_urbano_integrado_da_regiao_metropolitana_do_rio_de_janeiro_pduirmrj:
    {
      icon: <EstrategicoRMRJ />,
      file: "desenvolvimento_urbano_integrado.pdf",
      bg: "Plano_Desenv_Integ_.png",
    },
  plano_estrategico_de_desenvolvimento_urbano_integrado_da_regiao_metropolitana_do_rio_de_janeiro_pdui_rmrj_:
    {
      icon: <EstrategicoRMRJ />,
      file: "desenvolvimento_urbano_integrado.pdf",
      bg: "plano_urbano_integrado_metropolitanaa_rj.png",
    },
  plano_de_recursos_hidricos_da_regiao_hidrografica_da_baia_de_guanabara_e_dos_sistemas_lagunares_de_jacarepagua_e_marica:
    {
      icon: <RecursosHidricosJacarepagua />,
      bg: "recursos_hidricos.png",
      file: "sistema_lagunar.pdf",
    },
  plano_de_manejo_do_parque_natural_municipal_bosque_da_barra: {
    icon: <BosqueDaBarra />,
    bg: "mata_atlantica.png",
    file: "plano_municipal_bosque_barra.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_da_catacumba: {
    icon: <ParqueCatacumba />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_catacumba.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_da_cidade: {
    icon: <ParqueNaturalCidade />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_parque_cidade.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_da_prainha_e_do_parque_natural_municipal_de_grumari: {
    icon: <PrainhaGrumari />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_grumari.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_chico_mendes: {
    icon: <ParqueNaturalChicoMendes />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_chico_mendes.pdf",
  },
  plano_de_manejo_do_monumento_natural_dos_morros_do_pao_de_acucar_e_da_urca: {
    icon: <PaoAcucarUrca />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_pao_acucar_urca.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_marapendi: {
    icon: <ParqueMunicipalMarapendi />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_marapendi.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_paisagem_carioca: {
    icon: <PaisagemCarioca />,
    bg: "mata_atlantica.png",
    file: "plano_manejo_paisagem_carioca.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_da_serra_do_mendanha: {
    icon: <SerraMedanha />,
    bg: "mata_atlantica.png",
    file: "plano_mendanha.pdf",
  },
  plano_de_manejo_do_parque_estadual_da_chacrinha: {
    icon: <ParqueEstadualChacrinha />,
    bg: "mata_atlantica.png",
    file: "plano_de_manejo_do_parque_estadual_da_chacrinha.pdf",
  },
  plano_de_manejo_do_parque_natural_municipal_da_barra_da_tijuca_nelson_mandela: {
    icon: <EstrategicoRMRJ />,
    bg: "mata_atlantica.png",
    file: "plano_de_manejo_do_parque_natural_municipal_da_barra_da_tijuca_nelson_mandela_.pdf"
  },
  plano_de_manejo_do_parque_estadual_do_grajau: {
    icon: <ParqueEstadualGrajau />,
    bg: "mata_atlantica.png",
    file: "plano_de_manejo_do_parque_estadual_do_grajau.pdf"
  },

};
