import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import * as React from 'react';
import { CardConsultElaboration } from './Card';
import CloseIcon from '@mui/icons-material/Close';
import { ContinueButton, Container, ContentDetailsWrapper } from './styles';

interface IProps {
  title: string;
  open: boolean;
  data: Record<string, any>;
  setOpen: (value: boolean) => void;
}

export const DetailsConsultElaborationDialog: React.FC<IProps> = ({ 
  open, 
  title,
  data,
  setOpen, 
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container >
      <Dialog
        open={open}
        fullWidth
        maxWidth='md'
        sx={{ marginTop: '8rem', maxHeight: '677px' }}
        onClose={handleClose}
      >
        <div style={{ padding: '0 27px' }}>
          <DialogTitle>
            {title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#004A80'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ padding: '0px 24px' }}>
            <DialogContentText>
              <ContentDetailsWrapper>
                <div className='description_wrapper'>
                  <p>Esta informação será exibida no sistema conforme o exemplo abaixo.</p>
                  <span>
                    Para ver mais títulos de planos no Interplan.Rio, <a>clique aqui.</a>
                  </span>
                </div>
                <div className='content_card_wrapper'>
                  <CardConsultElaboration item={data} />
                </div>
              </ContentDetailsWrapper>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <ContinueButton onClick={handleClose}>
              <p>OK</p>
            </ContinueButton>
          </DialogActions>
        </div>
      </Dialog>
    </Container>
  );
}
