import React, { useEffect, useState } from "react";
import { Container } from './styles';
import { Grid } from "@mui/material";
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { PlansService } from "@/services/endpoints/plans";
import { useRouter } from "next/router";
import { Loading } from "@/components/global/Loading";
import { useForm, SubmitHandler, useFieldArray } from "react-hook-form";

export const data = [
  {
    title: 'Missão/Propósito',
    description: 'campo não preenchido'
  },
  {
    title: 'Objetivos',
    description: 'não há objetivo para um dos Eixos Temáticos'
  },
  {
    title: 'Projetos',
    description: 'falta preencher o campo território nos projetos'
  },
  {
    title: 'Produto/entrega',
    description: 'campo obrigatório não preenchido'
  },
]

export const PendingCheckLayerForm = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem, setDefaultItemMenuId, setNavigable, handleFetchProgressCodePlanId } = usePreparationOfPlans();

  const router = useRouter()
  const { GetVinculoFinalValidacao } = new PlansService()
  const [validacaoErros, SetValidacaoErros] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {

    if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
      setLoading(true)
      GetVinculoFinalValidacao(router.query.id)
        .then(({ data }: any) => {
          SetValidacaoErros(data.erros)
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }

  }, [router])

  const {
    handleSubmit,
  } = useForm()


  const onSubmit: SubmitHandler<any> = (data, event: any) => {
    if (event.nativeEvent.submitter.name == 'next_step') {
      setNavigable(true);
      router.push('/dashboard/elaboracao_planos/' + router.query.id)

      const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
      const id = plan[selectedItemsMenu?.layer as string]
        ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
      handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })

      setDefaultItemMenuId(id);
    }

  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} display='flex' flexDirection='column' gap='4px'>
            <p style={{ color: '#EC1F1F' }}>
              Seu plano apresenta pendências.
            </p>
            <b>
              Corrija os campos abaixo, também
              indicados no menu ao lado, antes de pré-visualizar o Plano.
            </b>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <ErrosLista loading={loading} validacaoErros={validacaoErros} />
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
        </Grid>
        <ActionButtonElaborations
          layer_indicator={true}
          isOptional="true"
        />
      </form>
    </Container>
  )
}


const ErrosLista = ({ loading = false, validacaoErros }: { loading: boolean, validacaoErros: any[] }) => {


  if (loading) {
    return (
      <Loading loading={loading} />
    )
  }

  return <>
    {validacaoErros.map((item: any, index) => (
      <Grid item xs={12} display='flex' gap='4px' alignItems='center'>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.3001 1.83252C12.4533 -0.326984 15.5473 -0.326984 16.7006 1.83252L27.3441 21.744C27.5935 22.2106 27.7178 22.7338 27.7048 23.2627C27.6919 23.7916 27.5422 24.3081 27.2702 24.7619C26.9983 25.2157 26.6134 25.5913 26.1532 25.8522C25.6929 26.113 25.1729 26.2502 24.6438 26.2503H3.35684C2.82779 26.2502 2.3078 26.113 1.84752 25.8522C1.38724 25.5913 1.00238 25.2157 0.73045 24.7619C0.458517 24.3081 0.308783 23.7916 0.295839 23.2627C0.282895 22.7338 0.407182 22.2106 0.65659 21.744L11.3001 1.83252ZM14.3853 3.06977C14.3479 3.00031 14.2923 2.94229 14.2245 2.90185C14.1567 2.86142 14.0793 2.84007 14.0003 2.84007C13.9214 2.84007 13.844 2.86142 13.7762 2.90185C13.7084 2.94229 13.6528 3.00031 13.6153 3.06977L2.97184 22.9813C2.93618 23.0479 2.91838 23.1226 2.92016 23.1981C2.92195 23.2736 2.94326 23.3474 2.98203 23.4123C3.02079 23.4771 3.07569 23.5308 3.14138 23.5681C3.20706 23.6055 3.28129 23.6251 3.35684 23.6253H24.6438C24.7194 23.6251 24.7936 23.6055 24.8593 23.5681C24.925 23.5308 24.9799 23.4771 25.0187 23.4123C25.0574 23.3474 25.0787 23.2736 25.0805 23.1981C25.0823 23.1226 25.0645 23.0479 25.0288 22.9813L14.3853 3.06977ZM15.3128 10.0628V14.4378C15.3128 14.7859 15.1746 15.1197 14.9284 15.3658C14.6823 15.612 14.3484 15.7503 14.0003 15.7503C13.6522 15.7503 13.3184 15.612 13.0723 15.3658C12.8261 15.1197 12.6878 14.7859 12.6878 14.4378V10.0628C12.6878 9.71467 12.8261 9.38083 13.0723 9.13469C13.3184 8.88855 13.6522 8.75027 14.0003 8.75027C14.3484 8.75027 14.6823 8.88855 14.9284 9.13469C15.1746 9.38083 15.3128 9.71467 15.3128 10.0628ZM15.7503 19.2503C15.7503 19.7144 15.566 20.1595 15.2378 20.4877C14.9096 20.8159 14.4645 21.0003 14.0003 21.0003C13.5362 21.0003 13.0911 20.8159 12.7629 20.4877C12.4347 20.1595 12.2503 19.7144 12.2503 19.2503C12.2503 18.7861 12.4347 18.341 12.7629 18.0128C13.0911 17.6846 13.5362 17.5003 14.0003 17.5003C14.4645 17.5003 14.9096 17.6846 15.2378 18.0128C15.566 18.341 15.7503 18.7861 15.7503 19.2503Z" fill="#EC1F1F" />
        </svg>
        <span>{item.campo}:</span>
        <p style={{ color: '#EC1F1F' }}>{item.error}</p>
      </Grid>
    ))}
  </>

}