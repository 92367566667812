export function processString(texto: string, len?: number) {
  if (texto.length > 0) {
    // Verificar se a string é maior que 260 caracteres

    const textoCortado = texto.slice(0, len ? len : 260);

    // Lista de artigos em português
    const artigos = [
      "o",
      "a",
      "os",
      "as",
      "um",
      "uma",
      "uns",
      "umas",
      "com",
      "e",
      "de",
      "dos",
      "e",
      "dim",
      "finan",
      "valores",
      "Os",
      "pr",
      "suste"
    ];


    // Divide o texto em palavras
    const palavras = textoCortado.split(' ');

    // Remove os artigos no final do texto
    for (let i = palavras.length - 1; i >= 0; i--) {
      if (artigos.includes(palavras[i])) {
        palavras.pop();
      } else {
        break;
      }
    }

    // Reconstrói o texto final
    let textoFinal = palavras.join(' ');
    return textoFinal + '...';
  }
  return texto
}
