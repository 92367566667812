
import { Circle, Wrapper } from "./styles";

type Props = {
  loading: boolean,
  width?: string,
  margin?: string,
}

export const Loading: React.FC<Props> = ({ loading = false, width, margin }) => {

  if (loading == false) {
    return
  }

  return (
    <Wrapper style={{margin: margin}}>
      <Circle style={{ '--width': width }as React.CSSProperties} ></Circle>
    </Wrapper>
  )
}