import styled from "styled-components";

export const Container = styled.div`

  width: 100%;
  display: grid;
  gap: 20px;

  margin-bottom: 20px;

  >div {
    h3 {
      font-size: 28px;
      color: #004A80;
    }

    p {
      display: flex;
      gap: 5px;
      span {
        font-weight: bolder;
      }
    }

    .destaque {
      padding: 10px 20px;
      border-radius: 5px;
      background: #fff;

      margin: 10px 20px 0 20px;
    }
  }

`;

