import { persist } from 'zustand/middleware';
import { UseBoundStore, StoreApi, create } from 'zustand';

interface IProps {
  theme: 'light' | 'dark';
  setTheme: () => void;
}

// contexto zustand responsável pelo tema dark e light da aplicação
export const useTheme: UseBoundStore<StoreApi<IProps>> = create(
  persist(
    (set, get) => ({
      theme: "light",
      setTheme: () => set(() => ({
          theme: get().theme === "dark" ? "light" : "dark"
      })),
    }),
    {
      name: 'theme',
    },
  ),
);

