import React from "react"
import { Grid } from '@mui/material'
import { Container } from './styled';
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";

export const ObjectivesDemonstrationForm: React.FC = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();

  return (
    <Container>
      <Grid container spacing={2}>
        <div className="first_row">
          <p>
          São resultados prioritários que devem ser alcançados ou mantidos no horizonte temporal de vigência do plano. O objetivo deve sintetizar a transformação que se buscará efetivar. Devem obedecer aos seguintes critérios: pertinentes, mensuráveis, motivadores, inteligíveis e alcançáveis.
          </p>
        </div>
        <div className="first_row">
          <span>Como montar um Objetivo?</span>
          <p>
            Para montar um objetivo efetivo, é essencial sintetizar a transformação desejada de forma clara e motivadora. Certifique-se de que seja pertinente, mensurável, motivador, inteligível e alcançável, alinhado com a direção estratégica da organização. Leve em conta o prazo do Plano, assim como os resultados da Camada Diagnóstica.
          </p>
        </div>
        <div className="second_row">
          <span>Exemplos</span>
          <p>
            “Ampliar e reorganizar os serviços de média complexidade, estruturando as principais linhas de cuidado em saúde” (Fonte: Plano Municipal de Saúde; 2021-2025)
          </p>
          <p>
            Melhorar a qualidade da educação básica, garantindo que todos os alunos alcancem níveis adequados de proficiência em leitura e matemática.
          </p>
          <p>
            Fortalecer a presença digital da Prefeitura, aumentando o engajamento nas redes sociais e a conversão de visitantes em usuários no website.
          </p>
        </div>
        <Grid item xs={12}>
          <ActionButtonElaborations onClick={() => {
            const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
            const id = plan[selectedItemsMenu?.layer as string]
              ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true });

          }} isOptional="false" />
        </Grid>
      </Grid>
    </Container>
  )
}
