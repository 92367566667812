import {
  IResPlans,
  ISearchPlans,
  ISearchJsonProps,
} from "@/types/endpoints/plans";
import { Api, ApiDashboard } from "@/services/configs/axios";
import { AxiosPromise, AxiosRequestConfig } from "axios";
import { mountUrl } from "@/utils/mountUrl";

export type IResponsePlans = IResPlans & { svg: any };
export type IResponseSearch = ISearchPlans & { svg: any };

export interface IPeriodProps {
  anoFinal: number;
  anoInicial: number;
}

export interface IClassificationRequest {
  sort?: string;
  date?: string;
  size?: string;
  page?: string;
}

interface ISearchQueryPlans {
  record: any;
  data: any;
}

export class PlansService {
  searchPlans = (
    data: AxiosRequestConfig<ISearchJsonProps>
  ): AxiosPromise<ISearchPlans[]> => Api.post("search", data);
  searchQueryPlans = ({ data, record }: ISearchQueryPlans): AxiosPromise<ISearchPlans[]> =>
    Api.post(mountUrl({ url: "search", record }), data);
  getFilter = (): AxiosPromise<any[]> => Api.get("search/filtros");
  getPeriod = (): AxiosPromise<IPeriodProps> => Api.get("search/periodo");
  getLastFive = (): AxiosPromise<IPeriodProps> => Api.get("plano/latest");
  getOnePlan = (id: any): AxiosPromise<IResPlans> => Api.get(`plano/${id}`);
  getAllPlans = (page: number): AxiosPromise<IResPlans[]> =>
    Api.get("plano?page=" + page);
  getClassificationPlans = (
    record: IClassificationRequest
  ): AxiosPromise<IResPlans[]> => Api.get(mountUrl({ url: "plano", record }));


  getPlanDashboardTema = (tipoId?: number): AxiosPromise => Api.get(`tema${tipoId ? `?tipo=${tipoId}` : ""}`);
  getPlanDashboardTipoPlano = (temaId?: number): AxiosPromise => Api.get(`tipo_plano${temaId ? `?tema=${temaId}` : ""}`);
  getPlanDashboardInfos = (json: any): AxiosPromise => Api.get("dashboard/infos", {
    headers: {
      'Content-Type': 'application/json'
    },
    data: {},
    params: json
  });
  getPlanDashboardInfoId = (id: any): AxiosPromise => Api.get(`dashboard/infos/${id}`);
  getMeusPlanosInfoId = (id: any): AxiosPromise => Api.post(`meus-planos/infos/${id}`);

  getPlanSimple = (json: any, params: any): AxiosPromise => Api.post(`plano/simple`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
    params: {
      ...params
    },
  });

  putPlanUpdateNumeros = (json: any, id: any): AxiosPromise => Api.put(`numeros/${id}`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });

  putMetasStatus = (json: any, id: any): AxiosPromise => Api.put(`metas/${id}`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });

  putEntregasStatus = (json: any, id: any): AxiosPromise => Api.put(`entregas/${id}`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });

  putMacroacoesStatus = (json: any, id: any): AxiosPromise => Api.put(`macroacoes/${id}`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });


  postMacroacaoAndamento = (json: any): AxiosPromise => Api.post(`macroacao-andamento`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });

  putMacroacaoAndamento = (json: any, id: any): AxiosPromise => Api.put(`macroacao-andamento/${id}`, json, {
    headers: {
      'Content-Type': 'application/json'
    },
  });


  getListagemPlanos = (params: any): AxiosPromise => Api.get(`plano/list-epl/`, { params: params });
  getTodosPlanos = (params?: any): AxiosPromise => ApiDashboard.get(`plano/drop-down`, { params: params });

  getPlanosVinculados = (): AxiosPromise => Api.get(`vinculo`);
  postPlanoSelecionado = (plan_id: any): AxiosPromise => Api.post(`vinculo/${plan_id}`);
  deletePlanoSelecionado = (plan_id: any): AxiosPromise => Api.delete(`vinculo/${plan_id}`);

  getPlanoObjetivo = (plan_id: any): AxiosPromise => Api.get(`objetivos/plano/${plan_id}`);
  getPlanoMetas = (plan_id: any): AxiosPromise => Api.get(`metas/plano/${plan_id}`);
  getPlanoMacroacoes = (plan_id: any): AxiosPromise => Api.get(`macroacoes/plano/${plan_id}`);

  PostEplEnviar = (params: any, plan_id: any): AxiosPromise => Api.post(`plano/epl-enviar/${plan_id}`, params);

  GetMacroactions = (id: any, page: any, size?: any) => Api.get(`/macroacoes/plano-page/${id}?page=${page}&size=${size}`)
  GetDeliveries = (id: any, page: any) => Api.get(`/entregas/plano-page/${id}?page=${page}`);
  GetGoals = (id: any, page: any, size?: any) => Api.get(`/metas/plano-page/${id}?page=${page}&size=${size}`)
  GetObjetivos = (id: any, page: any, size?: any) => Api.get(`/objetivos/plano-page/${id}?page=${page}&size=${size}`)

  GetPlano = (id: any) => Api.get(`/plano/${id}`)

  GetMacroactionsV2 = (id: any) => Api.get(`/macroacoes/plano/${id}`)
  GetDeliveriesV2 = (id: any) => Api.get(`/entregas/plano/${id}`);
  GetGoalsV2 = (id: any) => Api.get(`/metas/plano/${id}`)
  GetProjectsV2 = (id: any) => Api.get(`/projetos/plano/${id}`)

  GetObjetivoSimilar = (id: any) => Api.get(`/analises/similaridades_objetivos/${id}`)
  GetMacroactionsSimilar = (id: any) => Api.get(`/analises/similaridades_macroacoes/${id}`)
  GetGoalsSimilar = (id: any) => Api.get(`/analises/similaridades_metas/${id}`)


  getFinal = (plan_id: any): AxiosPromise => Api.get(`/final/${plan_id}`);
  PostFinal = (params: any, plan_id: any): AxiosPromise => Api.post(`/final/${plan_id}`, params, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  PostEnviar = (plan_id: any): AxiosPromise => Api.post(`/analises/enviar/${plan_id}`);

  DeleteVinculoFinal = (plan_id: any, params:any): AxiosPromise => Api.delete(`/vinculo-final/${plan_id}`, {data:params});
  GetVinculoFinal = (plan_id: any): AxiosPromise => Api.get(`/vinculo-final/${plan_id}`);
  PostVinculoFinal = (plan_id: any, params: any): AxiosPromise => Api.post(`/vinculo-final/${plan_id}`, params);

  GetFinalDownload = (name: any): AxiosPromise => Api.get(`/final/download/${name}`);
  DeleteFileFinal = (params: any): AxiosPromise => Api.delete(`/final/file`, {params:params});
  
  GetVinculoFinalValidacao = (plan_id: any): AxiosPromise => Api.get(`/vinculo-final/validacao/${plan_id}`);
  
  downloadExcel = (plan_id: any): AxiosPromise => Api.get(`/relatorios/vizualizar-relatorio/${plan_id}`);
  DeleteFileSinteseScenario = ({ planoId, fileType, fileName }: { planoId: any, fileType: any, fileName: any }): AxiosPromise => 
    Api.delete(`sintese-cenario/file?planoId=${planoId}&fileType=${fileType}&fileName=${fileName}`)
  DeleteFileSinteseDiagnostico = ({ planoId, fileType, fileName }: { planoId: any, fileType: any, fileName: any }): AxiosPromise => 
    Api.delete(`sintese-diagnostico/file?planoId=${planoId}&fileType=${fileType}&fileName=${fileName}`)
}
