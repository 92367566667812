import dayjs from "dayjs";

export const customOrderPlanManagement = (row: any) => {
  const now = dayjs();
  const threeMonthsAgo = now.subtract(3, 'month');

  // Primeira regra: Itens sem metas, macroacoes ou entregas
  if (
    (!row.metas || row.metas.length === 0) &&
    (!row.macroacoes || row.macroacoes.length === 0) &&
    (!row.entregas || row.entregas.length === 0)
  ) {
    return '1';
  }

  // Segunda regra: Itens com updatedAt maior que 3 meses
  if (
    dayjs(row.updatedAt).isValid() &&
    dayjs(row.updatedAt).isBefore(threeMonthsAgo)
  ) {
    return '2';
  }

  // Terceira regra: anoFinalVigencia maior que o ano atual
  if (dayjs(row.anoFinalVigencia).year() > now.year()) {
    return '3';
  }

  // Caso não atenda nenhuma das regras acima, retorna '4'
  return '4';
};