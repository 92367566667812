import React from "react";

export function ParqueEstadualGrajau() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Camada 1"
    viewBox="0 0 60 60"
  >
    <defs>
      <linearGradient
        id="a"
        x1={42.31}
        x2={11.07}
        y1={54.49}
        y2={9.88}
        data-name="Gradiente sem nome 7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#23bcef" />
        <stop offset={1} stopColor="#004b80" />
      </linearGradient>
    </defs>
    <path
      d="M55.13 42.65c0 2.17-1.28 4.15-3.27 5.03l-12.16 5.4c-.64.29-1.34.44-2.04.43H6.54c-.93 0-1.68-.75-1.68-1.68s.75-1.68 1.68-1.68h31.12c.23 0 .47-.05.68-.14l12.16-5.4a2.16 2.16 0 0 0 1.28-1.97c0-1.19-.96-2.15-2.15-2.15-.3 0-.6.06-.87.19l-9.05 4.02c-.64.29-1.34.44-2.04.43H24.98c-.93 0-1.68-.75-1.68-1.68s.75-1.68 1.68-1.68h8.38c.93 0 1.68-.75 1.68-1.68s-.75-1.68-1.68-1.68H21.34c-.44 0-.87.18-1.19.49l-3.06 3.06c-.94.95-2.22 1.48-3.55 1.47H6.55c-.93 0-1.68-.75-1.68-1.68s.75-1.68 1.68-1.68h6.99c.44 0 .87-.18 1.19-.49l3.06-3.06c.94-.95 2.22-1.48 3.55-1.47h12.02a5.027 5.027 0 0 1 4.76 6.65c.08-.02.16-.05.23-.08l9.05-4.02c2.78-1.23 6.03.02 7.26 2.79.31.7.47 1.47.47 2.24ZM14.08 10.8c0-.87.09-1.74.24-2.6.12-.69.66-1.22 1.35-1.35.86-.16 1.72-.24 2.6-.24 7.4 0 13.39 6 13.4 13.4v10.05c0 .93-.75 1.68-1.68 1.68s-1.68-.75-1.68-1.68v-5.9c-.28.02-.56.04-.84.04-7.4 0-13.39-6-13.4-13.4Zm3.35 0c0 5.55 4.5 10.05 10.05 10.05.26 0 .53-.01.8-.03.02-.27.03-.54.03-.8 0-5.55-4.5-10.05-10.05-10.05-.26 0-.53.01-.8.03-.02.27-.03.54-.03.8Zm17.11 2.17a9.964 9.964 0 0 1 7.18-3.01c.26 0 .53.01.8.03a10.095 10.095 0 0 1-7.87 10.62c-.91.19-1.48 1.08-1.29 1.99.19.91 1.08 1.48 1.99 1.29h.02a13.464 13.464 0 0 0 10.53-13.1c0-.87-.09-1.74-.24-2.6a1.67 1.67 0 0 0-1.35-1.35c-.86-.16-1.72-.24-2.6-.24-3.6-.01-7.06 1.44-9.57 4.02a1.675 1.675 0 1 0 2.39 2.35Z"
      style={{
        fill: "url(#a)",
        strokeWidth: 0,
      }}
    />
  </svg>
  );
}
