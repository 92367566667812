import React from "react";
import { useAuth } from "@/hooks/useAuth";
import { usePlans } from "@/hooks/usePlans";
import { parseToSnakeCase } from "@/utils/parseToSnakCase";
import { loopArraySort } from "@/utils/descriptionFilterOrder";
import { PlansService } from "@/services/endpoints/plans";
import { useRouter } from "next/router";

interface FilterChangeProps {
  e: any;
}

export interface IFilterClassificationContextProps {
  endDate: string;
  filterItems: any;
  startDate: string;
  sliderMarks: any[];
  checkboxItems: any;
  filterError: boolean;
  sliderValue: number[];
  checkboxSubItems: any;
  filterError404: boolean;
  lessSliderLimitDate: number;
  checkboxSubItemsClicked: any;
  diffSliderCurrentYear: number;
  checkedAll: Record<string, boolean>;
  filterValues: Record<string, boolean>;
  handleClearAllSearchInputs: () => void;
  setCheckboxSubItemsClicked: React.Dispatch<any>;
  handleFilterChange: ({ e }: FilterChangeProps) => void;
  handleCheckedAll: (value: boolean, key: string) => void;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setFilterError: React.Dispatch<React.SetStateAction<boolean>>;
  handleChangeSliderCommited: (event: any, newValue: any) => void;
  // setClickedAllItems: React.Dispatch<React.SetStateAction<string>>;
  handleEndDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleStartDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSlider: (event: Event, newValue: number | number[]) => void;
  handleChangeOneByOneCheckBoxItem: (
    id: string,
    value: boolean,
    key: string
  ) => void;
  handleChangeOneByOneCheckBoxSubItem: (
    id: string,
    value: boolean,
    key: string
  ) => void;
}

export const FilterClassificationContext = React.createContext(
  {} as IFilterClassificationContextProps
);

interface FilterChangeProps {
  e: any;
}

type FilterValuesProps = Record<string, boolean>;

interface IProps {
  children: React.ReactNode;
}

export const FilterClassificationContextProvider: React.FC<IProps> = ({
  children,
}) => {
  const { getFilter, getPeriod } = new PlansService();
  const { authenticated } = useAuth();
  const {
    setIsResearch,
    isAlphaOrder,
    isFinalOrder,
    isInitialOrder,
    searchJsonTerms,
    handleGetAllPlans,
    handleSearchPlans,
    setSearchJsonTerms,
    setInputResearchText,
  } = usePlans();

  // estado que constroi as marcas do slide de periodo de vigência
  const [sliderMarks, setSliderMarks] = React.useState<any[]>([]);
  // estado que armazena o limite inferior no periodo de vigência
  const [lessSliderLimitDate, setLessSliderLimitDate] =
    React.useState<number>(0);
  // estado que armazena o limite superior no periodo de vigência
  const [highstSliderLimitDate, setHighstSliderLimitDate] =
    React.useState<number>(0);
  // estado que armazena a diferença do ano atual com o menor ano dos planos
  const [diffSliderCurrentYear, setDiffSliderCurrentYear] =
    React.useState<number>(0);
  // estado que armazena o valor absoluto na mudança do componente de slide
  const [sliderValue, setSliderValue] = React.useState<number[]>([
    0,
    diffSliderCurrentYear,
  ]);

  // Array que contem objetos do filtro lateral
  const [filterItems, setFilterItems] = React.useState({} as any);
  const [filterItemSelected, setFilterItemSelected] = React.useState(false);
  const [nameFilterItemSelected, setNameFilterItemSelected] =
    React.useState("");

  // Estado que controla o estado aberto e fechado do select do filtro lateral
  const [filterValues, setFilterValues] = React.useState<FilterValuesProps>({
    tipos: false,
    temas: false,
  });

  // erros na busca dos filtros
  const [filterError, setFilterError] = React.useState(false);
  const [filterError404, setFilterError404] = React.useState(false);
  const router = useRouter();

  // Função que busca todos os filtros, é usada na primeira renderização
  const handleGetAllFilters = (): void => {

    getFilter()
      .then((res) => {
        const { data } = res.data as any;
        const reorganizedData = {
          tipos: data["tipos"],
          temas: data["temas"],
        };
        setFilterItems(reorganizedData as any);
      })
      .catch((e) => {
        setFilterError(true);
        if (e?.response?.status === 404) {
          setFilterError404(true);
        }
      });
  };

  // UseEffect da primeira renderização
  React.useEffect(() => {
    const isValidPathName = router.pathname.split("/").includes('mapa_interativo') ||  router.pathname.split("/").includes('planos')
    if (isValidPathName)
    handleGetAllFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  React.useEffect(() => {
    const isValidPathName = router.pathname.split("/").includes('mapa_interativo') ||  router.pathname.split("/").includes('planos')
    if (authenticated && isValidPathName) {
      getPeriod().then((res) => {
        const { anoFinal, anoInicial } = res.data;
        setLessSliderLimitDate(anoFinal);
        setHighstSliderLimitDate(anoInicial);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, router.pathname]);

  React.useEffect(() => {
    setDiffSliderCurrentYear(highstSliderLimitDate - lessSliderLimitDate);
    setSliderMarks(
      Array.from({ length: diffSliderCurrentYear }, (_, index) => ({
        value: index,
      }))
    );
    setSliderValue([0, diffSliderCurrentYear]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lessSliderLimitDate, highstSliderLimitDate, diffSliderCurrentYear]);

  const handleClearAllSearchInputs = () => {
    // limpa objeto da busca
    delete searchJsonTerms['temas'];
    delete searchJsonTerms['tipos'];
    delete searchJsonTerms['query'];
    delete searchJsonTerms['subtemas'];
    delete searchJsonTerms['periodo_vigencia'];

    // limpa inputs de periodo de vigência
    setEndDate("");
    setStartDate("");

    // fecha as seções abertas
    setFilterValues({ tipos: false, tema: false });

    // desceleciona todos os items do array
    setCheckedAll({ tipos: false, tema: false });

    // limpa o item "selecionar todos"
    // setClickedAllItems("");

    // limpa o slider de data
    setDiffSliderCurrentYear(
      (highstSliderLimitDate - lessSliderLimitDate) * -1
    );
    setSliderValue([0, diffSliderCurrentYear]);

    // estado que limpa a descrição dos resultados de busca
    setIsResearch(false);
    handleGetAllPlans(1);
    setCheckboxItems({});
    handleFillFilterItems();
    setCheckboxSubItems({});
    setInputResearchText("");
    setCheckboxSubItemsClicked({});
  };

  // Funcao que muda o estado aberto e fechado do select do filtro lateral
  const handleFilterChange = ({ e }: FilterChangeProps) => {
    const name = e.currentTarget.innerText.toLocaleLowerCase() as string;
    setFilterItemSelected(!filterItemSelected);
    setNameFilterItemSelected(name);
  };

  React.useEffect(() => {
    setFilterValues((prevState) => ({
      ...prevState,
      [nameFilterItemSelected]: filterItemSelected,
    }));
  }, [filterItemSelected, nameFilterItemSelected]);

  /*
    ESTADOS E FUNÇÕES QUE CONTROLAM OS CHECKBOX NO MENU LATERAL
  */

  // estado responsável pela alteração dos itens checkbox
  const [checkboxItems, setCheckboxItems] = React.useState({} as any);
  const [checkboxSubItems, setCheckboxSubItems] = React.useState({} as any);
  const [checkboxSubItemsClicked, setCheckboxSubItemsClicked] = React.useState(
    {} as any
  );

  // atribui a propriedade cheked: false aos items do menu lateral
  const parseData = (data: any) => {
    return data?.map((item: any) => ({ ...item, checked: false }));
  };

  const handleFillFilterItems = () => {
    const items = {} as any;
    const subItems = {} as any;

    Object.entries(filterItems).forEach(
      ([key, value]) => (items[key] = parseData(value))
    );

    Object.entries(filterItems).forEach(([_, value]) => {
      const items = value as any[];
      items?.map((item) => {
        const descricao = item?.descricao;
        const subtemas = item?.subtemas;
        if (subtemas?.length > 0) {
          subItems[parseToSnakeCase(descricao)] = item?.subtemas;
          setCheckboxSubItems((prev: any) => ({
            ...prev,
            [parseToSnakeCase(descricao)]: false,
          }));
        }
      });
    });

    Object.entries(subItems).forEach(
      ([key, value]) => (subItems[key] = parseData(value))
    );

    const sortItems = loopArraySort(items);
    const sortSubItems = loopArraySort(subItems);

    setCheckboxItems(sortItems);
    setCheckboxSubItems(sortSubItems);
    setCheckboxSubItemsClicked(sortSubItems);
  };

  // responsável por preencher os itens do filtro lateral
  React.useEffect(() => {
    handleFillFilterItems();
  }, [filterItems]);

  // Estado que controla se o item "selecionar todos" está clicado
  // const [clickedAllItems, setClickedAllItems] = React.useState("");

  // Estado que controla se todos os checkbox estão selecionados no filtro lateral
  const [checkedAll, setCheckedAll] = React.useState({
    tipos: false,
    temas: false,
  } as Record<string, boolean>);

  const customItems = {} as any;
  // Função que controla a mudança de checkbox no filtro lateral
  const handleCheckedAll = (value: boolean, key: string) => {
    const data = { ...filterItems } as Record<string, unknown>;
    const arr = data[key] as any[];
    if (value) {
      searchJsonTerms[key] = arr.map((item) => item?.id);

      Object.entries(filterItems).forEach(([internalkey, value]) => {
        const v = value as any[];
        if (internalkey === key) {
          customItems[key] = v?.map((item: any) => ({
            ...item,
            checked: true,
          }));
        }
      });

      const itemsKey = loopArraySort(customItems);

      setCheckboxItems((prev: any) => ({
        ...prev,
        [key]: itemsKey[key],
      }));
    } else {
      delete searchJsonTerms[key];
      delete searchJsonTerms["subtemas"];

      Object.entries(filterItems).forEach(([key, value]) => {
        const v = value as any[];
        customItems[key] = v?.map((item: any) => ({ ...item, checked: false }));
      });

      setCheckboxSubItems((prev: any) => {
        const data = { ...prev };
        Object.entries(data).forEach(
          ([key, value]) => (data[key] = parseData(value))
        );
        return data;
      });

      const itemsKey = loopArraySort(customItems);

      setCheckboxItems((prev: any) => ({
        ...prev,
        [key]: itemsKey[key],
      }));

      if (Object.keys(searchJsonTerms).length === 0) {
        handleGetAllPlans(1);
        setIsResearch(false);
      }
    }

    setCheckedAll((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (Object.keys(searchJsonTerms).length > 0) {
      handleSearchPlans({ data: searchJsonTerms, isAlphaOrder, isFinalOrder, isInitialOrder });
    }
  };

  // Função que adiciona um item no searchJsonTerm a partir da interação com checkbox
  const addItemFromCheckbox = (key: any, findItem: any) => {
    const prevItems = searchJsonTerms[key] as number[];
    if (
      prevItems &&
      (prevItems.length === 0 || prevItems.indexOf(findItem) === -1)
    ) {
      prevItems.push(findItem);
    } else {
      searchJsonTerms[key] = [findItem];
    }
  };

  // Função que remove um item no searchJsonTerm a partir da interação com checkbox
  const removeItemFromCheckbox = (key: any, findItem: any) => {
    if (Object.keys(searchJsonTerms).length > 0) {
      const prevItems = searchJsonTerms[key] as number[];
      searchJsonTerms[key] = prevItems?.filter((item) => item !== findItem);
      const newPrevItems = searchJsonTerms[key] as number[];

      if (newPrevItems?.length === 0) {
        delete searchJsonTerms[key];
      }
    } else {
      delete searchJsonTerms[key];
    }
  };

  // Função que executa a requisição a partir da seleção dos checkbox
  const handleChangeOneByOneCheckBoxItem = (
    id: string,
    value: boolean,
    key: string
  ) => {
    const data = { ...filterItems } as Record<string, unknown>;
    const newArray = data[key] as any[];
    const findItem = newArray?.find((item) => +item.id === +id)?.id;

    if (value) {
      addItemFromCheckbox(key, findItem);
    } else {
      removeItemFromCheckbox(key, findItem);
    }

    setCheckboxItems((prev: any) => {
      const data = { ...prev };
      const index = data[key].findIndex((item: any) => +item?.id === +id);
      const item = data[key].find((item: any) => +item?.id === +id);

      if (!value && item?.subtemas?.length > 0) {
        const findIFulltem = newArray?.find((item) => +item.id === +id);
        const itemKey = parseToSnakeCase(findIFulltem?.descricao);
        const subItemsIds: any[] = [];

        setCheckboxSubItems((prev: any) => {
          const data = { ...prev };
          const newObject = data[itemKey]?.map((item: any) => {
            if (item?.checked) {
              subItemsIds.push(item?.id);
            }

            return {
              ...item,
              checked: false,
            };
          });

          const prevItems = searchJsonTerms["subtemas"] as number[];

          if (prevItems !== undefined) {
            searchJsonTerms["subtemas"] = prevItems?.filter(
              (numero) => !subItemsIds.includes(numero)
            );

            data[itemKey] = newObject;
          }

          return data;
        });

        const subtemas = searchJsonTerms["subtemas"] as any[];

        if (subtemas?.length === 0) {
          delete searchJsonTerms["subtemas"];
        }
      }

      data[key][index] = { ...item, checked: !item?.checked };
      return data;
    });

    if (Object.keys(searchJsonTerms).length === 0) {
      handleGetAllPlans(1);
    } else {
      handleSearchPlans({ data: searchJsonTerms, isAlphaOrder, isFinalOrder, isInitialOrder });
    }
  };

  const addSubItemFromCheckbox = (findItem: any) => {
    const prevItems = searchJsonTerms["subtemas"] as number[];
    if (
      prevItems &&
      (prevItems.length === 0 || prevItems.indexOf(findItem) === -1)
    ) {
      prevItems.push(findItem);
    } else {
      searchJsonTerms["subtemas"] = [findItem];
    }
  };

  const removeSubItemFromCheckbox = (findItem: any) => {
    if (Object.keys(searchJsonTerms).length > 0) {
      const prevItems = searchJsonTerms["subtemas"] as number[];
      searchJsonTerms["subtemas"] = prevItems?.filter(
        (item) => item !== findItem
      );
      const newPrevItems = searchJsonTerms["subtemas"] as number[];

      if (newPrevItems?.length === 0) {
        delete searchJsonTerms["subtemas"];
      }
    } else {
      delete searchJsonTerms["subtemas"];
    }
  };

  const handleChangeOneByOneCheckBoxSubItem = (
    id: string,
    value: boolean,
    key: string
  ) => {
    const data = { ...checkboxSubItems } as Record<string, unknown>;
    const items = data[key] as any[];

    const findItem = items?.find((item) => +item?.id === +id)?.id;

    setCheckboxSubItems((prev: any) => {
      const data = { ...prev };
      const index = data[key].findIndex((item: any) => +item?.id === +id);
      const item = data[key].find((item: any) => +item?.id === +id);
      data[key][index] = { ...item, checked: !item?.checked };
      return data;
    });

    if (value) {
      addSubItemFromCheckbox(findItem);
    } else {
      removeSubItemFromCheckbox(findItem);
    }

    if (Object.keys(searchJsonTerms).length === 0) {
      handleGetAllPlans(1);
    } else {
      handleSearchPlans({ data: searchJsonTerms, isAlphaOrder, isFinalOrder, isInitialOrder });
    }
  };

  /*
   * ESTADOS E FUNÇÕES QUE CONTROLAM O PERIODO DE VIGÊNCIA NO MENU LATERAL
   */
  const [endDate, setEndDate] = React.useState("");
  const [startDate, setStartDate] = React.useState("");

  // Função que muda os valores relacionados ao componente slider
  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number[]);
  };

  const handleChangeSliderCommited = (event: any, newValue: any) => {
    const arr = newValue as number[];
    const it = arr.map((item) => Number(Number(lessSliderLimitDate) + item));

    setSearchJsonTerms((prev) => ({
      ...prev,
      ["periodo_vigencia"]: it,
    }));
    setEndDate(String(it[1]));
    setStartDate(String(it[0]));
  };

  // Função que muda os valores relacionados ao input de data
  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const inputNumbers = inputValue.replace(/\D/g, "");
    const limitedNumbers = inputNumbers.slice(0, 4);
    const currentYear = Number(highstSliderLimitDate);

    if (limitedNumbers.length > 0) {
      const clampedYear = String(
        Math.min(currentYear, parseInt(limitedNumbers, 10))
      );
      setStartDate(clampedYear);
    } else {
      setStartDate(limitedNumbers);
    }
  };

  // Função que muda os valores relacionados ao input de data
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const inputNumbers = inputValue.replace(/\D/g, "");
    const limitedNumbers = inputNumbers.slice(0, 4);
    const currentYear = Number(highstSliderLimitDate);

    if (limitedNumbers.length > 0) {
      const clampedYear = String(
        Math.min(currentYear, parseInt(limitedNumbers, 10))
      );
      setEndDate(clampedYear);
    } else {
      setEndDate(limitedNumbers);
    }
  };

  // Chamada de api para o periodo de vigência
  React.useEffect(() => {
      const isValidPathName = router.pathname.split("/").includes('mapa_interativo') ||  router.pathname.split("/").includes('planos')
    if (isValidPathName) 
    if (startDate?.length === 4 && endDate?.length === 4) {
      searchJsonTerms["periodo_vigencia"] = [
        Number(startDate),
        Number(endDate),
      ];
      handleSearchPlans({ data: searchJsonTerms, isAlphaOrder, isFinalOrder, isInitialOrder });
    }

    if (!startDate && !endDate && Object.keys(searchJsonTerms).length === 0) {
      handleGetAllPlans(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, router.pathname]);

  const value = {
    endDate,
    startDate,
    checkedAll,
    setEndDate,
    sliderValue,
    filterItems,
    sliderMarks,
    filterError,
    setStartDate,
    filterValues,
    checkboxItems,
    setFilterError,
    filterError404,
    handleCheckedAll,
    checkboxSubItems,
    handleFilterChange,
    handleChangeSlider,
    handleEndDateChange,
    lessSliderLimitDate,
    handleStartDateChange,
    diffSliderCurrentYear,
    checkboxSubItemsClicked,
    handleChangeSliderCommited,
    handleClearAllSearchInputs,
    setCheckboxSubItemsClicked,
    handleChangeOneByOneCheckBoxItem,
    handleChangeOneByOneCheckBoxSubItem,
  } as IFilterClassificationContextProps;

  return (
    <FilterClassificationContext.Provider value={value}>
      {children}
    </FilterClassificationContext.Provider>
  );
};
