import { InfoIncon } from "../info";
import { Container } from "./styled";
import React, { useState } from "react";
import { Tooltip } from '@mui/material';
import { useRouter } from "next/router";
import { ConsideracaoIcon } from "../consideracao";
import { PlansService } from "@/services/endpoints/plans";
import { ConsiderationModal } from "../../Forms/ConsiderationModal";

interface IProps {
  errors: any;
  context?: any;
  name: string;
  planId?: any;
  register: any;
  label: string;
  tooltip?: any;
  getDados?: any,
  required: boolean;
  labelLink?: string,
  downloadName?: string,
  defaultValue?: string;
  commentTittle?: string;
  labelLinkLabel?: string
  accept: "image/*" | ".pdf" | ".svg" | ".docx";
}

export const FileFieldElaboration: React.FC<IProps> = ({
  name,
  label,
  errors,
  accept,
  planId,
  tooltip,
  context,
  required,
  register,
  getDados,
  defaultValue,
  downloadName,
  commentTittle,
  labelLink = '',
  labelLinkLabel = '',
}) => {

  const router = useRouter();
  const [preview, setPreview] = React.useState<boolean>(false);
  const { DeleteFileFinal, DeleteFileSinteseScenario, DeleteFileSinteseDiagnostico } = new PlansService()
  const [buferValue, setBufferValue] = useState<any>(null)
  const [buferDownloadValue, setBufferDownloadValue] = useState<any>(null)

  React.useEffect(() => {
    if (!router.isReady) return
    if (!router.query.id) return

    setPreview(router.asPath.includes('preview'));
  }, [router])

  React.useEffect(() => {
    setBufferDownloadValue(downloadName)
  }, [downloadName])

  const downloadFile = () => {
    const spanSpace = {}
    const deleteFile = () => {
      const params = {
        fileType: name,
        fileName: downloadName,
        planoId: router.query.id,
      }

      if (context == 'sintese_cenario') {
        DeleteFileSinteseScenario({ fileName: downloadName, fileType: name, planoId: planId })
        .then(() => {
          if (getDados) getDados()
          buferDownloadValue(null)
        })
        .catch((err) => console.error(err))
      } else if (context == 'sintese_diagnostico') {
        DeleteFileSinteseDiagnostico({ fileName: downloadName, fileType: name, planoId: planId })
        .then(() => {
          if (getDados) getDados()
          buferDownloadValue(null)
        })
        .catch((err) => console.error(err))
      } else {
        DeleteFileFinal(params)
        .then(() => {
          if (getDados) getDados()
          buferDownloadValue(null)
        })
        .catch((err) => console.error(err))
      }
      

    }

    const clearFile = () => {
      const input = document.getElementById("file-input" + name) as HTMLInputElement
      if (input) {
        input.value = '';
        setBufferValue(null)
      }
    }

    if (buferValue) {
      return (
        <label className={preview ? 'file-input__label_preview' : 'file-input__label'} htmlFor={"file-input" + name}>
          {accept == 'image/*' && (
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.00610352 9.1875C0.00610352 7.56305 0.651414 6.00513 1.80007 4.85647C2.94873 3.70781 4.50665 3.0625 6.1311 3.0625H42.8811C44.5056 3.0625 46.0635 3.70781 47.2121 4.85647C48.3608 6.00513 49.0061 7.56305 49.0061 9.1875V39.8125C49.0061 41.437 48.3608 42.9949 47.2121 44.1435C46.0635 45.2922 44.5056 45.9375 42.8811 45.9375H6.1311C4.50665 45.9375 2.94873 45.2922 1.80007 44.1435C0.651414 42.9949 0.00610352 41.437 0.00610352 39.8125V9.1875ZM3.0686 36.75V39.8125C3.0686 40.6247 3.39126 41.4037 3.96559 41.978C4.53992 42.5523 5.31888 42.875 6.1311 42.875H42.8811C43.6933 42.875 44.4723 42.5523 45.0466 41.978C45.6209 41.4037 45.9436 40.6247 45.9436 39.8125V29.0938L34.3765 23.1311C34.0893 22.9872 33.7642 22.9373 33.4471 22.9884C33.1299 23.0395 32.8369 23.1891 32.6095 23.4159L21.2476 34.7778L13.1014 29.351C12.8072 29.1552 12.4544 29.0671 12.1028 29.1017C11.7511 29.1363 11.4223 29.2915 11.172 29.5409L3.0686 36.75ZM18.3811 16.8438C18.3811 15.6254 17.8971 14.457 17.0356 13.5955C16.1741 12.734 15.0057 12.25 13.7874 12.25C12.569 12.25 11.4006 12.734 10.5391 13.5955C9.67759 14.457 9.1936 15.6254 9.1936 16.8438C9.1936 18.0621 9.67759 19.2305 10.5391 20.092C11.4006 20.9535 12.569 21.4375 13.7874 21.4375C15.0057 21.4375 16.1741 20.9535 17.0356 20.092C17.8971 19.2305 18.3811 18.0621 18.3811 16.8438Z" fill="#818181" />
            </svg>
          )}
          {accept == '.svg' && (
            <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.00610352 9.1875C0.00610352 7.56305 0.651414 6.00513 1.80007 4.85647C2.94873 3.70781 4.50665 3.0625 6.1311 3.0625H42.8811C44.5056 3.0625 46.0635 3.70781 47.2121 4.85647C48.3608 6.00513 49.0061 7.56305 49.0061 9.1875V39.8125C49.0061 41.437 48.3608 42.9949 47.2121 44.1435C46.0635 45.2922 44.5056 45.9375 42.8811 45.9375H6.1311C4.50665 45.9375 2.94873 45.2922 1.80007 44.1435C0.651414 42.9949 0.00610352 41.437 0.00610352 39.8125V9.1875ZM3.0686 36.75V39.8125C3.0686 40.6247 3.39126 41.4037 3.96559 41.978C4.53992 42.5523 5.31888 42.875 6.1311 42.875H42.8811C43.6933 42.875 44.4723 42.5523 45.0466 41.978C45.6209 41.4037 45.9436 40.6247 45.9436 39.8125V29.0938L34.3765 23.1311C34.0893 22.9872 33.7642 22.9373 33.4471 22.9884C33.1299 23.0395 32.8369 23.1891 32.6095 23.4159L21.2476 34.7778L13.1014 29.351C12.8072 29.1552 12.4544 29.0671 12.1028 29.1017C11.7511 29.1363 11.4223 29.2915 11.172 29.5409L3.0686 36.75ZM18.3811 16.8438C18.3811 15.6254 17.8971 14.457 17.0356 13.5955C16.1741 12.734 15.0057 12.25 13.7874 12.25C12.569 12.25 11.4006 12.734 10.5391 13.5955C9.67759 14.457 9.1936 15.6254 9.1936 16.8438C9.1936 18.0621 9.67759 19.2305 10.5391 20.092C11.4006 20.9535 12.569 21.4375 13.7874 21.4375C15.0057 21.4375 16.1741 20.9535 17.0356 20.092C17.8971 19.2305 18.3811 18.0621 18.3811 16.8438Z" fill="#818181" />
            </svg>
          )}
          {accept == '.pdf' && (
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 6.25C0 2.80273 2.80273 0 6.25 0H21.875V12.5C21.875 14.2285 23.2715 15.625 25 15.625H37.5V29.6875H17.1875C13.7402 29.6875 10.9375 32.4902 10.9375 35.9375V50H6.25C2.80273 50 0 47.1973 0 43.75V6.25ZM37.5 12.5H25V0L37.5 12.5ZM17.1875 34.375H20.3125C23.3301 34.375 25.7812 36.8262 25.7812 39.8438C25.7812 42.8613 23.3301 45.3125 20.3125 45.3125H18.75V48.4375C18.75 49.2969 18.0469 50 17.1875 50C16.3281 50 15.625 49.2969 15.625 48.4375V35.9375C15.625 35.0781 16.3281 34.375 17.1875 34.375ZM20.3125 42.1875C21.6113 42.1875 22.6562 41.1426 22.6562 39.8438C22.6562 38.5449 21.6113 37.5 20.3125 37.5H18.75V42.1875H20.3125ZM29.6875 34.375H32.8125C35.4004 34.375 37.5 36.4746 37.5 39.0625V45.3125C37.5 47.9004 35.4004 50 32.8125 50H29.6875C28.8281 50 28.125 49.2969 28.125 48.4375V35.9375C28.125 35.0781 28.8281 34.375 29.6875 34.375ZM32.8125 46.875C33.6719 46.875 34.375 46.1719 34.375 45.3125V39.0625C34.375 38.2031 33.6719 37.5 32.8125 37.5H31.25V46.875H32.8125ZM40.625 35.9375C40.625 35.0781 41.3281 34.375 42.1875 34.375H46.875C47.7344 34.375 48.4375 35.0781 48.4375 35.9375C48.4375 36.7969 47.7344 37.5 46.875 37.5H43.75V40.625H46.875C47.7344 40.625 48.4375 41.3281 48.4375 42.1875C48.4375 43.0469 47.7344 43.75 46.875 43.75H43.75V48.4375C43.75 49.2969 43.0469 50 42.1875 50C41.3281 50 40.625 49.2969 40.625 48.4375V42.1875V35.9375Z" fill="#818181" />
            </svg>
          )}
          {accept == '.docx' && (
            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_299_3726)">
                <path d="M47.7419 5.12178H29.5221V0.578125H26.1257L0 5.13311V44.8782L25.9724 49.4219H29.5221V44.8952H46.5719C47.5374 44.8498 48.6108 44.9236 49.44 44.333C50.1045 43.3164 49.9342 42.0441 49.9909 40.8969V7.32539C50.1102 6.03047 49.0368 4.99111 47.7419 5.12178ZM18.9468 32.7185C17.9983 33.2126 16.5784 32.6957 15.4539 32.7752C14.6985 29.0154 13.8182 25.2783 13.1424 21.5015C12.4778 25.1704 11.6146 28.8052 10.8535 32.4515C9.76309 32.3947 8.66699 32.3265 7.5708 32.2527C6.62793 27.2547 5.52051 22.2908 4.64014 17.2815C5.61133 17.2361 6.58818 17.1964 7.55937 17.1623C8.14434 20.7802 8.80889 24.381 9.32012 28.0045C10.1209 24.29 10.9387 20.5757 11.7339 16.8613C12.813 16.7988 13.8921 16.7534 14.9712 16.6966C15.7266 20.5303 16.4989 24.3582 17.3168 28.1748C17.9586 24.2333 18.6685 20.303 19.3558 16.3672C20.4916 16.3274 21.6275 16.2649 22.7577 16.1968C21.4742 21.7002 20.3496 27.2491 18.9468 32.7185ZM47.2878 42.1978H29.5221V38.6308H44.2887V36.359H29.5221V33.5192H44.2887V31.2475H29.5221V28.4076H44.2887V26.1359H29.5221V23.2961H44.2887V21.0244H29.5221V18.1846H44.2887V15.9128H29.5221V13.073H44.2887V10.8013H29.5221V7.80225H47.2878V42.1978Z" fill="#818181" />
              </g>
              <defs>
                <clipPath id="clip0_299_3726">
                  <rect width="50" height="50" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          <span style={{ whiteSpace: 'break-spaces', wordBreak: 'break-all' }}>{buferValue}</span>
          {
            !preview && (
              <button onClick={() => clearFile()} style={{ background: 'transparent', border: 'none', marginLeft: 15 }}>
                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.755 1.12685C4.82416 0.988448 4.93048 0.872034 5.06206 0.790643C5.19364 0.709252 5.34528 0.666098 5.5 0.666016H10.5C10.6547 0.666098 10.8064 0.709252 10.9379 0.790643C11.0695 0.872034 11.1758 0.988448 11.245 1.12685L12.6817 3.99935H14.6667C14.8877 3.99935 15.0996 4.08715 15.2559 4.24343C15.4122 4.39971 15.5 4.61167 15.5 4.83268C15.5 5.0537 15.4122 5.26566 15.2559 5.42194C15.0996 5.57822 14.8877 5.66602 14.6667 5.66602H13.8333V14.8327C13.8333 15.4957 13.5699 16.1316 13.1011 16.6004C12.6323 17.0693 11.9964 17.3327 11.3333 17.3327H4.66667C4.00363 17.3327 3.36774 17.0693 2.8989 16.6004C2.43006 16.1316 2.16667 15.4957 2.16667 14.8327V5.66602H1.33333C1.11232 5.66602 0.900358 5.57822 0.744078 5.42194C0.587797 5.26566 0.5 5.0537 0.5 4.83268C0.5 4.61167 0.587797 4.39971 0.744078 4.24343C0.900358 4.08715 1.11232 3.99935 1.33333 3.99935H3.31833L4.755 1.12685ZM9.985 2.33268L10.8183 3.99935H5.18167L6.015 2.33268H9.985ZM7.16667 8.16602C7.16667 7.945 7.07887 7.73304 6.92259 7.57676C6.76631 7.42048 6.55435 7.33268 6.33333 7.33268C6.11232 7.33268 5.90036 7.42048 5.74408 7.57676C5.5878 7.73304 5.5 7.945 5.5 8.16602V13.166C5.5 13.387 5.5878 13.599 5.74408 13.7553C5.90036 13.9116 6.11232 13.9993 6.33333 13.9993C6.55435 13.9993 6.76631 13.9116 6.92259 13.7553C7.07887 13.599 7.16667 13.387 7.16667 13.166V8.16602ZM10.5 8.16602C10.5 7.945 10.4122 7.73304 10.2559 7.57676C10.0996 7.42048 9.88768 7.33268 9.66667 7.33268C9.44565 7.33268 9.23369 7.42048 9.07741 7.57676C8.92113 7.73304 8.83333 7.945 8.83333 8.16602V13.166C8.83333 13.387 8.92113 13.599 9.07741 13.7553C9.23369 13.9116 9.44565 13.9993 9.66667 13.9993C9.88768 13.9993 10.0996 13.9116 10.2559 13.7553C10.4122 13.599 10.5 13.387 10.5 13.166V8.16602Z" fill="#EC1F1F" />
                </svg>
              </button>
            )
          }

        </label>
      )
    }

    if (buferDownloadValue) {
      return (
        <>
          <a
            href={`https://dev.interplan.macrodevplan.kinghost.net/api/final/download/${downloadName}`}
            style={{ color: '#004A80' }}
          >Baixar arquivo</a>
          {!preview && (
            <button onClick={() => deleteFile()} style={{ background: 'transparent', border: 'none', marginLeft: 15 }}>
              <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.755 1.12685C4.82416 0.988448 4.93048 0.872034 5.06206 0.790643C5.19364 0.709252 5.34528 0.666098 5.5 0.666016H10.5C10.6547 0.666098 10.8064 0.709252 10.9379 0.790643C11.0695 0.872034 11.1758 0.988448 11.245 1.12685L12.6817 3.99935H14.6667C14.8877 3.99935 15.0996 4.08715 15.2559 4.24343C15.4122 4.39971 15.5 4.61167 15.5 4.83268C15.5 5.0537 15.4122 5.26566 15.2559 5.42194C15.0996 5.57822 14.8877 5.66602 14.6667 5.66602H13.8333V14.8327C13.8333 15.4957 13.5699 16.1316 13.1011 16.6004C12.6323 17.0693 11.9964 17.3327 11.3333 17.3327H4.66667C4.00363 17.3327 3.36774 17.0693 2.8989 16.6004C2.43006 16.1316 2.16667 15.4957 2.16667 14.8327V5.66602H1.33333C1.11232 5.66602 0.900358 5.57822 0.744078 5.42194C0.587797 5.26566 0.5 5.0537 0.5 4.83268C0.5 4.61167 0.587797 4.39971 0.744078 4.24343C0.900358 4.08715 1.11232 3.99935 1.33333 3.99935H3.31833L4.755 1.12685ZM9.985 2.33268L10.8183 3.99935H5.18167L6.015 2.33268H9.985ZM7.16667 8.16602C7.16667 7.945 7.07887 7.73304 6.92259 7.57676C6.76631 7.42048 6.55435 7.33268 6.33333 7.33268C6.11232 7.33268 5.90036 7.42048 5.74408 7.57676C5.5878 7.73304 5.5 7.945 5.5 8.16602V13.166C5.5 13.387 5.5878 13.599 5.74408 13.7553C5.90036 13.9116 6.11232 13.9993 6.33333 13.9993C6.55435 13.9993 6.76631 13.9116 6.92259 13.7553C7.07887 13.599 7.16667 13.387 7.16667 13.166V8.16602ZM10.5 8.16602C10.5 7.945 10.4122 7.73304 10.2559 7.57676C10.0996 7.42048 9.88768 7.33268 9.66667 7.33268C9.44565 7.33268 9.23369 7.42048 9.07741 7.57676C8.92113 7.73304 8.83333 7.945 8.83333 8.16602V13.166C8.83333 13.387 8.92113 13.599 9.07741 13.7553C9.23369 13.9116 9.44565 13.9993 9.66667 13.9993C9.88768 13.9993 10.0996 13.9116 10.2559 13.7553C10.4122 13.599 10.5 13.387 10.5 13.166V8.16602Z" fill="#EC1F1F" />
              </svg>
            </button>
          )}
        </>
      )
    }

    if (preview) {
      <a style={{ color: '#004A80' }} >Sem Arquivo salvo</a>
    }

    return (
      <label className={preview ? 'file-input__label_preview' : 'file-input__label'} htmlFor={"file-input" + name}>
        {accept == 'image/*' && (
          <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00610352 9.1875C0.00610352 7.56305 0.651414 6.00513 1.80007 4.85647C2.94873 3.70781 4.50665 3.0625 6.1311 3.0625H42.8811C44.5056 3.0625 46.0635 3.70781 47.2121 4.85647C48.3608 6.00513 49.0061 7.56305 49.0061 9.1875V39.8125C49.0061 41.437 48.3608 42.9949 47.2121 44.1435C46.0635 45.2922 44.5056 45.9375 42.8811 45.9375H6.1311C4.50665 45.9375 2.94873 45.2922 1.80007 44.1435C0.651414 42.9949 0.00610352 41.437 0.00610352 39.8125V9.1875ZM3.0686 36.75V39.8125C3.0686 40.6247 3.39126 41.4037 3.96559 41.978C4.53992 42.5523 5.31888 42.875 6.1311 42.875H42.8811C43.6933 42.875 44.4723 42.5523 45.0466 41.978C45.6209 41.4037 45.9436 40.6247 45.9436 39.8125V29.0938L34.3765 23.1311C34.0893 22.9872 33.7642 22.9373 33.4471 22.9884C33.1299 23.0395 32.8369 23.1891 32.6095 23.4159L21.2476 34.7778L13.1014 29.351C12.8072 29.1552 12.4544 29.0671 12.1028 29.1017C11.7511 29.1363 11.4223 29.2915 11.172 29.5409L3.0686 36.75ZM18.3811 16.8438C18.3811 15.6254 17.8971 14.457 17.0356 13.5955C16.1741 12.734 15.0057 12.25 13.7874 12.25C12.569 12.25 11.4006 12.734 10.5391 13.5955C9.67759 14.457 9.1936 15.6254 9.1936 16.8438C9.1936 18.0621 9.67759 19.2305 10.5391 20.092C11.4006 20.9535 12.569 21.4375 13.7874 21.4375C15.0057 21.4375 16.1741 20.9535 17.0356 20.092C17.8971 19.2305 18.3811 18.0621 18.3811 16.8438Z" fill="#818181" />
          </svg>
        )}
        {accept == '.svg' && (
          <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.00610352 9.1875C0.00610352 7.56305 0.651414 6.00513 1.80007 4.85647C2.94873 3.70781 4.50665 3.0625 6.1311 3.0625H42.8811C44.5056 3.0625 46.0635 3.70781 47.2121 4.85647C48.3608 6.00513 49.0061 7.56305 49.0061 9.1875V39.8125C49.0061 41.437 48.3608 42.9949 47.2121 44.1435C46.0635 45.2922 44.5056 45.9375 42.8811 45.9375H6.1311C4.50665 45.9375 2.94873 45.2922 1.80007 44.1435C0.651414 42.9949 0.00610352 41.437 0.00610352 39.8125V9.1875ZM3.0686 36.75V39.8125C3.0686 40.6247 3.39126 41.4037 3.96559 41.978C4.53992 42.5523 5.31888 42.875 6.1311 42.875H42.8811C43.6933 42.875 44.4723 42.5523 45.0466 41.978C45.6209 41.4037 45.9436 40.6247 45.9436 39.8125V29.0938L34.3765 23.1311C34.0893 22.9872 33.7642 22.9373 33.4471 22.9884C33.1299 23.0395 32.8369 23.1891 32.6095 23.4159L21.2476 34.7778L13.1014 29.351C12.8072 29.1552 12.4544 29.0671 12.1028 29.1017C11.7511 29.1363 11.4223 29.2915 11.172 29.5409L3.0686 36.75ZM18.3811 16.8438C18.3811 15.6254 17.8971 14.457 17.0356 13.5955C16.1741 12.734 15.0057 12.25 13.7874 12.25C12.569 12.25 11.4006 12.734 10.5391 13.5955C9.67759 14.457 9.1936 15.6254 9.1936 16.8438C9.1936 18.0621 9.67759 19.2305 10.5391 20.092C11.4006 20.9535 12.569 21.4375 13.7874 21.4375C15.0057 21.4375 16.1741 20.9535 17.0356 20.092C17.8971 19.2305 18.3811 18.0621 18.3811 16.8438Z" fill="#818181" />
          </svg>
        )}
        {accept == '.pdf' && (
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 6.25C0 2.80273 2.80273 0 6.25 0H21.875V12.5C21.875 14.2285 23.2715 15.625 25 15.625H37.5V29.6875H17.1875C13.7402 29.6875 10.9375 32.4902 10.9375 35.9375V50H6.25C2.80273 50 0 47.1973 0 43.75V6.25ZM37.5 12.5H25V0L37.5 12.5ZM17.1875 34.375H20.3125C23.3301 34.375 25.7812 36.8262 25.7812 39.8438C25.7812 42.8613 23.3301 45.3125 20.3125 45.3125H18.75V48.4375C18.75 49.2969 18.0469 50 17.1875 50C16.3281 50 15.625 49.2969 15.625 48.4375V35.9375C15.625 35.0781 16.3281 34.375 17.1875 34.375ZM20.3125 42.1875C21.6113 42.1875 22.6562 41.1426 22.6562 39.8438C22.6562 38.5449 21.6113 37.5 20.3125 37.5H18.75V42.1875H20.3125ZM29.6875 34.375H32.8125C35.4004 34.375 37.5 36.4746 37.5 39.0625V45.3125C37.5 47.9004 35.4004 50 32.8125 50H29.6875C28.8281 50 28.125 49.2969 28.125 48.4375V35.9375C28.125 35.0781 28.8281 34.375 29.6875 34.375ZM32.8125 46.875C33.6719 46.875 34.375 46.1719 34.375 45.3125V39.0625C34.375 38.2031 33.6719 37.5 32.8125 37.5H31.25V46.875H32.8125ZM40.625 35.9375C40.625 35.0781 41.3281 34.375 42.1875 34.375H46.875C47.7344 34.375 48.4375 35.0781 48.4375 35.9375C48.4375 36.7969 47.7344 37.5 46.875 37.5H43.75V40.625H46.875C47.7344 40.625 48.4375 41.3281 48.4375 42.1875C48.4375 43.0469 47.7344 43.75 46.875 43.75H43.75V48.4375C43.75 49.2969 43.0469 50 42.1875 50C41.3281 50 40.625 49.2969 40.625 48.4375V42.1875V35.9375Z" fill="#818181" />
          </svg>
        )}
        {accept == '.docx' && (
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_299_3726)">
              <path d="M47.7419 5.12178H29.5221V0.578125H26.1257L0 5.13311V44.8782L25.9724 49.4219H29.5221V44.8952H46.5719C47.5374 44.8498 48.6108 44.9236 49.44 44.333C50.1045 43.3164 49.9342 42.0441 49.9909 40.8969V7.32539C50.1102 6.03047 49.0368 4.99111 47.7419 5.12178ZM18.9468 32.7185C17.9983 33.2126 16.5784 32.6957 15.4539 32.7752C14.6985 29.0154 13.8182 25.2783 13.1424 21.5015C12.4778 25.1704 11.6146 28.8052 10.8535 32.4515C9.76309 32.3947 8.66699 32.3265 7.5708 32.2527C6.62793 27.2547 5.52051 22.2908 4.64014 17.2815C5.61133 17.2361 6.58818 17.1964 7.55937 17.1623C8.14434 20.7802 8.80889 24.381 9.32012 28.0045C10.1209 24.29 10.9387 20.5757 11.7339 16.8613C12.813 16.7988 13.8921 16.7534 14.9712 16.6966C15.7266 20.5303 16.4989 24.3582 17.3168 28.1748C17.9586 24.2333 18.6685 20.303 19.3558 16.3672C20.4916 16.3274 21.6275 16.2649 22.7577 16.1968C21.4742 21.7002 20.3496 27.2491 18.9468 32.7185ZM47.2878 42.1978H29.5221V38.6308H44.2887V36.359H29.5221V33.5192H44.2887V31.2475H29.5221V28.4076H44.2887V26.1359H29.5221V23.2961H44.2887V21.0244H29.5221V18.1846H44.2887V15.9128H29.5221V13.073H44.2887V10.8013H29.5221V7.80225H47.2878V42.1978Z" fill="#818181" />
            </g>
            <defs>
              <clipPath id="clip0_299_3726">
                <rect width="50" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
        {defaultValue && (<span>{defaultValue}</span>)}
        {!defaultValue && accept == 'image/*' && (<span>Carregar foto do banco de imagens da prefeitura</span>)}
        {!defaultValue && accept == '.pdf' && (<span>Selecione ou arraste o arquivo PDF</span>)}
        {!defaultValue && accept == '.svg' && (<span>Selecionar ícone do plano</span>)}
        {!defaultValue && accept == '.docx' && (<span>Selecione ou arraste o arquivo word</span>)}
      </label>
    )
  }

  const bufferValueHandler = (e: any) => {
    const file = e.target.files[0];
    setBufferValue(file.name)
  }

  return (
    <Container>
      <div className="title_wrapper_textfield">
        <ToolTipWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          register={register}
          value={defaultValue}
          labelLink={labelLink}
          commentTittle={commentTittle}
          labelLinkLabel={labelLinkLabel}
        />
      </div>
      <div className="file-input">
        <input
          type="file"
          name={name}
          accept={accept}
          id={"file-input" + name}
          ref={"file-input" + name}
          className="file-input__input"
          {...register(name, { required: required })}
          onChange={(e) => bufferValueHandler(e)}
        />
        {downloadFile()}
      </div>
      <div className="error_wrapper">
        <span>{errors[name]?.message}</span>
      </div>
    </Container>
  )
}

type TooltipWrapperProps = {
  tooltip?: string,
  label?: string,
  required?: boolean,
  local?: string,
  hiddeInput?: boolean,
  register?: any,
  name?: string,
  value?: string,
  commentTittle?: string;

  labelLink?: string,
  labelLinkLabel?: string,
}

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.withe,
    backgroundColor: theme.palette.common.white,
  },
}));


const ToolTipWrapper = ({
  label,
  tooltip,
  required,
  register,
  name,
  labelLink,
  commentTittle,
  labelLinkLabel,
  value, }: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label} {labelLink && <a href={labelLink} target="blank">({labelLinkLabel})</a>}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl && <>
        {tooltip && tooltip?.length > 0 && (
          <LightTooltip title={tooltip}>
            <div>
              <InfoIncon />
            </div>
          </LightTooltip>
        )}
      </>
      }
      {epl && (
        <CondiserationWrapper
          register={register}
          label={label}
          name={name}
          commentTittle={commentTittle}
          value={value}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  register,
  label,
  name,
  value,
  commentTittle,
}: {
  register?: any,
  label?: string,
  name?: string,
  commentTittle?: string;

  value?: string,
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        handleClose={handleClose}
        register={register}
        label={label}
        name={name}
        commentTittle={commentTittle}
        value={value}
      />
    </>
  )
}