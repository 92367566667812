import styled from 'styled-components';


export const ContentConsultElaborationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: #004A80;
    font-size: 28px;
    font-weight: 700;
    overflow: hidden;
    margin-top: 2rem;
    line-height: 21px;
    font-style: normal;
    font-family: "Open Sans";
  }
`;


interface Props {
  status: number;
  selected: boolean;
}

// Define o componente styled com interpolação de função

export const ContentConsultItemWrapper = styled.div`
  display: flex;
  margin-top: 2rem;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow: auto;
  padding: 0 1rem 0 0;
  max-height: auto;

  .input_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .input_component {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
    height: 250px;
  }

  .content {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 8px;
    border-radius: 0px 6px 6px 0px;
    border: 1px solid var(--Gray-300, #DEE2E6);
    background: #FAFAFA;

    span {
      color: #7B7B7B;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }

  .checkbox_wrapper {
    padding: 16px; 
    border-radius: 10px 0px 0px 10px;
    background: #004A80;
  }

  svg {
    fill: white;
  }

  .disclaimer_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;

    :nth-child(1) {
      color: #004A80;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }

    :nth-child(2) {
      color: var(--gray-3, #797979);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;


export const ContentDetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',

  '& p, span': {
    color: '#000',
    fontFamily: "Open Sans",
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '130%',
  },

  '& .description_wrapper': {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column'
  },

  'a': {
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  '& .content_card_wrapper': {
    width: '100%',
    display: 'flex',
    margin: '16px 0',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export const RadioWrapper = styled('div')({
  display: 'flex',
  marginTop: '30px',
  flexDirection: 'column',

  '& .description_wrapper': {
    gap: '16px',
    display: 'flex',
    flexDirection: 'column'
  },

  'a': {
    textDecoration: 'underline',
    cursor: 'pointer'
  },

  '& .content_card_wrapper': {
    width: '100%',
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

export const ItemConsult = styled('div')<Props>(({ status, selected }) => ({
  display: 'flex',
  background: selected ? '#F1F1F1' : '#FFF',
  padding: '10px 0 8px 16px',
  borderRadius: '10px',
  flexDirection: 'column',
  border: '1px solid #E4E4E4',
  boxShadow: '0px 2px 3px 0px rgba(0, 0, 0, 0.10)',

  '& p': {
    color: '#0B0C0C',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px',
  },

  '& span': {
    color: status == 0 ? '#E33636' : status == 1 ? '#F07E35' : '#247D09',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
  }
}));


export const Container = styled.div`
  width: 100%;

  .radio_wrapper {
    margin-top: 30px;

    label {
      color: #272930;
      font-size: 16px;
      font-weight: 700 !important;
      line-height: 21px;
      font-style: normal;
      font-family: "Open Sans";
    }
  }
 
  .content_consult_item_wrapper {
    display: flex;
    flex-direction: column;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  background: #FFF;
  padding: 4px 11px;
  border-radius: 20px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #004A80;

  p {
    color: #004A80;
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    font-family: "Open Sans";
  }
`;

export const ContinueButton = styled.button`
  border: none;
  display: flex;
  padding: 4px 11px;
  background: #004A80;
  border-radius: 20px;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  box-shadow: 
  0px 1px 5px 0px rgba(0, 0, 0, 0.12), 
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 
  0px 3px 1px -2px rgba(0, 0, 0, 0.20);

  p {
    color: #FFF;
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    font-family: "Open Sans";
  }
`;
