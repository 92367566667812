export const PlanoEstadoIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="account_balance_FILL0_wght400_GRAD0_opsz24">
        <path id="Vector" d="M18.75 67.7344V39.8438H26.25V67.7344H18.75ZM41.25 67.7344V39.8438H48.75V67.7344H41.25ZM7.5 83.6719V75.7031H82.5V83.6719H7.5ZM63.75 67.7344V39.8438H71.25V67.7344H63.75ZM7.5 31.875V23.9062L45 3.98438L82.5 23.9062V31.875H7.5ZM24.1875 23.9062H65.8125L45 12.9492L24.1875 23.9062Z" fill="white" />
      </g>
    </svg>

  );
}
