import VLibras from 'vlibras-nextjs';
import type { AppProps } from 'next/app';
import { Layout } from '@/components/global/layout/Layout';
import { MiddlewareContextProvider } from '@/services/providers';

export default function App({ Component, pageProps }: AppProps) {



  return (
    <MiddlewareContextProvider>
      <Layout>
        {/* {ACCESS.NODE_ENV === "prod" && <VLibras forceOnload />} */}
        <VLibras forceOnload/>
        <Component {...pageProps} />
      </Layout>
    </MiddlewareContextProvider>
  );
}
