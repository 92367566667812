import { useTheme } from "@/services/zustand/theme";

export const EstrategicoAssistenciaSocial = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30 112.5L24 108L37 90.625L36 66.75C35.8333 62.5 36.0625 57.9583 36.6875 53.125C37.3125 48.2917 38.2917 44.0417 39.625 40.375L27.5 47.375V61.25H20V43L40.875 31C42.2917 30.1667 43.6667 29.5417 45 29.125C46.3333 28.7083 47.625 28.5 48.875 28.5C50.875 28.5 52.6458 28.9792 54.1875 29.9375C55.7292 30.8958 56.875 32.2917 57.625 34.125L60.875 41.5C62.4583 45.0833 65.5 48.0208 70 50.3125C74.5 52.6042 79.5 53.75 85 53.75V61.25H79.25L107.875 110.625L104.625 112.5L74.375 60.25C70.875 59.4167 67.2708 57.7292 63.5625 55.1875C59.8542 52.6458 57.0417 49.875 55.125 46.875C54.125 49.7083 53.3542 52.7708 52.8125 56.0625C52.2708 59.3542 52.0833 62.3333 52.25 65L64.375 82.125V112.5H56.875V85.125L46.25 73.25L45 92.5L30 112.5ZM57.25 23.25C54.75 23.25 52.6042 22.3542 50.8125 20.5625C49.0208 18.7708 48.125 16.625 48.125 14.125C48.125 11.625 49.0208 9.47917 50.8125 7.6875C52.6042 5.89583 54.75 5 57.25 5C59.75 5 61.8958 5.89583 63.6875 7.6875C65.4792 9.47917 66.375 11.625 66.375 14.125C66.375 16.625 65.4792 18.7708 63.6875 20.5625C61.8958 22.3542 59.75 23.25 57.25 23.25Z"
        fill="url(#paint0_linear_1941_43899)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43899"
          x1="40.3211"
          y1="21.125"
          x2="95.2514"
          y2="93.7959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
