import { useTheme } from "@/services/zustand/theme";

export const EstrategicoCGM_2021_2024 = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.25 72.125L86.75 43.625L81.5 38.5L58.375 61.75L46.25 49.625L41 54.875L58.25 72.125ZM29.5 95C27.5 95 25.75 94.25 24.25 92.75C22.75 91.25 22 89.5 22 87.5V17.5C22 15.5 22.75 13.75 24.25 12.25C25.75 10.75 27.5 10 29.5 10H99.5C101.5 10 103.25 10.75 104.75 12.25C106.25 13.75 107 15.5 107 17.5V87.5C107 89.5 106.25 91.25 104.75 92.75C103.25 94.25 101.5 95 99.5 95H29.5ZM29.5 87.5H99.5V17.5H29.5V87.5ZM14.5 110C12.5 110 10.75 109.25 9.25 107.75C7.75 106.25 7 104.5 7 102.5V25H14.5V102.5H92V110H14.5Z"
        fill="url(#paint0_linear_1941_43871)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43871"
          x1="30.125"
          y1="25"
          x2="77.625"
          y2="101.875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
