import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ContinueButton, Container, ContentConsultElaborationWrapper, ContentConsultItemWrapper, DeleteButton } from './styles';

interface IProps {
  open: boolean;
  onDelete: () => void;
  setOpen: (value: boolean) => void;
}


export const DeleteElaborationDialog: React.FC<IProps> = ({
  open,
  setOpen,
  onDelete,
}) => {

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <Dialog
          open={open}
          sx={{ mt: 10 }}
          maxWidth='md'
          onClose={handleClose}
        >
          <div style={{ padding: '0 27px', width: '875px' }}>
            <DialogTitle>
              Excluir elemento
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#004A80'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ mt: -4, display: 'flex', flexDirection: 'column', gap: '10px' }} >
              <p style={{ fontWeight: 'bold', color: 'red' }}>Tem certeza de que deseja excluir esse elemento?</p>
              <span style={{ color: 'red' }}>A exclusão também removerá quaisquer elementos relacionados em sequência.</span>
              <span style={{ color: 'red' }}>Por exemplo, se você excluir um objetivo vinculado a uma meta, a meta também será removida. Da mesma forma, ao excluir
                um programa, todos os projetos e produtos/entregas relacionados a ele serão excluidos.
              </span>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
            <DeleteButton onClick={handleClose}>
                <p>Cancelar</p>
              </DeleteButton>
              <ContinueButton style={{ background: 'red' }} onClick={onDelete}>
                <p>Excluir</p>
              </ContinueButton>
            </DialogActions>
          </div>
        </Dialog>
      </Container>
    </>
  );
}
