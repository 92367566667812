export const Svg_001 = () => {
  return (
    <svg width="58" height="59" viewBox="0 0 58 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M55.9922 18.2168H58V11.5872H51.4827V13.6295C51.4827 16.159 53.5057 18.2168 55.9922 18.2168Z" fill="url(#paint0_linear_1026_32689)" />
      <path d="M6.5173 13.6295V11.5872H0V18.2168H2.0078C4.49432 18.2168 6.5173 16.159 6.5173 13.6295Z" fill="url(#paint1_linear_1026_32689)" />
      <path d="M41.8008 25.9442C40.9888 25.9442 40.3281 26.6163 40.3281 27.4423V31.5577C40.3281 32.3837 40.9888 33.0558 41.8008 33.0558C42.6128 33.0558 43.2734 32.3837 43.2734 31.5577V27.4423C43.2734 26.6163 42.6128 25.9442 41.8008 25.9442Z" fill="url(#paint2_linear_1026_32689)" />
      <path d="M2.0078 40.7832H0V47.4128H6.5173V45.3705C6.5173 42.841 4.49432 40.7832 2.0078 40.7832Z" fill="url(#paint3_linear_1026_32689)" />
      <path d="M29 25.9442C28.188 25.9442 27.5273 26.6163 27.5273 27.4423V31.5577C27.5273 32.3837 28.188 33.0558 29 33.0558C29.812 33.0558 30.4727 32.3837 30.4727 31.5577V27.4423C30.4727 26.6163 29.812 25.9442 29 25.9442Z" fill="url(#paint4_linear_1026_32689)" />
      <path d="M51.4827 45.3705V47.4128H58V40.7832H55.9922C53.5057 40.7832 51.4827 42.841 51.4827 45.3705Z" fill="url(#paint5_linear_1026_32689)" />
      <path d="M55.9922 21.6739C51.6318 21.6739 48.0843 18.0652 48.0843 13.6295V11.5872H9.91573V13.6295C9.91573 18.0652 6.36822 21.6739 2.0078 21.6739H0V37.3261H2.0078C6.36822 37.3261 9.91573 40.9348 9.91573 45.3705V47.4128H48.0844V45.3705C48.0844 40.9348 51.6319 37.3261 55.9923 37.3261H58V21.6739H55.9922ZM21.4149 36.5128H13.249V33.0558H15.5195V27.8672C14.7924 28.2034 13.9853 28.3906 13.1359 28.3906V24.9336C14.4503 24.9336 15.5195 23.8458 15.5195 22.5087V22.4872H18.918V33.0558H21.4149V36.5128ZM33.8711 31.5577C33.8711 34.2899 31.6859 36.5128 29 36.5128C26.3141 36.5128 24.1289 34.2899 24.1289 31.5577V27.4423C24.1289 24.7101 26.3141 22.4872 29 22.4872C31.6859 22.4872 33.8711 24.7101 33.8711 27.4423V31.5577ZM46.6719 31.5577C46.6719 34.2899 44.4867 36.5128 41.8008 36.5128C39.1149 36.5128 36.9297 34.2899 36.9297 31.5577V27.4423C36.9297 24.7101 39.1149 22.4872 41.8008 22.4872C44.4867 22.4872 46.6719 24.7101 46.6719 27.4423V31.5577Z" fill="url(#paint6_linear_1026_32689)" />
      <defs>
        <linearGradient id="paint0_linear_1026_32689" x1="52.9898" y1="12.5816" x2="56.1627" y2="17.6297" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint1_linear_1026_32689" x1="1.50712" y1="12.5816" x2="4.68002" y2="17.6297" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint2_linear_1026_32689" x1="41.0092" y1="27.011" x2="44.503" y2="29.3528" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint3_linear_1026_32689" x1="1.50712" y1="41.7776" x2="4.68002" y2="46.8257" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint4_linear_1026_32689" x1="28.2084" y1="27.011" x2="31.7022" y2="29.3528" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint5_linear_1026_32689" x1="52.9898" y1="41.7776" x2="56.1627" y2="46.8257" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
        <linearGradient id="paint6_linear_1026_32689" x1="13.4125" y1="16.961" x2="26.0901" y2="50.1782" gradientUnits="userSpaceOnUse">
          <stop stop-color="#004A80" />
          <stop offset="1" stop-color="#00C0F3" />
        </linearGradient>
      </defs>
    </svg>
  )
}