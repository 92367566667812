import { useTheme } from "@/services/zustand/theme";

export const DefesaCivil = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.6251 64.7916L69.1667 41.2499L63.2292 35.3124L45.6251 52.9166L36.8751 44.1666L30.9376 50.1041L45.6251 64.7916ZM50.0001 91.6666C40.3473 89.236 32.3786 83.6978 26.0938 75.052C19.8091 66.4062 16.6667 56.8055 16.6667 46.2499V20.8333L50.0001 8.33325L83.3334 20.8333V46.2499C83.3334 56.8055 80.1911 66.4062 73.9063 75.052C67.6216 83.6978 59.6529 89.236 50.0001 91.6666ZM50.0001 82.9166C57.2223 80.6249 63.1945 76.0416 67.9167 69.1666C72.639 62.2916 75.0001 54.6527 75.0001 46.2499V26.5624L50.0001 17.1874L25.0001 26.5624V46.2499C25.0001 54.6527 27.3612 62.2916 32.0834 69.1666C36.8056 76.0416 42.7779 80.6249 50.0001 82.9166Z"
        fill="url(#paint0_linear_2605_49792)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2605_49792"
          x1="32.0834"
          y1="20.8333"
          x2="74.907"
          y2="76.2786"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" : "white"} />
          <stop
            offset="1"
            stop-color={theme === "light" ? "#00C0F3" : "white"}
          />
        </linearGradient>
      </defs>
    </svg>
  );
};
