import styled from "styled-components";

export const SnackbarMessageContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 24px;
  background: #247d09;
  flex-direction: column;
  border-radius: 4px;
  background: var(--secondary-green, #247d09);

  .message_wrapper {
    display: flex;
    justify-content: space-between;

    svg {
      cursor: pointer;
      path {
        fill: white;
      }
    }
  }

  p {
    color: var(--primary-white, #fff);

    /* calibri bold/subtitulo bol */
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 26.4px */
  }

  span {
    color: var(--primary-white, #fff);

    /* calibri regular/corpo regular */
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 24.7px */
  }
`;
