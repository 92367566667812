import { Container } from "./styles";
import { useAuth } from "@/hooks/useAuth";
import { useMenu } from "@/hooks/useMenu";

export function ButtonMenu() {
  const { isDashboard } = useAuth();
  const { setOpen, open, setOpenDashboardMenu, openDashboardMenu } = useMenu();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickDashboardMenu = () => {
    setOpenDashboardMenu(!openDashboardMenu);
  };

  return (
    <Container
      onClick={() => isDashboard ? handleClickDashboardMenu() : handleClick()}
      width="18"
      height="13"
      viewBox="0 0 18 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="MenuFilled" clipPath="url(#clip0_1286_5735)">
        <path
          id="Vector"
          d="M0 12.5H18V10.5H0V12.5ZM0 7.5H18V5.5H0V7.5ZM0 0.5V2.5H18V0.5H0Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1286_5735">
          <rect
            width="18"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </Container>
  );
}
