import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;

  .first_row {
    gap: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 700;
    }

    li {
      margin-left: 2rem;
    }
  }

  .second_row {
    gap: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      margin-top: 30px;
      font-weight: 700;
    }

    ul {
      gap: 30px;
      display: flex;
      flex-direction: column;

      .first_li {
        font-weight: 700;
        margin-left: 1rem;
      }

      .second_li {
        margin-left: 2rem;
      }
    }
  }

  .third_row {
    margin-top: 30px;
    gap: 30px;
    display: flex;
    flex-direction: column;

    span {
      font-weight: 700;
    }

    .third_describe {
      font-weight: 700;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;

  button {
    display: flex;
    width: 40%;
    padding: 8px 38px 8px 42px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 20px;
    background: var(--primary-links-blue, #004A80);

    /* elevation/2 */
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20);
   
    p {
    color: var(--primary-white, #FFF);

    /* calibri bold/corpo bold */
    font-family: "Open Sans";
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 24.7px */
   }
  }

  .goals_wrapper {
    gap: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    span {
      color: var(--primary-links-blue, #004A80);
      font-family: Roboto;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 24.2px */
    }

    .load_more {
      width: 100%;
      display: flex;
      justify-content: center;
  
      p {
        cursor: pointer;
        color: var(--primary-links-blue, #004A80);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 125% */
      }

      &:hover {
        p {
          text-decoration: underline;
        }
      }
    }

    .plan_wrapper {
      width: 100%;
      background: #fff;
      
      .plan_title_wrapper {
        width: 100%;
        padding: .5rem;

        background: #B1B4B6;
        border-radius: 5px 5px 0px 0px;

        p {
          color: #FFF;
          font-family: "Open Sans";
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%; /* 26px */
        }
      }

      .plan_content_wrapper {
        width: 100%;
        padding: .5rem;
      }
    }
  }

`;