import { useTheme } from "@/services/zustand/theme";

export const MunicipalEsgotamentoSanitario = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3342 86.6667H37.4177C35.3344 84.9167 33.4524 82.9167 31.7719 80.6666C30.0913 78.4166 28.7093 75.9722 27.626 73.3332H13.3342V86.6667ZM59.9834 86.6667C67.3562 86.6667 73.6468 84.0685 78.8552 78.8719C84.0636 73.6753 86.6677 67.3905 86.6677 60.0176C86.6677 52.6448 84.0694 46.3542 78.8728 41.1458C73.6763 35.9374 67.3915 33.3333 60.0186 33.3333C52.6458 33.3333 46.3551 35.9315 41.1467 41.1281C35.9384 46.3247 33.3342 52.6095 33.3342 59.9824C33.3342 67.3552 35.9325 73.6458 41.1291 78.8543C46.3257 84.0626 52.6104 86.6667 59.9834 86.6667ZM92.376 46.6667H106.668V33.3333H82.5842C84.6676 35.0833 86.5495 37.0833 88.2301 39.3334C89.9107 41.5834 91.2926 44.0278 92.376 46.6667ZM5.00098 100V60H13.3342V65H25.376C25.2093 64.1667 25.1051 63.3542 25.0635 62.5625C25.0218 61.7708 25.001 60.9167 25.001 60C25.001 50.25 28.3968 41.9792 35.1885 35.1875C41.9801 28.3958 50.251 25 60.001 25H106.668V20H115.001V60H106.668V55H94.626C94.7926 55.8333 94.8968 56.6458 94.9385 57.4375C94.9801 58.2292 95.001 59.0833 95.001 60C95.001 69.75 91.6051 78.0208 84.8135 84.8125C78.0218 91.6042 69.751 95 60.001 95H13.3342V100H5.00098ZM59.9936 73.6666C57.4708 73.6666 55.3135 72.769 53.5219 70.9739C51.7302 69.1788 50.8344 67.0208 50.8344 64.5C50.8344 62.5833 51.1607 60.868 51.8135 59.3541C52.4662 57.8403 53.8343 55.5 55.9176 52.3334L60.001 46.3334L64.0844 52.3334C66.1677 55.5 67.5357 57.8403 68.1885 59.3541C68.8412 60.868 69.1676 62.5833 69.1676 64.5C69.1676 67.0208 68.2693 69.1788 66.4727 70.9739C64.6761 72.769 62.5164 73.6666 59.9936 73.6666Z"
        fill="url(#paint0_linear_1941_43842)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43842"
          x1="30.4385"
          y1="32"
          x2="62.2101"
          y2="102.702"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
