import { useTheme } from "@/services/zustand/theme";

export const ArborizacaoPDAU = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.75 110V102.5H56.25V80H45C38.0833 80 32.1875 77.5625 27.3125 72.6875C22.4375 67.8125 20 61.9167 20 55C20 50 21.375 45.3958 24.125 41.1875C26.875 36.9792 30.5833 33.9167 35.25 32C36 25.75 38.7292 20.5208 43.4375 16.3125C48.1458 12.1042 53.6667 10 60 10C66.3333 10 71.8542 12.1042 76.5625 16.3125C81.2708 20.5208 84 25.75 84.75 32C89.4167 33.9167 93.125 36.9792 95.875 41.1875C98.625 45.3958 100 50 100 55C100 61.9167 97.5625 67.8125 92.6875 72.6875C87.8125 77.5625 81.9167 80 75 80H63.75V102.5H92.5V110H28.75ZM45 72.5H75C79.8611 72.5 83.993 70.7986 87.3959 67.3959C90.7986 63.993 92.5 59.8611 92.5 55C92.5 51.5 91.5 48.3125 89.5 45.4375C87.5 42.5625 84.9167 40.4167 81.75 39L77.75 37.25L77.25 32.875C76.6667 28.4583 74.7424 24.7917 71.4771 21.875C68.2119 18.9583 64.3862 17.5 60 17.5C55.6138 17.5 51.7881 18.9583 48.5229 21.875C45.2576 24.7917 43.3333 28.4583 42.75 32.875L42.25 37.25L38.25 39C35.0833 40.4167 32.5 42.5719 30.5 45.4658C28.5 48.3596 27.5 51.5428 27.5 55.0154C27.5 59.8385 29.2014 63.9583 32.6041 67.375C36.007 70.7917 40.1389 72.5 45 72.5Z"
        fill="url(#paint0_linear_1941_17656)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_17656"
          x1="38.5"
          y1="25"
          x2="89.8884"
          y2="91.5344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
