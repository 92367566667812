import React from "react"
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styles';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

type InputsMissionPurpose = {
  missao_proposito: any;
};

const SchemaMissionPurpose = Yup.object().shape({
  missao_proposito: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required("Campo obrigatório")
    })
  ).required('Campo obrigatório')
});

const defaultValue = {
  id: '',
  texto: '',
}

export const MissionPurposeForm: React.FC = () => {
  const {
    watch: watchMissionPurpose,
    control: controlMissionPurpose,
    register: registerMissionPurpose,
    setValue: setValueMissionPurpose,
    handleSubmit: handleSubmitMissionPurpose,
    formState: { errors: errosMissionPurpose } } = useForm<InputsMissionPurpose>({
      resolver: yupResolver(SchemaMissionPurpose),
      defaultValues: { missao_proposito: [defaultValue] }
    });

  const { } = useFieldArray({
    control: controlMissionPurpose,
    name: "missao_proposito"
  });

  const fields = watchMissionPurpose("missao_proposito");

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [reutilizar, setReutilizar] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { MissionPurposeElaboration, GetMissionPurposeElaboration } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.missao_proposito?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  React.useEffect(() => {
    if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))
      setLoading(true);
    GetMissionPurposeElaboration(router.query.id).then((res: any) => {
      if (res.data.length > 0) {
        setValueMissionPurpose('missao_proposito', res.data);
      } else {
        setValueMissionPurpose("missao_proposito", [defaultValue]);
      }
    }).finally(() => setLoading(false));
  }, [router.pathname, router.query, completCode]);

  React.useEffect(() => {
    if (planId && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))
      GetMissionPurposeElaboration(planId).then((res: any) => {
        setData(res.data);
      })
  }, [planId]);

  const [disabeSubmit, setDisableSubmit] = React.useState(false);

  const onSubmit = async (data: any, event: any) => {
    if (disabeSubmit) return;
    setDisableSubmit(true);

    try {
      const { missao_proposito } = data;
      const { id, ...rest } = missao_proposito[0];

      await MissionPurposeElaboration({
        data: id ? { ...rest, id } : { ...rest },
        id: Number(router.query.id)
      });

      toggleSuccessSnackbar()

      const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
      let planId = plan[selectedItemsMenu?.layer as string]
        .find((item: any) => item.id == selectedItemsMenu?.id).id;

      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);
        planId += 1;
      } else {
        setNavigable(false);
      }

      setDefaultItemMenuId(planId);
      router.push('/dashboard/elaboracao_planos/' + router.query.id);
      handleFetchProgressCodePlanId(router.query.id);

      setTimeout(() => toggleSuccessSnackbar(), 3000)
      setDisableSubmit(false);
    } catch (error) {
      toggleErrorSnackbar()
      setDisableSubmit(false);
    }
  };

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, reutilizar, openDisclaimer, data]);

  const Fields = React.useCallback(() => {
    return (
      <Grid item xs={12} sx={{ mt: -2 }}>
        {fields.map((item: any, index: any) => {
          const texto = watchMissionPurpose(`missao_proposito.${index}.texto`)
          return (
            <>
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <input
                  type="hidden"
                  {...registerMissionPurpose(`missao_proposito.${index}.id`, { value: item.id })}
                  defaultValue={item.id}
                />
                <TextFieldElaboration
                  key={item.id}
                  required={true}
                  label="Missão/Propósito"
                  errors={errosMissionPurpose}
                  register={registerMissionPurpose}
                  name={`missao_proposito.${index}.texto`}
                  commentTittle={texto}
                  defaultValue={texto}
                  placeholder={`Digite o valor ${index + 1} do plano`}
                />
              </div>
              <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
                <span>{getMessageErrorByNameRef(errosMissionPurpose, 'texto')}</span>
              </div>
            </>
          )
        })}
      </Grid>
    );
  }, [fields]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitMissionPurpose(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ mb: 2 }}>
            {ReplaceButton()}
          </Grid>
          {Fields()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional="true" />
          </Grid>
          <ReutilizarElaborationDialog
            title="Eixo Temático"
            reuseData={data}
            open={reutilizar}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              e.forEach((item: any, index: any) => {
                setValueMissionPurpose(`missao_proposito.${index}.texto`, item.texto)
              });
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
}


