import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import { alpha, styled as MuiStyled } from '@mui/material/styles';

export const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#DEE2E6' : '#2D3843',
    fontSize: 16,
    width: '100%',
    height: '15px',
    color: '#ADB5BD',
    padding: '7px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    fontFamily: [
      "Inter",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .content_wrapper {
    width: 80%;
    z-index: 1;
    margin-top: -5rem;

    .content_actions{
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    }
  }

  .back_navigation_button {
    display: flex;
    background: red;
    cursor: pointer;
    background: #FFF;
    margin-top: 40px;
    width: fit-content;
    border-radius: 5px;
    align-items: center;
    padding: 1rem 1rem .5rem .5rem;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);

    .svg_container {
      height: auto;
      svg {
        width: 100%;
        transform: scale(.9);
      }
    }
    
    p {
      color: #505A5F;
      font-size: 22px;
      font-weight: 700;
      line-height: 109%;
      text-align: center;
      margin-top: -.5rem;
      font-style: normal;
      font-family: "Open Sans";
    }
  }

  .select_aprove_wrapper {
    width: 30%;
    margin-top: 26px;
    label {
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 100% */
    }
  }
`;

export const ModalContent = styled.div`
  
  padding: 16px 24px;

  display: grid;
  gap: 20px;

  .select_section{
    label{
      color: var(--Text-Primary, #272930);
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 21px; /* 150% */ 
    }
  }

  .items_selected_wrapper{
    display: grid;
    gap: 5px;

    .items_selected{
  
      max-height: 15rem;
      overflow-y: auto;
  
      display: grid;
      gap: 10px;
  
      .items_selected_items{
        display: flex;
        gap: 10px;
  
        button {
          background-color: transparent;
          border: none;
        }
  
      }
    }
  }

  .button_wrapper{
    display: flex;
    gap: 10px;
    justify-content: center;

    button{
      padding: 8px 22px; 
      border-radius: 20px;
      font-weight: bold;
    }

    .cancel{
      border: 1px solid var(--primary-links-blue, #004A80);
      background: var(--primary-white, #FFF); 
      color: var(--primary-links-blue, #004A80);
    }
  }
`

export const TextEllipses = styled.p`

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      width: 50ch;
      overflow: hidden;
      text-overflow: ellipsis;
`


export const TextEllipsesSelect = styled.p`

      width: 55ch;
      overflow: hidden;
      text-overflow: ellipsis;
`


