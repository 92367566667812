import { useTheme } from "@/services/zustand/theme";

export const EstrategicoEspecialTurismo = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M80.375 107L67.375 85H51.625C50.5417 85 49.6458 84.6458 48.9375 83.9375C48.2292 83.2292 47.875 82.3333 47.875 81.25C47.875 80.1667 48.2292 79.2708 48.9375 78.5625C49.6458 77.8542 50.5417 77.5 51.625 77.5H67.375L80.375 55.5H83.5L77 77.5H93.5L97.25 72.5H100L97.375 81.25L100 90H97.25L93.5 85H77L83.5 107H80.375ZM36.5 64.5L43 42.5H26.5L22.75 47.5H20L22.625 38.75L20 30H22.75L26.5 35H43L36.5 13H39.625L52.625 35H68.375C69.4583 35 70.3542 35.3542 71.0625 36.0625C71.7708 36.7708 72.125 37.6667 72.125 38.75C72.125 39.8333 71.7708 40.7292 71.0625 41.4375C70.3542 42.1458 69.4583 42.5 68.375 42.5H52.625L39.625 64.5H36.5Z"
        fill="url(#paint0_linear_1941_43897)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43897"
          x1="38.5"
          y1="27.1"
          x2="85.9714"
          y2="92.4861"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
