import React from "react";
import { useFloatingFilterButton } from "@/services/zustand/flotingFilterButton";

export interface IAccessibilityContextProps {
  isFixedPosition: boolean;
  fontSizeIncreased: boolean;
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  floatinButtonRef: React.RefObject<any>;
  monitoringFloatingButtonRef: React.RefObject<any>;
}

export const AccessibilityContext = React.createContext(
  {} as IAccessibilityContextProps
);

interface IProps {
  children: React.ReactNode;
}

export const AccessibilityContextProvider: React.FC<IProps> = ({
  children,
}) => {
  // acessibilidade ao botão do filtro
  const [isFixedPosition, setIsFixedPosition] = React.useState(false);
  const filterOpen = useFloatingFilterButton((state) => state.open);
  const floatinButtonRef = React.createRef<any>();
  const monitoringFloatingButtonRef = React.createRef<any>();

  React.useEffect(() => {
    function handleScroll() {
      if (floatinButtonRef.current && monitoringFloatingButtonRef.current) {
        const buttonRect = floatinButtonRef?.current?.getBoundingClientRect();
        const componentRect =
          monitoringFloatingButtonRef?.current?.getBoundingClientRect();

        // Verifique se o botão atingiu a posição horizontal do componente
        if (componentRect.top * 4 <= window.innerHeight - buttonRect.height) {
          setIsFixedPosition(true);
        } else {
          setIsFixedPosition(false);
        }
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [floatinButtonRef, isFixedPosition, monitoringFloatingButtonRef]);

  React.useEffect(() => {
    if (filterOpen) {
      window.scrollTo({ top: 930, behavior: "smooth" });
    }
  }, [filterOpen]);

  // Mudança no tamanho das foontes
  const [fontSizeIncreased, setFontSizeIncreased] = React.useState(false);

  const increaseFontSize = () => {
    if (!fontSizeIncreased) {
      setFontSizeIncreased(true);
      const divs = document.querySelectorAll(
        ".resizeable-accessibility"
      ) as any;

      divs.forEach((div: any) => {
        const currentFontSize = window.getComputedStyle(div).fontSize;
        const newFontSize = parseFloat(currentFontSize) * 1.2 + "px";
        div.style.fontSize = newFontSize;
      });
    }
  };

  const decreaseFontSize = () => {
    if (fontSizeIncreased) {
      setFontSizeIncreased(false);
      const divs = document.querySelectorAll(
        ".resizeable-accessibility"
      ) as any;

      divs.forEach((div: any) => {
        const currentFontSize = window.getComputedStyle(div).fontSize;
        const newFontSize = parseFloat(currentFontSize) / 1.2 + "px";
        div.style.fontSize = newFontSize;
      });
    }
  };

  const value = {
    increaseFontSize,
    decreaseFontSize,
    fontSizeIncreased,
    floatinButtonRef,
    isFixedPosition,
    monitoringFloatingButtonRef,
  } as IAccessibilityContextProps;

  return (
    <AccessibilityContext.Provider value={value}>
      {children}
    </AccessibilityContext.Provider>
  );
};
