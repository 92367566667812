import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .add_line_wrapper {

    p {
      color: #004A80;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      width: fit-content;
      line-height: 20px;
      font-style: normal;
    }

    p:hover {
      text-decoration: underline;
      text-decoration-color: #004A80;
    }
  }

  .recicle_content_button {
    border: none;
    outline: none;
    display: flex;
    padding: 8px 22px;
    background: gray;
    border-radius: 20px;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 1px 5px 0px 
    rgba(0, 0, 0, 0.12), 0px 2px 2px 0px 
    rgba(0, 0, 0, 0.14), 0px 3px 1px -2px 
    rgba(0, 0, 0, 0.20);

    p {
      color: #FFF;
      font-size: 19px;
      font-weight: 700;
      line-height: 130%;
      font-style: normal;
      font-family: "Open Sans";
    }
  }
`;