import React from "react";

export function PaoAcucarUrca() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Camada 1"
    viewBox="0 0 60 60"
  >
    <defs>
      <linearGradient
        id="a"
        x1={45.57}
        x2={37.78}
        y1={19.26}
        y2={8.12}
        data-name="Gradiente sem nome 7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#23bcef" />
        <stop offset={1} stopColor="#004b80" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={39.77}
        x2={14.15}
        y1={56.94}
        y2={20.36}
        data-name="Gradiente sem nome 7"
      />
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={42.3}
        x2={39.56}
        y1={53.2}
        y2={49.28}
        data-name="Gradiente sem nome 7"
      />
      <linearGradient
        xlinkHref="#a"
        id="d"
        x1={28.36}
        x2={21.72}
        y1={55.97}
        y2={46.49}
        data-name="Gradiente sem nome 7"
      />
    </defs>
    <path
      d="M41.68 20.45c8.94-.28 8.94-13.24 0-13.52-8.94.28-8.94 13.24 0 13.52Zm0-9.6c3.73.07 3.73 5.62 0 5.68-3.73-.07-3.73-5.62 0-5.68Z"
      style={{
        fill: "url(#a)",
        strokeWidth: 0,
      }}
    />
    <path
      d="m57.16 42.45-9.32-14.27c-2.11-3.46-7.59-3.46-9.69-.01l-.93 1.52-8.13-12.44c-2.12-3.47-7.59-3.46-9.72 0l-6.81 10.42c-.04.05-.09.1-.13.16L2.91 42.46c-.39.6-.43 1.37-.08 2 .34.63 1 1.03 1.72 1.03h50.98c1.5.05 2.51-1.79 1.64-3.03Zm-48.99-.89 6.44-9.91s.07-.08.1-.12l7.94-12.14c.69-1.12 2.47-1.14 3.16 0l8.14 12.45c1.35 2.32 5.11 2.32 6.48 0l1.01-1.52c.68-1.12 2.45-1.12 3.13 0l7.34 11.24H8.17Z"
      style={{
        fill: "url(#b)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M41.67 49.27h-1.49c-1.08 0-1.96.88-1.96 1.96s.88 1.96 1.96 1.96h1.49c2.58-.04 2.58-3.88 0-3.92Z"
      style={{
        fill: "url(#c)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M31.69 49.27h-13.3c-2.57.04-2.58 3.88 0 3.92h13.3c2.57-.04 2.58-3.88 0-3.92Z"
      style={{
        strokeWidth: 0,
        fill: "url(#d)",
      }}
    />
  </svg>
  );
}