export const Svg_004 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={58}
    height={59}
    fill="none"
  >
    <path
      fill="url(#a)"
      d="M42.488 39.224H58V28.853H42.488v10.37Zm3.398-6.914h3.399v3.457h-3.399V32.31Z"
    />
    <path fill="url(#b)" d="M0 42.68h58v9.342H0v-9.341Z" />
    <path fill="url(#c)" d="M0 28.853h39.09v10.37H0v-10.37Z" />
    <path fill="url(#d)" d="M0 16.197h58v9.199H0v-9.2Z" />
    <path fill="url(#e)" d="M3.398 6.978h51.204v5.761H3.398V6.978Z" />
    <defs>
      <linearGradient
        id="a"
        x1={46.075}
        x2={49.962}
        y1={30.408}
        y2={39.819}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={13.412}
        x2={14.39}
        y1={44.082}
        y2={53.908}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={9.039}
        x2={10.798}
        y1={30.408}
        y2={41.137}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={13.412}
        x2={14.361}
        y1={17.576}
        y2={27.255}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={15.239}
        x2={15.663}
        y1={7.842}
        y2={13.933}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)