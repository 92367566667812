import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFormReset,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";

interface IFormValues {
  isOptionalValues: string;
  removeValues: UseFieldArrayRemove;
  fieldsValues: Record<"id", string>[];
  errosValues: FieldErrors<InputsValues>;
  resetValues: UseFormReset<InputsValues>;
  watchValues: UseFormWatch<InputsValues>;
  controlValues: Control<InputsValues, any>;
  setValueValues: UseFormSetValue<InputsValues>;
  registerValues: UseFormRegister<InputsValues>;
  appendValues: UseFieldArrayAppend<InputsValues, "valores">;
  handleSubmitValues: UseFormHandleSubmit<InputsValues, undefined>;
}

export const ValuesContext = React.createContext({} as IFormValues);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsValues = {
  valores: any;
};

const SchemaValues = Yup.object().shape({
  valores: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required("Campo obrigatório")
    })
  ).required('Campo obrigatório')
});

const defaultValue = {
  id: '',
  texto: '',
}


export const ValuesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetValues,
    watch: watchValues,
    control: controlValues,
    register: registerValues,
    setValue: setValueValues,
    handleSubmit: handleSubmitValues,
    formState: { errors: errosValues } } = useForm<InputsValues>({
      resolver: yupResolver(SchemaValues),
      defaultValues: { valores: [defaultValue] }
    });

  const { fields: fieldsValues, append: appendValues, remove: removeValues } = useFieldArray({
    control: controlValues,
    name: "valores"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans()
  const { GetValuesElaboration } = new ElaborationService();

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))
  //     GetValuesElaboration(router.query.id).then((res: any) => {
  //     if (res.data.length > 0) {
  //       setValueValues('valores', res.data);
  //     } else {
  //       setValueValues("valores", [defaultValue]);
  //     }
  //   })
  // }, [router.pathname, router.query, completCode]);

  const value = {
    errosValues,
    resetValues,
    watchValues,
    removeValues,
    appendValues,
    fieldsValues,
    controlValues,
    setValueValues,
    registerValues,
    handleSubmitValues,
  } as IFormValues;

  return <ValuesContext.Provider value={value}>{children}</ValuesContext.Provider>;
};