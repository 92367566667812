import { useTheme } from "@/services/zustand/theme";

export const EstrategicoOrdemPublica = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 105V97.5H56.25V37C54.0833 36.25 52.1458 35.0208 50.4375 33.3125C48.7292 31.6042 47.5 29.6667 46.75 27.5H26.875L42.5 65.25C42.4167 69 40.8125 72.1875 37.6875 74.8125C34.5625 77.4375 30.75 78.75 26.25 78.75C21.75 78.75 17.9375 77.4375 14.8125 74.8125C11.6875 72.1875 10.0833 69 10 65.25L25.625 27.5H15V20H46.75C47.75 17.0833 49.4583 14.6875 51.875 12.8125C54.2917 10.9375 57 10 60 10C63 10 65.7083 10.9375 68.125 12.8125C70.5417 14.6875 72.25 17.0833 73.25 20H105V27.5H94.375L110 65.25C109.917 69 108.312 72.1875 105.188 74.8125C102.062 77.4375 98.25 78.75 93.75 78.75C89.25 78.75 85.4375 77.4375 82.3125 74.8125C79.1875 72.1875 77.5833 69 77.5 65.25L93.125 27.5H73.25C72.5 29.6667 71.2708 31.6042 69.5625 33.3125C67.8542 35.0208 65.9167 36.25 63.75 37V97.5H110V105H10ZM84.375 65H103.125L93.75 42L84.375 65ZM16.875 65H35.625L26.25 42L16.875 65ZM60 30C61.75 30 63.2292 29.375 64.4375 28.125C65.6458 26.875 66.25 25.4167 66.25 23.75C66.25 22 65.6458 20.5208 64.4375 19.3125C63.2292 18.1042 61.75 17.5 60 17.5C58.3333 17.5 56.875 18.1042 55.625 19.3125C54.375 20.5208 53.75 22 53.75 23.75C53.75 25.4167 54.375 26.875 55.625 28.125C56.875 29.375 58.3333 30 60 30Z"
        fill="url(#paint0_linear_2012_91972)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91972"
          x1="33.125"
          y1="24.25"
          x2="77.1806"
          y2="99.3031"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
