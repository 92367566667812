import { Pagination } from "@mui/material"
import { PaginationBox, PaginationWrapper } from "./styles";
import React from "react";

type Props = {
  currentPage: number,
  totalPages: number,
  setPage: any,
  dataSize: number,
}

export const PaginationItr: React.FC<Props> = ({ currentPage, totalPages, setPage, dataSize }) => {

  const handleChange = (_: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const onNext = () => {
    setPage(currentPage + 1);
  }
  const onPrevious = () => {
    if (currentPage > 1) {
      setPage(currentPage - 1);
    }
  }

  if (currentPage == 1 && totalPages == 1) {
    return
  }

  if (currentPage == 0) {
    return
  }

  return (
    <PaginationWrapper>

      <PaginationBox>
        {currentPage > 1 && (
          <p onClick={onPrevious}> Anterior</p>
        )
        }
        <h2 >
          {currentPage}
        </h2>
        {dataSize > 11 && (
          <p onClick={onNext}> Próxima</p>
        )}
      </PaginationBox>
      {/* <Pagination
        defaultPage={currentPage}
        onChange={handleChange}
        count={totalPages }
      /> */}
    </PaginationWrapper>
  )
}