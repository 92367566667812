import { useTheme } from "@/services/zustand/theme";

export const PlanoPlurianual = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 110V102.5H27.5V98.75H20V91.25H27.5V87.5H15V80H30C31.4167 80 32.6042 80.4792 33.5625 81.4375C34.5208 82.3958 35 83.5833 35 85V90C35 91.4167 34.5208 92.6042 33.5625 93.5625C32.6042 94.5208 31.4167 95 30 95C31.4167 95 32.6042 95.4792 33.5625 96.4375C34.5208 97.3958 35 98.5833 35 100V105C35 106.417 34.5208 107.604 33.5625 108.562C32.6042 109.521 31.4167 110 30 110H15ZM15 75V61.25C15 59.8333 15.4792 58.6458 16.4375 57.6875C17.3958 56.7292 18.5833 56.25 20 56.25H27.5V52.5H15V45H30C31.4167 45 32.6042 45.4792 33.5625 46.4375C34.5208 47.3958 35 48.5833 35 50V58.75C35 60.1667 34.5208 61.3542 33.5625 62.3125C32.6042 63.2708 31.4167 63.75 30 63.75H22.5V67.5H35V75H15ZM22.5 40V17.5H15V10H30V40H22.5ZM46.125 93.875V86.375H105V93.875H46.125ZM46.125 63.5V56H105V63.5H46.125ZM46.125 33.125V25.625H105V33.125H46.125Z"
        fill="url(#paint0_linear_2012_91953)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91953"
          x1="35.8125"
          y1="25"
          x2="85.3779"
          y2="97.1959"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
