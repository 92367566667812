import { useTheme } from "@/services/zustand/theme";

export const DiretorDecenal2011 = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 105C14.5 105 12.75 104.25 11.25 102.75C9.75 101.25 9 99.5 9 97.5V22.5C9 20.5 9.75 18.75 11.25 17.25C12.75 15.75 14.5 15 16.5 15H103.5C105.5 15 107.25 15.75 108.75 17.25C110.25 18.75 111 20.5 111 22.5V97.5C111 99.5 110.25 101.25 108.75 102.75C107.25 104.25 105.5 105 103.5 105H16.5ZM16.5 97.5H103.5V22.5H16.5V97.5ZM25 85H50V75H25V85ZM72.75 75L97.5 50.25L90.375 43.125L72.75 60.875L65.625 53.75L58.625 60.875L72.75 75ZM25 65H50V55H25V65ZM25 45H50V35H25V45Z"
        fill="url(#paint0_linear_1941_43855)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43855"
          x1="32.5875"
          y1="28.5"
          x2="72.7665"
          y2="102.197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
