import { useTheme } from "@/services/zustand/theme";

export const EstrategicoComlurb = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.25 77.625H63.75V53.125L73.75 63.75L79.125 58.375L60 39.125L40.875 58.375L46.25 63.75L56.25 53.125V77.625ZM32.625 105C30.625 105 28.875 104.25 27.375 102.75C25.875 101.25 25.125 99.5 25.125 97.5V26.25H20V18.75H43.5V15H76.5V18.75H100V26.25H94.875V97.5C94.875 99.5 94.125 101.25 92.625 102.75C91.125 104.25 89.375 105 87.375 105H32.625ZM87.375 26.25H32.625V97.5H87.375V26.25Z"
        fill="url(#paint0_linear_1941_43853)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43853"
          x1="38.5"
          y1="28.5"
          x2="83.3054"
          y2="92.9569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
