import { useTheme } from "@/services/zustand/theme";

export const PMUSustentavel = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.125 105C30.0417 105 29.0833 104.688 28.25 104.062C27.4167 103.438 27 102.625 27 101.625V91.125C24.5833 89.7917 22.8125 87.875 21.6875 85.375C20.5625 82.875 20 80.2083 20 77.375V27.75C20 21.5833 23.1875 17.0833 29.5625 14.25C35.9375 11.4167 46.125 10 60.125 10C73.9583 10 84.0625 11.4167 90.4375 14.25C96.8125 17.0833 100 21.5833 100 27.75V77.375C100 80.2083 99.4375 82.875 98.3125 85.375C97.1875 87.875 95.4167 89.7917 93 91.125V101.625C93 102.625 92.5833 103.438 91.75 104.062C90.9167 104.688 89.9583 105 88.875 105H86.5C85.3333 105 84.3333 104.688 83.5 104.062C82.6667 103.438 82.25 102.625 82.25 101.625V94.75H37.75V101.625C37.75 102.625 37.3333 103.438 36.5 104.062C35.6667 104.688 34.6667 105 33.5 105H31.125ZM60.125 24.5H92.5H27.5H60.125ZM82.25 61.125H27.5H92.5H82.25ZM27.5 53.625H92.5V32H27.5V53.625ZM40.75 81C42.6667 81 44.2917 80.3333 45.625 79C46.9583 77.6667 47.625 76.0417 47.625 74.125C47.625 72.2083 46.9583 70.5833 45.625 69.25C44.2917 67.9167 42.6667 67.25 40.75 67.25C38.8333 67.25 37.2083 67.9167 35.875 69.25C34.5417 70.5833 33.875 72.2083 33.875 74.125C33.875 76.0417 34.5417 77.6667 35.875 79C37.2083 80.3333 38.8333 81 40.75 81ZM79.25 81C81.1667 81 82.7917 80.3333 84.125 79C85.4583 77.6667 86.125 76.0417 86.125 74.125C86.125 72.2083 85.4583 70.5833 84.125 69.25C82.7917 67.9167 81.1667 67.25 79.25 67.25C77.3333 67.25 75.7083 67.9167 74.375 69.25C73.0417 70.5833 72.375 72.2083 72.375 74.125C72.375 76.0417 73.0417 77.6667 74.375 79C75.7083 80.3333 77.3333 81 79.25 81ZM27.5 24.5H92.5C90.5 22.3333 86.6667 20.625 81 19.375C75.3333 18.125 68.375 17.5 60.125 17.5C50.2917 17.5 42.75 18.0625 37.5 19.1875C32.25 20.3125 28.9167 22.0833 27.5 24.5ZM37.75 87.25H82.25C85.1667 87.25 87.6042 86.125 89.5625 83.875C91.5208 81.625 92.5 79.0417 92.5 76.125V61.125H27.5V76.125C27.5 79.0417 28.4792 81.625 30.4375 83.875C32.3958 86.125 34.8333 87.25 37.75 87.25Z"
        fill="url(#paint0_linear_1941_17664)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_17664"
          x1="38.5"
          y1="24.25"
          x2="86.6314"
          y2="89.8474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
