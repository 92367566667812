import React from "react"
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { ReutilizarElaborationDialogV2 } from "../Vision/ReutilizarModal";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DeleteIcon, transformGuidelineAspirationStrategyV2 } from "../utils";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

function validateArray(array: any) {
  if (!Array.isArray(array)) {
    throw new TypeError('O parâmetro deve ser um array.');
  }

  for (let i = 0; i < array.length; i++) {
    const innerArray = array[i];

    const hasFilledItem = innerArray.some((item: any) => {
      return item.label !== null &&
        item.eixo_tematico_id !== null &&
        item.texto !== null &&
        item.data !== null
    });

    if (hasFilledItem) {
      return { error: null };
    }
  }

  return { error: 'Pelo menos um dos campos deve ter itens preenchidos.', index: [0, 0] };
}

type InputsGuidelines = {
  diretriz: any;
  is_optional?: boolean;
};

const SchemaGuidelines = Yup.object().shape({
  diretriz: Yup.array().required('Campo obrigatório')
});

const defaultValue = {
  id: '',
  texto: '',
  label: '',
  data: null,
}

export const GuidelinesForm: React.FC = () => {
  const {
    watch: watchGuidelines,
    control: controlGuidelines,
    register: registerGuidelines,
    setError: setErrorGuidelines,
    setValue: setValueGuidelines,
    handleSubmit: handleSubmitGuidelines,
    formState: { errors: errosGuidelines } } = useForm<InputsGuidelines>({
      resolver: yupResolver(SchemaGuidelines),
      defaultValues: { diretriz: [[defaultValue]] }
    });

  const { update: updateGuidelines } = useFieldArray({
    control: controlGuidelines,
    name: "diretriz"
  });


  const fields = watchGuidelines('diretriz');
  const is_optional = watchGuidelines('is_optional') as unknown as string;

  const router = useRouter();
  const [data, setData] = React.useState<any[]>([]);
  const replaceButtonRef = React.useRef(null) as any;
  const [loading, setLoading] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [disable, setDisable] = React.useState(false);
  const [deleteField, setDeleteField] = React.useState([]);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [fieldIndexItem, setFieldIndexItem] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteStrategyId, setDeleteStrategyId] = React.useState(null);
  const [reuseClickedIndex, setReuseClickedIndex] = React.useState(null);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { GuideLinesElaboration, GetThematicAxisElaboration, GetGuideLinesElaboration, DeleteGuideLinesElaborations, PostProgressCode } = new ElaborationService();
  const { itemsMenu, selectedItemsMenu, completCode, handleFetchProgressCodePlanId, setNavigable, setDefaultItemMenuId } = usePreparationOfPlans();

  const handleAddLine = (field: any, it: any, fieldIndex: any) => {
    const newPlanejados = [...field, { texto: '', data: null, eixo_tematico_id: it.eixo_tematico_id }];
    updateGuidelines(fieldIndex, newPlanejados);
  };

  React.useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const { id } = router.query;
      const { pathname } = router;

      if (id && pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos')) {
        try {
          const { data: axisData } = await GetThematicAxisElaboration(router.query.id);
          const axisLabel = axisData.map((item: any) => ({
            label: item.texto,
            eixo_tematico_id: item.id,
          }));

          const { data } = await GetGuideLinesElaboration(id);
          const parsedData = data.filter((item: any) => item?.eixoTematico);

          const transformedArray = transformGuidelineAspirationStrategyV2(parsedData, axisLabel);

          if (transformedArray.length > 0) {
            setValueGuidelines('diretriz', transformedArray);
            setLoading(false);
          }

        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      }
    };

    fetchData();
  }, [router.query, router.pathname, tipoFluxo, completCode]);

  React.useEffect(() => {
    const { pathname } = router;

    if (planId && pathname.includes('/elaboracao_planos') || pathname.includes('/aprovacao_planos') && tipoFluxo == 'substituir') {
      const fetchData = async () => {
        try {
          const { data } = await GetGuideLinesElaboration(planId);
          const parsedData = data.filter((item: any) => item?.eixoTematico);

          if (parsedData.length > 0) {
            const transformedArray = parsedData.map((item: any) => ({ data: item.data, texto: item.texto }))

            setData(transformedArray);
            setLoading(false);
          }

        } catch (error) {
          console.error('Failed to fetch aspiration elaboration data:', error);
        }
      };

      fetchData();
    }
  }, [planId, router.pathname, tipoFluxo, completCode]);

  React.useEffect(() => {
    const code = completCode?.filter(item => item?.code == 'diretrizes');
    if (code.length > 0) {
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [completCode]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'diretrizes' && item.skipped == false);

    if (code != undefined) {
      setValueGuidelines("is_optional", true)
    }
  }, [completCode])

  const [disabeSubmit, setDisableSubmit] = React.useState(false);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { diretriz } = data;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    try {
      const { error } = validateArray(diretriz);

      if (error !== null) {
        setErrorGuidelines('root', { message: error as any });
        return;
      }

      const filteredArrays = diretriz
        .map((subArray: any) =>
          subArray.filter((item: any) => !(item.texto === null || (item.texto === "" && item.data === null)))
        )
        .filter((subArray: any) => subArray.length > 0)
        .flat();

      const newItem = filteredArrays.map((item: any) => {
        const { id, label, plano, ...res } = item;

        if (id == null || id === '') {
          return {
            ...res,
            // data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00'
          };
        } else {
          return {
            ...res,
            id,
            // data: dayjs(item.data).format('YYYY-MM-DD') + 'T00:00:00',
          };
        }
      });

      await GuideLinesElaboration({
        id: Number(router.query.id),
        data: { itens: newItem },
      });

      toggleSuccessSnackbar()

      // handleOpenSnackbar('Diretriz', 'Criada com sucesso!')
      if (event.nativeEvent.submitter.name === 'next_step') {
        setNavigable(true);

        router.push('/dashboard/elaboracao_planos/' + router.query.id);

        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId =
          plan[selectedItemsMenu?.layer as string].find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
        handleFetchProgressCodePlanId(router.query.id);
      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId =
          plan[selectedItemsMenu?.layer as string].find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);

        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }
      setTimeout(() => toggleSuccessSnackbar(), 3000)
      setDisableSubmit(false);
    } catch (error) {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }
  };

  const [deleteFieldId, setDeleteFieldId] = React.useState(0);

  const Fields = React.useCallback(() => {
    if (Boolean(is_optional) == false || is_optional == undefined) return null;
    if (is_optional == 'false' || is_optional == undefined) return null;

    const inputs = fields?.reduce((acc: any, field: any, fieldIndex: any) => {
      field.map((it: any, itIndex: any) => {
        const texto = watchGuidelines(`diretriz.${fieldIndex}.${itIndex}.texto`);

        acc.push(
          <React.Fragment key={it?.id || `${fieldIndex}-${itIndex}`}>
            <input
              type="hidden"
              {...registerGuidelines(`diretriz.${fieldIndex}.${itIndex}.id`, { value: it?.id })}
              defaultValue={it?.id}
            />
            <Grid item xs={12}>
              {ReplaceButton(fieldIndex)}
            </Grid>
            <Grid item xs={4}>
              <TextFieldElaboration
                rows={3}
                required
                multiline
                defaultValue={texto}
                commentTittle={texto}
                errors={errosGuidelines}
                register={registerGuidelines}
                placeholder="Digite a diretriz"
                label={itIndex === 0 ? it?.label : undefined}
                name={`diretriz.${fieldIndex}.${itIndex}.texto`}
              />
            </Grid>
            <Grid item xs={4} sx={{ mt: -0.5 }}>
              {itIndex != 0 && (
                <div onClick={() => {
                  setOpenDeleteModal(true);
                  setDeleteStrategyId(itIndex)
                  setDeleteField(field)
                  setDeleteFieldId(it?.id)
                  setFieldIndexItem(fieldIndex)
                }} style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}>
                  <DeleteIcon />
                </div>
              )}
            </Grid>
            {itIndex === field.length - 1 && (
              <Grid item xs={12} sx={{ mt: -2 }}>
                <div className="add_line_wrapper">
                  <p onClick={() => handleAddLine(field, it, fieldIndex)}>
                    + Adicionar linha
                  </p>
                </div>
              </Grid>
            )}
            <DeleteElaborationDialog
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
              onDelete={() => {
                if (deleteFieldId) {
                  DeleteGuideLinesElaborations(deleteFieldId)
                  setDeleteFieldId(0)
                };

                updateGuidelines(fieldIndexItem, fields[fieldIndexItem].filter((_: any, index: any) => index !== deleteStrategyId));
                setOpenDeleteModal(false);
              }}
            />
          </React.Fragment>
        );

        return acc;
      });

      return acc;
    }, []);

    return (
      <>

        {inputs}
      </>
    );
  }, [fields, openDeleteModal, is_optional]);

  const ReplaceButton = React.useCallback((index: any) => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (replaceButtonRef?.current?.reuseLen > 0) {
            setReutilizar(true);
            setReuseClickedIndex(index);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, setReutilizar, data, openDisclaimer, reutilizar]);

  const actionButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'true' ? 'true' : 'false';
      } else {
        return Boolean(is_optional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'false' || is_optional == undefined;
      } else {
        return Boolean(is_optional) == false || is_optional == undefined;
      }
    }

    return (
      <ActionButtonElaborations disable={disabeSubmit} isOptional={opitional()} layer_indicator={false} onClick={() => {
        if (optionalV2()) {
          PostProgressCode({ code: 'diretrizes', skipped: true }, router.query.id)
          setNavigable(true)

          router.push('/dashboard/elaboracao_planos/' + router.query.id);

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            setDefaultItemMenuId(planId);
          }
        }
      }} />
    )
  }, [is_optional, disabeSubmit])

  const RadioButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'true' ? 'true' : 'false';
      } else {
        return Boolean(is_optional) == true ? 'true' : 'false'
      }
    }

    return (
      <RadioCheckedElaboration
        required={false}
        name="is_optional"
        disabled={disable}
        errors={errosGuidelines}
        control={controlGuidelines}
        defaultValue={opitional()}
        data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
        label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
      />
    )
  }, [is_optional, disable])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitGuidelines(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {RadioButton()}
          </Grid>
          {Fields()}
          {is_optional == 'true' && (
            <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
              <span>{errosGuidelines.root?.message}</span>
            </div>

          )}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          <Grid item xs={12}>
            {actionButton()}
          </Grid>
          <ReutilizarElaborationDialogV2
            title="Diretriz"
            reuseData={data}
            open={reutilizar}
            ref={replaceButtonRef}
            setOpen={() => setReutilizar(false)}
            setValue={(e) => {
              if (reuseClickedIndex != null) {
                e.map((item: any, index: any) => {
                  const { checked, ...res } = item
                  if (index == 0) {
                    setValueGuidelines(`diretriz.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                    setValueGuidelines(`diretriz.${reuseClickedIndex}.${index}.label` as any, fields[reuseClickedIndex][0]?.label)
                    return;
                  }

                  setValueGuidelines(`diretriz.${reuseClickedIndex}.${index}.texto` as any, res.texto)
                });
              }
            }}
          />
          <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        </Grid>
      </Container>
    </form>
  )
}
