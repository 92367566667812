import React from "react";

interface OpenContextType {
  open: boolean;
  openDashboardMenu: boolean;
  setOpen: (open: boolean) => void;
  setOpenDashboardMenu: (open: boolean) => void;
}

export const MenuContext = React.createContext({} as OpenContextType);

interface OpenProviderProps {
  children: React.ReactNode;
}

export const MenuProvider = ({ children }: OpenProviderProps) => {
  const [open, setOpen] = React.useState(false);
  const [openDashboardMenu, setOpenDashboardMenu] = React.useState(false);

  const value = {
    open,
    setOpen,
    openDashboardMenu,
    setOpenDashboardMenu,
  };

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
