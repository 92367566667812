import { UseBoundStore, StoreApi, create } from 'zustand';

interface IProps {
  open: boolean;
  toggle: () => void;
}

// contexto zustand reponsável pela abertura do botão flutuante na página de busca dos planos
export const useFloatingFilterButton: UseBoundStore<StoreApi<IProps>> = create(
  (set, get) => ({
    open: false,
    toggle: () => set({ open: !get().open }),
  }),
);
