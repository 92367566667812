import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFormReset,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";

interface IFormMissionPurpose {
  isOptionalMissionPurpose: string;
  removeMissionPurpose: UseFieldArrayRemove;
  fieldsMissionPurpose: Record<"id", string>[];
  errosMissionPurpose: FieldErrors<InputsMissionPurpose>;
  resetMissionPurpose: UseFormReset<InputsMissionPurpose>;
  watchMissionPurpose: UseFormWatch<InputsMissionPurpose>;
  controlMissionPurpose: Control<InputsMissionPurpose, any>;
  setValueMissionPurpose: UseFormSetValue<InputsMissionPurpose>;
  registerMissionPurpose: UseFormRegister<InputsMissionPurpose>;
  appendMissionPurpose: UseFieldArrayAppend<InputsMissionPurpose, "missao_proposito">;
  handleSubmitMissionPurpose: UseFormHandleSubmit<InputsMissionPurpose, undefined>;
}

export const MissionPurposeContext = React.createContext({} as IFormMissionPurpose);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsMissionPurpose = {
  missao_proposito: any;
};

const SchemaMissionPurpose = Yup.object().shape({
  missao_proposito: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required("Campo obrigatório")
    })
  ).required('Campo obrigatório')
});

const defaultValue = {
  id: '',
  texto: '',
}


export const MissionPurposeContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetMissionPurpose,
    watch: watchMissionPurpose,
    control: controlMissionPurpose,
    register: registerMissionPurpose,
    setValue: setValueMissionPurpose,
    handleSubmit: handleSubmitMissionPurpose,
    formState: { errors: errosMissionPurpose } } = useForm<InputsMissionPurpose>({
      resolver: yupResolver(SchemaMissionPurpose),
      defaultValues: { missao_proposito: [defaultValue] }
    });

  const { fields: fieldsMissionPurpose, append: appendMissionPurpose, remove: removeMissionPurpose } = useFieldArray({
    control: controlMissionPurpose,
    name: "missao_proposito"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans()
  const { GetMissionPurposeElaboration } = new ElaborationService();

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos"))
  //     GetMissionPurposeElaboration(router.query.id).then((res: any) => {
  //     if (res.data.length > 0) {
  //       setValueMissionPurpose('missao_proposito', res.data);
  //     } else {
  //       setValueMissionPurpose("missao_proposito", [defaultValue]);
  //     }
  //   })
  // }, [router.pathname, router.query, completCode]);

  const value = {
    errosMissionPurpose,
    resetMissionPurpose,
    watchMissionPurpose,
    removeMissionPurpose,
    appendMissionPurpose,
    fieldsMissionPurpose,
    controlMissionPurpose,
    setValueMissionPurpose,
    registerMissionPurpose,
    handleSubmitMissionPurpose,
  } as IFormMissionPurpose;

  return <MissionPurposeContext.Provider value={value}>{children}</MissionPurposeContext.Provider>;
};