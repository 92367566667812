import { useTheme } from "@/services/zustand/theme";

export const ContingenciaOperacoesResiliencia = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 105V15H58.75V35.625H110V78.125H102.5V43.125H58.75V56.25H68.75V63.75H58.75V76.875H68.75V84.375H58.75V97.5H83.125V105H10ZM17.5 97.5H30.625V84.375H17.5V97.5ZM17.5 76.875H30.625V63.75H17.5V76.875ZM17.5 56.25H30.625V43.125H17.5V56.25ZM17.5 35.625H30.625V22.5H17.5V35.625ZM38.125 97.5H51.25V84.375H38.125V97.5ZM38.125 76.875H51.25V63.75H38.125V76.875ZM38.125 56.25H51.25V43.125H38.125V56.25ZM38.125 35.625H51.25V22.5H38.125V35.625ZM101.625 115V104.125H90.625V96.625H101.625V85.625H109.125V96.625H120V104.125H109.125V115H101.625ZM81.875 63.75V56.25H89.375V63.75H81.875ZM81.875 84.375V76.875H89.375V84.375H81.875Z"
        fill="url(#paint0_linear_2012_91966)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91966"
          x1="35.4375"
          y1="30"
          x2="80.7943"
          y2="110.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
