import { AxiosPromise } from "axios";
import { Api, ApiDashboard } from "@/services/configs/axios";

export class ElaborationService {
  PreRegisterElaboration = (data: any): AxiosPromise<any> => ApiDashboard.post("plano", data);
  GetPreRegisterElaboration = (id: any): AxiosPromise<any> => Api.get("plano/" + id);
  UpdatePreRegisterElaboration = (data: any, id: any): AxiosPromise<any> => Api.post("plano/update/" + id, data);
  DeletePreRegisterElaboration = (id: any): AxiosPromise<any> => Api.delete("plano/" + id);

  SummaryDiagnosisElaboration = ({ data, id }: { data: FormData, id: number }): AxiosPromise<any> =>
    Api.post('sintese-diagnostico/' + id, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

  GetSummaryDiagnosisElaboration = (id: any) => Api.get('sintese-diagnostico/plano/' + id);
  DeleteSummaryDiagnosisElaborations = (id: any) => Api.delete('/sintese-diagnostico/' + id);

  SummaryScenarioElaboration = ({ data, id }: { data: FormData, id: number }): AxiosPromise<any> =>
    Api.post(`sintese-cenario/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  GetSummaryScenarioElaboration = (id: any) => Api.get('sintese-cenario/plano/' + id);
  DeleteSummaryScenarioElaborations = (id: any) => Api.delete('/sintese-cenario/' + id);

  GetVisionElaboration = (id: any): AxiosPromise<any> => Api.get('visao/plano/' + id);
  VisionElaboration = (data: any, id: any): AxiosPromise<any> => Api.post('visao/' + id, data);

  GetThematicAxisElaboration = (id: any): AxiosPromise<any> => Api.get('eixos-tematicos/plano/' + id);
  ThematicAxisElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('eixos-tematicos/' + id, data);
  DeleteThematicAxisElaborations = (id: any) => Api.delete('/eixos-tematicos/' + id);

  GetValuesElaboration = (id: any): AxiosPromise<any> => Api.get('valores/plano/' + id);
  ValuesElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('valores/' + id, data);
  DeleteValuesElaborations = (id: any) => Api.delete('/valores/' + id);

  GetMissionPurposeElaboration = (id: any): AxiosPromise<any> => Api.get('missao/plano/' + id);
  MissionPurposeElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('missao/' + id, data);

  GetGuideLinesElaboration = (id: any): AxiosPromise<any> => Api.get('diretrizes/plano/' + id);
  GuideLinesElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('diretrizes/' + id, data);
  GuidelinesElaboration = ({ id, data }: { id: any, data: any }): AxiosPromise<any> => Api.post('diretrizes', + id, data);
  DeleteGuideLinesElaborations = (id: any) => Api.delete('/diretrizes/' + id);

  GetAspirationElaboration = (id: any): AxiosPromise<any> => Api.get('aspiracao/plano/' + id);
  AspirationElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('aspiracao/' + id, data);
  DeleteAspirationElaborations = (id: any) => Api.delete('/aspiracao/' + id);

  GetEstrategyElaboration = (id: any): AxiosPromise<any> => Api.get('estrategia/plano/' + id);
  EstrategyElaboration = ({ data, id }: { data: any, id: number }): AxiosPromise<any> => Api.post('estrategia/' + id, data);
  DeleteStrategyElaborations = (id: any) => Api.delete('/estrategia/' + id);

  GetObjectivesElaboration = (id: any) => Api.get('objetivos/plano/' + id);
  ObjectivesElaboration = ({ data, id }: { data: any, id: number }) => Api.post('objetivos/' + id, data);
  GetObjectivesEixoElaboration = (id: any, eixo: any) => Api.get('objetivos/plano/' + id + "?eixo=" + eixo);
  DeleteObjectivesElaborations = (id: any) => Api.delete('/objetivos/' + id);

  GoalsElaborations = (data: any) => Api.post('/metas', data);
  UpdateGoalsElaborations = (data: any, id: any) => Api.put('/metas/' + id, data);
  GetGoalsEixoElaborations = (id: any, eixo?: any) => Api.get("/metas/plano/" + id + "?eixo=" + eixo);
  GetGoalsElaborations = (id: any) => Api.get("/metas/plano/" + id);
  DeleteGoasElaborations = (id: any) => Api.delete('/metas/' + id);

  DeleteMacroactionsElaborations = (id: any) => Api.delete('/macroacoes/' + id);
  MacroactionsElaboration = (data: any, id: any) => Api.post('/macroacoes/' + id, data);
  UpdateMacroactionsElaborations = (data: any, id: any) => Api.put('/macroacoes/' + id, data);
  GetMacroactionsEixoElaborations = (id: any, eixo?: any) => Api.get("/macroacoes/plano/" + id + "?eixo=" + eixo);
  GetMacroactionsElaborations = (id: any) => Api.get("/macroacoes/plano/" + id);

  DeleteActivitiesElaborations = (id: any) => Api.delete('/atividades/' + id);
  ActivitiesElaboration = (data: any, id: any) => Api.post('/atividades/' + id, data);
  UpdateActivitiesElaborations = (data: any, id: any) => Api.put('/atividades/' + id, data);
  GetActivitiesEixoElaborations = (id: any, eixo?: any) => Api.get("/atividades/plano/" + id + "?eixo=" + eixo);
  GetActivitiesElaborations = (id: any, eixo?: any) => Api.get("/atividades/plano/" + id);

  DeleteProgramsElaborations = (id: any) => Api.delete('/programas/' + id);
  ProgramsElaboration = (data: any, id: any) => Api.post('/programas/' + id, data)
  UpdateProgramsElaborations = (data: any, id: any) => Api.put('/programas/' + id, data);
  GetProgramsEixoElaborations = (id: any, eixo?: any) => Api.get("/programas/plano/" + id + "?eixo=" + eixo);
  GetProgramsElaborations = (id: any, eixo?: any) => Api.get("/programas/plano/" + id);

  DeleteProjectsElaborations = (id: any) => Api.delete('/projetos/' + id);
  ProjectsElaboration = (data: any, id: any) => Api.post('/projetos/' + id, data)
  UpdateProjectsElaborations = (data: any, id: any) => Api.put('/projetos/' + id, data);
  GetProjectsEixoElaborations = (id: any, eixo?: any) => Api.get("/projetos/plano/" + id + "?eixo=" + eixo);
  GetProjectsElaborations = (id: any, eixo?: any) => Api.get("/projetos/plano/" + id);

  DeleteProductsDeliveriesElaborations = (id: any) => Api.delete('/entregas/' + id);
  ProductsDeliveriesElaboration = (data: any, id: any) => Api.post('/entregas/' + id, data)
  UpdateProductsDeliveriesElaborations = (data: any, id: any) => Api.put('/entregas/' + id, data);
  GetProductsDeliveriesEixoElaborations = (id: any, eixo?: any) => Api.get("/entregas/plano/" + id + "?eixo=" + eixo);
  GetProductsDeliveriesElaborations = (id: any, eixo?: any) => Api.get("/entregas/plano/" + id);

  DeleteInitiativesElaborations = (id: any) => Api.delete('/iniciativas/' + id);
  InitiativesElaboration = (data: any, id: any) => Api.post('/iniciativas/' + id, data)
  GetInitiativesElaborations = (id: any, eixo?: any) => Api.get("/iniciativas/plano/" + id);
  UpdateInitiativesElaborations = (data: any, id: any) => Api.put('/iniciativas/' + id, data);
  GetInitiativesEixoElaborations = (id: any, eixo?: any) => Api.get("/iniciativas/plano/" + id + "?eixo=" + eixo);

  DeleteNumbersElaboration = (id: any) => Api.delete('/numeros/' + id);
  DeleteIndicatorElaboration = (id: any) => Api.delete('/indicadores/' + id);

  DropDownMetas = () => Api.get("tema");
  NumberElaboration = (data: any) => Api.post('/numeros', data);
  DropDownPlans = (): AxiosPromise<any> => Api.get('plano/drop-down');
  IndicatorsElaboration = (data: any) => Api.post('/indicadores', data);
  ValidateQueryId = (id: number): AxiosPromise<any> => Api.get("plano/" + id);
  ProgressCodePlanId = (id: number): AxiosPromise<any> => Api.get("progress-code/plano/" + id);
  PutProgressCode = (data: any, id: any): AxiosPromise<any> => Api.post("progress-code/" + id, data);
  PostProgressCode = (data: any, id: any): AxiosPromise<any> => Api.post("/progress-code/" + id, data);
  PostProgressCodePlanId = (data: any, id: number): AxiosPromise<any> => Api.post("progress-code/" + id);

  PostManagementAccess = (data: any): AxiosPromise<any> => ApiDashboard.post("acessos", data);
  GetManagementAccess = (id: any): AxiosPromise<any> => ApiDashboard.get("acessos/plano/" + id);
  GetSubmittedDates = (id: any): AxiosPromise<any> => ApiDashboard.get('plano/submited-dates/' + id);
  ApproveAndReproveAccess = (id: any, data: any): AxiosPromise<any> => ApiDashboard.put("acessos/gerir/" + id, data);
  GetUnidadesMedida = (): AxiosPromise<any> => Api.get('unidade-medida');
  PostUnidadesMedida = (data: any): AxiosPromise<any> => Api.post('unidade-medida', data);
  GetFinalBond = (id: any): AxiosPromise<any> => Api.get('vinculo-final/' + id);
}