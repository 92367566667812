import { useTheme } from "@/services/zustand/theme";

export const RecursosHidricosJacarepagua = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 100V60H10L60 15L110 60H95V100H25ZM35 90H85V51L60 28.5L35 51V90ZM60 80C62.75 80 65.1042 79.0208 67.0625 77.0625C69.0208 75.1042 70 72.75 70 70C70 67.75 69.375 65.3542 68.125 62.8125C66.875 60.2708 64.1667 56 60 50C55.8333 56 53.125 60.2708 51.875 62.8125C50.625 65.3542 50 67.75 50 70C50 72.75 50.9792 75.1042 52.9375 77.0625C54.8958 79.0208 57.25 80 60 80Z"
        fill="url(#paint0_linear_2105_38560)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2105_38560"
          x1="33.125"
          y1="27.75"
          x2="70.2936"
          y2="98.5199"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
