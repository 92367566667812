export const Svg_005 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M53.674 26.312a4.05 4.05 0 0 0 3.1-.244A4.05 4.05 0 0 0 59 22.44v-.878L29.5.002 0 21.562v.878c0 1.54.853 2.93 2.227 3.628a4.038 4.038 0 0 0 3.1.244L29.5 8.644l24.174 17.668Z"
      />
      <path
        fill="url(#c)"
        d="M29.5 30.528a2.525 2.525 0 1 0 0-5.051 2.525 2.525 0 0 0 0 5.05Z"
      />
      <path
        fill="url(#d)"
        d="M52.517 55.541V29.75L29.5 12.925 6.483 29.748v25.793H0v3.457h59v-3.457h-6.483ZM29.5 22.02a5.99 5.99 0 0 1 5.982 5.982 5.99 5.99 0 0 1-5.982 5.983 5.99 5.99 0 0 1-5.982-5.983A5.99 5.99 0 0 1 29.5 22.02Zm-8.066 26.705c0-4.447 3.618-8.066 8.066-8.066s8.066 3.619 8.066 8.066v6.816H34.11v-6.816a4.615 4.615 0 0 0-4.609-4.609 4.615 4.615 0 0 0-4.61 4.61v6.815h-3.456v-6.816Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={13.644}
        x2={20.902}
        y1={3.978}
        y2={30.123}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={28.143}
        x2={30.542}
        y1={26.235}
        y2={30.117}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={13.644}
        x2={32.798}
        y1={19.837}
        y2={59.535}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h59v59H0z" />
      </clipPath>
    </defs>
  </svg>
)
