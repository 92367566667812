import styled from "styled-components";

export const Container = styled.div`
  width: auto;
  height: auto;
  gap: 10px;
  display: flex;
  margin: 0 0 0 49px;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  cursor: pointer;
`;

export const Title = styled.h1`
  color: white;
  font-size: 19px;
  text-align: left;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
`;
