import { ModalContent } from "@/components/aprovacao_planos/partials/TypePlans/styles";
import { Dialog } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { SendPlanosWrapper } from "./styles";
import { PlansService } from "@/services/endpoints/plans";
import { useSnackbar } from "@/hooks/useSnackbar";

export const DevolverPlan = () => {
  const router = useRouter();

  const { handleOpenSnackbar } = useSnackbar();
  const { PostEplEnviar } = new PlansService()
  const [epl, setEpl] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false)
  }

  const handleEnviar = () => {

    const params = {
      isRefused: true
    }

    PostEplEnviar(params, router.query.id)
      .then(() => {
        handleOpenSnackbar(
          "Plano devolvido com sucesso!",
          ""
        )
        handleClose()
        window.location.href ='/dashboard/aprovacao_planos'
      }).catch((err:any) => {
        console.error(err)
      })
  }

  useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl'))
  }, [router])

  if (!epl) return

  return (
    <SendPlanosWrapper>
      <button data-action='insert_plan' className="devolver" onClick={() => setOpen(true)} >
        <p>Devolver plano ao órgão</p>
      </button>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <ModalContent>
          <h3>
            Devolver plano ao órgão
          </h3>
          <div>
            <p>
              Ao devolver o Plano ao usuário responsável, ele terá acesso às considerações apontadas para ajustes. Atente-se que após enviar o Plano, essa ação não pode ser desfeita, e será necessário aguardar a nova devolução do usuário. Deseja prosseguir com o envio do Plano?            </p>
          </div>
          <div className='button_wrapper'>
            <button
              data-not-shadow
              onClick={() => handleClose()}
              className='cancel'
            >
              Cancelar
            </button>
            <button
              data-not-shadow
              autoFocus
              onClick={() => handleEnviar()}
            >
              Enviar plano
            </button>
          </div>
        </ModalContent>
      </Dialog>
    </SendPlanosWrapper>
  )
}