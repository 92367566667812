import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { ListItemText, Checkbox, OutlinedInput, MenuItem, Select, FormControl } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightBold
        : theme.typography.fontWeightMedium,
  };
}

interface IProps {
  data: any[];
  value: string[];
  fullWidth?: boolean;
  setValue: (value: any) => void;
}

export function MultipleSelect({ data, value, setValue, fullWidth }: IProps) {
  const theme = useTheme();

  const handleChange = (event: any) => {
    const { target: { value } } = event;
    const newSelection = typeof value === 'string' ? value.split(',') : value;
    
    setValue(newSelection);
  };

  return (
    <div>
      <FormControl sx={{ width: fullWidth ? '100%' : 200 }} fullWidth>
        <Select
          multiple
          fullWidth
          size='small'
          value={value}
          MenuProps={MenuProps}
          onChange={handleChange}
          input={<OutlinedInput fullWidth sx={{ background: '#fff' }} />}
          renderValue={(selected) => selected.map((i: any) => i.descricao).join(', ')}
        >
          {data.map((item) => (
            <MenuItem
              key={item.id}
              value={item}
              style={getStyles(item, value, theme)}
            >
              <Checkbox checked={value.some((i: any) => i.id == item.id)} />
              <ListItemText primary={item?.descricao} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}