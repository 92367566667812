import React from "react";

export interface ISnackbarContextProps {
  handleOpenSnackbar: (message: string, description: string) => void;
  openSnackbar: boolean;
  message: string;
  description: string;
  handleCloseSnackbar: (
    _?: React.SyntheticEvent | Event,
    reason?: string
  ) => void;
}

export const SnackbarContext = React.createContext({} as ISnackbarContextProps);

interface IProps {
  children: React.ReactNode;
}

export const SnackbarContextProvider: React.FC<IProps> = ({ children }) => {
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleOpenSnackbar = (message: string, description: string) => {
    setMessage(message);
    setDescription(description);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (
    _?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const value = {
    openSnackbar,
    description,
    message,
    handleOpenSnackbar,
    handleCloseSnackbar,
  } as ISnackbarContextProps;

  return (
    <SnackbarContext.Provider value={value}>
      {children}
    </SnackbarContext.Provider>
  );
};
