import React from 'react';
import { InfoIncon } from '../info';
import { Container } from './styled';
import { Controller, Control } from 'react-hook-form';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Button, Tooltip } from '@mui/material';
import { ConsideracaoIcon } from '../consideracao';
import { ConsiderationModal } from '../../Forms/ConsiderationModal';
import { useRouter } from 'next/router';

interface IProps {
  data: any[];
  errors: any;
  name: string;
  label: string;
  tooltip?: string;
  disabled?: boolean;
  required: boolean;
  defaultValue?: string;
  commentTittle?: string;
  control: Control<any, any>;
}

export const RadioCheckedElaboration: React.FC<IProps> = ({
  label,
  required,
  tooltip,
  disabled,
  control,
  name,
  defaultValue,
  commentTittle,
  data
}) => {

  const router = useRouter();

  const disabledhandle = () => {

    if (disabled) return true
    if (router.asPath.includes('/preview')) return true

    return false
  }

  return (
    <Container>
      <div className="title_wrapper_datepicker">
        <ToolTipWrapper
          label={label}
          commentTittle={commentTittle}
          tooltip={tooltip}
          required={required}
          name={name}
          value={data.find(item => item.value == true).label}
        />
      </div>
      <FormControl component="fieldset">
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <RadioGroup {...field}>
              <div style={{ display: 'flex' }}>
                {data.map(item => (<FormControlLabel disabled={disabledhandle()} value={item.value} control={<Radio />} label={item.label} />))}
              </div>
            </RadioGroup>
          )}
        />
      </FormControl>
    </Container>
  );
}

type TooltipWrapperProps = {
  tooltip?: string,
  label?: string,
  required?: boolean,
  local?: string,
  hiddeInput?: boolean,
  register?: any,
  name?: string,
  value?: string,
  commentTittle?: string;

}

const ToolTipWrapper = ({
  label,
  tooltip,
  required,
  commentTittle,
  register,
  name,
  value, }: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label className='interFont'>{`${label} ${required ? '*' : ''}`}</label>
      {
        !epl &&
        <>
          {tooltip && (
            <Tooltip arrow title={tooltip}>
              <div>
                <InfoIncon />
              </div>
            </Tooltip>
          )}
        </>
      }
      {epl && (
        <CondiserationWrapper
          register={register}
          label={label}
          name={name}
          commentTittle={commentTittle}
          value={value}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  register,
  label,
  name,
  value,
  commentTittle,
}: {
  register?: any,
  label?: string,
  name?: string,
  commentTittle?: string;
  value?: string,
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        handleClose={handleClose}
        register={register}
        label={label}
        commentTittle={commentTittle}
        name={name}
        value={value}
      />
    </>
  )
}