import dayjs from "dayjs";
import { Container } from "./styled";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import FormControl from '@mui/material/FormControl';
import { Loading } from "@/components/global/Loading";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { alpha, styled as MuiStyled } from '@mui/material/styles';
import { ComentariosService } from "@/services/endpoints/comentario";
import { ElaborationService } from "@/services/endpoints/elaboracao";

export const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    width: '100%',
    height: '15px',
    borderRadius: 4,
    color: '#ADB5BD',
    border: '1px solid',
    padding: '7px 12px',
    position: 'relative',
    borderColor: theme.palette.mode === 'light' ? '#DEE2E6' : '#2D3843',
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#1A2027',
    transition: theme.transitions.create([
      'box-shadow',
      'border-color',
      'background-color',
    ]),
    fontFamily: [
      "Inter",
      'Arial',
      'Roboto',
      '"Segoe UI"',
      'sans-serif',
      '-apple-system',
      '"Segoe UI Emoji"',
      '"Helvetica Neue"',
      '"Segoe UI Symbol"',
      'BlinkMacSystemFont',
      '"Apple Color Emoji"',
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    },
  },
}));

export const ConsiderationLayerForm = () => {
  const router = useRouter();
  const [datas, setDatas] = useState<any[]>([]);
  const [comentarios, setComentarios] = useState([]);
  const { getComentarios } = new ComentariosService();
  const [dataSelected, setDataSelected] = useState('');
  const [date, setDate] = useState<string | null>(null);
  const { GetSubmittedDates } = new ElaborationService();
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (event: SelectChangeEvent) => {
    setDataSelected(event.target.value as string);
  };

  useEffect(() => {
    setLoading(true);
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && router.query.id) {
      const params = { data: date };

      getComentarios(router.query.id, params).then(({ data }: any) => {
        const filter = data.filter((item: any) => {
          const selectDate = dataSelected.split('T')[0] + 'T00:00:00';
          const parsedCurrentDate = item.createAt.split('T')[0] + 'T00:00:00';
          return parsedCurrentDate == selectDate && item;
        });

        if (dataSelected.length > 0) {
          setComentarios(filter);
        } else {
          setComentarios(data)
        }
      }).finally(() => setLoading(false));
    };
  }, [router, date, router.pathname, dataSelected]);

  useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      GetSubmittedDates(router.query.id).then(({ data: { data } }) => setDatas(data))
    };
  }, [router.query, router.pathname]);

  return (
    <Container>
      <div>
        <FormControl fullWidth sx={{ width: '30%' }}>
          <Select
            value={dataSelected || '0'}
            onChange={handleChange}
            input={<BootstrapInput />}
            id='mui-component-select-planos_vinculados'
          >
            <MenuItem sx={{ color: '#ADB5BD', fontSize: '14px' }} value='0'>Selecione uma data</MenuItem>
            {datas.map(item => (
              <MenuItem value={item.data}>{dayjs(item.data).format('DD/MM/YYYY')}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <PageContent loading={loading} comentarios={comentarios} />
    </Container>
  )
}


const PageContent = ({ loading, comentarios }: { loading: boolean, comentarios: any[] }) => {
  if (loading) {
    return (
      <Loading loading={loading} />
    )
  }

  if (comentarios.length == 0) {
    return (
      <span>
        Sem comentários
      </span>
    )
  }

  return (
    <>
      {comentarios.map((item: any) => (
        <div>
          <h3>{item.label}</h3>
          <p>
            <span>
              {item.id} -
            </span>
            {item.title}
          </p>

          <div className="destaque">
            <p>
              {item.comentario}
            </p>
          </div>
        </div>
      ))}
    </>
  )
}