import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayUpdate,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";

interface IFormActivites {
  page: number;
  label: string;
  projetos: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removeActivities: UseFieldArrayRemove;
  fieldsActivites: Record<"id", string>[];
  errosActivites: FieldErrors<InputsActivites>;
  resetActivites: UseFormReset<InputsActivites>;
  watchActivites: UseFormWatch<InputsActivites>;
  controlActivites: Control<InputsActivites, any>;
  setValueActivites: UseFormSetValue<InputsActivites>;
  registerActivites: UseFormRegister<InputsActivites>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  updateActivites: UseFieldArrayUpdate<InputsActivites, "atividades">;
  appendActivites: UseFieldArrayAppend<InputsActivites, "atividades">;
  handleSubmitActivites: UseFormHandleSubmit<InputsActivites, undefined>;
}

export const ActivitesContext = React.createContext({} as IFormActivites);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsActivites = {
  atividades: any;
};

const SchemaActivites = Yup.object().shape({
  atividades: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório')
    })
  ).required()
});

const defaultValue = {
  id: '',
  texto: '',
}

export const ActivitesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetActivites,
    watch: watchActivites,
    control: controlActivites,
    clearErrors,
    register: registerActivites,
    setValue: setValueActivites,
    handleSubmit: handleSubmitActivites,
    formState: { errors: errosActivites } } = useForm<InputsActivites>({
      resolver: yupResolver(SchemaActivites),
      defaultValues: { atividades: [defaultValue] }
    });

  const { fields: fieldsActivites, append: appendActivites, update: updateActivites, remove: removeActivities } = useFieldArray({
    control: controlActivites,
    name: "atividades"
  });

  const router = useRouter();
  const { GetThematicAxisElaboration, GetProjectsEixoElaborations: GetProjectsElaborations } = new ElaborationService();
  const { completCode } = usePreparationOfPlans()
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [projetos, setProjetos] = React.useState([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id || router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id) {
  //     GetProjectsElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //       setProjetos(res.data);
  //       setLabel('Projetos');
  //     });
  //   }
  // }, [router.query, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  // React.useEffect(() => {
  //   const projeto = projetos[page - 1] as any;
  //   clearErrors()
  //   if (projeto?.atividadesProjetos?.length > 0) {
  //     setValueActivites('atividades', projeto?.atividadesProjetos.map((item: any) => ({ id: item?.id, texto: item?.texto })))
  //   } else {
  //     setValueActivites('atividades', [defaultValue])
  //   }
  // }, [projetos, page, completCode]);


  const value = {
    page,
    label,
    setPage,
    projetos,
    errosActivites,
    resetActivites,
    watchActivites,
    eixos_tematicos,
    fieldsActivites,
    appendActivites,
    updateActivites,
    removeActivities,
    currentIndexEixo,
    controlActivites,
    setValueActivites,
    registerActivites,
    setCurrentIndexEixo,
    handleSubmitActivites,
  } as IFormActivites;

  return <ActivitesContext.Provider value={value}>{children}</ActivitesContext.Provider>;
};