import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFormSetError,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

interface IFormAspiration {
  isOptionalAspiration: string;
  fieldsAspiration: Record<"id", string>[];
  errosAspiration: FieldErrors<InputsAspiration>;
  resetAspiration: UseFormReset<InputsAspiration>;
  watchAspiration: UseFormWatch<InputsAspiration>;
  controlAspiration: Control<InputsAspiration, any>;
  setValueAspiration: UseFormSetValue<InputsAspiration>;
  setAspirationError: UseFormSetError<InputsAspiration>;
  registerAspiration: UseFormRegister<InputsAspiration>;
  updateAspiration: UseFieldArrayUpdate<InputsAspiration, "aspiracao">;
  appendAspiration: UseFieldArrayAppend<InputsAspiration, "aspiracao">;
  handleSubmitAspiration: UseFormHandleSubmit<InputsAspiration, undefined>;
}

export const AspirationContext = React.createContext({} as IFormAspiration);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsAspiration = {
  aspiracao: any;
};

const SchemaAspiration = Yup.object().shape({
  aspiracao: Yup.array().required('Campo obrigatório')
});

// Define o esquema para cada objeto dentro do array
const defaultValue = {
  id: '',
  texto: '',
  data: null,
}

export const AspirationContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetAspiration,
    watch: watchAspiration,
    setError: setAspirationError,
    control: controlAspiration,
    register: registerAspiration,
    setValue: setValueAspiration,
    handleSubmit: handleSubmitAspiration,
    formState: { errors: errosAspiration } } = useForm<InputsAspiration>({
      resolver: yupResolver(SchemaAspiration) as any,
      defaultValues: { aspiracao: [[defaultValue]] }
    });

  const { fields: fieldsAspiration, append: appendAspiration, update: updateAspiration } = useFieldArray({
    control: controlAspiration,
    name: "aspiracao"
  });

  const value = {
    errosAspiration,
    resetAspiration,
    watchAspiration,
    fieldsAspiration,
    appendAspiration,
    updateAspiration,
    controlAspiration,
    setValueAspiration,
    setAspirationError,
    registerAspiration,
    handleSubmitAspiration,
  } as IFormAspiration;

  return <AspirationContext.Provider value={value}>{children}</AspirationContext.Provider>;
};