import styled, { css } from 'styled-components';
import InputBase from '@mui/material/InputBase';
import { alpha, styled as MuiStyled } from '@mui/material/styles';

export const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#1A2027',
    border: '1px solid',
    borderColor: theme.palette.mode === 'light' ? '#DEE2E6' : '#2D3843',
    fontSize: 16,
    width: '100%',
    height: '16px',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Inter",
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

interface IContainerProps {
  subLabel?: string;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title_wrapper_textfield {
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;

      p {
        margin-right: .5rem;
      }

      span {
        margin-left: -.5rem;
        
      }
      }


    ${({ subLabel }) => subLabel && css`
      label {
        color: #004A80;
        font-size: 18px;
        font-weight: 700;
        line-height: 110%;
        font-style: normal;
        font-family: Roboto;
      }
    `}
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }

  input {
    &::placeholder {
      color: var(--Gray-500, #ADB5BD);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;