import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFormReset,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

interface IFormObjectives {
  data: any[];
  label: string;
  page: number;
  isEmpty: boolean;
  eixos_tematicos: any;
  currentIndexEixo: number;
  removeObjectives: UseFieldArrayRemove;
  fieldsObjectives: Record<"id", string>[];
  errosObjectives: FieldErrors<InputsObjectives>;
  watchObjectives: UseFormWatch<InputsObjectives>;
  controlObjectives: Control<InputsObjectives, any>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setValueObjectives: UseFormSetValue<InputsObjectives>;
  registerObjectives: UseFormRegister<InputsObjectives>;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  updateObjectives: UseFieldArrayUpdate<InputsObjectives, "objetivos">;
  appendObjectives: UseFieldArrayAppend<InputsObjectives, "objetivos">;
  handleSubmitObjectives: UseFormHandleSubmit<InputsObjectives, undefined>;
}

export const ObjectivesContext = React.createContext({} as IFormObjectives);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsObjectives = {
  label?: any;
  objetivos: any;
};

const SchemaObjectives = Yup.object().shape({
  objetivos: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Objetivo obrigatório.')
    })
  )
});

const defaultValue = {
  id: '',
  texto: '',
}


export const ObjectivesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    watch: watchObjectives,
    control: controlObjectives,
    register: registerObjectives,
    setValue: setValueObjectives,
    clearErrors,
    handleSubmit: handleSubmitObjectives,
    formState: { errors: errosObjectives } } = useForm<InputsObjectives>({
      resolver: yupResolver(SchemaObjectives) as any,
      defaultValues: { objetivos: [defaultValue] }
    });

  const { fields: fieldsObjectives, append: appendObjectives, update: updateObjectives, remove: removeObjectives } = useFieldArray({
    control: controlObjectives,
    name: "objetivos"
  });

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const { completCode } = usePreparationOfPlans();
  const [data, setData] = React.useState<any[]>([]);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { GetThematicAxisElaboration, GetEstrategyElaboration, GetGuideLinesElaboration } = new ElaborationService();

  // const handleHierarchyAxis = (codes: any, typeOfPlans: any) => {
  //   const estrategia = codes.some((item: any) => item.code == 'estrategia' && item.skipped == false);
  //   const diretrizes = codes.some((item: any) => item.code == 'diretrizes' && item.skipped == false);
  //   const eixo_tematico = codes.some((item: any) => item.code == 'eixos_tematicos' && item.skipped == false);


  //   if (typeOfPlans == "Plano de Estado" || typeOfPlans == "Plano Setorial") {
  //     if (estrategia && eixo_tematico) {
  //       setLabel('Estratégia');
  //       GetEstrategyElaboration(router.query.id).then((res: any) => {
  //         const data = res.data as any[];
  //         const arr: any[] = [];
  //         data.filter(item => item.eixoTematico == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
  //         setData(arr);

  //       });

  //       return 'Estratégia'
  //     };

  //     if (!estrategia && eixo_tematico) {
  //       setLabel('Eixo Temático')
        
  //       GetThematicAxisElaboration(router.query.id).then(res => {
  //         const data = res.data as any[];
  //         const arr: any[] = [];
          
  //         data.map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
  //         setData(arr);
  //       });

  //       return 'Eixo Temático'
  //     };

  //   }

  //   if (typeOfPlans == "Plano de Gestão" || typeOfPlans == "Plano Orçamentário") {
  //     if (diretrizes && eixo_tematico) {
  //       GetGuideLinesElaboration(router.query.id).then(res => {
  //         const data = res.data as any[];
  //         const arr: any[] = [];
  //         data.filter(item => item.eixoTematico == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));

  //         setData(arr);

  //       })
  //       setLabel('Diretriz')
  //       return 'Diretriz'
  //     }

  //     if (!diretrizes && eixo_tematico) {
  //       setLabel('Eixo Temático')

  //       GetThematicAxisElaboration(router.query.id).then(res => {
  //         const data = res.data as any[];
  //         const arr: any[] = [];
  //         data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
  //         setData(arr);
  //         const item = data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id);
  //         if (item.length == 0) {
  //           setIsEmpty(false);
  //         }
  //       });

  //       return 'Eixo Temático'
  //     }
  //   }

  //   if (typeOfPlans == "Plano Institucional") {
  //     setLabel('Eixo Temático')

  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       const data = res.data as any[];
  //       const arr: any[] = [];
  //       data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id).map(item => arr.push({ value: item.id, name: item.texto, objetivos: item.obejtivos }));
  //       setData(arr);
  //       const item = data.filter(item => item.id == eixos_tematicos[currentIndexEixo]?.id);

  //       if (item.length == 0) {
  //         setIsEmpty(false);
  //       }
  //     });
  //     return 'Eixo Temático'
  //   }
  // };

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split("/").includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes("aprovacao_planos")) {
  //     handleHierarchyAxis(completCode, zustandSelectedCardItem.title);
  //   }
  // }, [completCode, zustandSelectedCardItem, router, eixos_tematicos, currentIndexEixo]);

  // React.useEffect(() => {
  //   const d = data[page - 1] as any;
  //   clearErrors()
  //   if (d?.objetivos?.length > 0) {
  //     setValueObjectives('objetivos', d?.objetivos)
  //   } else {
  //     setValueObjectives('objetivos', [defaultValue])
  //   }
  // }, [data, page, completCode]);

  const value = {
    data,
    page,
    setCurrentIndexEixo,
    setPage,
    label,
    isEmpty,
    currentIndexEixo,
    removeObjectives,
    errosObjectives,
    watchObjectives,
    fieldsObjectives,
    appendObjectives,
    updateObjectives,
    controlObjectives,
    eixos_tematicos,
    setValueObjectives,
    registerObjectives,
    handleSubmitObjectives,
  } as IFormObjectives;

  return <ObjectivesContext.Provider value={value}>{children}</ObjectivesContext.Provider>;
};