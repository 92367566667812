import styled, { css } from "styled-components";

export const GlobalContainer = styled.main`
  width: 100%;
  margin-top: 100px;
  max-width: var(--max-width);
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Cor de fundo com opacidade */
  z-index: 999;
`;