import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";

interface IFormInitiatives {
  page: number;
  label: string;
  iniciativas: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removeInitiatives: UseFieldArrayRemove;
  fieldsInitiatives: Record<"id", string>[];
  errosInitiatives: FieldErrors<InputsInitiatives>;
  resetInitiatives: UseFormReset<InputsInitiatives>;
  watchInitiatives: UseFormWatch<InputsInitiatives>;
  controlInitiatives: Control<InputsInitiatives, any>;
  transformInitiativesDataReuse: (data: any[]) => any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setValueInitiatives: UseFormSetValue<InputsInitiatives>;
  registerInitiatives: UseFormRegister<InputsInitiatives>;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  updateInitiatives: UseFieldArrayUpdate<InputsInitiatives, "iniciativas">;
  appendInitiatives: UseFieldArrayAppend<InputsInitiatives, "iniciativas">;
  handleSubmitInitiatives: UseFormHandleSubmit<InputsInitiatives, undefined>;
}

export const InitiativesContext = React.createContext({} as IFormInitiatives);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsInitiatives = {
  iniciativas: any;
}

const SchemaInitiatives = Yup.object().shape({
  iniciativas: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string(),
      orgao: Yup.string(),
      // prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string(),
      publicoAlvo: Yup.string().required('Campo obrigatório')
    })
  ).required(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  // prazo: null,
  texto: '',
  territorio: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformInitiativesData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.iniciativasTerritorios.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.iniciativasTerritorios)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      // prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

const transformInitiativesDataReuse = (data: any[]) => {
  return data.map(item => {
    const tor = item?.iniciativasTerritorios.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.iniciativasTerritorios)

    return {
      tor,
      territorio,
      texto: item.texto || '',
      orgao: item.orgao?.id || '',
      setor: item.setor?.id || '',
      publicoAlvo: item.publicoAlvo || '',
      responsavel: item.responsavel || '',
      // prazo: item.data ? dayjs(item.data) : null,
    };
  });
};

export const InitiativesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetInitiatives,
    watch: watchInitiatives,
    control: controlInitiatives,
    register: registerInitiatives,
    clearErrors,
    setValue: setValueInitiatives,
    handleSubmit: handleSubmitInitiatives,
    formState: { errors: errosInitiatives } } = useForm<InputsInitiatives>({
      resolver: yupResolver(SchemaInitiatives),
      defaultValues: { iniciativas: [defaultValue] }
    });

  const { fields: fieldsInitiatives, append: appendInitiatives, update: updateInitiatives, remove: removeInitiatives } = useFieldArray({
    control: controlInitiatives,
    name: "iniciativas"
  });

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const { completCode } = usePreparationOfPlans();
  const [iniciativas, setIniciativas] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const { GetThematicAxisElaboration, GetMacroactionsEixoElaborations, GetGoalsEixoElaborations } = new ElaborationService();

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
  //     const metas = completCode.find(item => item.code == 'metas' && item.skipped == false);
  //     const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped == false);

  //     if (macroacoes != undefined && metas != undefined) {
  //       GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setIniciativas(res.data);
  //         setLabel('Macroação')
  //       });
  //     }

  //     if (macroacoes != undefined && metas == undefined) {
  //       GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setIniciativas(res.data);
  //         setLabel('Macroação')
  //       });
  //     }

  //     if (macroacoes == undefined && metas != undefined) {
  //       GetGoalsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setIniciativas(res.data);
  //         setLabel('Meta')
  //       });
  //     }
  //   }
  // }, [completCode, router.pathname, router.query, eixos_tematicos, currentIndexEixo]);

  // React.useEffect(() => {
  //   const data = iniciativas[page - 1];
  //   clearErrors()
  //   if (data?.iniciativas?.length > 0) {
  //     setValueInitiatives('iniciativas', transformInitiativesData(data.iniciativas))
  //   } else {
  //     setValueInitiatives('iniciativas', [defaultValue])
  //   }
  // }, [iniciativas, page, completCode]);


  const value = {
    page,
    label,
    setPage,
    iniciativas,
    eixos_tematicos,
    currentIndexEixo,
    errosInitiatives,
    watchInitiatives,
    resetInitiatives,
    fieldsInitiatives,
    updateInitiatives,
    removeInitiatives,
    appendInitiatives,
    controlInitiatives,
    setCurrentIndexEixo,
    setValueInitiatives,
    registerInitiatives,
    handleSubmitInitiatives,
    transformInitiativesDataReuse,
  } as IFormInitiatives;

  return <InitiativesContext.Provider value={value}>{children}</InitiativesContext.Provider>;
};