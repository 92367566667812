export const dark = {
  colors: {
    text: '#FFFFFF',
    button: '#FFFFFF',
    primary: '#004A80',
    sub_nav: '#004A80',
    bg_words:"#FFFFFF",
    card_body: '#004A80',
    secundary: '#FFFFFF',
    background: '#004A80',
    home_card_bg:'#004A80',
    button_text: '#004A80',
    footer_text: "#FFFFFF",
    card_title:  '#FBFBFB',
    border_btn_theme:'#000',
    home_diagonal:'#004A80',
    bg_info_plans: "#FFFFFF",
    order_buttons: '#FFFFFF',
    home_card_border:"#FFFFFF",
    home_card_bg_bage:"#FFFFFF",
    footer_text_last: "#FFFFFF",
    bg_info_plans_gray:"#FFFFFF",
    home_card_bg_white:"#FFFFFF",
    footer_text_middle: "#FFFFFF",
    bg_info_plans_light: "#FFFFFF",
    select_open_buttons_text: "#FFFFFF",
    select_close_buttons_text: "#FFFFFF",
    select_open_filter_buttons: "#0B0C0C",
    select_close_filter_buttons: "#0B0C0C",
    description_text_dashboard: '#FFFF',
  }
};
