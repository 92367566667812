import styled, { css } from "styled-components";

interface IProps {
  len: number;
}

export const GradientContainer = styled.div`
  width: 330px;
  height: auto;
  border-radius: 5px;
  transition: all 0.5s;
  box-sizing: content-box;

  padding: 4px 4px 5px 4px;
  box-shadow: 10px 11px 21px 0px rgba(0, 0, 0, 0.16);

  background: linear-gradient(#ffffff, #ffffff) 80% 50% / calc(100% - 20px)
      calc(100% - 20px) no-repeat,
    linear-gradient(290deg, transparent 0%, #d2effa 100%);

  /* &:hover {
    transform: scale(1.02);
  } */

  @media (max-width: 847px) {
    width: auto;
  }

  @media screen and (min-width: 583px) and (max-width: 820px) {
    width: auto;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 322px;
  max-width: 530px;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.card_body};

  svg {
    width: 60px;
    height: 60px;
  }

  .wrapper_content {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 117px;
    margin: -0.5rem 2rem 0 2rem;
  }

  p {
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    line-height: 130%;
    font-style: normal;
    color: ${({ theme }) => theme.colors.text};
  }
`;

export const Title = styled.div<IProps>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 20px 0 20px;
  justify-content: center;
  background: ${({ theme }) => theme.colors.card_title};
  min-height: 95px;

  p {
    color: #004a80 !important;
    font-weight: 700 !important;
    line-height: 130%;
    font-style: normal;
    text-align: center;
  }

  ${({ len }) =>
    len > 100 &&
    css`
      p {
        font-size: 16px;
        line-height: 100%;
      }
    `};

  ${({ len }) =>
    len > 100
      ? css`
          p {
            font-size: 16px;
            line-height: 100%;
          }
        `
      : css`
          p {
            font-size: 16px;
          }
        `};
`;

export const Button = styled.div`
  width: auto;
  display: flex;
  gap: 10px;
  padding: 8px 16px;
  border-radius: 20px;
  background: #004a80;
  align-items: center;
  margin: 1rem 0 2rem 0;
  justify-content: center;

  transition: background-color 0.3s;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  &:hover {
    cursor: pointer;
    background-color: #89e2fa;
  }

  h1 {
    color: #ffff;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
    line-height: 25px;
    letter-spacing: 0em;
  }
`;

export const WrapperResults = styled.div`
  width: 100%;

  p {
    margin: 0 2rem;
    color: #00c0f3;
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
    font-style: normal;
  }
`;

interface IStatusWrapper {
  status: 1 | 0;
}

export const StatusWrapper = styled.div<IStatusWrapper>`
  display: flex;
  width: 100%;
  justify-content: end;

  .status_item {
    display: flex;
    padding: 3px 6px;
    margin: 0.5rem 1rem 0 0;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 100px;

    p {
      color: var(--primary-white, #000);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 15.6px */
    }
    ${({ status }) =>
      status === 1
        ? css`
            background: var(--secondary-green, #AAE0AB);
          `
        : css`
            background: var(--secondary-red, #FA9F8A);
          `}
  }
`;
