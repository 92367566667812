const removeAccent = (text: string) => {
  return text?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const parseToSnakeCase = (text: string) =>  {
  const stringWithouAccent = removeAccent(text);
  const newStr = stringWithouAccent?.toLowerCase()
  .replace(/[\s()-]+/g, '_')
  .replace(/[^a-z0-9_]/g, '');

  return newStr;
};