import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import dayjs from "dayjs";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import React, { useState, createContext, Dispatch, SetStateAction, ReactNode } from "react";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";

interface IFormGoals {
  page: number;
  objetivos: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removeGoals: UseFieldArrayRemove;
  controlGoals: Control<InputsGoals>;
  fieldsGoals: Record<"id", string>[];
  errosGoals: FieldErrors<InputsGoals>;
  resetGoals: UseFormReset<InputsGoals>;
  watchGoals: UseFormWatch<InputsGoals>;
  setPage: Dispatch<SetStateAction<number>>;
  transformMetaDataReuse: (item: any) => any;
  setValueGoals: UseFormSetValue<InputsGoals>;
  registerGoals: UseFormRegister<InputsGoals>;
  handleSubmitGoals: UseFormHandleSubmit<InputsGoals>;
  updateGoals: UseFieldArrayUpdate<InputsGoals, "meta">;
  appendGoals: UseFieldArrayAppend<InputsGoals, "meta">;
  setCurrentIndexEixo: Dispatch<SetStateAction<number>>;
}

export const GoalsContext = createContext({} as IFormGoals);

interface IFormsPreparationOfPlansProps {
  children: ReactNode;
}

type InputsGoals = {
  meta: any;
}

const SchemaGoals = Yup.object().shape({
  meta: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      setor: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      indicadores: Yup.array().of(
        Yup.object().shape({
          fonte: Yup.string().required("Campo obrigatório"),
          indicador: Yup.string().required('Campo obrigatório'),
          polaridade: Yup.string().required('Campo obrigatório'),
          unidadeMedida: Yup.string().required('Campo obrigatório'),
          formulaCalculo: Yup.string().required('Campo obrigatório'),
          planejados: Yup.array().of(
            Yup.object().shape({
              prazo: Yup.date().nonNullable().required('Campo obrigatório'),
              planejado: Yup.string().required('Campo obrigatório')
            })
          )
        })
      )
    })
  ).required(''),
});

const defaultValue = {
  id: '',
  tor: [],
  texto: '',
  orgao: '',
  setor: '',
  prazo: null,
  territorio: '',
  publicoAlvo: '',
  responsavel: '',
  indicadores: [
    {
      id: '',
      fonte: '',
      indicador: '',
      polaridade: '',
      unidadeMedida: '',
      formulaCalculo: '',
      planejados: [
        {
          id: '',
          prazo: null,
          planejado: '',
        }
      ]
    }
  ]
}

const transformMetaData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.metasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.metasTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item.data ? dayjs(item.data) : null,
      indicadores: item?.indicadores?.map((indicador: any) => ({
        id: indicador?.id,
        fonte: indicador?.fonte || '',
        indicador: indicador?.indicador || '',
        polaridade: indicador?.polaridade || '',
        unidadeMedida: indicador?.unidadeMedida || '',
        formulaCalculo: indicador?.formulaCalculo || '',
        planejados: indicador?.values?.map((planejado: any) => ({
          id: planejado?.id,
          prazo: planejado?.data ? dayjs(planejado?.data) : null,
          planejado: planejado?.planejado || ''
        }))
      }))
    };
  });
};

const transformMetaDataReuse = (item: any) => {

  let tor = [] as any[];
  
  if (item?.metasTerritorio.length > 0) {
    tor = item?.metasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);
  }


  const territorio = analisarTerritorioArray(item?.metasTerritorio)

  return {
    tor,
    territorio,
    texto: item?.texto || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    publicoAlvo: item?.publicoAlvo || '',
    responsavel: item?.responsavel || '',
    prazo: item.data ? dayjs(item.data) : null,
    indicadores: item?.indicadores?.map((indicador: any) => ({
      id: indicador?.id,
      fonte: indicador?.fonte || '',
      indicador: indicador?.indicador || '',
      polaridade: indicador?.polaridade || '',
      unidadeMedida: indicador?.unidadeMedida || '',
      formulaCalculo: indicador?.formulaCalculo || '',
      planejados: indicador?.values?.map((planejado: any) => ({
        id: planejado?.id,
        prazo: planejado?.data ? dayjs(planejado?.data) : null,
        planejado: planejado?.planejado || ''
      }))
    }))
  };
};

export const GoalsContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetGoals,
    watch: watchGoals,
    control: controlGoals,
    register: registerGoals,
    setValue: setValueGoals,
    clearErrors,
    handleSubmit: handleSubmitGoals,
    formState: { errors: errosGoals }
  } = useForm<InputsGoals>({
    resolver: yupResolver(SchemaGoals),
    defaultValues: { meta: [defaultValue] }
  });

  const { fields: fieldsGoals, append: appendGoals, update: updateGoals, remove: removeGoals } = useFieldArray({
    control: controlGoals,
    name: "meta"
  });

  const router = useRouter();
  const routerId = router.query.id;
  const { completCode } = usePreparationOfPlans();
  const { GetThematicAxisElaboration, GetObjectivesEixoElaboration } = new ElaborationService();

  const [page, setPage] = useState(1);
  const [objetivos, setObjetivos] = useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = useState(0);
  const [eixos_tematicos, setEixosTematicos] = useState<any[]>([]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes("elaboracao_planos") || router.pathname.split('/').includes("aprovacao_planos")) {
      GetThematicAxisElaboration(routerId).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if (
      (router.query.id && router.pathname.split('/').includes("elaboracao_planos") && eixos_tematicos.length > 0 || router.pathname.split('/').includes("aprovacao_planos") && eixos_tematicos.length > 0)
      || (router.pathname.split('/').includes("aprovacao_planos") && eixos_tematicos.length > 0)) {
      GetObjectivesEixoElaboration(routerId, eixos_tematicos[currentIndexEixo].id).then((res: any) => {
        setObjetivos(res.data);
      });
    }
  }, [currentIndexEixo, router.query, eixos_tematicos, router.pathname, page, completCode]);

  React.useEffect(() => {
    const objetivo = objetivos[page - 1];
    clearErrors();
    if (objetivo?.metas?.length > 0) {
      setValueGoals('meta', transformMetaData(objetivo.metas))
    } else {
      setValueGoals('meta', [defaultValue])
    }
  }, [objetivos, page, completCode]);

  const value = {
    page,
    setPage,
    objetivos,
    watchGoals,
    resetGoals,
    errosGoals,
    fieldsGoals,
    removeGoals,
    updateGoals,
    appendGoals,
    controlGoals,
    setValueGoals,
    registerGoals,
    eixos_tematicos,
    currentIndexEixo,
    transformMetaData,
    handleSubmitGoals,
    setCurrentIndexEixo,
    transformMetaDataReuse,
  };

  return <GoalsContext.Provider value={value}>{children}</GoalsContext.Provider>;
};
