import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
} from "react-hook-form";
import _ from 'lodash';
import dayjs from "dayjs";
import React from "react";
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray } from "@/components/global/elaboracao_planos/Forms/utils";

interface IFormProjects {
  page: number;
  label: string;
  projetos: any[];
  eixos_tematicos: any[];
  currentIndexEixo: number;
  removeProjects: UseFieldArrayRemove;
  fieldsProjects: Record<"id", string>[];
  errosProjects: FieldErrors<InputsProjects>;
  resetProjects: UseFormReset<InputsProjects>;
  watchProjects: UseFormWatch<InputsProjects>;
  controlProjects: Control<InputsProjects, any>;
  transformProjectsDataReuse: (item: any) => any;
  setValueProjects: UseFormSetValue<InputsProjects>;
  registerProjects: UseFormRegister<InputsProjects>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  updateProjects: UseFieldArrayUpdate<InputsProjects, "projetos">;
  appendProjects: UseFieldArrayAppend<InputsProjects, "projetos">;
  setCurrentIndexEixo: React.Dispatch<React.SetStateAction<number>>;
  handleSubmitProjects: UseFormHandleSubmit<InputsProjects, undefined>;
}

export const ProjectsContext = React.createContext({} as IFormProjects);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsProjects = {
  projetos: any;
  is_optional?: boolean;

}

const SchemaProjects = Yup.object().shape({
  projetos: Yup.array().of(
    Yup.object().shape({
      setor: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      texto: Yup.string().required('Campo obrigatório'),
      valor: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      description: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      publicoAlvo: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
    })
  ).required(''),
  is_optional: Yup.boolean(),
});

const defaultValue = {
  id: '',
  tor: [],
  setor: '',
  orgao: '',
  texto: '',
  valor: '',
  prazo: null,
  territorio: '',
  description: '',
  responsavel: '',
  publicoAlvo: '',
}

const transformProjectsData = (data: any[]) => {
  return data.map(item => {
    const tor = item?.ptojetosTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.ptojetosTerritorio)

    return {
      tor,
      territorio,
      id: item?.id,
      valor: item?.valor,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      description: item?.description,
      publicoAlvo: item?.publicoAlvo || '',
      responsavel: item?.responsavel || '',
      prazo: item?.data ? dayjs(item?.data) : null,
    };
  });
};

const transformProjectsDataReuse = (item: any) => {
  const tor = item?.ptojetosTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.ptojetosTerritorio)

  return {
    tor,
    territorio,
    valor: item?.valor,
    texto: item?.texto || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    description: item?.description,
    publicoAlvo: item?.publicoAlvo || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
  };
};

export const ProjectsContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetProjects,
    watch: watchProjects,
    control: controlProjects,
    register: registerProjects,
    setValue: setValueProjects,
    clearErrors,
    handleSubmit: handleSubmitProjects,
    formState: { errors: errosProjects } } = useForm<InputsProjects>({
      resolver: yupResolver(SchemaProjects),
      defaultValues: { projetos: [defaultValue] }
    });

  const { fields: fieldsProjects, append: appendProjects, update: updateProjects, remove: removeProjects } = useFieldArray({
    control: controlProjects,
    name: "projetos"
  });

  const router = useRouter();
  const routerId = router.query.id;
  const { completCode } = usePreparationOfPlans();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { 
    GetThematicAxisElaboration, 
    GetProgramsEixoElaborations, 
    GetInitiativesEixoElaborations, 
    GetMacroactionsEixoElaborations, 
  } = new ElaborationService();

  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [projetos, setProjetos] = React.useState<any[]>([]);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
  //     GetThematicAxisElaboration(router.query.id).then(res => {
  //       setEixosTematicos(res.data);
  //     });
  //   }
  // }, [router.query, router.pathname, completCode]);

  // React.useEffect(() => {
  //   if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
  //     const programas = completCode.find(item => item.code == 'programas' && item.skipped === false);
  //     const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped === false);
  //     const iniciativas = completCode.find(item => item.code == 'iniciativas' && item.skipped === false);


  //     if (zustandSelectedCardItem.title == 'Plano Orçamentário') {
  //       GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //         setProjetos(res.data);
  //         setLabel('Programas');
  //       });
  //     } else {
  //       if (programas !== undefined && macroacoes != undefined && iniciativas != undefined) {
  //         GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Programas');
  //         });
  //         return;
  //       }

  //       if (programas !== undefined && macroacoes != undefined && iniciativas == undefined) {
  //         GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Programas');
  //         });
  //         return;
  //       }
        

  //       if (programas !== undefined && macroacoes == undefined && iniciativas != undefined) {
  //         GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Programas');
  //         });
  //         return;
  //       }


  //       if (programas == undefined && macroacoes != undefined && iniciativas == undefined) {
  //         GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Macroação');
  //         });
  //         return;
  //       }

  //       if (programas == undefined && macroacoes == undefined && iniciativas != undefined) {
  //         GetInitiativesEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Iniciativas');
  //         })
  //       }

  //       if (programas == undefined && macroacoes != undefined && iniciativas != undefined) {
  //         GetInitiativesEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
  //           setProjetos(res.data);
  //           setLabel('Iniciativas');
  //         })
  //       }
  //     }

  //   }
  // }, [completCode, routerId, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
  //     const data = projetos[page - 1];
  //     clearErrors()
  //     if (data?.projetos?.length > 0) {
  //       setValueProjects('projetos', transformProjectsData(data.projetos));
  //     } else {
  //       setValueProjects('projetos', [defaultValue])
  //     }
  //   }
  // }, [router.query.id, page, projetos, router.pathname, completCode])

  const value = {
    page,
    label,
    setPage,
    projetos,
    errosProjects,
    watchProjects,
    resetProjects,
    fieldsProjects,
    updateProjects,
    removeProjects,
    appendProjects,
    controlProjects,
    eixos_tematicos,
    currentIndexEixo,
    setValueProjects,
    registerProjects,
    setCurrentIndexEixo,
    handleSubmitProjects,
    transformProjectsDataReuse,
  } as IFormProjects;

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
};