import { Container } from "./styles";
import { useTheme } from "styled-components";

interface IProps {
  onClick: () => void;
}

export function ButtonPlus({ onClick }: IProps) {

  return (
    <Container
      onClick={onClick}
      width="43"
      height="15"
      viewBox="0 0 23 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.94873 14.2082H3.2679L4.48498 10.7773H9.89956L11.1071 14.2082H13.4262L8.3854 0.791504H5.98956L0.94873 14.2082ZM5.18456 8.83192L7.12998 
        3.28317H7.24498L9.1904 8.83192H5.18456ZM19.1666 6.5415H22.0416V8.45817H19.1666V11.3332H17.25V8.45817H14.375V6.5415H17.25V3.6665H19.1666V6.5415Z"
        fill="white"
      />
    </Container>
  );
}
