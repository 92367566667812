import Cookies from 'js-cookie';
import { useState } from 'react';

const useCookieStorage = (key: any, initialValue: any) => {

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = Cookies.get(key);
      if (item !== undefined && item !== 'undefined' && item !== '') {
        return JSON.parse(item);
      } else {
        return initialValue;
      }
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      Cookies.set(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };


  return [storedValue, setValue];
}

export default useCookieStorage;