import React from "react"
import * as Yup from 'yup';
import { Grid } from '@mui/material'
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { FileFieldElaboration } from "../../Inputs/FileInput";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { transformDiagnosisLayer } from "@/utils/elaborationParseData";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { isNumber } from "lodash";

type InputsScenario = {
  texto: any;
  file_pdf?: any;
  file_word?: any;
  is_optional?: boolean;
  is_complete_pdf?: boolean;
};

const defaultValue = {
  id: '',
  texto: ''
}

const SchemaScenario = Yup.object().shape({
  is_optional: Yup.boolean(),
  is_complete_pdf: Yup.boolean(),
  texto: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
    })
  ),
  file_pdf: Yup.mixed(),
  file_word: Yup.mixed(),
}).required('A síntese é obrigatória');

export const SummaryScenarioForm: React.FC = () => {
  const {
    watch: watchScenario,
    control: controlScenario,
    register: registerScenario,
    setValue: setValueScenario,
    handleSubmit: handleSubmitScenario,
    formState: { errors: errosScenario } } = useForm<InputsScenario>({
      resolver: yupResolver(SchemaScenario as any) as any,
      defaultValues: { texto: [defaultValue] }
    });

  const { append: appendScenario, remove: removeScenario } = useFieldArray({
    control: controlScenario,
    name: "texto"
  });

  const fields = watchScenario('texto');
  const filePdf = watchScenario('file_pdf');
  const fileWord = watchScenario('file_word');
  const is_complete_pdf = watchScenario('is_complete_pdf') as unknown as string;
  const isOptionalScenario = watchScenario("is_optional") as unknown as string;

  const {
    itemsMenu,
    completCode,
    setNavigable,
    selectedItemsMenu,
    setDefaultItemMenuId,
    handleFetchProgressCodePlanId,
  } = usePreparationOfPlans();

  const router = useRouter();
  const { planId } = useTipeOfFlow();
  const { tipo: tipoFluxo } = useTipeOfFlow();
  const [deleteId, setDeleId] = React.useState(0);
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [deleteIndex, setDeleIndex] = React.useState(null);
  const [disablePdf, setDisablePdf] = React.useState(false);
  const [reutilizar, setReutilizar] = React.useState(false);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration()
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { SummaryScenarioElaboration, GetSummaryScenarioElaboration, DeleteSummaryScenarioElaborations, PostProgressCode } = new ElaborationService();

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.texto?.find((item: any) => item && item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  React.useEffect(() => {
    setLoading(true)
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos')) {
      GetSummaryScenarioElaboration(Number(router.query.id)).then((res: any) => {
        const result = res.data.sintese.map((item: any) => {
          return {
            ...item,
            texto: item.texto.replace("descricao: ", "")
          };
        });

        if (result.length > 0) {
          setValueScenario('texto', result);
          setValueScenario("file_pdf", res.data.filePdf);
          setValueScenario("file_word", res.data.fileWord);
        } else {
          setValueScenario('texto', [defaultValue]);
        }
      }).finally(() => setLoading(false))
    }
  }, [router.query, router.pathname, completCode])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'sintese_cenario');

    if (code != undefined) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode])

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'sintese_cenario' && item.skipped == false);
    const codePdf = completCode.find(item => item.code == 'sintese_cenario' && item.isPdf == true);
    if (code?.code?.length > 0) {
      setValueScenario('is_optional', true)
    }
    
    if (codePdf?.isPdf) {
      setValueScenario("is_complete_pdf", false);
      setDisablePdf(true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title != 'Orçamentário' && zustandSelectedCardItem.title != 'Plano Setorial') {
      setValueScenario('is_optional', true)
    }
  }, [zustandSelectedCardItem])

  React.useEffect(() => {
    if (router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos') && planId && planId != '0') {
      GetSummaryScenarioElaboration(Number(planId)).then((res: any) => {
        const result = res.data.map((item: any) => {
          return {
            ...item,
            texto: item.texto.replace("descricao: ", "")
          };
        });
        setData(result)
      })
    }
  }, [router.pathname, planId])

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  const onSubmit: SubmitHandler<any> = (data, event: any) => {
    const { texto, file_pdf, file_word } = data;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    const formData = new FormData();

    const parsedSummaryData = texto.map((item: any) => {
      const newItem = { ...item };

    
      if (newItem.texto) {
        newItem.texto = `${newItem.texto}`;
      }
    
      if (isNumber(newItem.id)) {
        return newItem;
      } else {
        const { id, ...res } = newItem;
        return res;
      }
    });


    formData.append('itens', JSON.stringify(parsedSummaryData));

    if (file_pdf?.length > 0 && typeof filePdf != 'string') {
      if (file_pdf) formData.append('file_pdf', file_pdf[0]);
    }

    if (file_word?.length > 0 && typeof file_word != 'string'){
      if (file_word) formData.append('file_word', file_word[0]);
    }
    
    SummaryScenarioElaboration({
      data: formData,
      id: Number(router.query.id)
    })
      .then(() => {
        toggleSuccessSnackbar();

        const validatePdf = () => {
          if (typeof is_complete_pdf == 'string') {
            return is_complete_pdf == 'true' ? false : true
          } else {
            return is_complete_pdf == true ? false : true
          }
        };

        PostProgressCode({ code: 'sintese_cenario', skipped: true, pdf: validatePdf(), isPdf: validatePdf() }, router.query.id)

        if (event.nativeEvent.submitter.name === 'next_step') {
          setNavigable(true);

          router.push('/dashboard/elaboracao_planos/' + router.query.id);

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id === selectedItemsMenu?.id).id + 1;
          setDefaultItemMenuId(planId);
          handleFetchProgressCodePlanId(router.query.id);
        } else {
          setNavigable(false);

          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          const planId = plan[selectedItemsMenu?.layer as string]
            .find((item: any) => item.id === selectedItemsMenu?.id).id;
          setDefaultItemMenuId(planId);

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
          handleFetchProgressCodePlanId(router.query.id);
        }

        setTimeout(() => toggleSuccessSnackbar(), 3000);
        setDisableSubmit(false);
      })
      .catch(() => {
        toggleErrorSnackbar()
        setDisableSubmit(false);
      })
  };

  const FileInputs = React.useCallback(() => {
    if (is_complete_pdf == undefined || is_complete_pdf == 'true') return null;


    const handleFileValidate = (file: any) => {
      if (typeof file == "object") {
        return file.length > 0 ? file : undefined;
      } else {
        return file?.length > 0 && !file?.split('_').includes('null') ? file : undefined
      }
    }


    return (
      <>
        <Grid item xs={6}>
          <FileFieldElaboration
            accept=".pdf"
            name="file_pdf"
            required={false}
            errors={errosScenario}
            planId={router.query.id}
            context='sintese_cenario'
            register={registerScenario}
            label="Carregar PDF do cenário completo"
            downloadName={handleFileValidate(filePdf)}
          />
        </Grid>
        <Grid item xs={6}>
          <FileFieldElaboration
            accept=".docx"
            name="file_word"
            required={false}
            errors={errosScenario}
            planId={router.query.id}
            context='sintese_cenario'
            register={registerScenario}
            label="Carregar Word de concepção do cenário"
            downloadName={handleFileValidate(fileWord)}
          />
        </Grid>
      </>
    );
  }, [is_complete_pdf, filePdf, fileWord, router.query]);

  const Fields = React.useCallback(() => {
    if (isOptionalScenario == 'false' || isOptionalScenario == undefined) return null;
    if (Boolean(isOptionalScenario) == false || isOptionalScenario == undefined) return null;

    return (
      <>
        {fields.map((field: any, index: any) => {
          const randomId = Math.random();
          const texto = watchScenario(`texto.${index}.texto`)
          return (
            <>
              <Grid display='flex' alignItems='center' gap='8px' item xs={12} key={randomId}>
                <input
                  type="hidden"
                  defaultValue={field.id}
                  {...registerScenario(`texto.${index}.id`, { value: field.id })}
                />
                <TextFieldElaboration
                  rows={2}
                  required={true}
                  commentTittle={texto}
                  multiline={true}
                  errors={errosScenario}
                  register={registerScenario}
                  name={`texto.${index}.texto`}
                  label={`Digite a síntese do cenário ${index + 1}`}
                  placeholder={`Descrição da síntese do cenário ${index + 1}`}
                />

                {index != 0 && !preview && (
                  <div
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleIndex(index);
                      setDeleId(field.id)
                    }}
                    style={{ marginTop: '1rem', display: 'flex', alignItems: 'center' }}
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
                    </svg>
                  </div>
                )}
              </Grid>

              <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={() => {

                if (deleteIndex != null) {
                  removeScenario(deleteIndex)
                }

                if (deleteId != 0) {
                  DeleteSummaryScenarioElaborations(deleteId);
                  setDeleId(0)
                }
                setOpenDeleteModal(false);
              }} />
            </>
          )
        })}
        <>
          <div className="error_wrapper" style={{ marginLeft: '1rem' }}>
            <span>{getMessageErrorByNameRef(errosScenario, 'texto')}</span>
          </div>
          <Grid item xs={6}>
            <div className="add_line_wrapper">
              <p onClick={() => appendScenario({ id: '', texto: '' })}>
                {`+ Adicionar linha`}
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <RadioCheckedElaboration
              required={false}
              name="is_complete_pdf"
              errors={errosScenario}
              disabled={disablePdf}
              control={controlScenario}
              label="O PDF do Plano contém o Diagnóstico completo?"
              data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
            />
          </Grid>
          {FileInputs()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
        </>
      </>
    );
  }, [isOptionalScenario, fields, removeScenario, appendScenario, is_complete_pdf, openDeleteModal, errosScenario, disablePdf]);

  const ReplaceButton = React.useCallback(() => {
    if (tipoFluxo != 'substituir') return null;
    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, reutilizar, openDisclaimer])

  const CheckBoxField = React.useCallback(() => {
    if (zustandSelectedCardItem.title != 'Plano de Estado') {

      const defaultValue = () => {
        if (typeof isOptionalScenario == 'string') {
          return isOptionalScenario == 'true' ? 'true' : 'false'
        } else {
          return Boolean(isOptionalScenario) == true ? 'true' : 'false'
        }
      }

      return (
        <Grid item xs={12}>
          <RadioCheckedElaboration
            required={false}
            name="is_optional"
            disabled={disable}
            tooltip="lorem ipsum"
            errors={errosScenario}
            control={controlScenario}
            defaultValue={defaultValue()}
            data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
            label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
          />
        </Grid>
      );
    }
  }, [zustandSelectedCardItem, disable, isOptionalScenario]);

  const SubmitButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof isOptionalScenario == 'string') {
        return isOptionalScenario == 'true' ? 'true' : 'false'
      } else {
        return Boolean(isOptionalScenario) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof isOptionalScenario == 'string') {
        return isOptionalScenario == 'false' || isOptionalScenario == undefined;
      } else {
        return Boolean(isOptionalScenario) == false || isOptionalScenario == undefined;
      }
    }

    return (
      <ActionButtonElaborations disable={disabeSubmit} isOptional={opitional()} layer_indicator={false} onClick={() => {
        if (optionalV2()) {
          setNavigable(true)

          router.push('/dashboard/elaboracao_planos/' + router.query.id);
          PostProgressCode({ code: 'sintese_cenario', skipped: true }, router.query.id)
          const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
          if (plan && plan[selectedItemsMenu?.layer as string]) {
            const planId = plan[selectedItemsMenu?.layer as string]
              .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
            setDefaultItemMenuId(planId);
          }
        }
      }} />
    )
  }, [isOptionalScenario, disabeSubmit])

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitScenario(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          {CheckBoxField()}
          {ReplaceButton()}
          <Grid item xs={12}>
            <div style={{ height: '1px', background: '#B4B4B4' }}></div>
          </Grid>
          {Fields()}
          <Grid item xs={12}>
            {SubmitButton()}
          </Grid>
        </Grid>
        <ReutilizarElaborationDialog reuseData={data} title="Síntese do cenário" open={reutilizar} setOpen={() => setReutilizar(false)} setValue={(e) => {
          e.map((item: any, index: any) => setValueScenario(`texto.${index}.texto`, item.texto))
        }} />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
      </Container>
    </form>
  )
};