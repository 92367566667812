import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .list_icon_wrapper {
    background: red !important;
    display: flex;
    gap: 10px;
  }

  .title_wrapper_textfield {
    gap: 8px;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;

    label {
    }
  }
  
  .file-input__input {
    opacity: 0;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
  }

  .file-input__label {
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    font-size: 14px;
    color: #818181;
    font-weight: 600;
    border-radius: 5px;
    background: #FFF;
    padding: 10px 12px;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    border: 1px dashed #000;
  }

  .file-input__label_preview {
    width: 100%;
    font-size: 14px;
    color: #818181;
    font-weight: 600;
    border-radius: 5px;
    background: #FFF;
    padding: 10px 12px;
    border-radius: 4px;
    align-items: center;
    display: inline-flex;
    border: 1px dashed #000;
  }


  .file-input__label:hover {
    background-color: rgba(66, 69, 168, 0.25);
  }

  .file-input__label svg {
    margin-right: 4px;
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }
`;

export const ListIconWrapper = styled.div`
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
`;

interface ISvgProps {
  clicked: boolean;
}

export const SvgContainer = styled.div<ISvgProps>`
  padding: .5rem;
  ${({ clicked }) => clicked && css`
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
    radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
    radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
    radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
    linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
    linear-gradient(90deg, transparent 0%, #48abe0 41%);
  border-radius: 8px;
  padding: 9px;
  box-sizing: border-box;
  `}
`;