import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 2px red solids;

  .description_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .gif_wrapper {
    display: flex;
    background: #D9D9D9;
    justify-content: center;
    align-items: center;

    p {
      width: 70%;
      color: #000;
      font-family: "Open Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 52px */
    }
  }
`;