import { useTheme } from "@/services/zustand/theme";

export const SegurancaAlimentarPlansan = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 90H55V70C57.0833 70 58.8542 69.2708 60.3125 67.8125C61.7708 66.3542 62.5 64.5833 62.5 62.5V47.5H57.5V62.5H55V47.5H50V62.5H47.5V47.5H42.5V62.5C42.5 64.5833 43.2292 66.3542 44.6875 67.8125C46.1458 69.2708 47.9167 70 50 70V90ZM70 90H75V47.5C72.25 47.5 69.8958 48.4792 67.9375 50.4375C65.9792 52.3958 65 54.75 65 57.5V72.5H70V90ZM20 105V45L60 15L100 45V105H20ZM27.5 97.5H92.5V48.25L60 24.625L27.5 48.25V97.5Z"
        fill="url(#paint0_linear_1941_43909)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43909"
          x1="38.5"
          y1="28.5"
          x2="83.3054"
          y2="92.9569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
