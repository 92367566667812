import * as React from "react";

export const Svg_002 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={59}
    height={59}
    fill="none"
  >
    <path fill="url(#a)" d="M59 34.817V16.121L45.038 25.47 59 34.817Z" />
    <path
      fill="url(#b)"
      d="m41.932 27.55-8.399 5.623-8.398-5.623L8.066 38.978v3.92H59v-3.92L41.932 27.549Z"
    />
    <path fill="url(#c)" d="M8.066 16.121v18.696l13.962-9.348-13.962-9.348Z" />
    <path fill="url(#d)" d="M4.61 14.949H0v36.016h52.086v-4.61H4.609V14.95Z" />
    <path
      fill="url(#e)"
      d="M33.533 29.012 59 11.961V8.035H8.066v3.926l25.467 17.051Z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={48.267}
        x2={58.021}
        y1={18.926}
        y2={30.715}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={19.845}
        x2={22.779}
        y1={29.852}
        y2={45.61}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={11.295}
        x2={21.049}
        y1={18.926}
        y2={30.715}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={12.045}
        x2={25.868}
        y1={20.351}
        y2={52.704}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={19.845}
        x2={25.171}
        y1={11.181}
        y2={32.109}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)