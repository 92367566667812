import styled from 'styled-components';
import InputBase from '@mui/material/InputBase';
import { alpha, styled as MuiStyled } from '@mui/material/styles';

export const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    width: '100%',
    height: '15px',
    borderRadius: 4,
    color: '#ADB5BD',
    border: '1px solid',
    padding: '7px 12px',
    position: 'relative',
    borderColor: theme.palette.mode === 'light' ? '#DEE2E6' : '#2D3843',
    backgroundColor: theme.palette.mode === 'light' ? '#FFF' : '#1A2027',
    transition: theme.transitions.create([
      'box-shadow',
      'border-color',
      'background-color',
    ]),
    fontFamily: [
      "Inter",
      'Arial',
      'Roboto',
      '"Segoe UI"',
      'sans-serif',
      '-apple-system',
      '"Segoe UI Emoji"',
      '"Helvetica Neue"',
      '"Segoe UI Symbol"',
      'BlinkMacSystemFont',
      '"Apple Color Emoji"',
    ].join(','),
    '&:focus': {
      borderColor: theme.palette.primary.main,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    },
  },
}));

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title_wrapper_textfield {
    display: flex;
    align-items: center;
    margin-bottom: .3rem;

    label {
      color: #000;
      display: flex;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
      align-items: center;
      p {
        margin-right: .5rem;
      }

      span {
        margin-left: -.5rem;
      }
    }
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }

  select { &::placeholder {
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;