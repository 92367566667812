import React from "react"
import { InfoIncon } from "../info";
import { Container } from "./styled";
import { useRouter } from "next/router";
import { Tooltip } from '@mui/material';
import { ConsideracaoIcon } from "../consideracao";
import { Asynchronous } from "@/components/global/AutoComplete";
import { ConsiderationModal } from "../../Forms/ConsiderationModal";

interface Data {
  value: any,
  name: string
}

interface IProps {
  data: Data[];
  errors: any;
  name: string;
  register: any;
  setItem?: any;
  label: string;
  tooltip?: string;
  required: boolean;
  disabled?: boolean;
  placeholder: string;
  defaultValue?: string;
  commentTittle?: string
}

export const AsynchronousSelectField: React.FC<IProps> = ({
  data,
  name,
  label,
  errors,
  tooltip,
  setItem,
  required,
  register,
  placeholder,
  defaultValue,
  commentTittle,
  disabled = false,
  ...props
}) => {

  return (
    <Container>
      <div className="title_wrapper_textfield">
        <ToolTipWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          register={register}
          value={defaultValue}
          commentTittle={commentTittle}
        />
      </div>
      <div style={{ marginTop: 10 }} >
        <Asynchronous
          {...props}
          name={name}
          label={placeholder}
          items={data}
          setItem={setItem}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </div>
      <div className="error_wrapper">
        <span>{errors[name]?.message}</span>
      </div>
    </Container>
  )
}

type TooltipWrapperProps = {
  name?: string,
  register?: any,
  label?: string,
  value?: string,
  local?: string,
  tooltip?: string,
  required?: boolean,
  hiddeInput?: boolean,
  commentTittle?: string,
}

const ToolTipWrapper = ({
  name,
  value,
  label,
  tooltip,
  required,
  register,
  commentTittle,
}: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl &&
        <>
          {tooltip && (
            <Tooltip arrow title={tooltip}>
              <div>
                <InfoIncon />
              </div>
            </Tooltip>
          )}
        </>
      }
      {epl && (
        <CondiserationWrapper
          name={name}
          label={label}
          value={value}
          register={register}
          commentTittle={commentTittle}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  name,
  value,
  label,
  register,
  commentTittle,
}: {
  name?: string,
  label?: string,
  value?: string,
  register?: any,
  commentTittle?: string,
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        name={name}
        label={label}
        value={value}
        register={register}
        handleClose={handleClose}
        commentTittle={commentTittle}
      />
    </>
  )
}