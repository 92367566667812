import React from "react";

export function ParqueMunicipalMarapendi() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Camada 1"
    viewBox="0 0 60 60"
  >
    <defs>
      <linearGradient
        id="a"
        x1={24.96}
        x2={3.9}
        y1={53.23}
        y2={23.14}
        data-name="Gradiente sem nome 7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#23bcef" />
        <stop offset={1} stopColor="#004b80" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={52.05}
        x2={36.29}
        y1={49.95}
        y2={27.45}
        data-name="Gradiente sem nome 7"
      />
      <linearGradient
        xlinkHref="#a"
        id="c"
        x1={37.07}
        x2={23.13}
        y1={30.05}
        y2={10.14}
        data-name="Gradiente sem nome 7"
      />
    </defs>
    <path
      d="M26.47 44.75c-.21-.84-.91-1.48-1.77-1.62l-2.67-10.12a.893.893 0 0 0-.42-.55l-3.22-1.9c-.72-.43-1.58-.55-2.39-.34h-.01l-2.34-9.06a2.863 2.863 0 0 0-3.48-2.05l-.28.07c-1.3.33-2.19 1.52-2.14 2.85l.32 10.51c.04 1.24.37 2.46.96 3.56l3.65 6.74.83 3.2c-.68.54-.98 1.43-.76 2.27l1.47 5.67c.13.49.62.78 1.11.65l11.98-3.1c.49-.13.78-.62.65-1.11l-1.47-5.67Zm-15.86-9.52c-.46-.84-.71-1.78-.74-2.74l-.32-10.51c-.02-.48.31-.92.78-1.03l.28-.07c.55-.14 1.12.19 1.26.74l2.49 9.64c-.11.13-.21.27-.3.41a3.16 3.16 0 0 0 1.12 4.32l2.36 1.39c.43.27.99.14 1.26-.29a.91.91 0 0 0-.29-1.26c-.01 0-.03-.02-.04-.03l-2.36-1.39c-.63-.37-.85-1.19-.47-1.83.37-.63 1.19-.85 1.83-.47l2.9 1.71 2.52 9.56-7.69 1.99-.81-3.11c-.02-.07-.05-.14-.08-.21l-3.7-6.83Zm5.13 17.41-1.24-4.79a.4.4 0 0 1 .28-.48l9.46-2.45s.07-.01.1-.01c.07 0 .14.02.2.05a.4.4 0 0 1 .18.24l1.24 4.79-10.21 2.64Z"
      style={{
        fill: "url(#a)",
        strokeWidth: 0,
      }}
    />
    <path
      d="m50.25 19.18-.29-.08c-1.53-.39-3.08.53-3.48 2.05l-2.34 9.06h-.01a3.08 3.08 0 0 0-2.39.33l-3.22 1.9c-.21.12-.36.32-.42.55l-2.67 10.12c-.86.14-1.55.78-1.77 1.62l-1.47 5.67c-.13.49.17.98.65 1.11l11.98 3.1c.49.13.98-.17 1.11-.65l1.47-5.67c.22-.84-.08-1.73-.76-2.27l.83-3.2 3.65-6.74c.59-1.09.92-2.31.96-3.56l.32-10.51c.05-1.34-.84-2.52-2.14-2.85Zm-4.63 28.67-1.24 4.79L34.17 50l1.24-4.79c.05-.21.27-.33.48-.28l9.46 2.45c.21.05.33.27.28.47Zm4.63-15.36c-.03.96-.28 1.9-.74 2.74l-3.7 6.83a.76.76 0 0 0-.08.21l-.8 3.11-7.69-1.99 2.52-9.56 2.9-1.71c.63-.37 1.45-.16 1.83.47s.16 1.45-.47 1.83l-2.36 1.39c-.44.24-.6.8-.36 1.24.24.44.8.6 1.24.36.01 0 .03-.02.04-.03l2.36-1.39c1.5-.88 2-2.82 1.12-4.32-.09-.15-.19-.28-.3-.41l2.49-9.64c.14-.55.71-.89 1.26-.74l.29.07c.47.12.79.55.78 1.03l-.32 10.51Z"
      style={{
        fill: "url(#b)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M40.78 27.14c2.42-2.75 3.48-6.44 2.9-10.05 0 0 0-.02-.01-.03-.02-.06-.04-.13-.07-.19a.72.72 0 0 0-.47-.46.442.442 0 0 0-.16-.06c-.01 0-.02 0-.03-.01-2.54-.38-5.13.01-7.44 1.12.17-.88.24-1.79.19-2.69-.24-3.65-2.1-7.01-5.06-9.16 0 0-.02 0-.03-.01-.06-.03-.12-.06-.18-.08-.05-.02-.09-.04-.14-.05h-.35c-.05.01-.11.03-.16.06-.06.02-.12.05-.17.08 0 0-.02 0-.03.01a12.436 12.436 0 0 0-5.05 9.16c-.04.92.03 1.84.21 2.74a12.776 12.776 0 0 0-7.55-1.17c-.01 0-.02 0-.03.01-.05.01-.1.03-.15.06-.11.03-.21.09-.28.18-.05.04-.1.09-.14.14-.02.04-.05.09-.06.14a.62.62 0 0 0-.07.19s0 .02-.01.03c-.58 3.62.49 7.3 2.9 10.06 2.37 2.5 5.68 3.89 9.12 3.82.54 0 1.07-.03 1.6-.1.53.07 1.06.11 1.6.1 3.44.07 6.76-1.31 9.13-3.82Zm1.19-9.08c.27 2.85-.65 5.69-2.53 7.86-1.88 2.01-4.5 3.17-7.25 3.22l4.57-4.63c.35-.36.35-.94 0-1.29s-.94-.35-1.29 0l-4.42 4.48c-.01-.26-.03-.54-.06-.83.25-2.4 1.36-4.63 3.13-6.27 2.16-1.9 5.01-2.81 7.87-2.53Zm-15.63-3.2c.19-2.86 1.56-5.52 3.77-7.34a10.33 10.33 0 0 1 3.76 7.34c.07 1.43-.18 2.86-.74 4.17-.08.07-.17.13-.25.21-.73.67-1.37 1.44-1.9 2.28v-5.76c.01-.5-.4-.91-.9-.91s-.91.41-.91.91v5.77a11.12 11.12 0 0 0-1.92-2.29c-.02-.02-.05-.04-.07-.06a9.605 9.605 0 0 1-.83-4.32Zm-8.19 3.2c2.86-.28 5.71.64 7.87 2.53a9.951 9.951 0 0 1 3.13 6.27c-.03.29-.05.57-.06.83l-4.42-4.48c-.35-.36-.93-.36-1.29 0s-.36.93 0 1.29l4.57 4.63c-2.75-.05-5.37-1.21-7.25-3.22-1.88-2.16-2.8-5-2.53-7.86Z"
      style={{
        strokeWidth: 0,
        fill: "url(#c)",
      }}
    />
  </svg>
  );
}