import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ContinueButton, Container, DeleteButton } from './styles';
import InputBase from '@mui/material/InputBase';
import { alpha, styled as MuiStyled } from '@mui/material/styles';

interface IProps {
  open: boolean;
  onClose: () => void;
  save: (value: any) => void;
}

const BootstrapInput = MuiStyled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#FFFF',
    border: '1px solid',
    borderColor: '#E0E3E7',
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },

  },
}));

export const CreateUnityElaboration: React.FC<IProps> = ({
  open,
  save,
  onClose,
}) => {
  const [value, setValue] = React.useState('')
  return (
    <>
      <Container>
        <Dialog
          open={open}
          sx={{ mt: 10 }}
          maxWidth='md'
          onClose={onClose}
        >
          <div style={{ padding: '0 27px', width: '875px' }}>
            <DialogTitle>
              Criar unidade de medida
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#004A80'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: '10px' }} >
              <BootstrapInput
                fullWidth
                size='small'
                autoComplete="off"
                onChange={(e) => setValue(e.target.value)}
              />
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
              <DeleteButton onClick={onClose}>
                <p>Cancelar</p>
              </DeleteButton>
              <ContinueButton onClick={() => save(value)}>
                <p>Salvar</p>
              </ContinueButton>
            </DialogActions>
          </div>
        </Dialog>
      </Container>
    </>
  );
}
