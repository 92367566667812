import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  border: 2px red solids;

  .description_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .gif_wrapper {
    display: flex;
    height: 458px; 
    background: #D9D9D9;
    justify-content: center;
    align-items: center;

    p {
      width: 70%;
      color: #000;
      font-family: "Open Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 52px */
    }
  }
`;

export const ContentFinalWrapper = styled.div`
  gap: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  p {
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    font-family: "Open Sans";
    color: var(--primary-white, green);
  }
`;

interface IButtonProps {
  color: string;
}

export const ButtonWrapper = styled.button<IButtonProps>`
  border: none;
  outline: none;
  display: flex;
  padding: 8px 22px;
  border-radius: 20px;
  background: ${({ color }) => color};
  border: 1px solid var(--primary-links-blue, #004A80); 

  p {
    color: #004A80;
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    font-family: "Open Sans";
  }
`;

export const ButtonWrapperCancel = styled.button<IButtonProps>`
  border: none;
  outline: none;
  display: flex;
  padding: 8px 22px;
  border-radius: 20px;
  background: ${({ color }) => color};
  border: 1px solid var(--primary-links-blue, #004A80); 

  p {
    color: #fff;
    font-size: 19px;
    font-weight: 700;
    line-height: 130%;
    font-style: normal;
    font-family: "Open Sans";
  }
`;

export const SendPlanosWrapper = styled.div`

  display: flex;
  justify-content: flex-end;
  gap: 20px;
    button {
      padding: 8px 38px;
      width: fit-content;
      border-radius: 20px;
      font-size: 19px;
      font-style: normal;
      font-weight: 700;

      border: none;
    }

    .devolver{
      background: var(--primary-links-blue-2, #00C0F3);
    }
`;
