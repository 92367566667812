import styled from "styled-components";

import LPref from "../../../../public/header/logoPrefeitura.svg";
import LogoI from "../../../../public/footer/iplanrio.svg";
import F from "../../../../public/footer/face.svg";
import T from "../../../../public/footer/twitter.svg";
import Y from "../../../../public/footer/youtube.svg";
import I from "../../../../public/footer/insta.svg";
import PrefeituraI from "../../../../public/footer/prefeitura.svg";
import EscritorioI from "../../../../public/footer/escritorio.svg";
import ParticipaI from "../../../../public/footer/participa.svg";
import LegislacaoI from "../../../../public/footer/leg.svg";

export const LogoPrefeitura = styled(LPref)` 
margin-left:49px;

`;
export const LogoIpan = styled(LogoI)``;
export const Facebook = styled(F)``;
export const Twitter = styled(T)``;
export const Youtube = styled(Y)``;
export const Instagram = styled(I)``;

export const PrefeituraIcon = styled(PrefeituraI)``;
export const EscritorioIcon = styled(EscritorioI)``;
export const ParticipaIcon = styled(ParticipaI)``;
export const LegislacaoIcon = styled(LegislacaoI)``;



interface ListA {
  width?: number;
  mobileNone?: boolean;
}
export const Container = styled.footer`
  width: 100%;
  background: #004a80;
  min-height: 313px;
  padding: 39px 44px 39px 44px;
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #89e2fa;
    margin-top: 2%;
    margin-bottom: 1%;
    color: ${({ theme }) => theme.colors.footer_text};
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.colors.footer_text_middle};
    margin-bottom: 2%;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: ${({ theme }) => theme.colors.footer_text_last};
    margin-bottom: 2%;
  }

  @media (max-width: 1200px) {
    h1,
    h2,
    p {
      text-align: center;
    }

    h1{
      margin-top: 3%;
    }
    padding: 39px 20px 39px 20px;
    a {
      margin-top: 10px;
    }
  }
  @media (max-width: 820px) {
    h2 {
      margin-top: 9%;
    }
    h1{
      margin-top: 9%;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .boxIcon {
    width: 280px;
  }
  .boxIconMobile {
    display: none;
    
    svg{
      margin-left: 0px;

    }
  }
  .imgWrraper {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 20px;
    }
  }
  @media (max-width: 1200px) {
    flex-wrap: wrap;

    img {
      width: 100px;
    }
    .boxIcon {
      width: 100px;
      scale: 0.8;
      display: none;
    }
    .boxIconMobile {
      scale: 0.8;
      display: flex;
    }
    .imgWrraper {
      width: 100%;
      justify-content: space-between;
      img {
        margin-right: 0;
      }
    }
  }
`;

export const List = styled.div<ListA>`
  display: ${(props) => (props.mobileNone ? "flex" : "none")};
  width: ${(props) => (props.width ? props.width : 49)}%;
  justify-content: space-between;
  
  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    list-style-type: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 8px;

  }

  @media (max-width: 1200px) {
    display: ${(props) => (props.mobileNone ? "none" : "flex")};
  }
`;
export const Estate = styled.div`
  display: none;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 6%;

  a{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p {
    list-style-type: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  
    margin-top: 8px;
  }

  @media (max-width: 1200px) {
    display: flex;
    justify-content: space-around;
  }

  
`;

export const SocialWrraper = styled.div`
  display: flex;
  width: 20%;
  justify-content: space-between;

  @media (max-width: 820px) {
    width: 60%;
    
  }
  
`;
