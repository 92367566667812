import { Container } from "./styled"
import { InfoIncon } from "../info";
import { Tooltip } from "@mui/material";

interface IProps {
  register?: any;
  errors?: any;
  name?: string;
  value?: string;
  hiddeInput?: boolean;
  label?: string;
  id?: any;
  local?: string;
  rows?: number;
  subLabel?: string;
  tooltip?: string;
  required?: boolean;
  multiline?: boolean;
  placeholder?: string;
  defaultValue?: string;
  onChange?: any,
}

export const TextAreaElaboration: React.FC<IProps> = ({
  name,
  label,
  local,
  errors,
  tooltip,
  required,
  hiddeInput,
  subLabel,
  ...props
}) => {

  return (
    <Container subLabel={subLabel}>
      {label && (
        <div className="title_wrapper_textfield">
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            {local == 'sintese_cenario' && (
              <>
                {!hiddeInput && (
                  <>
                    <label className="interFont">
                      <p>{label}</p>
                      <span>
                        {required ? '*' : ''}
                      </span>
                    </label>
                    {tooltip && (
                      <Tooltip arrow title={tooltip}>
                        <div>
                          <InfoIncon />
                        </div>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}

            {!local && (
              <>
                <label className="interFont">
                  <p>{label}</p>
                  <span>
                    {required ? '*' : ''}
                  </span>
                </label>
                {tooltip && (
                  <Tooltip arrow title={tooltip}>
                    <div>
                      <InfoIncon />
                    </div>
                  </Tooltip>
                )}
              </>
            )}
          </div>
          <span style={{ marginBottom: '6px' }}>{subLabel}</span>
        </div>
      )}

      {!hiddeInput && (
        <textarea {...props}></textarea>
      )}

      {errors && name && (
        <div className="error_wrapper">
          <span>{errors[name]?.message}</span>
        </div>
      )}
    </Container>
  )
}