interface IProps {
  EMAIL: string;
  API_URL: string;
  PASSWORD: string;
  NODE_ENV: string;
  CERBERUS: string;

/*   ETL_USERNAME: string;
  ETL_PASSWORD: string;
  ETL_API_URL: string; */

}

export const ACCESS: IProps = {
  EMAIL: process.env.NEXT_PUBLIC_EMAIL as string,
  API_URL: process.env.NEXT_PUBLIC_API_URL as string,
  PASSWORD: process.env.NEXT_PUBLIC_PASSWORD as string,
  NODE_ENV: process.env.NEXT_PUBLIC_NODE_ENV as string,
  CERBERUS: process.env.NEXT_PUBLIC_CERBERUS as string,

/*   ETL_USERNAME: process.env.NEXT_PUBLIC_ETL_USERNAME as string,
  ETL_PASSWORD: process.env.NEXT_PUBLIC_ETL_PASSWORD as string,
  ETL_API_URL: process.env.NEXT_PUBLIC_ETL_API_URL as string, */

};
