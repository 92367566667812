import { createGlobalStyle } from 'styled-components';


export default createGlobalStyle`
  .verdanaFont{
    font-family: 'Verdana';
    src: url('/fonts/Verdana.ttf') format('truetype');
  }

  .interFont{
    label {
      font-family: 'Inter';
    }
    src: url('/fonts/inter.ttf') format('truetype');
  }
  
  :root {
    //Fonts 
    --font-inter: 'Inter', 'Open Sans', Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    --font-main: 'Roboto', 'Open Sans', Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    --max-width: 1920px;
  }

  label, li {
    font-family: var(--font-inter);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--font-main);
  }

  ::-webkit-scrollbar {
    width: 5px !important;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  body {
    max-width: 100vw;
    overflow-x: hidden;
    background: ${props => props.theme.colors.background};
  }

  button[data-not-shadow]{
    border: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${props => props.theme.colors.text};
  }

  p {
    color: ${props => props.theme.colors.text};
  }

  button {
    cursor:pointer;
    background: ${props => props.theme.colors.button};
    color: ${props => props.theme.colors.button_text};

    p {
      color: ${props => props.theme.colors.button_text};
    }
  }

  .clear-filter{
    width: fit-content;
  }


  main{
    position:relative;

    > .col-1{
      z-index:9999;
      height: 100% !important;
      width: 350px  !important;
      position:absolute;
    }

    > .col-2{
      min-height: 100vh;
      width: 100% !important;
    }
  }

  a{
    cursor: pointer;
  }
`;
