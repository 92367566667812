import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { useSnackbar } from "@/hooks/useSnackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarMessageContainer } from "./styles";

export function CustomizedSnackbars() {
  const { handleCloseSnackbar, openSnackbar, description, message } = useSnackbar();

  return (
    <Stack
      spacing={2}
      sx={{ width: "100%", position: "relative", zIndex: 9999999999 }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarMessageContainer>
          <div className="message_wrapper">
            <p>{message}</p>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleCloseSnackbar}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <span>{description}</span>
        </SnackbarMessageContainer>
      </Snackbar>
    </Stack>
  );
}
