import React from "react";
import {
  List,
  Estate,
  Youtube,
  Twitter,
  Facebook,
  Instagram,
  Container,
  SocialWrraper,
  MainContainer,
  LogoPrefeitura,
  ContainerWrapper,
  PrefeituraIcon,
  EscritorioIcon,
  ParticipaIcon,
  LegislacaoIcon,
} from "./styles";
import Link from "next/link";
import Image from "next/image";

export default function Footer() {
  return (
    <Container>
      <MainContainer>
        <ContainerWrapper>
          <div className="boxIcon">
            <LogoPrefeitura />
          </div>
          <List mobileNone>
            <Link href="https://prefeitura.rio/" target="_blank">
              <PrefeituraIcon />
              <p className="resizeable-accessibility">Prefeitura do Rio</p>
            </Link>
            <Link
              href="https://escritorio-de-planejamento-pcrj.hub.arcgis.com/"
              target="_blank"
            >
              <EscritorioIcon />
              <p className="resizeable-accessibility">
                Escritório de Planejamento
              </p>
            </Link>
            <Link
              href="https://participario-pcrj.hub.arcgis.com/"
              target="_blank"
            >
              <ParticipaIcon />

              <p className="resizeable-accessibility">Participa.rio</p>
            </Link>
            <Link href="https://www2.rio.rj.gov.br/conlegis/" target="_blank">
              <LegislacaoIcon />
              <p className="resizeable-accessibility">Legislação</p>
            </Link>
          </List>
          <div className="imgWrraper">
            <div className="boxIconMobile">
              <LogoPrefeitura />
            </div>
            <Image
              loading="eager"
              src="/footer/iplan.png"
              priority={true}
              className="img"
              quality={100}
              width={129}
              height={37}
              alt=""
            />
            <Image
              loading="eager"
              src="/footer/plan.png"
              priority={true}
              quality={100}
              width={129}
              height={56}
              alt=""
            />
          </div>
        </ContainerWrapper>
        <Estate>
          <Link href="https://prefeitura.rio/" target="_blank">
            <PrefeituraIcon />
            <p>Prefeitura do Rio</p>
          </Link>
          <Link
            href="https://participario-pcrj.hub.arcgis.com/"
            target="_blank"
          >
            <ParticipaIcon />
            <p>Participa.rio</p>
          </Link>
          <Link
            href="https://escritorio-de-planejamento-pcrj.hub.arcgis.com/"
            target="_blank"
          >
            <EscritorioIcon />
            <p>Escritório de Planejamento</p>
          </Link>
         
          <Link href="https://www2.rio.rj.gov.br/conlegis/" target="_blank">
            <LegislacaoIcon />
            <p>Legislação</p>
          </Link>
        </Estate>
        <h1 className="resizeable-accessibility">
          {" "}
          Prefeitura da Cidade do Rio de Janeiro - Rua Afonso Cavalcanti, 455 -
          Cidade Nova - 20211-110
        </h1>
        <h2 className="resizeable-accessibility">
          Secretaria Municipal de Fazenda e
          Planejamento | Subsecretaria de Planejamento e Acompanhamento de
          Resultados{" "}
        </h2>
        <SocialWrraper>
          <p>
            <Link href={"https://www.facebook.com/PrefeituradoRio"} target="_blank">
              <Facebook />{" "}
            </Link>
          </p>
          <p>
            <Link href={"https://twitter.com/Prefeitura_Rio"} target="_blank">
              <Twitter />
            </Link>

          </p>
          <p>
            <Link href={"https://www.youtube.com/channel/UCBf3rlo_iHd4kRePPhFXDUQ"} target="_blank">
              <Youtube />
            </Link>

          </p>
          <p>
            <Link href={"https://www.instagram.com/prefeitura_rio/"} target="_blank">
              <Instagram />
            </Link>

          </p>
        </SocialWrraper>
      </MainContainer>
    </Container>
  );
}
