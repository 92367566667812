import _ from 'lodash';
import React from "react";
import { ButtonWrapper, ButtonWrapperCancel } from './styles';
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { CloseIcon } from "@/components/gestao_planos_dashboard/svgs/close";

export const ConfirmDialog = ({ open = false, handleClose, handleConfirm }: { open: boolean, handleClose: any, handleConfirm: any }) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p>
              Enviar plano
            </p>
            <CloseIcon onClose={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} display='flex' flexDirection='column' gap='10px'>
              <p>Tem certeza de que deseja enviar seu Plano para aprovação?</p>
              <p>Uma vez enviado, você não poderá mais fazer edições e precisará aguardar o parecer.</p>
            </Grid>
            <Grid item xs={12} mt={2} display='flex' justifyContent='center' gap='15px'>
              <ButtonWrapper color='#FFF' onClick={handleClose}>
                <p>Cancelar</p>
              </ButtonWrapper>
              <ButtonWrapperCancel color='#004A80' onClick={handleConfirm}>
                <p>Enviar plano</p>
              </ButtonWrapperCancel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
