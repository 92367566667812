import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  .sub_container {
    label {
      display: flex;
      text-align: center;
    }

  }

  .svg_container {
    height: auto;

    svg {
      width: 100%;
      transform: scale(.7);

    }
  }
  .goals_select_wrapper {
    width: 100%;
    height: 82px;
    display: flex;
    background: #fff;
    align-items: center;
    justify-content: center;
  }

  .goals_left_arrow {
    width: 27px;
    height: 82px; 
    display: flex;
    cursor: pointer;
    align-items: center;
    background: #E9E9E9;
    justify-content: center;
  }

  .goals_rigth_arrow {
    width: 27px;
    height: 82px; 
    display: flex;
    cursor: pointer;
    align-items: center;
    background: #E9E9E9;
    justify-content: center;
  }

  .goals_select_content_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      color: #004A80;
      font-size: 28px;
      font-weight: 700;
      line-height: 110%;
      font-style: normal;
      font-family: Roboto;
    }

    span {
      color: #909090;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      font-style: normal;
    }
  }

  .pagination_wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      color: #909090;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      margin-top: .5rem;
      font-style: normal;
    }
  }


  .add_line_wrapper {

    p {
      color: #004A80;
      width: fit-content;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }

    p:hover {
      text-decoration: underline;
      text-decoration-color: #004A80;
    }
  }

  .remove_line_wrapper {
    display: flex;
    align-items: center;

    p {
      cursor: pointer;
      color: #EC1F1F;
    }
  }

    .sub_item_topographic {
      color: var(--primary-links-blue, #004A80);
      font-family: Roboto;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%; /* 19.8px */
    }

    .recicle_content_button {
    border: none;
    outline: none;
    display: flex;
    padding: 8px 22px;
    background: gray;
    border-radius: 20px;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 1px 5px 0px 
    rgba(0, 0, 0, 0.12), 0px 2px 2px 0px 
    rgba(0, 0, 0, 0.14), 0px 3px 1px -2px 
    rgba(0, 0, 0, 0.20);

    p {
      color: #FFF;
      font-size: 19px;
      font-weight: 700;
      line-height: 130%;
      font-style: normal;
      font-family: "Open Sans";
    }
  }

  .error_wrapper {
    width: 100%;
    margin-top: .5rem;

    span {
      color: red;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      font-style: normal;
    }
  }
`;

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

    .first_row, .second_row {
      display: flex;
      gap: 10px;
      flex-direction: column;

      span {
        color: var(--primary-links-blue, #004A80);
        font-family: Roboto;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 30.8px */
      }
    }

    .second_row {
      margin-top: 1rem;
    }

`;