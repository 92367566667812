export const PlanoSetorialIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plano_setorial">
        <path id="Vector" d="M80 10V55H55V80H10V10H80ZM80 0H10C4.5 0 0 4.5 0 10V80C0 85.5 4.5 90 10 90H60L90 60V10C90 4.5 85.5 0 80 0ZM45 55H20V45H45V55ZM70 35H20V25H70V35Z" fill="#004A80" />
      </g>
    </svg>

  )
}
