import { useTheme } from "@/services/zustand/theme";

export const MunicipalAssistenciaSocial = () => {
  const theme = useTheme((state) => state.theme);
  return (

    <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      
      width="120"
      height="120"
      viewBox="0 0 300 300"
      fill="none"
    >
      <defs>
        <linearGradient id="Gradiente_sem_nome_5" x1="45.6" y1="61.5" x2="274.29" y2="290.19"
          gradientUnits="userSpaceOnUse">
          <stop offset="0" stop-color={theme === "light" ? "#004A80" : 'white'} />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3" : 'white'} />

        </linearGradient>
      </defs>
      <title>Capas_Planos Interplan_VF</title>
      <path fill="url(#Gradiente_sem_nome_5)"
        d="M242.05,87.05a24.07,24.07,0,0,1-24-23.94,23.15,23.15,0,0,1,7-16.94A24,24,0,0,1,266,63.05,23.18,23.18,0,0,1,259,80,23,23,0,0,1,242.05,87.05ZM222.32,299.47V189.15a32.82,32.82,0,0,0-4.92-17.73A28.24,28.24,0,0,0,203,159.93l13.46-39.39a24.82,24.82,0,0,1,9.69-13.14,28.23,28.23,0,0,1,31.85,0,24.86,24.86,0,0,1,9.68,13.14l33.49,96.85H265v82.08Zm-54.17-137.9a19.73,19.73,0,0,1-14-33.65,19.73,19.73,0,0,1,27.9,27.91A19,19,0,0,1,168.15,161.57ZM77.89,87.05a24.07,24.07,0,0,1-24-23.94,23.14,23.14,0,0,1,7-16.94,23.83,23.83,0,0,1,33.84,0,23,23,0,0,1,7.06,16.9,24.05,24.05,0,0,1-23.94,24Zm-23,212.42V204.26h-23V122.18a19.75,19.75,0,0,1,19.7-19.7h52.53a19.75,19.75,0,0,1,19.7,19.7v82.08h-23v95.21Zm95.21,0V243.65H133.68v-54.5a14.37,14.37,0,0,1,14.44-14.44h40.06a14.37,14.37,0,0,1,14.44,14.44v54.5H186.21v55.82Z" />
    </svg>

  );
};


