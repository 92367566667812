import { useRouter } from "next/router";
import { ButtonWrapper } from "./styles";
import useCookieStorage from "@/hooks/useCookieStorage";
import { TextAreaElaboration } from "../../Inputs/TextArea";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ComentariosService } from "@/services/endpoints/comentario";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";


type Props = {
  open: boolean,
  name?: string,
  register?: any,
  label?: string,
  value?: string,
  handleClose: any,
  commentTittle?: string;
}

export const ConsiderationModal: React.FC<Props> = ({
  open,
  name,
  label,
  register,
  handleClose,
  commentTittle,
}) => {

  const router = useRouter();
  const { postComentarios } = new ComentariosService();
  const [loggedUser, setLoggedUser] = useCookieStorage('LOGGED_USER', '');
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();

  const saveConsideration = () => {
    let formValue = document.getElementById('formName') as any;
    let consideration = document.getElementById('formConsideration') as any;

    consideration = consideration ? consideration.value : null
    formValue = formValue ? formValue.value : null

    if (consideration == null || consideration == '') return

    const params = {
      title: commentTittle,
      label: label,
      valor: formValue,
      user: loggedUser.nome,
      comentario: consideration,
      registroId: register?.id ?? 0,
      identification: loggedUser?.cpf ?? null,
    }

    postComentarios(router.query.id, params)
      .then(() => {
        toggleSuccessSnackbar('Consideração salva com sucesso!');

        setTimeout(() => toggleSuccessSnackbar(), 3000)
        if (handleClose) {
          handleClose()
        }
      }).catch(() => toggleErrorSnackbar())
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">
        Adicionar considerações
      </DialogTitle>
      <DialogContent>
        <p>
          Caso haja necessidade de revisão desta seção do Plano,
          adicione um comentário.
        </p>
        <TextAreaElaboration
          id="formConsideration"
          placeholder="Escreva sua consideração"
          rows={10}
        />
        {register &&
          <input
            disabled
            type="hidden"
            id="formName"
            {...register(name, { setValues: (v: any) => { } })}
          />
        }
        <ButtonWrapper>
          <button className="cancel" onClick={() => handleClose()}>
            <p>Cancelar</p>
          </button>
          <button onClick={() => saveConsideration()}>
            <p>Salvar</p>
          </button>
        </ButtonWrapper>
      </DialogContent>

    </Dialog>
  )
}