import { persist } from 'zustand/middleware';
import { UseBoundStore, StoreApi, create } from 'zustand';
import { ICardProps } from '@/contexts/elaboracao_planos/constants';

interface IProps {
  cardItem: ICardProps;
  setCardItem: (cardItem: ICardProps) => void;
}

// contexto zustand responsável pelo tema dark e light da aplicação
export const useElaborationCardItem: UseBoundStore<StoreApi<IProps>> = create(
  persist(
    (set, get) => ({
      cardItem: {} as ICardProps,
      setCardItem: (cardItem) => set(() => ({
        cardItem
      })),
    }),
    {
      name: 'selectedCardItem',
    },
  ),
);

