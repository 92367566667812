import React from "react";
import Head from "next/head";
import Footer from "../Footer";
import { Header } from "../Header";
import { SideBar } from "../SideBar";
import GlobalStyles from "@/styles/global";
import { dark } from "@/styles/themes/dark";
import { light } from "@/styles/themes/light";
import { ThemeProvider } from "styled-components";
import { MenuProvider } from "@/contexts/menu/menu";
import { useTheme } from "@/services/zustand/theme";
import { Container, GlobalContainer } from "./styles";
import { CustomizedSnackbars } from "../Snackbar";

interface IProps {
  children: React.ReactNode;
}

export const Layout: React.FC<IProps> = ({ children }) => {
  const theme = useTheme((state) => state.theme);
  const [isClient, setIsClient] = React.useState(false);

  React.useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && (
        <MenuProvider>
          <ThemeProvider theme={theme === "light" ? light : dark}>
            <Head>
              <title>Interplan.Rio</title>
              <meta name="description" content="Generated by create next app" />
              <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Head>
            <GlobalStyles />
            <Header />
            <Container>
              <SideBar />
              <GlobalContainer>{children}</GlobalContainer>
              <Footer />
            </Container>
          </ThemeProvider>
          <CustomizedSnackbars />
        </MenuProvider>
      )}
    </>
  );
};
