import { AxiosPromise } from "axios";
import { Api } from "@/services/configs/axios";

interface LoginProps {
    email: string;
    password: string;
}

export class DashboardService {
  LoginDashboard = (data: LoginProps): AxiosPromise<any[]> => Api.post("user/login", data);
  LoginCerberusDashboard = (data: LoginProps): AxiosPromise<any[]> => Api.post("user/cerberus/login", data);
}
