import React from "react";
import { Container } from './styles';
import { Grid } from "@mui/material";
import { FinalDialog } from "./finalDialog";
import { ConfirmDialog } from "./ConfirmDialog";
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { InsertPlan } from "./InsertPlan";
import { DevolverPlan } from "./DevolverPlan";
import { PlansService } from "@/services/endpoints/plans";
import { useRouter } from "next/router";

export const SendingPlanForm = () => {

  const router = useRouter();
  const [open, setOpen] = React.useState(false);
  const { selectedItemsMenu } = usePreparationOfPlans();
  const [openFinal, setOpenFinal] = React.useState(false);
  const {PostEnviar} = new PlansService()

  const handleConfirm = () => {
    
    PostEnviar(router.query.id)
    .then(() => {
      setOpenFinal(true);
      setOpen(false);
    })
  }

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
      </Grid>
      <ActionButtonElaborations sendingText="Enviar plano para revisão" onClick={() => {
        setOpen(true)
      }} layer_indicator={selectedItemsMenu?.layer_indicator} isOptional={'false'} />
      <ConfirmDialog open={open} handleClose={() => setOpen(false)} handleConfirm={() => {handleConfirm()}} />
      <div style={{display: 'flex', gap: 10, justifyContent: 'flex-end'}}>
        <DevolverPlan />
        <InsertPlan />
      </div>
      <FinalDialog open={openFinal} handleClose={() => {setOpenFinal(false), window.location.href ='/dashboard/elaboracao_planos'}} />
    </Container>
  )
}