import { useTheme } from "@/services/zustand/theme";

export const EstrategicoRMRJ = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M54.75 68.375L81.25 41.875L75.875 36.625L54.7084 57.875L44.125 47.25L38.75 52.5L54.75 68.375ZM5 105V97.5H115V105H5ZM17.5 90C15.5 90 13.75 89.25 12.25 87.75C10.75 86.25 10 84.5 10 82.5V22.5C10 20.5 10.75 18.75 12.25 17.25C13.75 15.75 15.5 15 17.5 15H102.5C104.5 15 106.25 15.75 107.75 17.25C109.25 18.75 110 20.5 110 22.5V82.5C110 84.5 109.25 86.25 107.75 87.75C106.25 89.25 104.5 90 102.5 90H17.5ZM17.5 82.5H102.5V22.5H17.5V82.5Z"
        fill="url(#paint0_linear_1941_43869)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43869"
          x1="30.4375"
          y1="28.5"
          x2="68.9307"
          y2="104.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
