import { useTheme } from "@/services/zustand/theme";

export const EstrategicoProcuradoriaGeral = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M87.125 42.125H94.625V34.625H87.125V42.125ZM87.125 63.5H94.625V56H87.125V63.5ZM87.125 84.75H94.625V77.25H87.125V84.75ZM80.125 105V97.5H107.5V22.5H58.125V36.5L50.625 31.25V15H115V105H80.125ZM5 105V56.25L38.875 32L72.625 56.25V105H45.5V79.875H32.25V105H5ZM12.5 97.5H24.75V72.375H53V97.5H65.125V60.125L38.875 41.25L12.5 60.1777V97.5Z"
        fill="url(#paint0_linear_2012_91968)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91968"
          x1="30.4375"
          y1="28.5"
          x2="68.9307"
          y2="104.642"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
