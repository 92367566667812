import {
  Control,
  useForm,
  FieldErrors,
  UseFormReset,
  UseFormWatch,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFieldArrayUpdate,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFormSetError,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

interface IFormGuidelines {
  isOptionalGuidelines: string;
  fieldsGuidelines: Record<"id", string>[];
  errosGuidelines: FieldErrors<InputsGuidelines>;
  resetGuidelines: UseFormReset<InputsGuidelines>;
  watchGuidelines: UseFormWatch<InputsGuidelines>;
  controlGuidelines: Control<InputsGuidelines, any>;
  setErrorGuidelines: UseFormSetError<InputsGuidelines>;
  setValueGuidelines: UseFormSetValue<InputsGuidelines>;
  registerGuidelines: UseFormRegister<InputsGuidelines>;
  updateGuidelines: UseFieldArrayUpdate<InputsGuidelines, "diretriz">;
  appendGuidelines: UseFieldArrayAppend<InputsGuidelines, "diretriz">;
  handleSubmitGuidelines: UseFormHandleSubmit<InputsGuidelines, undefined>;
}

export const GuidelinesContext = React.createContext({} as IFormGuidelines);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsGuidelines = {
  diretriz: any;
  is_optional?: boolean;
};

const SchemaGuidelines = Yup.object().shape({
  diretriz: Yup.array().required('Campo obrigatório')
});

const defaultValue = {
  texto: '',
  data: null,
  label: '',
  id: '',
}

export const GuidelinesContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetGuidelines,
    watch: watchGuidelines,
    setError: setErrorGuidelines,
    control: controlGuidelines,
    register: registerGuidelines,
    setValue: setValueGuidelines,
    handleSubmit: handleSubmitGuidelines,
    formState: { errors: errosGuidelines } } = useForm<InputsGuidelines>({
      resolver: yupResolver(SchemaGuidelines),
      defaultValues: { diretriz: [[defaultValue]] }
    });

  const { fields: fieldsGuidelines, append: appendGuidelines, update: updateGuidelines } = useFieldArray({
    control: controlGuidelines,
    name: "diretriz"
  });

  const value = {
    errosGuidelines,
    resetGuidelines,
    watchGuidelines,
    fieldsGuidelines,
    appendGuidelines,
    updateGuidelines,
    controlGuidelines,
    setValueGuidelines,
    registerGuidelines,
    setErrorGuidelines,
    handleSubmitGuidelines,
  } as IFormGuidelines;

  return <GuidelinesContext.Provider value={value}>{children}</GuidelinesContext.Provider>;
};