export const CheckboxFakeItem = {
  "planos": [
    {
      "id": 1,
      "nome": "Plano da Cultura"
    },
    {
      "id": 2,
      "nome": "Plano da Economia"
    }
  ],
  "temas": [
    {
      "id": 3,
      "nome": "Cultura"
    },
    {
      "id": 4,
      "nome": "Economia"
    }
  ],
  "bairros": [
    {
      "id": 5,
      "nome": "Ipanema"
    },
    {
      "id": 6,
      "nome": "Botafogo"
    },
    {
      "id": 7,
      "nome": "Laranjeiras"
    },
    {
      "id": 8,
      "nome": "Barra da Tijuca"
    },
  ]
}