import _ from 'lodash';
import dayjs from 'dayjs';

export const encontrarCorrespondentes = (obj_1: any[], obj_2: any) => {
  if (obj_1.length == 0) {
    return null
  }

  let correspondentes = {
    "AP": [] as any[],
    "RP": [] as any[],
    "RA": [] as any[],
    "BAIRROS": [] as any[]
  } as Record<string, any>;

  function encontrarNoObjeto2(item: any) {
    for (let categoria in obj_2) {
      obj_2[categoria].forEach((elemento: any) => {
        if (elemento.descricao === item.descricao && elemento.id === item.id) {
          correspondentes[categoria].push(elemento.id);
        }
      });
    }
  }

  obj_1?.forEach((item: any) => {
    encontrarNoObjeto2(item);
  });

  return correspondentes;
}

export const transformArray = (array: any) => {
  // Cria um mapa para agrupar os indicadores por index
  const map = new Map<number, number[]>();

  array.forEach((item: any) => {
    const { index, indicador } = item;
    if (!map.has(index)) {
      map.set(index, []);
    }
    map.get(index)?.push(indicador);
  });

  // Converte o mapa para o formato desejado
  const result: any = Array.from(map, ([index, indicadores]) => ({ index, indicadores }));

  return result;
};


export const handleContentRelatedToTerritorySelected = (filterData: any, territorioId: string, tor: any) => {
  if (territorioId == '1') {
    return _.orderBy(filterData.territorio['AP'], ['descricao'], ['asc'])
  }

  if (territorioId == '2') {
    return _.orderBy(filterData.territorio['RP'], ['descricao'], ['asc'])
  }

  if (territorioId == '3') {
    return _.orderBy(filterData.territorio['RA'], ['descricao'], ['asc'])
  }

  return _.orderBy(filterData.territorio['BAIRROS'], ['descricao'], ['asc'])
}

export const updateObjectAspirationAndGuidelines = (objA: any, objB: any) => {
  // Transformar objA em um array plano, caso seja necessário
  const flattenedObjA = objA.flat();

  return objB.map((subArrayB: any) => {
    const itemB = subArrayB[0];

    // Buscar uma correspondência no array achatado
    const matchA = flattenedObjA.find((itemA: any) => itemA.label === itemB.label);

    if (matchA) {
      // Retorna um array com o item atualizado
      return [{
        ...itemB,
        texto: matchA.texto,
        data: matchA.data,
        eixo_tematico_id: matchA.eixo_tematico_id,
        id: matchA.id
      }];
    }

    // Caso não haja correspondência, retorne o subArrayB original
    return subArrayB;
  });
};

export function populateObjectA(A: any, B: any) {
  // Cria um objeto para armazenar as correspondências de B por eixo_tematico_id
  const bMap = {} as Record<string, any>;

  // Preenche o objeto bMap com os dados de B
  for (const groupB of B) {
    for (const itemB of groupB) {
      if (!bMap[itemB.eixo_tematico_id]) {
        bMap[itemB.eixo_tematico_id] = [];
      }
      bMap[itemB.eixo_tematico_id].push({
        texto: itemB.texto,
        data: itemB.data,
        id: itemB.id
      });
    }
  }

  // Atualiza A com os dados de B usando o bMap para busca rápida
  const updatedA = [];

  for (const groupA of A) {
    const newGroup = [];

    for (const itemA of groupA) {
      const matches = bMap[itemA.eixo_tematico_id] || [];
      
      for (const match of matches) {
        newGroup.push({
          ...itemA,
          texto: match.texto,
          data: match.data,
          id: match.id
        });
      }
    }

    updatedA.push(newGroup);
  }

  return updatedA;
}
export function analisarTerritorioArray(array: any) {
  if (!Array.isArray(array) || array.length === 0) {
    return 0; // Retorna 0 se o array estiver vazio ou não for um array
  }

  const objeto = array[0]; // Acessa o primeiro objeto do array

  if (objeto.bairro) {
    return 4;
  } else if (objeto.areaPlanejamento) {
    return 1;
  } else if (objeto.regiaoPlanejamento) {
    return 2;
  } else if (objeto.regiaoAdministrativa) {
    return 3;
  } else {
    return 0; // Retorna 0 se nenhuma das propriedades estiver presente
  }
}

export function removeDuplicatesFromA(objetoA: any, objetoB: any) {
  // Contar a ocorrência de cada ID no Objeto B
  const idCounts = objetoB.reduce((acc: any, item: any) => {
    acc[item.id] = (acc[item.id] || 0) + 1;
    return acc;
  }, {});

  const idsInA = new Set(objetoA.map((item: any) => item.id));

  // Remover itens do objetoA se o ID aparecer 2 vezes ou mais no objetoB
  let updatedObjetoA = objetoA.filter((item: any) => idCounts[item.id] < 2);

  // Adicionar itens de objetoB em objetoA se o ID aparecer menos de 2 vezes e não estiver em objetoA
  objetoB.forEach((item: any) => {
    if (idCounts[item.id] < 2 && !idsInA.has(item.id)) {
      updatedObjetoA.push(item);
    }
  });

  return updatedObjetoA;

}

export const DeleteIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.755 2.12783C6.82416 1.98942 6.93048 1.87301 7.06206 1.79162C7.19364 1.71023 7.34528 1.66707 7.5 1.66699H12.5C12.6547 1.66707 12.8064 1.71023 12.9379 1.79162C13.0695 1.87301 13.1758 1.98942 13.245 2.12783L14.6817 5.00033H16.6667C16.8877 5.00033 17.0996 5.08812 17.2559 5.2444C17.4122 5.40068 17.5 5.61264 17.5 5.83366C17.5 6.05467 17.4122 6.26663 17.2559 6.42291C17.0996 6.57919 16.8877 6.66699 16.6667 6.66699H15.8333V15.8337C15.8333 16.4967 15.5699 17.1326 15.1011 17.6014C14.6323 18.0703 13.9964 18.3337 13.3333 18.3337H6.66667C6.00363 18.3337 5.36774 18.0703 4.8989 17.6014C4.43006 17.1326 4.16667 16.4967 4.16667 15.8337V6.66699H3.33333C3.11232 6.66699 2.90036 6.57919 2.74408 6.42291C2.5878 6.26663 2.5 6.05467 2.5 5.83366C2.5 5.61264 2.5878 5.40068 2.74408 5.2444C2.90036 5.08812 3.11232 5.00033 3.33333 5.00033H5.31833L6.755 2.12783ZM11.985 3.33366L12.8183 5.00033H7.18167L8.015 3.33366H11.985ZM9.16667 9.16699C9.16667 8.94598 9.07887 8.73402 8.92259 8.57774C8.76631 8.42146 8.55435 8.33366 8.33333 8.33366C8.11232 8.33366 7.90036 8.42146 7.74408 8.57774C7.5878 8.73402 7.5 8.94598 7.5 9.16699V14.167C7.5 14.388 7.5878 14.6 7.74408 14.7562C7.90036 14.9125 8.11232 15.0003 8.33333 15.0003C8.55435 15.0003 8.76631 14.9125 8.92259 14.7562C9.07887 14.6 9.16667 14.388 9.16667 14.167V9.16699ZM12.5 9.16699C12.5 8.94598 12.4122 8.73402 12.2559 8.57774C12.0996 8.42146 11.8877 8.33366 11.6667 8.33366C11.4457 8.33366 11.2337 8.42146 11.0774 8.57774C10.9211 8.73402 10.8333 8.94598 10.8333 9.16699V14.167C10.8333 14.388 10.9211 14.6 11.0774 14.7562C11.2337 14.9125 11.4457 15.0003 11.6667 15.0003C11.8877 15.0003 12.0996 14.9125 12.2559 14.7562C12.4122 14.6 12.5 14.388 12.5 14.167V9.16699Z" fill="#EC1F1F" />
    </svg>
  )
}

export function transformGuidelineAspirationStrategy(json: any, objetoLabels: any) {

  if (json.length === 0) {
    const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

    return eixosTematicos.map(eixoId => [
      {
        label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
        eixo_tematico_id: eixoId,
        texto: null,
        data: null,
        id: null
      }
    ]);
  }

  const agrupadoPorEixo = {} as Record<string, any>;;

  json.forEach((item: any) => {
    const { eixoTematico, ...res } = item
    const eixo_tematico_id = eixoTematico;

    const dataFormatada = dayjs(item.data);

    const labelObj = objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixo_tematico_id);
    const label = labelObj ? labelObj.label : '';



    const itemFormatado = { 
      ...res, 
      data: dataFormatada, 
      label, 
      eixo_tematico_id: eixoTematico };

    if (!agrupadoPorEixo[eixo_tematico_id]) {
      agrupadoPorEixo[eixo_tematico_id] = [];
    }

    agrupadoPorEixo[eixo_tematico_id].push(itemFormatado);
  });

  const resultado = Object.values(agrupadoPorEixo);

  const eixosTematicosPresentes = new Set(resultado.flat().map(item => item.eixo_tematico_id));
  const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

  eixosTematicos.forEach(eixoId => {
    if (!eixosTematicosPresentes.has(eixoId)) {
      resultado.push([
        {
          label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
          eixo_tematico_id: eixoId,
          texto: null,
          data: null,
          id: null
        }
      ]);
    }
  });

  return resultado;
}

export function transformGuidelineAspirationStrategyV2(json: any, objetoLabels: any) {

  if (json.length === 0) {
    const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

    return eixosTematicos.map(eixoId => [
      {
        label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
        eixo_tematico_id: eixoId,
        texto: null,
        id: null
      }
    ]);
  }

  const agrupadoPorEixo = {} as Record<string, any>;;

  json.forEach((item: any) => {
    const { eixoTematico, ...res } = item
    const eixo_tematico_id = eixoTematico;


    const labelObj = objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixo_tematico_id);
    const label = labelObj ? labelObj.label : '';



    const itemFormatado = { 
      ...res, 
      label, 
      eixo_tematico_id: eixoTematico };

    if (!agrupadoPorEixo[eixo_tematico_id]) {
      agrupadoPorEixo[eixo_tematico_id] = [];
    }

    agrupadoPorEixo[eixo_tematico_id].push(itemFormatado);
  });

  const resultado = Object.values(agrupadoPorEixo);

  const eixosTematicosPresentes = new Set(resultado.flat().map(item => item.eixo_tematico_id));
  const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

  eixosTematicos.forEach(eixoId => {
    if (!eixosTematicosPresentes.has(eixoId)) {
      resultado.push([
        {
          label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
          eixo_tematico_id: eixoId,
          texto: null,
          id: null
        }
      ]);
    }
  });

  return resultado;
}


export const transformDataStrategy = (data: any, labels: any) => {
  const labelMap = labels.reduce((acc: any, item: any) => {
    acc[item.id] = item.label;
    return acc;
  }, {});

  // Agrupar por eixoTematico
  const groupedData = data.reduce((acc: any, item: any) => {
    // Extrair eixoTematico
    const eixoTematico = item.eixoTematico;
    
    // Remover a propriedade eixoTematico e adicionar label e eixo_tematico_id
    const newItem = {
      ...item,
      data: item.data + "T03:00:00.000Z",
      label: labelMap[eixoTematico] || "", // Atribuir o label correspondente
      eixo_tematico_id: eixoTematico
    };

    delete newItem.eixoTematico;

    // Adicionar ao grupo correto
    if (!acc[eixoTematico]) {
      acc[eixoTematico] = [];
    }
    acc[eixoTematico].push(newItem);

    return acc;
  }, {});

  // Converter para um array de arrays
  return Object.values(groupedData);
};

export function transformGuideline(json: any, objetoLabels: any) {

  if (json.length === 0) {
    const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

    return eixosTematicos.map(eixoId => [
      {
        label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
        eixo_tematico_id: eixoId,
        texto: null,
        // data: null,
        id: null
      }
    ]);
  }

  const agrupadoPorEixo = {} as Record<string, any>;;

  json.forEach((item: any) => {
    const { eixoTematico, ...res } = item
    const eixo_tematico_id = eixoTematico;

    // const dataFormatada = dayjs(item.data);

    const labelObj = objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixo_tematico_id);
    const label = labelObj ? labelObj.label : '';



    const itemFormatado = { 
      ...res, 
      // data: dataFormatada, 
      label, 
      eixo_tematico_id: eixoTematico };

    if (!agrupadoPorEixo[eixo_tematico_id]) {
      agrupadoPorEixo[eixo_tematico_id] = [];
    }

    agrupadoPorEixo[eixo_tematico_id].push(itemFormatado);
  });

  const resultado = Object.values(agrupadoPorEixo);

  const eixosTematicosPresentes = new Set(resultado.flat().map(item => item.eixo_tematico_id));
  const eixosTematicos = Array.from(new Set(objetoLabels.map((labelItem: any) => labelItem.eixo_tematico_id)));

  eixosTematicos.forEach(eixoId => {
    if (!eixosTematicosPresentes.has(eixoId)) {
      resultado.push([
        {
          label: objetoLabels.find((labelItem: any) => labelItem.eixo_tematico_id === eixoId).label,
          eixo_tematico_id: eixoId,
          texto: null,
          // data: null,
          id: null
        }
      ]);
    }
  });

  return resultado;
}

export const unidadesMedida = [
  { "value": "(extratv./km)", "name": "(extratv./km)" },
  { "value": "(l/hab.dia)", "name": "(l/hab.dia)" },
  { "value": "(l/s)", "name": "(l/s)" },
  { "value": "%", "name": "%" },
  { "value": "Agentes", "name": "Agentes" },
  { "value": "Alunos", "name": "Alunos" },
  { "value": "Anos", "name": "Anos" },
  { "value": "Áreas", "name": "Áreas" },
  { "value": "Atletas", "name": "Atletas" },
  { "value": "Comunidades", "name": "Comunidades" },
  { "value": "Conceito", "name": "Conceito" },
  { "value": "Contratos", "name": "Contratos" },
  { "value": "Convênios", "name": "Convênios" },
  { "value": "Crianças", "name": "Crianças" },
  { "value": "Dias", "name": "Dias" },
  { "value": "Entrega", "name": "Entrega" },
  { "value": "Espaços", "name": "Espaços" },
  { "value": "Favelas", "name": "Favelas" },
  { "value": "Habitantes", "name": "Habitantes" },
  { "value": "Hectares", "name": "Hectares" },
  { "value": "Hora", "name": "Hora" },
  { "value": "Hora/Mês", "name": "Hora/Mês" },
  { "value": "Hospitais", "name": "Hospitais" },
  { "value": "Jovens", "name": "Jovens" },
  { "value": "Km", "name": "Km" },
  { "value": "Km²", "name": "Km²" },
  { "value": "m²", "name": "m²" },
  { "value": "m³", "name": "m³" },
  { "value": "Metros", "name": "Metros" },
  { "value": "micrograma de material particulado/m³", "name": "micrograma de material particulado/m³" },
  { "value": "Minutos", "name": "Minutos" },
  { "value": "Moeda", "name": "Moeda" },
  { "value": "Nota", "name": "Nota" },
  { "value": "Nota média padronizada", "name": "Nota média padronizada" },
  { "value": "Número absoluto", "name": "Número absoluto" },
  { "value": "Órgãos", "name": "Órgãos" },
  { "value": "Pesquisas", "name": "Pesquisas" },
  { "value": "Pessoas", "name": "Pessoas" },
  { "value": "Posição", "name": "Posição" },
  { "value": "Posição no ranking", "name": "Posição no ranking" },
  { "value": "Projetos", "name": "Projetos" },
  { "value": "R$", "name": "R$" },
  { "value": "R$ bilhões", "name": "R$ bilhões" },
  { "value": "Refeições", "name": "Refeições" },
  { "value": "Sessões", "name": "Sessões" },
  { "value": "Taxa", "name": "Taxa" },
  { "value": "tCO2e das emissões", "name": "tCO2e das emissões" },
  { "value": "Tempo", "name": "Tempo" },
  { "value": "Toneladas", "name": "Toneladas" },
  { "value": "Trabalhadores", "name": "Trabalhadores" },
  { "value": "Unidade", "name": "Unidade" },
  { "value": "Unidade por mil nascidos vivos", "name": "Unidade por mil nascidos vivos" },
  { "value": "Unidades por 100.000", "name": "Unidades por 100.000" },
  { "value": "Vagas", "name": "Vagas" },
  { "value": "Visitantes", "name": "Visitantes" },
  { "value": "Visualizações", "name": "Visualizações" }
];