export const PlanoInstitucionalIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="plano_institucional">
        <path id="Vector" d="M70 40L70 -3.89454e-06L20 -1.11273e-06L20 20L1.11273e-06 20L5.00726e-06 90L40 90L40 70L50 70L50 90L90 90L90 40L70 40ZM20 80L10 80L10 70L20 70L20 80ZM20 60L10 60L10 50L20 50L20 60ZM20 40L10 40L10 30L20 30L20 40ZM40 60L30 60L30 50L40 50L40 60ZM40 40L30 40L30 30L40 30L40 40ZM40 20L30 20L30 10L40 10L40 20ZM60 60L50 60L50 50L60 50L60 60ZM60 40L50 40L50 30L60 30L60 40ZM60 20L50 20L50 10L60 10L60 20ZM80 80L70 80L70 70L80 70L80 80ZM80 60L70 60L70 50L80 50L80 60Z" fill="#004A80" />
      </g>
    </svg>

  )
}
