import { useTheme } from "@/services/zustand/theme";

export const GestaoResiduosSolidos = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56 105H32.625C30.625 105 28.875 104.25 27.375 102.75C25.875 101.25 25.125 99.5 25.125 97.5V26.25H19.75V18.75H43.375V15H74.375V18.75H98V26.25H92.625V50.125C91.4583 49.7917 90.2083 49.5 88.875 49.25C87.5417 49 86.2917 48.8333 85.125 48.75V26.25H32.625V97.5H52.25C52.5833 98.4167 53.125 99.625 53.875 101.125C54.625 102.625 55.3333 103.917 56 105ZM45 86.75H48.75C48.75 80.25 49.375 75.4583 50.625 72.375L52.5 67.75V36.875H45V86.75ZM65.375 54C66.375 53.3333 67.5833 52.6875 69 52.0625C70.4167 51.4375 71.7083 50.9167 72.875 50.5V36.875H65.375V54ZM83.4983 110.125C76.1661 110.125 69.9375 107.541 64.8125 102.373C59.6875 97.2054 57.125 90.9554 57.125 83.6232C57.125 76.2911 59.6966 70.0625 64.8398 64.9375C69.9828 59.8125 76.2029 57.25 83.5 57.25C90.8317 57.25 97.0813 59.8215 102.249 64.9646C107.416 70.1078 110 76.3279 110 83.625C110 90.9567 107.416 97.2062 102.248 102.374C97.0804 107.541 90.8304 110.125 83.4983 110.125ZM91.625 95.5L95.125 92L85.875 82.75V68.75H80.875V84.75L91.625 95.5Z"
        fill="url(#paint0_linear_1941_43873)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43873"
          x1="40.6203"
          y1="29.2688"
          x2="86.8288"
          y2="100.221"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
