import { useTheme } from "@/services/zustand/theme";

export const EstrategicoOperacoesResiliencia = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.375 79H63.75V54.75L73.625 64.875L79 59.5L59.875 40.5L40.875 59.5L46.25 64.875L56.375 54.75V79ZM17.5 100C15.5 100 13.75 99.25 12.25 97.75C10.75 96.25 10 94.5 10 92.5V27.5C10 25.5 10.75 23.75 12.25 22.25C13.75 20.75 15.5 20 17.5 20H102.5C104.5 20 106.25 20.75 107.75 22.25C109.25 23.75 110 25.5 110 27.5V92.5C110 94.5 109.25 96.25 107.75 97.75C106.25 99.25 104.5 100 102.5 100H17.5ZM17.5 92.5H102.5V27.5H17.5V92.5Z"
        fill="url(#paint0_linear_2012_91960)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2012_91960"
          x1="33.125"
          y1="32"
          x2="66.8828"
          y2="100.293"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
