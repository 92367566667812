import React from "react";
import dayjs from "dayjs";
import * as Yup from 'yup';
import { TrashIcon } from "./trash";
import { Container } from './styled';
import { useRouter } from "next/router";
import { LoadingBuffer } from "../utils/Loading";
import { Grid, Pagination } from '@mui/material';
import { yupResolver } from "@hookform/resolvers/yup";
import { DeleteElaborationDialog } from "../../Modal/Delete";
import { TextFieldElaboration } from "../../Inputs/TextField";
import { ActionButtonElaborations } from "../../ActionButton";
import { useTipeOfFlow } from "@/services/zustand/tipo_fluxo";
import { DatePickerElaboration } from "../../Inputs/DatePicker";
import { SelectFieldElaboration } from "../../Inputs/SelectField";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { CreateUnityElaboration } from "../../Modal/CreateUnidade";
import { RadioCheckedElaboration } from "../../Inputs/RadioButton";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { ReutilizarElaborationDialog } from "../Vision/ReutilizarModal";
import { useSnackbarElaboration } from "@/hooks/useSnackbarElaboration";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { MultipleSelect } from "@/components/global/Inputs/MultipleInput";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { DisclaimerReuseElaborationDialog } from "../Vision/disclaimerModal";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";
import { analisarTerritorioArray, encontrarCorrespondentes, handleContentRelatedToTerritorySelected, removeDuplicatesFromA, transformArray } from "../utils";
import { isNumber } from "lodash";

type InputsProductsDeliveries = {
  produtos_entregas: any;
  is_optional?: boolean;
}

const SchemaProductsDeliveries = Yup.object().shape({
  produtos_entregas: Yup.array().of(
    Yup.object().shape({
      valor: Yup.string(),
      texto: Yup.string().required('Campo obrigatório'),
      orgao: Yup.string().required('Campo obrigatório'),
      setor: Yup.string().required('Campo obrigatório'),
      territorio: Yup.string().required('Campo obrigatório'),
      responsavel: Yup.string().required('Campo obrigatório'),
      prazo: Yup.date().nonNullable().required('Campo obrigatório'),
      indicadores: Yup.array().of(
        Yup.object().shape({
          fonte: Yup.string().required("Campo obrigatório"),
          indicador: Yup.string().required('Campo obrigatório'),
          polaridade: Yup.string().required('Campo obrigatório'),
          unidadeMedida: Yup.string().required('Campo obrigatório'),
          formulaCalculo: Yup.string().required('Campo obrigatório'),
          planejados: Yup.array().of(
            Yup.object().shape({
              planejado: Yup.string().required('Campo obrigatório'),
              prazo: Yup.date().nonNullable().required('Campo obrigatório'),
            })
          )
        })
      )
    })
  ).required(''),
});

const defaultValue = {
  id: '',
  tor: [],
  texto: '',
  valor: '',
  orgao: '',
  setor: '',
  territorio: '',
  responsavel: '',
  indicadores: [
    {
      id: '',
      fonte: '',
      indicador: '',
      polaridade: '',
      unidadeMedida: '',
      formulaCalculo: '',
      planejados: [
        {
          id: '',
          prazo: null,
          planejado: '',
        }
      ]
    }
  ]
}

const transformProductsDeliveriesData = (data: any[], label?: string) => {
  return data.map(item => {
    let tor = [] as any[]
    tor = item?.entregasTerritorio.map((m: any) => {
      if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
      if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
      if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
      if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
      return null;
    }).filter(Boolean);

    const territorio = analisarTerritorioArray(item.entregasTerritorio);

    return {
      tor,
      territorio,
      id: item?.id,
      texto: item?.texto || '',
      orgao: item?.orgao?.id || '',
      setor: item?.setor?.id || '',
      prazo: item?.data ? dayjs(item?.data) : null,
      valor: item?.valor || '',
      responsavel: item?.responsavel || '',
      indicadores: item?.indicadores?.map((indicador: any) => ({
        id: indicador?.id,
        fonte: indicador?.fonte || '',
        indicador: indicador?.indicador || '',
        polaridade: indicador?.polaridade || '',
        unidadeMedida: indicador?.unidadeMedida || '',
        formulaCalculo: indicador?.formulaCalculo || '',
        planejados: indicador?.values?.map((planejado: any) => ({
          id: planejado?.id,
          prazo: planejado?.data ? dayjs(planejado?.data) : null,
          planejado: planejado?.planejado || ''
        }))
      }))
    };
  });
};

const transformProductsDeliveriesDataReuse = (item: any) => {
  let tor = [] as any[]

  tor = item?.entregasTerritorio.map((m: any) => {
    if (m?.bairro) return { id: m?.bairro?.id, descricao: m?.bairro?.name };
    if (m?.regiaoAdministrativa) return { id: m?.regiaoAdministrativa?.id, descricao: m?.regiaoAdministrativa?.name };
    if (m?.areaPlanejamento) return { id: m?.areaPlanejamento?.id, descricao: m?.areaPlanejamento?.name };
    if (m?.regiaoPlanejamento) return { id: m?.regiaoPlanejamento?.id, descricao: m?.regiaoPlanejamento?.name };
    return null;
  }).filter(Boolean);

  const territorio = analisarTerritorioArray(item.entregasTerritorio);

  return {
    texto: item?.texto || '',
    territorio,
    tor,
    valor: item?.valor || '',
    orgao: item?.orgao?.id || '',
    setor: item?.setor?.id || '',
    responsavel: item?.responsavel || '',
    prazo: item?.data ? dayjs(item?.data) : null,
    indicadores: item?.indicadores?.map((indicador: any) => ({
      id: indicador?.id,
      fonte: indicador?.fonte || '',
      indicador: indicador?.indicador || '',
      polaridade: indicador?.polaridade || '',
      unidadeMedida: indicador?.unidadeMedida || '',
      formulaCalculo: indicador?.formulaCalculo || '',
      planejados: indicador?.values?.map((planejado: any) => ({
        id: planejado?.id,
        prazo: planejado?.data ? dayjs(planejado?.data) : null,
        planejado: planejado?.planejado || ''
      }))
    }))
  };
};


export const ProductsAndDeliveriesForm: React.FC = () => {
  const {
    trigger,
    clearErrors,
    watch: watchProductsDeliveries,
    control: controlProductsDeliveries,
    register: registerProductsDeliveries,
    setValue: setValueProductsDeliveries,
    handleSubmit: handleSubmitProductsDeliveries,
    formState: { errors: errosProductsDeliveries }
  } = useForm<InputsProductsDeliveries>({
    resolver: yupResolver(SchemaProductsDeliveries),
    defaultValues: { produtos_entregas: [defaultValue] }
  });

  const { append: appendProductsDeliveries, update: updateProductsDeliveries, remove: removeProductsDeliveries } = useFieldArray({
    control: controlProductsDeliveries,
    name: "produtos_entregas"
  });

  const fields = watchProductsDeliveries('produtos_entregas');
  const is_optional = watchProductsDeliveries('is_optional') as unknown as string;

  const router = useRouter();
  const [page, setPage] = React.useState(1);
  const [label, setLabel] = React.useState('');
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const { tipo: tipoFluxo, planId } = useTipeOfFlow();
  const [filterData, setFilterData] = React.useState({
    setor: [] as any[],
    orgaoResponsavel: [] as any[],
    territorio: {} as Record<string, any>
  });
  const [reutilizar, setReutilizar] = React.useState(false);
  const [deleteGoalId, setDeleteGoalId] = React.useState(0);
  const [objetivos, setObjetivos] = React.useState<any[]>([]);
  const [preview, setPreview] = React.useState<boolean>(false);
  const [disabeSubmit, setDisableSubmit] = React.useState(false);
  const [deletePlannedId, setDeletePlannedId] = React.useState(0);
  const { getAllFilters } = new DashboardManagementPlansService();
  const [deleteGoalIndex, setDeleteGoalIndex] = React.useState(0);
  const [openDisclaimer, setOpenDisclaimer] = React.useState(false);
  const [currentIndexEixo, setCurrentIndexEixo] = React.useState(0);
  const [deleteIndicatorId, setDeleteIndicatorId] = React.useState(0);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deletePlannedIndex, setDeletePlannedIndex] = React.useState(0);
  const [openDeleteModal3, setOpenDeleteModal3] = React.useState(false);
  const [eixos_tematicos, setEixosTematicos] = React.useState<any[]>([]);
  const [deleteIndicatorIndex, setDeleteIndicatorIndex] = React.useState(0);
  const [openDeleteLinhaModal, setOpenDeleteLinhaModal] = React.useState(false);
  const { toggleErrorSnackbar, toggleSuccessSnackbar } = useSnackbarElaboration();
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handleFetchProgressCodePlanId, setDefaultItemMenuId, setNavigable, completCode } = usePreparationOfPlans();

  const total = objetivos.length;

  const {
    PostProgressCode,
    NumberElaboration,
    IndicatorsElaboration,
    DeleteNumbersElaboration,
    GetThematicAxisElaboration,
    DeleteIndicatorElaboration,
    GetProgramsEixoElaborations,
    GetProjectsEixoElaborations,
    ProductsDeliveriesElaboration,
    GetMacroactionsEixoElaborations,
    PostUnidadesMedida,
    GetUnidadesMedida,
    GetProductsDeliveriesElaborations,
    DeleteProductsDeliveriesElaborations,
    UpdateProductsDeliveriesElaborations,
  } = new ElaborationService();

  const handleExternalSubmit = async () => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitProductsDeliveries(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
  };

  function getMessageErrorByNameRef(json: any, title: any) {
    const foundObject = json?.produtos_entregas?.find((item: any) => item[title]);
    return foundObject ? foundObject[title].message : null;
  }

  function getMessageErrorByNameRefSubArray(json: any, title: any, index: any) {
    const foundObject = json?.produtos_entregas?.map((item: any) => {
      return item?.indicadores?.find((subItem: any) => subItem[title]);
    })

    return foundObject && foundObject[index] ? foundObject[index][title].message : null;
  }

  function getMessageErrorByNameRefSubArrayPlanned(json: any, title: any, index: any) {
    if (Object.keys(json).length == 0) return null;

    const planejadosArray = json.produtos_entregas.flatMap((item: any) =>
      item?.indicadores?.flatMap((indicador: any) => indicador?.planejados)
    );

    return planejadosArray && planejadosArray[index] && planejadosArray[index][title] ? planejadosArray[index][title].message : null;
  }

  const handleChange = async (event: any, value: any) => {
    const isValid = await trigger();

    if (isValid) {
      handleSubmitProductsDeliveries(onSubmit)({
        data: fields,
        nativeEvent: { submitter: { name: 'any' } }
      } as any);
    }
    setPage(value);
  };

  const handleLeftClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === 0 ? eixos_tematicos.length - 1 : currentIndexEixo - 1);
  };

  const handleRightClick = () => {
    handleExternalSubmit()
    setCurrentIndexEixo(currentIndexEixo === eixos_tematicos.length - 1 ? 0 : currentIndexEixo + 1);
  };

  const filterSectors = (data: any, orgao: any) => {
    const findItem = data?.orgaoResponsavel?.find((item: any) => item?.id == orgao);
    const filterItems =
      data?.setor?.filter((item: any) => item.codUa == findItem?.codUa)
        .map((item: any) => ({ value: item?.id, name: item?.descricao }))

    return filterItems
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      GetThematicAxisElaboration(router.query.id).then(res => {
        setEixosTematicos(res.data);
      });
    }
  }, [router.query.id, router.pathname, completCode]);

  React.useEffect(() => {
    setLoading(true);
    if ((router.query.id && router.pathname.includes('elaboracao_planos') && eixos_tematicos[currentIndexEixo]?.id) || (router.pathname.includes('aprovacao_planos') && eixos_tematicos[currentIndexEixo]?.id)) {
      const projetos = completCode.find(item => item.code == 'projetos' && item.skipped === false);
      const macroacoes = completCode.find(item => item.code == 'macroacoes' && item.skipped === false);
      const programas = completCode.find(item => item.code == 'programas' && item.skipped === false);


      if (programas !== undefined && macroacoes != undefined && projetos == undefined) {

        GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Programas');
        }).finally(() => setLoading(false));
        return;
      }

      if (projetos !== undefined && macroacoes == undefined && programas == undefined) {

        GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Projetos');
        }).finally(() => setLoading(false));
        return;
      }

      if (projetos != undefined && macroacoes == undefined && projetos != undefined) {

        GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Projetos');
        }).finally(() => setLoading(false));
        return;
      }

      if (programas != undefined && macroacoes != undefined && projetos != undefined) {
        GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          console.log('res data', res.data)
          setLabel('Projetos');
        }).finally(() => setLoading(false));
        return;
      }

      if (programas == undefined && macroacoes != undefined && projetos != undefined) {
        GetProjectsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Projetos');
        }).finally(() => setLoading(false));
        return;
      }

      if (programas != undefined && macroacoes == undefined && projetos == undefined) {

        GetProgramsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Programas');
        }).finally(() => setLoading(false));
        return;
      }

      if (macroacoes != undefined && programas == undefined && projetos == undefined) {
        GetMacroactionsEixoElaborations(router.query.id, eixos_tematicos[currentIndexEixo]?.id).then(res => {
          setObjetivos(res.data);
          setLabel('Macroação');
        }).finally(() => setLoading(false));
      }
    }
  }, [completCode, router.query, router.pathname, eixos_tematicos, currentIndexEixo, completCode]);

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      const data = objetivos[page - 1];
      clearErrors()

      if (label == 'Macroação') {
        if (data?.entregas?.length > 0) {
          setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregas, label));
        } else {
          setValueProductsDeliveries('produtos_entregas', [defaultValue])
        }
      }

      if (label == 'Projetos') {

        if (data?.entregasProjetos?.length > 0) {
          console.log('data.entregasProjetos', data.entregasProjetos)
          setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregasProjetos, label));
        } else {
          setValueProductsDeliveries('produtos_entregas', [defaultValue])
        }
      }
      if (label == 'Programas') {
        console.log('data', data)
        if (data?.entregas?.length > 0) {
          setValueProductsDeliveries('produtos_entregas', transformProductsDeliveriesData(data.entregas, label));
        } else {
          setValueProductsDeliveries('produtos_entregas', [defaultValue])
        }
      }


    }
  }, [objetivos, page, router.query, router.pathname, completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orçamentário') {
      setValueProductsDeliveries("is_optional", false)
    }
  }, []);

  React.useEffect(() => {
    if (router && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos') && tipoFluxo == 'substituir') {
      GetProductsDeliveriesElaborations(router.query.id).then(res => {
        setData(res.data);
      });
    }
  }, [planId, tipoFluxo, router.pathname]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'produtos_entregas');

    if (code?.code?.length > 0) {
      setDisable(true);
    } else {
      setDisable(false)
    }
  }, [completCode]);

  React.useEffect(() => {
    const code = completCode.find(item => item.code == 'produtos_entregas' && item.skipped == false);

    if (code != undefined) {
      setValueProductsDeliveries('is_optional', true)
    }
  }, [completCode]);

  React.useEffect(() => {
    if (zustandSelectedCardItem.title == "Plano de Gestão" || zustandSelectedCardItem.title == 'Plano Orçamentário') {
      setValueProductsDeliveries('is_optional', true);
    }
  }, [zustandSelectedCardItem])

  React.useEffect(() => {
    if (!router.isReady) return

    setPreview(router.asPath.includes('preview'));
  }, [router]);

  React.useEffect(() => {
    if (router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos'))
      getAllFilters().then((res: any) => {
        const { data } = res.data as any;
        setFilterData(data);
      })
  }, [router.pathname]);

  const onSubmit: SubmitHandler<any> = async (data, event: any) => {
    const { produtos_entregas } = data;

    if (disabeSubmit) return;

    setDisableSubmit(true);

    let submittedData = produtos_entregas;
    if (event?.data?.length > 0) {
      submittedData = event.data;
    }

    let eixo = '';
    if (label === 'Macroação') eixo = 'macroId';
    if (label === 'Programas') eixo = 'programaId';
    if (label === 'Projetos') eixo = 'projetosId';

    try {
      const indicatorPromises = submittedData.map(async (itemMeta: any, indexMeta: any) => {
        return Promise.all(
          itemMeta.indicadores.map(async (itemIndicador: any, indexIndicador: any) => {
            const { planejados, unidadeMedida: uni, id, ...rest } = itemIndicador;

            const indicador = isNumber(id) ? {
              ...rest,
              id,
              planoId: router.query.id,
              unidadeMedida: unidadesMedida.find(item => item.value === uni)?.name
            } :
              {
                ...rest,
                planoId: router.query.id,
                unidadeMedida: unidadesMedida.find(item => item.value === uni)?.name
              };

            const res = await IndicatorsElaboration(indicador);
            const data = res.data;
            setValueProductsDeliveries(`produtos_entregas.${indexMeta}.indicadores.${indexIndicador}.id`, data.id)
          })
        );
      });

      await Promise.all(indicatorPromises);

      const goalPromises = fields.map(async (itemMeta: any, indexMeta: any) => {
        const { indicadores, tor, id, ...rest } = itemMeta;

        const meta = {
          ...rest,
          planoId: router.query.id,
          [eixo]: objetivos[page - 1]?.id,
          eixo_tematico: eixos_tematicos[currentIndexEixo]?.id,
          indicadoresIds: indicadores.map((item: any) => item.id),
          prazo: dayjs(itemMeta.prazo).format('YYYY-MM-DD') + 'T00:00:00',
          territorio: encontrarCorrespondentes(itemMeta.tor, filterData.territorio),
        };

        if (itemMeta.id) {
          await UpdateProductsDeliveriesElaborations(meta, itemMeta.id);
        } else {
          await ProductsDeliveriesElaboration(meta, router.query.id);
        }

        const plannedPromises = indicadores.flatMap((indicadorItem: any, indexIndicador: any) => {
          return indicadorItem.planejados.map(async (planejadoItem: any, indexPlanejado: any) => {
            const planejado = isNumber(planejadoItem.id) ? {
              id: planejadoItem.id,
              planoId: Number(router.query.id),
              planejado: Number(planejadoItem.planejado),
              indicadorId: indicadorItem.id,
              date: dayjs(planejadoItem.prazo).format('YYYY-MM-DD') + 'T00:00:00',
            } : {
              planoId: Number(router.query.id),
              planejado: Number(planejadoItem.planejado),
              indicadorId: indicadorItem.id,
              date: dayjs(planejadoItem.prazo).format('YYYY-MM-DD') + 'T00:00:00',
            };

            const res = await NumberElaboration(planejado);
            const data = res.data;

            setValueProductsDeliveries(`produtos_entregas.${indexMeta}.indicadores.${indexIndicador}.planejados.${indexPlanejado}.id`, data.id);
          });
        });

        await Promise.all(plannedPromises);
      });

      await Promise.all(goalPromises);

      toggleSuccessSnackbar();

      if (event.nativeEvent.submitter.name == 'next_step') {
        setNavigable(true);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        setDefaultItemMenuId(planId);
      } else {
        setNavigable(false);
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const planId = plan[selectedItemsMenu?.layer as string]
          .find((item: any) => item.id == selectedItemsMenu?.id).id;
        setDefaultItemMenuId(planId);
        router.push('/dashboard/elaboracao_planos/' + router.query.id);
        handleFetchProgressCodePlanId(router.query.id);
      }

      setTimeout(() => toggleSuccessSnackbar(), 3000);
      setDisableSubmit(false);
    } catch {
      toggleErrorSnackbar();
      setDisableSubmit(false);
    }
  };
  const ThematicAxisBanner = React.useCallback(() => {
    if (Boolean(is_optional) == false || is_optional == undefined) return null;
    if (is_optional == 'false' || is_optional == undefined) return null;

    return (
      <>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12} className="goals_select_content_wrapper">
          <p>Eixo temático</p>
        </Grid>
        <Grid item xs={12}>
          <div className="goals_select_wrapper">
            <div className="goals_left_arrow" onClick={() => handleLeftClick()}>{"<"}</div>
            <div className="goals_select_content_wrapper">
              <p>{eixos_tematicos[currentIndexEixo]?.texto}</p>
              <span>Eixo temático {currentIndexEixo + 1} de {eixos_tematicos.length}</span>
            </div>
            <div className="goals_rigth_arrow" onClick={() => handleRightClick()}>{">"}</div>
          </div>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
          <div className="pagination_wrapper">
            <Pagination
              count={total}
              page={page}
              onChange={handleChange}
              hidePrevButton hideNextButton
              variant="outlined" shape="rounded"
            />
            <p>Página {page} de {total}</p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="strategy_wrapper">
            <p>
              <b>{label}:</b>&nbsp;
              {objetivos[page - 1]?.texto}
            </p>
          </div>
        </Grid>
      </>
    );
  }, [page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, is_optional, objetivos]);

  const [openUnity, setOpenUnity] = React.useState(false);
  const [unidadesMedida, setUnidadesMedida] = React.useState<any[]>([]);

  React.useEffect(() => {
    GetUnidadesMedida().then(res => {
      const { data } = res.data as any;
      setUnidadesMedida(data.map((item: any) => ({ value: item.unidade, name: item.unidade })));
    })
  }, []);


  const handleSaveUnity = (e: any) => {
    PostUnidadesMedida({ descricao: e }).then(res => {
      GetUnidadesMedida().then(res => {
        const { data } = res.data as any;
        setUnidadesMedida(data.map((item: any) => ({ value: item.unidade, name: item.unidade })));
        setOpenUnity(false);
      })
    })
  };

  const [goalSelectedIndex, setGoalIndex] = React.useState(0);
  const [indicadoresSelected, setIndicadores] = React.useState({} as any);
  const Fields = React.useCallback(() => {
    if (Boolean(is_optional) == false || is_optional == undefined) return null;
    if (is_optional == 'false' || is_optional == undefined) return null;

    return (
      <>
        <Grid item xs={12} sx={{ ml: 2 }}>
          {fields?.map((field: any, goalIndex: any) => {
            const randomProductId = Math.random();
            const tor = watchProductsDeliveries(`produtos_entregas.${goalIndex}.tor`);
            const orgao = watchProductsDeliveries(`produtos_entregas.${goalIndex}.orgao`);
            const setor = watchProductsDeliveries(`produtos_entregas.${goalIndex}.setor`);
            const prazo = watchProductsDeliveries(`produtos_entregas.${goalIndex}.prazo`);
            const texto = watchProductsDeliveries(`produtos_entregas.${goalIndex}.texto`);
            const territorio = watchProductsDeliveries(`produtos_entregas.${goalIndex}.territorio`);
            const responsavel = watchProductsDeliveries(`produtos_entregas.${goalIndex}.responsavel`);
            const publicoAlvo = watchProductsDeliveries(`produtos_entregas.${goalIndex}.publicoAlvo`);

            return (
              <React.Fragment key={randomProductId}>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 2,
                    borderRadius: '5px',
                    background: '#DFDFDF',
                    padding: '0 1rem  0 0'
                  }}
                >
                  <Grid item xs={12}>
                    <input
                      type="hidden"
                      defaultValue={field?.id}
                      {...registerProductsDeliveries(`produtos_entregas.${goalIndex}.id`, { value: field?.id })}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <DatePickerElaboration
                      width="90%"
                      label="Prazo"
                      commentTittle={texto}
                      required={true}
                      defaultValue={prazo}
                      errors={errosProductsDeliveries}
                      control={controlProductsDeliveries}
                      name={`produtos_entregas.${goalIndex}.prazo`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'prazo')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldElaboration
                      rows={3}
                      label="Produto/Entrega"
                      multiline={true}
                      required={true}
                      commentTittle={texto}
                      errors={errosProductsDeliveries}
                      defaultValue={texto}
                      register={registerProductsDeliveries}
                      placeholder="Digite a descrição do produto/entrega"
                      name={`produtos_entregas.${goalIndex}.texto`}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'texto')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <SelectFieldElaboration
                      required={true}
                      label="Território"
                      commentTittle={texto}
                      defaultValue={territorio}
                      errors={errosProductsDeliveries}
                      register={registerProductsDeliveries}
                      placeholder="Selecione o território"
                      data={[
                        { value: 0, name: '-' },
                        { value: 1, name: 'AP' },
                        { value: 2, name: 'RP' },
                        { value: 3, name: 'RA' },
                        { value: 4, name: 'Bairros' }
                      ]}
                      name={`produtos_entregas.${goalIndex}.territorio`}
                      onClick={() => updateProductsDeliveries(goalIndex, { ...field, tor: [] })}
                      setValue={(value) => setValueProductsDeliveries(`produtos_entregas.${goalIndex}.territorio`, value)}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'territorio')}</span>
                    </div>
                  </Grid>
                  {territorio != 0 && (
                    <Grid item xs={12}>
                      <MultipleSelect
                        fullWidth={true}
                        value={field.tor}
                        setValue={(e) => {
                          const items = removeDuplicatesFromA(tor, e)
                          updateProductsDeliveries(goalIndex, { ...field, tor: items })
                        }}
                        {...registerProductsDeliveries(`produtos_entregas.${goalIndex}.tor`, { required: false })}
                        data={handleContentRelatedToTerritorySelected(filterData, territorio, tor)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={3}>
                    <TextFieldElaboration
                      required={false}
                      type='number'
                      label="Valor"
                      commentTittle={texto}
                      defaultValue={publicoAlvo}
                      placeholder="Digite o valor"
                      errors={errosProductsDeliveries}
                      register={registerProductsDeliveries}
                      name={`produtos_entregas.${goalIndex}.valor`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'valor')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectFieldElaboration
                      label="Órgão"
                      required={true}
                      defaultValue={orgao}
                      commentTittle={texto}
                      placeholder="Selecione"
                      errors={errosProductsDeliveries}
                      register={registerProductsDeliveries}
                      name={`produtos_entregas.${goalIndex}.orgao`}
                      data={filterData.orgaoResponsavel.map((item) => ({ value: item.id, name: item.sigla }))}
                      setValue={(value) => setValueProductsDeliveries(`produtos_entregas.${goalIndex}.orgao`, value)}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'orgao')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <SelectFieldElaboration
                      label="Setor"
                      required={true}
                      defaultValue={setor}
                      commentTittle={texto}
                      placeholder="Selecione"
                      errors={errosProductsDeliveries}
                      register={registerProductsDeliveries}
                      data={filterSectors(filterData, orgao)}
                      name={`produtos_entregas.${goalIndex}.setor`}
                      setValue={(value) => setValueProductsDeliveries(`produtos_entregas.${goalIndex}.setor`, value)}
                    />
                    <div className="error_wrapper">
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'setor')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={3} sx={{ mt: -.3 }}>
                    <TextFieldElaboration
                      required={true}
                      errors={errosProductsDeliveries}
                      label="Responsável"
                      register={registerProductsDeliveries}
                      defaultValue={responsavel}
                      commentTittle={texto}
                      placeholder="Nome do responsável"
                      name={`produtos_entregas.${goalIndex}.responsavel`}
                    />
                    <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                      <span>{getMessageErrorByNameRef(errosProductsDeliveries, 'responsavel')}</span>
                    </div>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <p className="sub_item_topographic">
                      Indicadores
                    </p>
                  </Grid>
                  {field?.indicadores?.map((indicator: any, indicatorIndex: any, arr: any[]) => {
                    const randomIdIndicadores = Math.random();
                    const lastIdesOfIndicator = arr.lastIndexOf(arr[arr.length - 1]);
                    const fonte = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.fonte`);
                    const indicador = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.indicador`);
                    const unidade = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`);
                    const polaridade = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.polaridade`);
                    const formula = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.formulaCalculo`);

                    return (
                      <React.Fragment key={randomIdIndicadores}>
                        <Grid
                          container
                          spacing={2}
                          className="sub_container"
                          sx={{ background: '#fff', margin: '0 0 1rem 1rem', padding: '0 1rem 1rem 1rem ' }}
                        >
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <TextFieldElaboration
                              required={true}
                              label="Indicador"
                              commentTittle={texto}
                              defaultValue={indicador}
                              placeholder="Digite o indicador"
                              errors={errosProductsDeliveries}
                              register={registerProductsDeliveries}
                              name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.indicador`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosProductsDeliveries, 'indicador', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <SelectFieldElaboration
                              required={true}
                              data={unidadesMedida}
                              commentTittle={texto}
                              defaultValue={unidade}
                              label="Unidade de medida"
                              errors={errosProductsDeliveries}
                              register={registerProductsDeliveries}
                              handleCreate={() => setOpenUnity(true)}
                              placeholder="Crie uma nova unidade de medida"
                              name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`}
                              setValue={(value) => setValueProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.unidadeMedida`, value)}
                            />
                            <div className="error_wrapper" style={{ marginTop: '' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosProductsDeliveries, 'unidadeMedida', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <TextFieldElaboration
                              required={true}
                              placeholder="Digite"
                              defaultValue={formula}
                              commentTittle={texto}
                              label="Fórmula de cálculo"
                              errors={errosProductsDeliveries}
                              register={registerProductsDeliveries}
                              name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.formulaCalculo`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosProductsDeliveries, 'formulaCalculo', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3} sx={{ mt: 0 }}>
                            <TextFieldElaboration
                              label="Fonte"
                              required={true}
                              placeholder="Digite"
                              commentTittle={texto}
                              defaultValue={fonte}
                              errors={errosProductsDeliveries}
                              register={registerProductsDeliveries}
                              name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.fonte`}
                            />
                            <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosProductsDeliveries, 'fonte', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          <Grid item xs={3} sx={{ mt: 0 }}>
                            <SelectFieldElaboration
                              required={true}
                              label="Polaridade"
                              commentTittle={texto}
                              placeholder="Selecione"
                              defaultValue={polaridade}
                              errors={errosProductsDeliveries}
                              register={registerProductsDeliveries}
                              name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.polaridade`}
                              data={[{ value: 'Negativo', name: 'Negativo' }, { value: 'Positivo', name: 'Positivo' }, { value: 'Neutro', name: 'Neutro' }]}
                              setValue={(value) => setValueProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.polaridade`, value)}
                            />
                            <div className="error_wrapper" style={{ marginTop: '' }}>
                              <span>{getMessageErrorByNameRefSubArray(errosProductsDeliveries, 'polaridade', indicatorIndex)}</span>
                            </div>
                          </Grid>
                          {indicator?.planejados?.map((plannedField: any, plannedIndex: any, array: any) => {
                            const randomIdPlanejados = Math.random();
                            const lastIdesOfPlanned = array.lastIndexOf(array[array.length - 1]);
                            const id = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.id`);
                            const prazo = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.prazo`);
                            const planejado = watchProductsDeliveries(`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.planejado`);
                            return (
                              <React.Fragment key={randomIdPlanejados}>
                                <Grid item xs={12}>
                                  <input
                                    type="hidden"
                                    {
                                    ...registerProductsDeliveries(
                                      `produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.id`,
                                      { value: id })}
                                    defaultValue={id}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <DatePickerElaboration
                                    width="90%"
                                    commentTittle={texto}
                                    label="Prazo"
                                    required={true}
                                    defaultValue={prazo}
                                    errors={errosProductsDeliveries}
                                    control={controlProductsDeliveries}
                                    name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.prazo`}
                                  />
                                  <div className="error_wrapper" style={{ marginTop: '2rem' }}>
                                    <span>{getMessageErrorByNameRefSubArrayPlanned(errosProductsDeliveries, 'prazo', plannedIndex)}</span>
                                  </div>
                                </Grid>
                                <Grid item xs={9}>
                                  <TextFieldElaboration
                                    type='number'
                                    required={true}
                                    label="Planejado"
                                    errors={errosProductsDeliveries}
                                    commentTittle={texto}
                                    defaultValue={planejado}
                                    register={registerProductsDeliveries}
                                    placeholder="Digite o valor do planejado para o indicador da produtos_entregas"
                                    name={`produtos_entregas.${goalIndex}.indicadores.${indicatorIndex}.planejados.${plannedIndex}.planejado`}
                                  />
                                  <div className="error_wrapper" style={{ marginTop: '1rem' }}>
                                    <span>{getMessageErrorByNameRefSubArrayPlanned(errosProductsDeliveries, 'planejado', plannedIndex)}</span>
                                  </div>
                                </Grid>
                                <Grid item xs={12} sx={{ mb: 2, display: 'flex', justifyContent: lastIdesOfPlanned == plannedIndex ? 'space-between' : 'flex-end' }}>
                                  {lastIdesOfPlanned == plannedIndex && (
                                    <div className="add_line_wrapper">
                                      <p onClick={() => {
                                        const newPlanejados = [
                                          ...indicator.planejados,
                                          { prazo: null, planejado: '' },
                                        ];
                                        updateProductsDeliveries(goalIndex, {
                                          ...field,
                                          indicadores: field.indicadores.map((ind: any, i: number) => i === indicatorIndex ? { ...ind, planejados: newPlanejados } : ind)
                                        });
                                      }}>
                                        {`+ Adicionar linha`}
                                      </p>
                                    </div>
                                  )}
                                  {plannedIndex != 0 && (
                                    <div
                                      className="remove_line_wrapper"
                                      onClick={() => {
                                        setOpenDeleteLinhaModal(true);
                                        setDeletePlannedIndex(plannedIndex);
                                        setDeletePlannedId(plannedField.id);
                                        setGoalIndex(goalIndex)
                                        setIndicadores(indicator)
                                      }}
                                    >
                                      <TrashIcon />
                                      <p>Excluir</p>
                                    </div>
                                  )}
                                </Grid>
                                <DeleteElaborationDialog open={openDeleteLinhaModal} setOpen={(e) => setOpenDeleteLinhaModal(e)} onDelete={() => {
                                  const newPlanejados = indicadoresSelected.planejados.filter((_: any, i: any) => i != deletePlannedIndex);
                                  
                                  updateProductsDeliveries(goalSelectedIndex, {
                                    ...field,
                                    indicadores: field.indicadores.map((ind: any, i: number) => i === deleteIndicatorIndex ? { ...ind, planejados: newPlanejados } : ind)
                                  });

                                  if (deletePlannedId != 0) {
                                    DeleteNumbersElaboration(deletePlannedId);
                                    setDeletePlannedId(0)
                                  }

                                  setOpenDeleteLinhaModal(false);
                                }} />
                              </React.Fragment>
                            )
                          })}
                        </Grid>
                        <Grid item xs={12} sx={{ mb: 2, mt: -2, display: 'flex', justifyContent: lastIdesOfIndicator == indicatorIndex ? 'space-between' : 'flex-end' }}>
                          {lastIdesOfIndicator == indicatorIndex && (
                            <div className="add_line_wrapper">
                              <p onClick={() => {
                                const newIndicadores = [
                                  ...field.indicadores,
                                  {
                                    fonte: '',
                                    indicador: '',
                                    polaridade: '',
                                    unidadeMedida: '',
                                    formula_calculo: '',
                                    planejados: [
                                      {
                                        prazo: null,
                                        planejado: '',
                                      }
                                    ]
                                  }
                                ];
                                updateProductsDeliveries(goalIndex, { ...field, indicadores: newIndicadores });
                              }}>
                                {`+ Adicionar indicador para produtos_entregas`}
                              </p>
                            </div>

                          )}
                          {indicatorIndex != 0 && !preview && (
                            <div
                              className="remove_line_wrapper"
                              onClick={() => {
                                setOpenDeleteModal(true);
                                setDeleteIndicatorIndex(indicatorIndex)
                                setDeleteIndicatorId(field.indicadores[indicatorIndex]?.id)
                              }}
                            >
                              <TrashIcon />
                              <p>Excluir</p>
                            </div>
                          )}
                        </Grid>
                        <DeleteElaborationDialog open={openDeleteModal} setOpen={(e) => setOpenDeleteModal(e)} onDelete={() => {
                          const newIndicadores = field.indicadores.filter((_: any, i: any) => i !== deleteIndicatorIndex);
                          updateProductsDeliveries(goalIndex, { ...field, indicadores: newIndicadores });

                          if (deleteIndicatorId != 0) {
                            DeleteIndicatorElaboration(deleteIndicatorId);
                            setDeleteIndicatorId(0)
                          }

                          setOpenDeleteModal(false);
                        }} />
                      </React.Fragment>
                    )
                  })}
                  {goalIndex != 0 && (
                    <Grid item xs={12} sx={{ mb: 1 }} display='flex' justifyContent='flex-end'>
                      <div
                        className="remove_line_wrapper"
                        onClick={() => {
                          setOpenDeleteModal3(true);
                          setDeleteGoalIndex(goalIndex);
                          setDeleteGoalId(field.id)
                        }}
                      >
                        <TrashIcon />
                        <p>Excluir</p>
                      </div>
                    </Grid>
                  )}
                  <DeleteElaborationDialog open={openDeleteModal3} setOpen={(e) => setOpenDeleteModal3(e)} onDelete={() => {
                    removeProductsDeliveries(deleteGoalIndex);

                    if (deleteGoalId != 0) {
                      DeleteProductsDeliveriesElaborations(deleteGoalId);
                      setDeleteGoalId(0)
                    }

                    setOpenDeleteModal3(false);
                  }} />
                </Grid>
              </React.Fragment>
            );

          })}
          <Grid item xs={12} sx={{ ml: -2 }}>
            <div className="add_line_wrapper">
              <p onClick={() => appendProductsDeliveries(defaultValue)}>
                {`+ Adicionar produtos/entregas`}
              </p>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }, [
    fields,
    is_optional,
    filterData,
    openDeleteModal,
    openDeleteModal3,
    openDeleteLinhaModal,
    errosProductsDeliveries,
    watchProductsDeliveries,
    updateProductsDeliveries,
    controlProductsDeliveries,
    registerProductsDeliveries,
    handleContentRelatedToTerritorySelected,
  ]);

  const ReplaceButton = React.useCallback(() => {
    if (is_optional == 'false' || is_optional == undefined && (zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orçamentário')) return null;
    if (tipoFluxo != 'substituir') return null;

    return (
      <Grid item xs={4}>
        <button className="recicle_content_button" type='button' onClick={() => {
          if (data.length > 0) {
            setReutilizar(true);
          } else {
            setOpenDisclaimer(true);
          }
        }}>
          <p>Reutilizar conteúdo</p>
        </button>
      </Grid>
    );
  }, [tipoFluxo, data, objetivos, page, eixos_tematicos, handleChange, handleLeftClick, handleRightClick, label, reutilizar, openDisclaimer]);

  const SubmitButton = React.useCallback(() => {
    const opitional = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'true' ? 'true' : 'false'
      } else {
        return Boolean(is_optional) == true ? 'true' : 'false'
      }
    }

    const optionalV2 = () => {
      if (typeof is_optional == 'string') {
        return is_optional == 'false' || is_optional == undefined;
      } else {
        return Boolean(is_optional) == false || is_optional == undefined;
      }
    }
    return (
      <>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
        <Grid item xs={12}>
          <ActionButtonElaborations disable={disabeSubmit} layer_indicator={false} isOptional={opitional()} onClick={() => {
            if (optionalV2()) {
              setNavigable(true)
              PostProgressCode({ code: 'produtos_entregas', skipped: true }, router.query.id)
              router.push('/dashboard/elaboracao_planos/' + router.query.id);

              const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
              if (plan && plan[selectedItemsMenu?.layer as string]) {
                const planId = plan[selectedItemsMenu?.layer as string]
                  .find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
                setDefaultItemMenuId(planId);
              }
            }
          }} />
        </Grid>
      </>
    );
  }, [is_optional, objetivos, disabeSubmit]);

  if (loading) {
    return <LoadingBuffer />
  }

  return (
    <form onSubmit={handleSubmitProductsDeliveries(onSubmit)}>
      <Container>
        <Grid container spacing={2}>
          {(zustandSelectedCardItem.title == 'Plano Setorial' || zustandSelectedCardItem.title == 'Plano Institucional' || zustandSelectedCardItem.title != 'Plano de Gestão' && zustandSelectedCardItem.title != 'Plano Orçamentário') && (
            <Grid item xs={12}>
              <RadioCheckedElaboration
                required={false}
                name="is_optional"
                disabled={disable}
                errors={errosProductsDeliveries}
                control={controlProductsDeliveries}
                defaultValue={is_optional == 'true' ? 'true' : 'false'}
                data={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                label="Este elemento é opcional para o tipo de plano selecionado. Deseja preencher a informação?"
              />
            </Grid>
          )}

          {ReplaceButton()}
          {ThematicAxisBanner()}
          {Fields()}
          {SubmitButton()}
        </Grid>
        <ReutilizarElaborationDialog
          title="Produtos"
          reuseData={data}
          open={reutilizar}
          setOpen={() => setReutilizar(false)}
          setValue={(e) => {
            e.forEach((item: any, index: any) => {
              setValueProductsDeliveries(`produtos_entregas.${index}`, transformProductsDeliveriesDataReuse(item))
            })
          }}
        />
        <DisclaimerReuseElaborationDialog open={openDisclaimer} setOpen={setOpenDisclaimer} />
        <CreateUnityElaboration onClose={() => setOpenUnity(false)} open={openUnity} save={(e) => handleSaveUnity(e)} />
      </Container>
    </form>
  )
}
