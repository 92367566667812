import React from "react"
import { InfoIncon } from "../info";
import { BootstrapInput, Container } from "./styled"
import { Tooltip, Select, MenuItem, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, TextField } from '@mui/material';
import { ConsideracaoIcon } from "../consideracao";
import { ConsiderationModal } from "../../Forms/ConsiderationModal";
import { useRouter } from "next/router";
import CloseIcon from '@mui/icons-material/Close';
import { CancelButton, ContinueButton } from "../../Forms/Vision/styles.modal";
import { Api } from "@/services/configs/axios";
import { DashboardManagementPlansService } from "@/services/endpoints/dashboard";
import { ElaborationService } from "@/services/endpoints/elaboracao";

interface Data {
  value: any,
  name: string
}

interface IProps {
  errors: any;
  name: string;
  register: any;
  label: string;
  tooltip?: string;
  nameRef?: string;
  required: boolean;
  placeholder: string;
  defaultValue?: string;
  commentTittle?: string;
  setValue?: (e: any) => void;
}

function getMessageErrorByNameRef(json: any, title: any) {
  const foundObject = json?.pre_registro?.find((item: any) => item[title]);
  return foundObject ? foundObject[title].message : null;
}

export const AutoCompleteElaboration: React.FC<IProps> = ({
  name,
  label,
  errors,
  nameRef,
  tooltip,
  required,
  setValue,
  register,
  placeholder,
  defaultValue,
  commentTittle
}) => {
  const [open, setOpen] = React.useState(false);
  const { DropDownMetas } = new ElaborationService();
  const { createNewThemes } = new DashboardManagementPlansService();

  const [metas, setMetas] = React.useState<any[]>([])
  React.useEffect(() => {
    DropDownMetas().then(res => {
      const { data } = res.data as any;
      setMetas(data.map((item: any) => ({ value: item.id, name: item.descricao })))
    })
  }, [])

  const handleClose = () => {
    setOpen(false);
  };

  const [novoTema, setNovoTema] = React.useState("");

  const submitNewTheme = () => {
    createNewThemes({ descricao: novoTema }).then((newTheme) => {
      DropDownMetas().then(res => {
        const { data } = res.data as any;
        const parsedData = data.map((item: any) => ({ value: item.id, name: item.descricao }));
        setMetas([...parsedData, { value: newTheme.data.data.id, name: newTheme.data.data.descricao }])

        if (setValue) {
          setValue({ value: newTheme.data.data.id, name: newTheme.data.data.descricao })
        }
      });
      setOpen(false);
      setNovoTema('');
    });
  };

  return (
    <Container>
      <div className="title_wrapper_textfield">
        <ToolTipWrapper
          label={label}
          tooltip={tooltip}
          required={required}
          register={register}
          commentTittle={commentTittle}
          name={name}
          value={defaultValue}
        />
      </div>
      <Select
        size="small"
        input={<BootstrapInput />}
        defaultValue={defaultValue}
        value={defaultValue}
        {...register(name, { required, value: defaultValue })}
      >
        <MenuItem sx={{ color: '#ADB5BD', fontSize: '14px' }} onClick={() => setOpen(true)}>Adicionar novo tema</MenuItem>
        {metas.map(item => (
          <MenuItem value={item.value}>{item.name}</MenuItem>
        ))}
      </Select>
      <div className="error_wrapper">
        <span>{getMessageErrorByNameRef(errors, nameRef)}</span>
      </div>
      <Dialog
        open={open}
        maxWidth='md'
        onClose={handleClose}
      >
        <div style={{ padding: '0 27px', width: '875px' }}>
          <DialogTitle>
            Adicionar novo tema
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#004A80'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <TextField fullWidth label='Novo tema' onChange={(e) => setNovoTema(e.target.value)} value={novoTema} />
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
            <CancelButton onClick={handleClose}>
              <p>Cancelar</p>
            </CancelButton>
            <ContinueButton onClick={() => submitNewTheme()} >
              <p>Salvar</p>
            </ContinueButton>
          </DialogActions>
        </div>
      </Dialog>
    </Container>
  )
}

type TooltipWrapperProps = {
  tooltip?: string,
  label?: string,
  required?: boolean,
  local?: string,
  hiddeInput?: boolean,
  register?: any,
  commentTittle?: string;
  name?: string,
  value?: string,
}

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.withe,
    backgroundColor: theme.palette.common.white,
  },
}));

const ToolTipWrapper = ({
  label,
  tooltip,
  required,
  register,
  commentTittle,
  name,
  value, }: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {

    if (!router.isReady) return
    if (!router.query.id) return

    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl &&
        <>
          {tooltip && (
            <LightTooltip title={tooltip}>
              <div>
                <InfoIncon />
              </div>
            </LightTooltip>
          )}
        </>
      }
      {epl && (
        <CondiserationWrapper
          register={register}
          label={label}
          name={name}
          commentTittle={commentTittle}
          value={value}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  register,
  label,
  name,
  commentTittle,
  value
}: {
  register?: any,
  label?: string,
  name?: string,
  value?: string,
  commentTittle?: string;

}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        handleClose={handleClose}
        register={register}
        label={label}
        name={name}
        commentTittle={commentTittle}
        value={value}
      />
    </>
  )
}