export const Svg_006 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={59}
    fill="none"
  >
    <path fill="url(#a)" d="M28.242 4.582h3.516V8.04h-3.516V4.582Z" />
    <path fill="url(#b)" d="M35.273 8.04h3.516v3.456h-3.516V8.04Z" />
    <path fill="url(#c)" d="M21.21 8.04h3.517v3.456H21.21V8.04Z" />
    <path fill="url(#d)" d="M21.172 32.984h17.656v4.84H21.172v-4.84Z" />
    <path fill="url(#e)" d="M21.172 24.687h17.656v4.84H21.172v-4.84Z" />
    <path fill="url(#f)" d="M21.172 16.39h17.656v4.84H21.172v-4.84Z" />
    <path fill="url(#g)" d="M21.172 41.28h17.656v4.84H21.172v-4.84Z" />
    <path fill="url(#h)" d="M0 41.28h17.656v4.84H0v-4.84Z" />
    <path fill="url(#i)" d="M21.172 49.578h17.656v4.84H21.172v-4.84Z" />
    <path fill="url(#j)" d="M42.344 49.578H60v4.84H42.344v-4.84Z" />
    <path fill="url(#k)" d="M42.344 41.28H60v4.84H42.344v-4.84Z" />
    <path fill="url(#l)" d="M0 32.984h17.656v4.84H0v-4.84Z" />
    <path fill="url(#m)" d="M0 49.578h17.656v4.84H0v-4.84Z" />
    <defs>
      <linearGradient
        id="a"
        x1={29.055}
        x2={30.685}
        y1={5.101}
        y2={7.783}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="b"
        x1={36.087}
        x2={37.716}
        y1={8.558}
        y2={11.24}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={22.024}
        x2={23.654}
        y1={8.558}
        y2={11.24}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={25.255}
        x2={26.101}
        y1={33.71}
        y2={38.708}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={25.255}
        x2={26.101}
        y1={25.413}
        y2={30.411}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={25.255}
        x2={26.101}
        y1={17.116}
        y2={22.114}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="g"
        x1={25.255}
        x2={26.101}
        y1={42.007}
        y2={47.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={4.083}
        x2={4.929}
        y1={42.007}
        y2={47.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={25.255}
        x2={26.101}
        y1={50.304}
        y2={55.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="j"
        x1={46.427}
        x2={47.273}
        y1={50.304}
        y2={55.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="k"
        x1={46.427}
        x2={47.273}
        y1={42.007}
        y2={47.005}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="l"
        x1={4.083}
        x2={4.929}
        y1={33.71}
        y2={38.708}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
      <linearGradient
        id="m"
        x1={4.083}
        x2={4.929}
        y1={50.304}
        y2={55.302}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#004A80" />
        <stop offset={1} stopColor="#00C0F3" />
      </linearGradient>
    </defs>
  </svg>
)