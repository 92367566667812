import { useTheme } from "@/services/zustand/theme";

export const MunicipalSaude = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 47.5C54.5 47.5 50 45.75 46.5 42.25C43 38.75 41.25 34.25 41.25 28.75C41.25 23.25 43 18.75 46.5 15.25C50 11.75 54.5 10 60 10C65.5 10 70 11.75 73.5 15.25C77 18.75 78.75 23.25 78.75 28.75C78.75 34.25 77 38.75 73.5 42.25C70 45.75 65.5 47.5 60 47.5ZM60 40C63.25 40 65.9375 38.9375 68.0625 36.8125C70.1875 34.6875 71.25 32 71.25 28.75C71.25 25.5 70.1875 22.8125 68.0625 20.6875C65.9375 18.5625 63.25 17.5 60 17.5C56.75 17.5 54.0625 18.5625 51.9375 20.6875C49.8125 22.8125 48.75 25.5 48.75 28.75C48.75 32 49.8125 34.6875 51.9375 36.8125C54.0625 38.9375 56.75 40 60 40ZM20 110V77C20 73.8333 20.7917 71.125 22.375 68.875C23.9583 66.625 26 64.9127 28.5 63.7381C32.75 61.8294 37.5208 60.1042 42.8125 58.5625C48.1042 57.0208 53.8378 56.25 60.0134 56.25C66.189 56.25 71.8973 57.0208 77.1384 58.5625C82.3795 60.1042 87.125 61.8333 91.375 63.75C93.9583 64.9167 96.0417 66.625 97.625 68.875C99.2083 71.125 100 73.8333 100 77V102.5C100 104.5 99.25 106.25 97.75 107.75C96.25 109.25 94.5 110 92.5 110H48.75C45.2652 110 42.3106 108.786 39.8864 106.359C37.4621 103.932 36.25 100.973 36.25 97.484C36.25 93.9947 37.4621 91.0417 39.8864 88.625C42.3106 86.2083 45.2652 85 48.75 85H63.5L72.5 65C70.3333 64.5833 68.1875 64.2708 66.0625 64.0625C63.9375 63.8542 61.912 63.75 59.9861 63.75C53.9571 63.75 48.598 64.4375 43.9089 65.8125C39.2196 67.1875 35.0833 68.75 31.5 70.5C30.3333 71.0833 29.375 71.9688 28.625 73.1562C27.875 74.3438 27.5 75.625 27.5 77V110H20ZM48.75 102.5H55.5L60.125 92.5H48.75C47.4167 92.5 46.25 93 45.25 94C44.25 95 43.75 96.1667 43.75 97.5C43.75 98.8333 44.25 100 45.25 101C46.25 102 47.4167 102.5 48.75 102.5ZM63.75 102.5H92.5V77C92.5 75.625 92.1042 74.3438 91.3125 73.1562C90.5208 71.9688 89.5417 71.0833 88.375 70.5C87.375 70 86.1667 69.4583 84.75 68.875C83.3333 68.2917 81.7083 67.6667 79.875 67L63.75 102.5Z"
        fill="url(#paint0_linear_1941_43859)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43859"
          x1="38.5"
          y1="25"
          x2="89.8884"
          y2="91.5344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
