import React from "react";
import { AuthContextProvider } from "@/contexts/auth";
import { PlansContextProvider } from "@/contexts/plans";
import { SnackbarContextProvider } from "@/contexts/Snackbar";
import { AccessibilityContextProvider } from "@/contexts/accessibility";
import { TerritorialMarksProvider } from "@/contexts/marcacoes_territoriais";
import { PreparationPlansContextProvider } from "@/contexts/elaboracao_planos";
import { GoalsContextProvider } from "@/contexts/elaboracao_planos/forms/metas";
import { FilterClassificationContextProvider } from "@/contexts/classification";
import { VisionContextProvider } from "@/contexts/elaboracao_planos/forms/visao";
import { ValuesContextProvider } from "@/contexts/elaboracao_planos/forms/valores";
import { ProjectsContextProvider } from "@/contexts/elaboracao_planos/forms/projetos";
import { PreRegisterProvider } from "@/contexts/elaboracao_planos/forms/pre_register";
import { ProgramsContextProvider } from "@/contexts/elaboracao_planos/forms/programas";
import { GuidelinesContextProvider } from "@/contexts/elaboracao_planos/forms/diretriz";
import { StrategyContextProvider } from "@/contexts/elaboracao_planos/forms/estrategia";
import { ActivitesContextProvider } from "@/contexts/elaboracao_planos/forms/atividates";
import { ObjectivesContextProvider } from "@/contexts/elaboracao_planos/forms/objetivos";
import { AspirationContextProvider } from "@/contexts/elaboracao_planos/forms/aspiracao";
import { DiagnosisProvider } from "@/contexts/elaboracao_planos/forms/sintese_diagnostico";
import { MacroactionsContextProvider } from "@/contexts/elaboracao_planos/forms/macroacoes";
import { InitiativesContextProvider } from "@/contexts/elaboracao_planos/forms/iniciativas";
import { ScenarioContextProvider } from "@/contexts/elaboracao_planos/forms/sintese_cenario";
import { DashboardManagementPlansContextProvider } from "@/contexts/gestao_planos_dashboard";
import { HierarchyPreparationOfPlansProvider } from "@/contexts/elaboracao_planos/hierarquia";
import { ThematicAxisContextProvider } from "@/contexts/elaboracao_planos/forms/eixo_tematico";
import { ProductsDeliveriesContextProvider } from "@/contexts/elaboracao_planos/forms/produtos_entregas";
import { MissionPurposeContextProvider } from "@/contexts/elaboracao_planos/forms/missao_proposito";
import { SnackbarElaborationContextProvider } from "@/contexts/elaboracao_planos/sknackbar";

interface IProps {
  children: React.ReactNode;
}

const FormProviders: React.FC<{ children: React.ReactNode }> = React.memo(({ children }) => (
  <SnackbarElaborationContextProvider>
    <PreRegisterProvider>
      <DiagnosisProvider>
        <ScenarioContextProvider>
          <ThematicAxisContextProvider>
            <AspirationContextProvider>
              <StrategyContextProvider>
                <VisionContextProvider>
                  <ObjectivesContextProvider>
                    <GoalsContextProvider>
                      <MacroactionsContextProvider>
                        <ProgramsContextProvider>
                          <ProjectsContextProvider>
                            <GuidelinesContextProvider>
                              <ActivitesContextProvider>
                                <ProductsDeliveriesContextProvider>
                                  <InitiativesContextProvider>
                                    <ValuesContextProvider>
                                      <MissionPurposeContextProvider>
                                        {children}
                                      </MissionPurposeContextProvider>
                                    </ValuesContextProvider>
                                  </InitiativesContextProvider>
                                </ProductsDeliveriesContextProvider>
                              </ActivitesContextProvider>
                            </GuidelinesContextProvider>
                          </ProjectsContextProvider>
                        </ProgramsContextProvider>
                      </MacroactionsContextProvider>
                    </GoalsContextProvider>
                  </ObjectivesContextProvider>
                </VisionContextProvider>
              </StrategyContextProvider>
            </AspirationContextProvider>
          </ThematicAxisContextProvider>
        </ScenarioContextProvider>
      </DiagnosisProvider>
    </PreRegisterProvider>
  </SnackbarElaborationContextProvider>
));

export const MiddlewareContextProvider: React.FC<IProps> = React.memo(({ children }) => {
  return (
    <AuthContextProvider>
      <SnackbarContextProvider>
        <AccessibilityContextProvider>
          <DashboardManagementPlansContextProvider>
            <PlansContextProvider>
              <FilterClassificationContextProvider>
                <TerritorialMarksProvider>
                  <PreparationPlansContextProvider>
                    <HierarchyPreparationOfPlansProvider>
                      <FormProviders>
                        {children}
                      </FormProviders>
                    </HierarchyPreparationOfPlansProvider>
                  </PreparationPlansContextProvider>
                </TerritorialMarksProvider>
              </FilterClassificationContextProvider>
            </PlansContextProvider>
          </DashboardManagementPlansContextProvider>
        </AccessibilityContextProvider>
      </SnackbarContextProvider>
    </AuthContextProvider>
  );
});