import { useTheme } from "@/services/zustand/theme";

export const SegurancaViaria = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.5 115V100H78.5V92.5H93.5V77.5H101V92.5H116V100H101V115H93.5ZM93.5 65.125V20H101V65.125H93.5ZM21 100V20H28.5V100H21ZM57.25 38.375V20H64.75V38.375H57.25ZM57.25 69.125V50.75H64.75V69.125H57.25ZM57.25 100V81.5H64.75V100H57.25Z"
        fill="url(#paint0_linear_1941_43879)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43879"
          x1="42.9687"
          y1="34.25"
          x2="88.0938"
          y2="107.281"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
