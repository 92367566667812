import {
  Control,
  useForm,
  FieldErrors,
  UseFormWatch,
  UseFormReset,
  useFieldArray,
  UseFormRegister,
  UseFormSetValue,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
} from "react-hook-form";
import _ from 'lodash';
import React from "react";
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useRouter } from "next/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { ElaborationService } from "@/services/endpoints/elaboracao";

interface IFormVision {
  isOptionalVision: string;
  fieldsVision: Record<"id", string>[];
  errosVision: FieldErrors<InputsVision>;
  resetVision: UseFormReset<InputsVision>;
  watchVision: UseFormWatch<InputsVision>;
  controlVision: Control<InputsVision, any>;
  setValueVision: UseFormSetValue<InputsVision>;
  registerVision: UseFormRegister<InputsVision>;
  getValuesVision: UseFormGetValues<InputsVision>;
  updateVision: UseFieldArrayUpdate<InputsVision, "visao">;
  appendVision: UseFieldArrayAppend<InputsVision, "visao">;
  handleSubmitVision: UseFormHandleSubmit<InputsVision, undefined>;
}

export const VisionContext = React.createContext({} as IFormVision);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

type InputsVision = {
  visao: any;
  // data: any;
  is_optional?: boolean;
};

const defaultValue = {
  id: '',
  texto: '',
  data: null,
}

const SchemaVision = Yup.object().shape({
  visao: Yup.array().of(
    Yup.object().shape({
      texto: Yup.string().required('Campo obrigatório'),
      data: Yup.date().nullable().required('Campo obrigatório')
    })
  ).required('Campo obrigatório'),
});

export const VisionContextProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    reset: resetVision,
    watch: watchVision,
    control: controlVision,
    register: registerVision,
    getValues: getValuesVision,
    setValue: setValueVision,
    handleSubmit: handleSubmitVision,
    formState: { errors: errosVision } } = useForm<InputsVision>({
      resolver: yupResolver(SchemaVision),
      defaultValues: { visao: [defaultValue] }
    });

  const { fields: fieldsVision, append: appendVision, update: updateVision } = useFieldArray({
    control: controlVision,
    name: "visao"
  });

  const router = useRouter();
  const { completCode } = usePreparationOfPlans();
  const { GetVisionElaboration } = new ElaborationService();
  const isOptional = watchVision('is_optional') as unknown as string

  // React.useEffect(() => {
  //   if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))
  //     GetVisionElaboration(router.query.id).then((res: any) => {
  //       if (res.data.length > 0) {
  //         const data = res.data.map((item: any) => {
  //           return {
  //             texto: item.texto,
  //             data: dayjs(item.data),
  //             id: item.id
  //           }
  //         })
  //         setValueVision('visao', data)
  //       }
  //     })
  // }, [router, isOptional, completCode]);

  const value = {
    errosVision,
    resetVision,
    watchVision,
    fieldsVision,
    appendVision,
    updateVision,
    controlVision,
    setValueVision,
    registerVision,
    getValuesVision,
    handleSubmitVision,
  } as IFormVision;

  return <VisionContext.Provider value={value}>{children}</VisionContext.Provider>;
};