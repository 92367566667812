import React from "react";

export function ParqueNaturalChicoMendes() {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-name="Camada 1"
    viewBox="0 0 60 60"
  >
    <defs>
      <linearGradient
        id="a"
        x1={47.55}
        x2={21.6}
        y1={59.68}
        y2={22.63}
        data-name="Gradiente sem nome 7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#23bcef" />
        <stop offset={1} stopColor="#004b80" />
      </linearGradient>
      <linearGradient
        xlinkHref="#a"
        id="b"
        x1={36.56}
        x2={22.86}
        y1={29.78}
        y2={10.22}
        data-name="Gradiente sem nome 7"
      />
    </defs>
    <path
      d="M54.43 30.9h-5.04a.97.97 0 0 0-.97.97v2.93c-1.2-.45-2.56-.69-4.05-.69h-.1c-1.86.01-4.41 1.04-6.17 1.81l-.16.07c-2.22.96-4.03 1.06-4.17 1.07H23.13c-1.53.02-2.85.9-3.5 2.19l-8.88-6.4c-.1-.07-.22-.13-.34-.16-.11-.03-2.75-.64-4.38.88-1.03.96-1.39 2.47-1.06 4.47.03.17.1.33.22.47.08.1 2.13 2.51 5.35 5.34 4.35 3.82 8.57 6.43 12.54 7.76l.12.04c.76.26 2.79.94 4.24 1.18.73.12 1.47.17 2.21.17 1.71 0 3.4-.3 5.02-.86.02 0 .05-.02.07-.03l6.05-2.66c.82-.3 3.07-1.09 4.04-1.23 1.85-.26 2.95-.23 3.58-.12v5.58c0 .54.44.97.97.97h5.04c.54 0 .97-.44.97-.97V31.87a.97.97 0 0 0-.97-.97Zm-9.87 15.4c-1.37.19-4.16 1.22-4.47 1.34-.02 0-.04.01-.05.02L34 50.32c-2.62.9-4.84.82-6.25.59-1.3-.21-3.21-.86-3.93-1.1l-.12-.04c-4.63-1.55-9-4.86-11.85-7.35a54.212 54.212 0 0 1-4.99-4.95c-.11-.87-.09-1.91.5-2.47.65-.61 1.85-.55 2.43-.45l9.43 6.8a3.98 3.98 0 0 0 3.92 3.68H33.8c.54 0 .97-.44.97-.97s-.44-.97-.97-.97H23.14c-1.1-.02-1.98-.9-2-2-.02-1.13.88-2.05 2-2.07h10.68c.09 0 2.23-.07 4.9-1.23l.17-.07c2.48-1.08 4.3-1.64 5.4-1.65h.09c1.53 0 2.87.28 3.99.83v9.25c-1-.11-2.27-.05-3.79.17Zm8.9 6.41h-3.1V32.85h3.1v19.86Z"
      style={{
        fill: "url(#a)",
        strokeWidth: 0,
      }}
    />
    <path
      d="M29.16 28.96v4.13c0 .54.44.97.97.97s.97-.44.97-.97v-4.13c1.57-.09 4.6-.54 6.67-2.61 2.84-2.84 2.63-7.5 2.62-7.69a.974.974 0 0 0-.92-.92c-.2-.01-4.85-.22-7.69 2.62-.25.25-.48.52-.69.8v-1.29c.88-.89 3.26-3.63 3.26-6.98 0-4.01-3.44-7.16-3.59-7.29a.977.977 0 0 0-1.3 0c-.15.13-3.59 3.28-3.59 7.29 0 3.35 2.38 6.08 3.26 6.98v1.29c-.21-.28-.43-.54-.68-.79-2.84-2.84-7.5-2.63-7.69-2.62-.5.03-.89.42-.92.92-.01.2-.22 4.85 2.62 7.69 2.07 2.07 5.11 2.52 6.67 2.61Zm4-7.22c1.55-1.55 3.95-1.94 5.27-2.03-.09 1.32-.48 3.72-2.03 5.27-1.55 1.55-3.96 1.94-5.27 2.03.09-1.31.48-3.72 2.04-5.27ZM30.13 7.72c.87 1 2.29 2.97 2.29 5.17s-1.42 4.17-2.29 5.17c-.87-1-2.29-2.97-2.29-5.17s1.42-4.17 2.29-5.17Zm-3.04 14.02c1.55 1.55 1.94 3.95 2.03 5.27-1.32-.09-3.72-.48-5.27-2.03-1.55-1.55-1.94-3.96-2.03-5.27 1.32.09 3.72.48 5.27 2.04Z"
      style={{
        strokeWidth: 0,
        fill: "url(#b)",
      }}
    />
  </svg>
  );
}