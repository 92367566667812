import React from 'react';
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useMediaQuery, query } from '@/hooks/useMediaQuery';

type Severity = 'error' | 'info' | 'success' | 'warning';

interface IProps {
  open: boolean;
  title: string;
  message: string;
  margin?: string;
  severity: Severity;
  onClose: () => void;
}

export const SnackbarInterplan: React.FC<IProps> = ({ open, onClose, severity, message, title, margin }) => {
  const { isMobile } = useMediaQuery(query);

  const anchorOrigin = !isMobile
    ? { vertical: 'top', horizontal: "right" }
    : { vertical: 'top', horizontal: 'left' } as any;

  return (
    <Snackbar sx={{ zIndex: 9999999, margin }} open={open} anchorOrigin={anchorOrigin}>
      <Alert severity={severity} onClose={() => onClose()}>
        <AlertTitle>{title}</AlertTitle>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {message}
          {severity != 'success' && severity != 'info' && (
            <strong>Por favor, tente novamente!</strong>
          )}
        </div>
      </Alert>
    </Snackbar>
  );
};