import { useTheme } from "@/services/zustand/theme";

export const EstrategicoPromocaoMulher = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52 110V82.5H37L49.75 41.5C50.4167 39.4167 51.6667 37.8125 53.5 36.6875C55.3333 35.5625 57.3333 35 59.5 35C61.6667 35 63.6667 35.5625 65.5 36.6875C67.3333 37.8125 68.5833 39.4167 69.25 41.5L82 82.5H67V110H52ZM59.51 28.25C57.0033 28.25 54.8542 27.3575 53.0625 25.5725C51.2708 23.7874 50.375 21.6416 50.375 19.135C50.375 16.6283 51.2675 14.4792 53.0525 12.6875C54.8376 10.8958 56.9834 10 59.49 10C61.9967 10 64.1458 10.8925 65.9375 12.6775C67.7292 14.4626 68.625 16.6084 68.625 19.115C68.625 21.6217 67.7325 23.7708 65.9475 25.5625C64.1624 27.3542 62.0166 28.25 59.51 28.25Z"
        fill="url(#paint0_linear_1941_43905)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43905"
          x1="47.4062"
          y1="25"
          x2="97.9564"
          y2="61.8152"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
