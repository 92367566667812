import React from "react";
import {
  MenuItems,
  ICardProps,
  cardsItems,
  codes_case_04,
  IObjectMenuProps,
  findingMenuItemKey,
  IDetailsItemContentProps,
  IHandlePermissionClickMenuItem,
  updateFilledAndNavigableStatusItem
} from "./constants";
import { useRouter } from "next/router";
import { ElaborationService } from "@/services/endpoints/elaboracao";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

interface IPreparationOfPlansContext {
  isValidId: boolean;
  completCode: any[];
  cards: ICardProps[];
  newPlanId: string | null;
  itemsMenu: IObjectMenuProps;
  selectedCardItem: ICardProps;
  navigable: boolean;
  setCurrentPlanTitle: React.Dispatch<React.SetStateAction<string>>
  currentPlanTitle: string;
  setDefaultItemMenuId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedItemsMenu: IDetailsItemContentProps | null;
  handleValidateQueryElaborationId: (id: number) => void;
  handleFetchProgressCodePlanId: (id: any) => Promise<void>;
  setCompletCode: React.Dispatch<React.SetStateAction<any[]>>;
  setNavigable: React.Dispatch<React.SetStateAction<boolean>>;
  setCards: React.Dispatch<React.SetStateAction<ICardProps[]>>;
  setNewPlanId: React.Dispatch<React.SetStateAction<string | null>>;
  setItemsMenu: React.Dispatch<React.SetStateAction<IObjectMenuProps>>;
  setSelectedCardItem: React.Dispatch<React.SetStateAction<ICardProps>>;
  setSelectedItemsMenu: React.Dispatch<React.SetStateAction<IDetailsItemContentProps | null>>;
  handlePermissionClickAndRenderMenuItem: ({ plan, id, click }: IHandlePermissionClickMenuItem) => void;
}

export const PreparationOfPlansContext = React.createContext({} as IPreparationOfPlansContext);

interface IPreparationOfPlansProps {
  children: React.ReactNode;
}

export const PreparationPlansContextProvider: React.FC<IPreparationOfPlansProps> = ({ children }) => {
  const router = useRouter();

  const [cards, setCards] = React.useState(cardsItems);
  const [codes, setCodes] = React.useState<string[]>([]);
  const [currentPlanTitle, setCurrentPlanTitle] = React.useState("")
  const [completCode, setCompletCode] = React.useState<any[]>([]);
  const [isValidId, setIsValidId] = React.useState(false);
  const [navigable, setNavigable] = React.useState(false);
  const [itemsMenu, setItemsMenu] = React.useState(MenuItems);
  const [newPlanId, setNewPlanId] = React.useState<null | string>(null);
  const { ValidateQueryId, ProgressCodePlanId } = new ElaborationService();
  const [selectedCardItem, setSelectedCardItem] = React.useState({} as any);
  const zustandCardItem = useElaborationCardItem((state: any) => state.cardItem);
  const [defaultItemMenuId, setDefaultItemMenuId] = React.useState<number | null>(null);
  const [selectedItemsMenu, setSelectedItemsMenu] = React.useState<IDetailsItemContentProps | null>({} as IDetailsItemContentProps);

  const handleValidateQueryElaborationId = (id: number) => {
    ValidateQueryId(id).then(() => {
      setIsValidId(true)
    }).catch(e => {
      setIsValidId(false)
    })
  }

  const handleFetchProgressCodePlanId = async (id: any) => {
    try {
      const res = await ProgressCodePlanId(Number(id));
      if (id === '6') {
        setCompletCode(codes_case_04);
      } else {
        setCompletCode(res.data);
      }
      setCodes(res.data?.map((item: any) => item.code));
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (router.query.id && router.pathname.split('/').includes('elaboracao_planos') || router.pathname.split('/').includes('aprovacao_planos')) {
      handleFetchProgressCodePlanId(router.query.id);
    }
  }, [router.query, router.pathname])

  React.useEffect(() => {
    const newObjectItemsMenu = { ...itemsMenu } as Record<string, any>;
    setItemsMenu((prev) => {
      const newObject = { ...prev } as Record<string, any>;
      const { data, nextNavigableId } = updateFilledAndNavigableStatusItem({
        codes,
        plan: newObjectItemsMenu[findingMenuItemKey(zustandCardItem) as string],
      });

      // if (nextNavigableId) {
      //   const lastIdWithFilledtrue = nextNavigableId - 1;
      //   setDefaultItemMenuId(lastIdWithFilledtrue);
      // }

      newObject[findingMenuItemKey(zustandCardItem) as string] = data;

      return newObject as IObjectMenuProps;
    });
  }, [selectedCardItem, codes]);

  const handlePermissionClickAndRenderMenuItem = ({ plan, id, click, navigable }: IHandlePermissionClickMenuItem) => {
    let clickedItem: any = null;
    let lastPermittedItem: any = null;

    // Itera sobre o plano para encontrar o item com o id correspondente
    Object.values(plan).forEach((section: any) => {
      section.forEach((item: any) => {

        if (item.id === id) {
          clickedItem = { ...item, clicked: click };
          if (!navigable) {
            setSelectedItemsMenu(clickedItem);
            return;
          }
        }

        if (item.layer_indicator) {
          if (item.id === id) {
            clickedItem = { ...item, clicked: click };
          }
        } else if (item.filled || item.navigable) {
          lastPermittedItem = item;
        }
      });
    });

    // Se navigable for false e não encontrou clickedItem
    if (!clickedItem && lastPermittedItem) {
      clickedItem = { ...lastPermittedItem };
    }


    setSelectedItemsMenu(clickedItem);
  };

  function findLastNavigableItemId(data: any) {
    let lastNavigableItem = null;

    let lastItem = { ...selectedItemsMenu };

    if (!navigable) {
      return lastItem.id
    }

    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const item of data[key]) {
          if (item.navigable === true) {
            lastNavigableItem = item;
          }
        }
      }
    }
    return lastNavigableItem.id;
  }

  React.useEffect(() => {
    const plan = itemsMenu[findingMenuItemKey(zustandCardItem)];


    handlePermissionClickAndRenderMenuItem({
      plan,
      click: false,
      navigable,
      id: defaultItemMenuId as number || findLastNavigableItemId(plan)
    });
  }, [navigable, router, defaultItemMenuId]);

  const value = {
    cards,
    setCards,
    isValidId,
    itemsMenu,
    navigable,
    newPlanId,
    completCode,
    setItemsMenu,
    setNewPlanId,
    setNavigable,
    currentPlanTitle,
    setCurrentPlanTitle,
    setCompletCode,
    selectedCardItem,
    selectedItemsMenu,
    setSelectedCardItem,
    setDefaultItemMenuId,
    setSelectedItemsMenu,
    handleFetchProgressCodePlanId,
    handleValidateQueryElaborationId,
    handlePermissionClickAndRenderMenuItem,
  } as IPreparationOfPlansContext;

  return <PreparationOfPlansContext.Provider value={value}>{children}</PreparationOfPlansContext.Provider>;
};