import React from "react";
import { Container } from "./styles";
import { useTheme } from "styled-components";
import { useTheme as zustandTheme } from "@/services/zustand/theme";

export default function ButtonTheme() {
  const zusTheme = zustandTheme();

  return (
    <Container
      onClick={() => zusTheme.setTheme()}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4999 11.4999C11.4999 16.9153 11.4999 11.4999 11.4999 20.0505C6.77747 20.0505 2.94922 16.2223 2.94922 11.4999C2.94922 6.77747 6.77747 2.94922 11.4999 2.94922C11.4999 14.7581 11.4999 6.77747 11.4999 11.4999Z"
        fill="#004A80"
      />
      <path
        d="M11.4996 11.4996C11.4996 6.08424 11.4996 11.4996 11.4996 2.949C16.222 2.949 20.0503 6.77725 20.0503 11.4996C20.0503 16.222 16.222 20.0503 11.4996 20.0503C11.4996 8.24143 11.4996 16.222 11.4996 11.4996Z"
        fill="#F1F1F1"
      />
      <circle
        cx="11.5001"
        cy="11.5001"
        r="8.12069"
        stroke="#F1F1F1"
        strokeWidth="2"
      />
    </Container>
  );
}
