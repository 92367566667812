export const PlanoGestaoIcon = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="villa_FILL0_wght400_GRAD0_opsz24">
        <path id="Vector" d="M8.16309 81.1925V22.5L62.561 -0.0739746V40.5592H66.7454C66.7454 38.0761 67.5649 35.9504 69.2038 34.1821C70.8427 32.4138 72.8129 31.5296 75.1143 31.5296C77.4157 31.5296 79.3859 32.4138 81.0248 34.1821C82.6637 35.9504 83.4832 38.0761 83.4832 40.5592V81.1925H8.16309ZM16.532 72.1629H33.2698V40.5592H54.192V13.019L16.532 28.7079V72.1629ZM41.6387 72.1629H54.192V58.6185H62.561V72.1629H75.1143V49.5889H41.6387V72.1629Z" fill="#004A80" />
      </g>
    </svg>

  )
}
