import { useTheme } from "@/services/zustand/theme";

export const PlanoVerao = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.25 23.75V5H63.75V23.75H56.25ZM88.25 37L83 31.75L96.25 18.375L101.5 23.75L88.25 37ZM96.25 63.75V56.25H115V63.75H96.25ZM56.25 115V96.25H63.75V115H56.25ZM31.625 36.875L18.5 23.75L23.75 18.5L37 31.75L31.625 36.875ZM96.375 101.5L83 88.25L88.125 83.125L101.625 96.125L96.375 101.5ZM5 63.75V56.25H23.75V63.75H5ZM23.875 101.5L18.5 96.25L31.625 83.125L34.375 85.625L37.125 88.25L23.875 101.5ZM60 90C51.6667 90 44.5833 87.0833 38.75 81.25C32.9167 75.4167 30 68.3333 30 60C30 51.6667 32.9167 44.5833 38.75 38.75C44.5833 32.9167 51.6667 30 60 30C68.3333 30 75.4167 32.9167 81.25 38.75C87.0833 44.5833 90 51.6667 90 60C90 68.3333 87.0833 75.4167 81.25 81.25C75.4167 87.0833 68.3333 90 60 90ZM60 82.5C66.25 82.5 71.5625 80.3125 75.9375 75.9375C80.3125 71.5625 82.5 66.25 82.5 60C82.5 53.75 80.3125 48.4375 75.9375 44.0625C71.5625 39.6875 66.25 37.5 60 37.5C53.75 37.5 48.4375 39.6875 44.0625 44.0625C39.6875 48.4375 37.5 53.75 37.5 60C37.5 66.25 39.6875 71.5625 44.0625 75.9375C48.4375 80.3125 53.75 82.5 60 82.5Z"
        fill="url(#paint0_linear_1941_43885)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1941_43885"
          x1="30.4375"
          y1="21.5"
          x2="82.6875"
          y2="106.063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
