export const light = {
  colors: {
    text: '#0B0C0C',
    button: '#004A80',
    primary: '#FFFFFF',
    bg_words:"#DBDBDB",
    sub_nav: '#FFFFFF',
    card_body: '#FBFBFB',
    secundary: '#004A80',
    background: '#FFFFFF',
    card_title:  '#FBFBFB',
    button_text: '#FFFFFF',
    home_card_bg:"#FFFFFF",
    footer_text: "#89E2FA",
    home_diagonal:"#FBFBFB",
    order_buttons: '#D2EFFA',
    bg_info_plans: "#FFF5CE",
    border_btn_theme:'#004A80',
    home_card_border:"#D2EFFA",
    home_card_bg_bage:"#FBFBFB",
    footer_text_last: "#00C0F3",
    bg_info_plans_gray:"#F8F8F8",
    home_card_bg_white:"#f0f0f0",
    footer_text_middle: "#89E2FA",
    bg_info_plans_light: "#FFFAE8",
    select_open_buttons_text: "#FFFFFF",
    select_close_buttons_text: "#0B0C0C",
    select_open_filter_buttons: "#00C0F3",
    select_close_filter_buttons: "#DBDBDB",
    description_text_dashboard: '#0b0c0c',
  }
};
