import _ from 'lodash';
import React from "react";
import { 
  Control, 
  useForm,
  FieldErrors, 
  UseFormWatch, 
  useFieldArray, 
  UseFormRegister, 
  UseFormSetValue, 
  UseFieldArrayRemove, 
  UseFieldArrayUpdate, 
  UseFormHandleSubmit, 
} from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";

interface IFormPreRegister {
  removePreRegister: UseFieldArrayRemove;
  errosPreRegister: FieldErrors<InputsPreRegister>;
  watchPreRegister: UseFormWatch<InputsPreRegister>;
  controlPreRegister: Control<InputsPreRegister, any>;
  setValuePreRegister: UseFormSetValue<InputsPreRegister>;
  registerPreRegister: UseFormRegister<InputsPreRegister>;
  updatePreRegister: UseFieldArrayUpdate<InputsPreRegister, "pre_registro">;
  handleSubmitPreRegister: UseFormHandleSubmit<InputsPreRegister, undefined>;
}

export const PreRegisterContext = React.createContext({} as IFormPreRegister);

interface IFormsPreparationOfPlansProps {
  children: React.ReactNode;
}

const defaultValuePreRegister = {
  tema: null,
  titulo: '',
  descricao: '',
  estrutura: [''],
  // tipoPlano: null,
  enteResponsavel: null,
  informacoesGerais: [''],
};

type InputsPreRegister = {
  pre_registro: {
    tema: number;
    titulo: string;
    descricao: string;
    // tipoPlano: number;
    estrutura: string[];
    enteResponsavel: number;
    informacoesGerais: string[];
  }[];
};

const SchemaPreRegister = Yup.object().shape({
  pre_registro: Yup.array().of(
    Yup.object().shape({
      titulo: Yup.string().required('O título é obrigatório.'),
      descricao: Yup.string().required('A descrição é obrigatória.'),
      estrutura: Yup.array()
        .min(1, 'Não deve ser possível enviar uma estrutura preenchida.')
        .test(
          'no-empty-string',
          'Deve haver pelo menos uma estrutura preenchida.',
          (value) => value && value.every((item) => item.trim() !== '')
        ),
      informacoesGerais: Yup.array()
        .min(1, 'Deve haver pelo menos uma informação geral preenchida.')
        .test(
          'no-empty-string',
          'Deve haver pelo menos uma informação geral preenchida.',
          (value) => value && value.every((item) => item.trim() !== '')
        ),
      tema: Yup.number().required('O tema é obrigatório.'),
      enteResponsavel: Yup.number().required('A esfera governamental é obrigatória.'),
      // tipoPlano: Yup.number().required('O tipo do plano é obrigatório.'),
    })
  ),
});



export const PreRegisterProvider: React.FC<IFormsPreparationOfPlansProps> = ({ children }) => {
  const {
    watch: watchPreRegister,
    control: controlPreRegister,
    register: registerPreRegister,
    setValue: setValuePreRegister,
    handleSubmit: handleSubmitPreRegister,
    formState: { errors: errosPreRegister } } = useForm<InputsPreRegister>({
      resolver: yupResolver(SchemaPreRegister as any),
      defaultValues: { pre_registro: [defaultValuePreRegister as any] }
    });

  const { update: updatePreRegister, remove: removePreRegister } = useFieldArray({
    name: "pre_registro",
    control: controlPreRegister,
  });
  
  // const router = useRouter();

  // const { GetPreRegisterElaboration } = new ElaborationService();
  // const { completCode } = usePreparationOfPlans()

  // React.useEffect(() => {
  //   if (router.query.id && (router.pathname.includes('elaboracao_planos') || router.pathname.includes('aprovacao_planos'))) {
  //     GetPreRegisterElaboration(router.query.id).then((res: any) => {
  //       const { data } = res.data;
  //       setValuePreRegister('pre_registro.0.tema', data.tema.id);
  //       setValuePreRegister('pre_registro.0.titulo', data?.titulo);
  //       setValuePreRegister('pre_registro.0.descricao', data?.descricao);
  //       setValuePreRegister('pre_registro.0.enteResponsavel', data.enteResponsavel ? data.enteResponsavel.id : null);
  //       // setValuePreRegister('pre_registro.0.tipoPlano', data.tipoPlano ? data.tipoPlano.id : null);
  //       setValuePreRegister('pre_registro.0.estrutura', data?.estruturas?.map((item: any) => item.descricao ));
  //       setValuePreRegister('pre_registro.0.informacoesGerais', data?.informacoesGerais?.map((item: any) => item.descricao));
  //     });
  //   }
  // }, [completCode, router.query, router.pathname]);

  const value = {
    errosPreRegister,
    watchPreRegister,
    updatePreRegister,
    removePreRegister,
    controlPreRegister,
    registerPreRegister,
    setValuePreRegister,
    handleSubmitPreRegister,
  } as IFormPreRegister;

  return <PreRegisterContext.Provider value={value}>{children}</PreRegisterContext.Provider>;
};