import React from "react";
import { Container } from './styles';
import { Grid } from "@mui/material";
import { ActionButtonElaborations } from "../../ActionButton";
import { usePreparationOfPlans } from "@/hooks/elaboracao_planos";
import { findingMenuItemKey } from "@/contexts/elaboracao_planos/constants";
import { useElaborationCardItem } from "@/services/zustand/elaboration_card_item";

export const data = [
  {
    title: 'Dados Finais do Plano',
    description: `Revise e complete todos os detalhes finais do seu Plano. Certifique-se de que todas as informações estejam corretas e atualizadas para garantir a precisão e a integridade do documento.`,
  },
  {
    title: 'Vinculação com Objetivos e Metas de Longo Prazo',
    description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade.`,
  },
  {
    title: 'Verificação de Pendências',
    description: `Alinhe seu Plano com os objetivos, metas e macroações dos planos de longo prazo da prefeitura. Essa vinculação é essencial para garantir que seu Plano esteja em sintonia com a visão estratégica da cidade.`,
  },
  {
    title: 'Pré-visualização do Plano no Sistema',
    description: `Visualize uma prévia do Plano diretamente no sistema. Isso permite que você revise e valide todas as seções do documento, garantindo que tudo esteja conforme o esperado.`,
  },
  {
    title: 'Envio ao Órgão Aprovador',
    description: `Após a revisão e validação, envie seu Plano ao órgão aprovador responsável. Esse passo finaliza o processo, encaminhando o documento para aprovação e implementação.`
  }
]

export const ConclusionLayerForm = () => {
  const zustandSelectedCardItem = useElaborationCardItem(state => state.cardItem);
  const { itemsMenu, selectedItemsMenu, handlePermissionClickAndRenderMenuItem } = usePreparationOfPlans();
  return (
    <Container>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12}>
            <span>
              <b>
                {`${index + 1}. ${item.title}`}
              </b>
            </span>
            <p>
              {item.description}
            </p>
          </Grid>
        ))}
        <Grid item xs={12}>
          Complete essas etapas com atenção para garantir que seu Plano esteja completo, alinhado e pronto para aprovação.
        </Grid>
        <Grid item xs={12}>
          <div style={{ height: '1px', background: '#B4B4B4' }}></div>
        </Grid>
      </Grid>
      <ActionButtonElaborations onClick={() => {
        const plan = itemsMenu[findingMenuItemKey(zustandSelectedCardItem)] as Record<string, any>;
        const id = plan[selectedItemsMenu?.layer as string]
          ?.find((item: any) => item.id == selectedItemsMenu?.id).id + 1;
        handlePermissionClickAndRenderMenuItem({ id: id, plan, click: true })
      }} layer_indicator={selectedItemsMenu?.layer_indicator} isOptional={'false'} />
    </Container>
  )
}