import { useTheme } from "@/services/zustand/theme";

export const RodrigoFreitasGestao = () => {
  const theme = useTheme((state) => state.theme);
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 110C48.5833 110 39.0625 106.083 31.4375 98.25C23.8125 90.4167 20 80.6667 20 69C20 60.6667 23.3125 51.6042 29.9375 41.8125C36.5625 32.0208 46.5833 21.4167 60 10C73.4167 21.4167 83.4375 32.0208 90.0625 41.8125C96.6875 51.6042 100 60.6667 100 69C100 80.6667 96.1875 90.4167 88.5625 98.25C80.9375 106.083 71.4167 110 60 110ZM60 100C68.6667 100 75.8333 97.0625 81.5 91.1875C87.1667 85.3125 90 77.9167 90 69C90 62.9167 87.4792 56.0417 82.4375 48.375C77.3958 40.7083 69.9167 32.3333 60 23.25C50.0833 32.3333 42.6042 40.7083 37.5625 48.375C32.5208 56.0417 30 62.9167 30 69C30 77.9167 32.8333 85.3125 38.5 91.1875C44.1667 97.0625 51.3333 100 60 100ZM45 90H75V80H45V90ZM55 75H65V65H75V55H65V45H55V55H45V65H55V75Z"
        fill="url(#paint0_linear_2105_38556)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2105_38556"
          x1="38.5"
          y1="25"
          x2="89.8884"
          y2="91.5344"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color={theme === "light" ? "#004A80" :  'white'}  />
          <stop offset="1" stop-color={theme === "light" ? "#00C0F3"  :  'white'} />
        </linearGradient>
      </defs>
    </svg>
  );
};
