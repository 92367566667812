import React from "react";
import { InfoIncon } from "../info";
import { useRouter } from "next/router";
import { ConsideracaoIcon } from "../consideracao";
import { BootstrapInput, Container } from "./styled";
import { Tooltip, Select, MenuItem } from '@mui/material';
import { ConsiderationModal } from "../../Forms/ConsiderationModal";

interface Data {
  value: any,
  name: string
}

interface IProps {
  errors: any;
  data: Data[];
  name: string;
  register: any;
  label: string;
  onChange?: any;
  tooltip?: string;
  required: boolean;
  placeholder: string;
  onClick?: () => void;
  defaultValue?: string;
  commentTittle?: string;
  dataNotDisabled?: boolean,
  handleCreate?: () => void;
  setValue?: (value: any) => void;
}

export const SelectFieldElaboration: React.FC<IProps> = ({
  data,
  name,
  label,
  errors,
  tooltip,
  onClick,
  required,
  register,
  setValue,
  placeholder,
  defaultValue,
  commentTittle,
  handleCreate,
  dataNotDisabled = false,
  ...props
}) => {

  return (
    <Container data-not-disable={dataNotDisabled} className="do-not-disable">
      <div className="title_wrapper_textfield">
        <ToolTipWrapper
          name={name}
          label={label}
          tooltip={tooltip}
          required={required}
          register={register}
          value={defaultValue}
          commentTittle={commentTittle}
        />
      </div>
      <Select
        size="small"
        value={defaultValue}
        input={<BootstrapInput />}
        {...register(name, { required })}
        defaultValue={defaultValue || '0'}
        onClick={() => onClick && onClick()}
        onChange={(e) => setValue && setValue(e.target.value)}
        {...props}
      >
        <MenuItem sx={{ color: '#ADB5BD', fontSize: '14px' }} value='0' onClick={handleCreate}>{placeholder}</MenuItem>
        {data.map(item => ( <MenuItem value={item.value}>{item.name}</MenuItem> ))}
      </Select>
      <div className="error_wrapper">
        <span>{errors[name]?.message}</span>
      </div>
    </Container>
  )
}

type TooltipWrapperProps = {
  name?: string,
  register?: any,
  local?: string,
  value?: string,
  label?: string,
  tooltip?: string,
  required?: boolean,
  hiddeInput?: boolean,
  commentTittle?: string;
}

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.withe,
    backgroundColor: theme.palette.common.white,
  },
}));

const ToolTipWrapper = ({
  name,
  value,
  label,
  tooltip,
  required,
  register,
  commentTittle,
}: TooltipWrapperProps) => {

  const router = useRouter();
  const [epl, setEpl] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!router.isReady) return
    if (!router.query.id) return
    setEpl(router.asPath.includes('epl') && router.asPath.includes('dashboard/aprovacao_planos'));
  }, [router])


  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <label style={{ display: 'flex', alignItems: 'center' }} className="interFont">
        <p>{label}</p>
        <span>
          {required ? '*' : ''}
        </span>
      </label>
      {!epl &&
        <>
          {tooltip && (
            <LightTooltip title={tooltip}>
              <div>
                <InfoIncon />
              </div>
            </LightTooltip>
          )}
        </>
      }
      {epl && (
        <CondiserationWrapper
          name={name}
          label={label}
          value={value}
          register={register}
          commentTittle={commentTittle}
        />
      )}
    </div>
  )

}

const CondiserationWrapper = ({
  name,
  value,
  label,
  register,
  commentTittle,
}: {
  register?: any,
  label?: string,
  name?: string,
  value?: string,
  commentTittle?: string;
}) => {

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <a className="consideration-modal" onClick={() => { setOpen(true) }}>
        <ConsideracaoIcon />
      </a>
      <ConsiderationModal
        open={open}
        name={name}
        value={value}
        label={label}
        register={register}
        handleClose={handleClose}
        commentTittle={commentTittle}
      />
    </>
  )
}