import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';
import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ContinueButton, Container, ContentConsultElaborationWrapper, ContentConsultItemWrapper } from './styles.modal';

interface IProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}


export const DisclaimerReuseElaborationDialog: React.FC<IProps> = ({
  open,
  setOpen,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Container>
        <Dialog
          open={open}
          sx={{ mt: 10 }}
          maxWidth='md'
          onClose={handleClose}
        >
          <div style={{ padding: '0 27px', width: '875px' }}>
            <DialogTitle>
              Reutilizar conteúdo
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: '#004A80'
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ mt: -4 }}>
              <ContentConsultElaborationWrapper>
                <ContentConsultItemWrapper>
                  O Plano selecionado como base não possui este elemento em sua estrutura original.
                </ContentConsultItemWrapper>
              </ContentConsultElaborationWrapper>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'center', margin: '2rem 0' }}>
              <ContinueButton type='button' onClick={handleClose}>
                <p>OK</p>
              </ContinueButton>
            </DialogActions>
          </div>
        </Dialog>
      </Container>
    </>
  );
}
