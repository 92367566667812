
import PersonIcon from './PersonIcon';
import { useAuth } from '@/hooks/useAuth';
import { Container, Title } from './styles';

interface IProps {
  color?: string;
}

export function ButtonSair({ color }: IProps) {
  const { name } = useAuth();

  return (
    <Container className='enter_button'>
      {/* <PersonIcon color={'white'} /> */}
      <Title className='resizeable-accessibility enter_button_h1'>
        {/* Olá, {name} */}
      </Title>
    </Container>
  );
}
