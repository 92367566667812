import _ from 'lodash';
import React from "react";
import { ButtonWrapperCancel, ContentFinalWrapper } from './styles';
import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { CloseIcon } from "@/components/gestao_planos_dashboard/svgs/close";

export const FinalDialog = ({ open = false, handleClose }: { open: boolean, handleClose: any }) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
            <CloseIcon onClose={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={12} display='flex' flexDirection='column' gap='10px'>
              <ContentFinalWrapper>
                <p>Seu plano foi enviado com sucesso.</p>
                <p>Aguarde a revisão.</p>
              </ContentFinalWrapper>
            </Grid>
            <Grid item xs={12} mt={2} display='flex' justifyContent='center' gap='15px'>
              <ButtonWrapperCancel color='#004A80' onClick={handleClose}>
                <p>OK</p>
              </ButtonWrapperCancel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
